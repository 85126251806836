<script setup>
import FormSection from '@/Components/FormSection.vue';
import { useForm } from '@inertiajs/vue3';
import UserCombobox from '@/Components/UserCombobox.vue';
import { trans as $t } from 'laravel-vue-i18n';
import { ref } from 'vue';

const props = defineProps({
    customerId: {
        type: Number,
        required: true,
    },
    users: {
        type: Array,
        required: true,
    },
});

const form = useForm({
    users: props.users.map((user) => user.id),
});

const submitUserList = () => {
    form.post(route('customer.user.store', { customer: props.customerId }));
};

const userListSelect = ref(null);
const cancel = () => {
    userListSelect.value.reset();
};
</script>

<template>
    <FormSection @submitted="submitUserList">
        <template #title>
            {{ $t('Users') }}
        </template>
        <template #description>
            {{
                $t('Update the list of users that are acting as this Customer.')
            }}
        </template>
        <template #form>
            <UserCombobox
                ref="userListSelect"
                v-model="form.users"
                :selected="props.users"
            ></UserCombobox>
        </template>
        <template #actions>
            <v-btn
                :disabled="!form.isDirty"
                variant="elevated"
                type="submit"
                color="primary"
            >
                {{ $t('Save') }}
            </v-btn>
            <v-btn
                class="tw-ml-2"
                color="danger"
                variant="elevated"
                :disabled="!form.isDirty"
                @click="cancel"
                >{{ $t('Cancel') }}
            </v-btn>
        </template>
    </FormSection>
</template>

<style scoped></style>
