<script setup>
import { collect } from 'collect.js';
import PackagingTableRow from '@/Pages/Pack/Partials/PackagingTableRow.vue';
import { computed } from 'vue';

const props = defineProps({
    deliveryNote: {
        type: Object,
        required: true,
    },
    scansByLine: {
        type: Object,
        required: true,
    },
    withoutBinCode: {
        type: Boolean,
        required: true,
    },
});

const emit = defineEmits(['select']);

const lines = computed(() => {
    if (props.withoutBinCode) {
        let groupedByItemBatchUnit = collect(props.deliveryNote.lines)
            .groupBy((line) => line.item_number + line.batch + line.unit)
            .map((lineGroup) => {
                return lineGroup.reduce(
                    (carry, line) => {
                        carry.quantity += line.quantity;
                        return carry;
                    },
                    {
                        ...lineGroup.first(),
                        line_number: lineGroup.pluck('line_number').toArray(),
                        bin_code: null,
                        quantity: 0,
                    },
                );
            });

        return groupedByItemBatchUnit.toArray();
    }

    return props.deliveryNote.lines;
});

const scansByItemBatchUnit = computed(() => {
    if (!props.withoutBinCode) return null;

    return collect(lines.value)
        .mapWithKeys((line) => {
            const key = line.item_number + line.batch + line.unit;

            let scans = collect(line.line_number)
                .map((lineNumber) => {
                    return props.scansByLine[lineNumber] ?? [];
                })
                .flatten(1)
                .toArray();

            return [key, scans];
        })
        .all();
});

const getScansForLine = (line) => {
    if (props.withoutBinCode) {
        return (
            scansByItemBatchUnit.value[
                line.item_number + line.batch + line.unit
            ] ?? []
        );
    }

    return props.scansByLine[line.line_number] ?? [];
};

const onSelect = (line, customQuantity) => {
    if (props.withoutBinCode) {
        const lineToScan = collect(props.deliveryNote.lines)
            .filter((l) => line.line_number.includes(l.line_number))
            .filter(
                (l) =>
                    collect(props.scansByLine[l.line_number]).sum('quantity') <
                    l.quantity,
            )
            .first();

        // if no line with unscanned quantity is found, select the last line
        emit(
            'select',
            lineToScan ??
                props.deliveryNote.lines.find(
                    (l) =>
                        l.line_number ===
                        line.line_number[line.line_number.length - 1],
                ),
            customQuantity,
        );
        return;
    }
    emit('select', line, customQuantity);
};
</script>

<template>
    <tbody>
        <PackagingTableRow
            v-for="(line, index) in lines"
            :key="line.line_number"
            :line="line"
            :index="index"
            :scans="getScansForLine(line)"
            @select="onSelect"
        ></PackagingTableRow>
        <tr>
            <th class="tw-p-4 tw-text-right tw-text-xl tw-font-bold">
                Artikel zu scannen:
                {{ collect(deliveryNote.lines).sum('quantity') }}
            </th>
        </tr>
    </tbody>
</template>

<style scoped></style>
