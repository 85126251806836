<script setup>
import { mdiFileExcel, mdiFilePdfBox } from '@mdi/js';
import { computed } from 'vue';
import ValidationErrorCard from '@/Components/ValidationErrorCard.vue';
import { usePage } from '@inertiajs/vue3';
import moment from 'moment';
import { is_empty_or_null, join_names } from '@/lib/helpers';
import OrderTimeline from '@/Pages/Order/Partials/OrderTimeline.vue';

const props = defineProps({
    order: {
        type: Object,
        required: true,
    },
    showCustomerNumber: {
        type: Boolean,
        default: false,
    },
});
const page = usePage();

const orderIdentification = computed(() => {
    if (
        props.order?.reference !== null &&
        props.order.reference.trim() !== ''
    ) {
        const ident = join_names(
            props.order.reference,
            props.order.reference2,
            ' - ',
        );

        return (
            `${ident}` +
            (props.order.order_number ? ` (${props.order.order_number})` : '')
        );
    }
    return props.order.order_number;
});

const orderDate = computed(() => {
    return Object.prototype.hasOwnProperty.call(props.order, 'document_date')
        ? moment(props.order.document_date).format('DD.MM.YYYY')
        : null;
});
const createdAt = computed(() => {
    return Object.prototype.hasOwnProperty.call(props.order, 'created_at')
        ? moment(props.order.created_at).format('DD.MM.YYYY HH:mm')
        : null;
});

const trackingLinks = computed(() => {
    const links = props.order.tracking?.split(',');
    const trackingNumbers = {};

    if (links) {
        links.forEach((link) => {
            const linkMatch = link.match(/=([a-zA-Z0-9-]+)/);
            if (linkMatch !== null) trackingNumbers[linkMatch[1]] = link;
        });
    }

    return trackingNumbers;
});

const linesHaveBatch = computed(() => {
    return props.order.lines.some((line) => line.batch !== null);
});
const getLineDescription = (line) => {
    if (line.description !== undefined) {
        return line.description2
            ? line.description + ', ' + line.description2
            : line.description;
    } else if (line.item !== undefined) {
        return line.item.description2
            ? line.item.description + ', ' + line.item.description2
            : line.item.description;
    } else {
        return '';
    }
};

const hasShippingOptions = computed(
    () =>
        page.props.customer_settings[props.order.customer_number]
            ?.has_shipping_options,
);
const requestedDeliveryDate = computed(() =>
    moment(props.order.requested_delivery_date).format('DD.MM.YYYY'),
);
const needsAvisoFields = computed(
    () =>
        page.props.customer_settings[props.order.customer_number]
            ?.needs_aviso_fields,
);
const showLiftPlatform = computed(() => {
    switch (props.order.servicelevel) {
        case 'ABHOLUNG':
        case 'TERMIN':
            return true;
    }
    return false;
});
</script>

<template>
    <v-row>
        <v-col>
            <ValidationErrorCard
                v-if="
                    order.validation_errors &&
                    Object.keys(order.validation_errors).length > 0
                "
                class="tw-mb-4"
                :errors="order.validation_errors"
            ></ValidationErrorCard>
            <div
                class="tw-rounded-md tw-border tw-border-solid tw-border-gray-200 tw-py-2 tw-shadow-md tw-shadow-gray-200"
            >
                <table class="tw-w-full tw-border-collapse">
                    <tbody>
                        <!--                        <tr class="even:tw-bg-gray-50 tw-align-top">-->
                        <!--                            <th class="tw-px-4 tw-py-2 tw-text-left tw-pr-4">-->
                        <!--                                {{ $t('Customer') }}-->
                        <!--                            </th>-->
                        <!--                            <td class=tw-py-2>{{ order.customer_number }}</td>-->
                        <!--                        </tr>-->
                        <tr class="tw-align-top even:tw-bg-gray-50">
                            <th class="tw-px-4 tw-py-1 tw-text-left">
                                {{ $t('Order') }}
                            </th>
                            <td class="tw-py-2">{{ orderIdentification }}</td>
                        </tr>
                        <tr
                            v-if="order.delivery_status"
                            class="tw-align-top even:tw-bg-gray-50"
                        >
                            <th class="tw-px-4 tw-py-1 tw-text-left">
                                {{ $t('Delivery Status') }}
                            </th>
                            <td class="tw-py-2">{{ order.delivery_status }}</td>
                        </tr>
                        <tr
                            v-if="Object.keys(trackingLinks).length > 0"
                            class="tw-align-top even:tw-bg-gray-50"
                        >
                            <th class="tw-px-4 tw-py-1 tw-text-left">
                                {{ $t('Tracking') }}
                            </th>
                            <td class="tw-py-2">
                                <span
                                    v-for="trackingNumber in Object.keys(
                                        trackingLinks,
                                    )"
                                    :key="trackingNumber"
                                    ><a
                                        target="_blank"
                                        :href="trackingLinks[trackingNumber]"
                                        >{{ trackingNumber }}</a
                                    ><br
                                /></span>
                            </td>
                        </tr>
                        <tr
                            v-if="orderDate"
                            class="tw-align-top even:tw-bg-gray-50"
                        >
                            <th class="tw-px-4 tw-py-1 tw-text-left">
                                {{ $t('Order Date') }}
                            </th>
                            <td class="tw-py-1">{{ orderDate }}</td>
                        </tr>
                        <tr
                            v-else-if="createdAt"
                            class="tw-align-top even:tw-bg-gray-50"
                        >
                            <th class="tw-px-4 tw-py-1 tw-text-left">
                                {{ $t('Creation Date') }}
                            </th>
                            <td class="tw-py-1">{{ createdAt }}</td>
                        </tr>
                        <tr class="tw-align-top even:tw-bg-gray-50">
                            <th class="tw-px-4 tw-py-1 tw-text-left">
                                {{ $t('Service Level') }}
                            </th>
                            <td class="tw-py-1">{{ order.servicelevel }}</td>
                        </tr>
                        <tr class="tw-align-top even:tw-bg-gray-50">
                            <th class="tw-px-4 tw-py-1 tw-text-left">
                                {{ $t('Name') }}
                            </th>
                            <td class="tw-py-1">
                                {{ order.name }}
                                {{ order.name2 ? ', ' + order.name2 : '' }}
                                <br />
                            </td>
                        </tr>
                        <tr class="tw-align-top even:tw-bg-gray-50">
                            <th class="tw-px-4 tw-py-1 tw-text-left">
                                {{ $t('Address') }}
                            </th>
                            <td class="tw-py-1">
                                {{ order.address }}
                                {{
                                    order.address2 ? ', ' + order.address2 : ''
                                }}
                                <br />
                                {{ order.postal_code }} {{ order.city }} <br />
                                {{ order.country }}
                            </td>
                        </tr>
                        <tr
                            v-if="hasShippingOptions"
                            class="tw-align-top even:tw-bg-gray-50"
                        >
                            <th class="tw-px-4 tw-py-1 tw-text-left">
                                {{ $t('Requested Delivery Date') }}
                            </th>
                            <td class="tw-py-1">{{ requestedDeliveryDate }}</td>
                        </tr>
                        <tr
                            v-if="!is_empty_or_null(order.email)"
                            class="tw-align-top even:tw-bg-gray-50"
                        >
                            <th class="tw-px-4 tw-py-1 tw-text-left">
                                {{ $t('E-Mail') }}
                            </th>
                            <td class="tw-py-1">{{ order.email }}</td>
                        </tr>
                        <tr
                            v-if="!is_empty_or_null(order.contact)"
                            class="tw-align-top even:tw-bg-gray-50"
                        >
                            <th class="tw-px-4 tw-py-1 tw-text-left">
                                {{ $t('Contact Person') }}
                            </th>
                            <td class="tw-py-1">{{ order.contact }}</td>
                        </tr>
                        <tr
                            v-if="!is_empty_or_null(order.phone)"
                            class="tw-align-top even:tw-bg-gray-50"
                        >
                            <th class="tw-px-4 tw-py-1 tw-text-left">
                                {{ $t('Phone') }}
                            </th>
                            <td class="tw-py-1">{{ order.phone }}</td>
                        </tr>
                        <tr
                            v-if="needsAvisoFields"
                            class="tw-align-top even:tw-bg-gray-50"
                        >
                            <th class="tw-px-4 tw-py-1 tw-text-left">
                                {{ $t('Aviso Type') }}
                            </th>
                            <td class="tw-py-1">{{ order.aviso_type }}</td>
                        </tr>
                        <tr
                            v-if="needsAvisoFields"
                            class="tw-align-top even:tw-bg-gray-50"
                        >
                            <th class="tw-px-4 tw-py-1 tw-text-left">
                                {{ $t('Aviso E-Mail/Telephone') }}
                            </th>
                            <td class="tw-py-1">
                                {{ order.aviso_destination }}
                            </td>
                        </tr>
                        <tr
                            v-if="needsAvisoFields"
                            class="tw-align-top even:tw-bg-gray-50"
                        >
                            <th class="tw-px-4 tw-py-1 tw-text-left">
                                {{ $t('Aviso Contact') }}
                            </th>
                            <td class="tw-py-1">{{ order.aviso_contact }}</td>
                        </tr>
                        <tr
                            v-if="needsAvisoFields"
                            class="tw-align-top even:tw-bg-gray-50"
                        >
                            <th class="tw-px-4 tw-py-1 tw-text-left">
                                {{ $t('Number of Pallets') }}
                            </th>
                            <td class="tw-py-1">
                                {{ order.number_of_pallets }}
                            </td>
                        </tr>
                        <tr
                            v-if="hasShippingOptions"
                            class="tw-align-top even:tw-bg-gray-50"
                        >
                            <th class="tw-px-4 tw-py-1 tw-text-left">
                                {{ $t('Shipping Agent') }}
                            </th>
                            <td class="tw-py-1">
                                {{ order.shipping_agent_code }}
                            </td>
                        </tr>
                        <tr
                            v-if="hasShippingOptions"
                            class="tw-align-top even:tw-bg-gray-50"
                        >
                            <th class="tw-px-4 tw-py-1 tw-text-left">
                                {{ $t('Shipping Service') }}
                            </th>
                            <td class="tw-py-1">
                                {{ order.shipping_agent_service_code }}
                            </td>
                        </tr>
                        <tr
                            v-if="needsAvisoFields && showLiftPlatform"
                            class="tw-align-top even:tw-bg-gray-50"
                        >
                            <th class="tw-px-4 tw-py-1 tw-text-left">
                                {{ $t('Lift Platform') }}
                            </th>
                            <td class="tw-py-1">
                                {{ order.lift_platform ? $t('Yes') : $t('No') }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div
                class="tw-mt-4 tw-overflow-x-auto tw-rounded-md tw-border tw-border-solid tw-border-gray-200 tw-py-1 tw-shadow-md tw-shadow-gray-200"
            >
                <table class="tw-w-full tw-border-collapse">
                    <thead>
                        <tr class="tw-align-top even:tw-bg-gray-50">
                            <th class="tw-px-4 tw-py-1 tw-text-left">SKU</th>
                            <th class="tw-px-0 tw-py-1 tw-pr-4 tw-text-left">
                                Maillog SKU
                            </th>
                            <th class="tw-px-0 tw-py-1 tw-pr-4 tw-text-left">
                                {{ $t('Description') }}
                            </th>
                            <th
                                v-if="linesHaveBatch"
                                class="tw-px-0 tw-py-1 tw-pr-4 tw-text-left"
                            >
                                {{ $t('Batch') }}
                            </th>
                            <th class="tw-px-0 tw-py-1 tw-pr-4 tw-text-right">
                                {{ $t('Quantity') }}
                            </th>
                            <th class="tw-px-0 tw-py-1 tw-pr-4 tw-text-left">
                                {{ $t('Unit') }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(line, index) in order.lines"
                            :key="index"
                            class="tw-align-top even:tw-bg-gray-50"
                        >
                            <td class="tw-px-4 tw-py-1 tw-text-left">
                                {{ line.sku }}
                            </td>
                            <td class="tw-px-0 tw-py-1 tw-pr-4 tw-text-left">
                                {{ line.item_number }}
                            </td>
                            <td class="tw-px-0 tw-py-1 tw-pr-4 tw-text-left">
                                {{ getLineDescription(line) }}
                            </td>
                            <td
                                v-if="linesHaveBatch"
                                class="tw-px-0 tw-py-1 tw-pr-4 tw-text-left"
                            >
                                {{ line.batch }}
                            </td>
                            <td class="tw-px-0 tw-py-1 tw-pr-4 tw-text-right">
                                {{ line.quantity }}
                            </td>
                            <td class="tw-px-0 tw-py-1 tw-pr-4 tw-text-left">
                                {{ line.unit }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div
                v-if="
                    (order.documents && order.documents.length > 0) ||
                    order.has_packing_list
                "
                class="tw-mt-4 tw-overflow-x-auto tw-rounded-md tw-border tw-border-solid tw-border-gray-200 tw-py-1 tw-shadow-md tw-shadow-gray-200"
            >
                <div
                    v-if="order.has_packing_list"
                    class="tw-flex tw-items-center tw-px-4"
                >
                    <v-icon
                        :icon="mdiFileExcel"
                        class="tw-mr-2 tw-text-green-700"
                    ></v-icon>
                    <a
                        :href="
                            route('pack.packing-list', {
                                pack_process: order.delivery_note_number,
                            })
                        "
                        >Packliste_{{ order.delivery_note_number }}.xlsx</a
                    >
                </div>
                <div
                    v-for="(document, index) in order.documents"
                    :key="index"
                    class="tw-flex tw-items-center tw-px-4"
                >
                    <v-icon
                        :icon="mdiFilePdfBox"
                        class="tw-mr-2 tw-text-red-600"
                    ></v-icon>
                    <a
                        v-if="order.order_number"
                        :href="
                            route('order.document.show', {
                                order: order.order_number,
                                document: document.id,
                            })
                        "
                        target="_blank"
                        >{{ document.filename }}</a
                    >
                    <span v-else>{{ document.filename }}</span>
                </div>
            </div>
        </v-col>
        <v-col
            v-if="
                order.status_events !== undefined &&
                order.status_events.length > 0
            "
            md="4"
        >
            <OrderTimeline :order="order"></OrderTimeline>
        </v-col>
    </v-row>
</template>

<style scoped></style>
