<script setup>
import { computed, inject, onMounted, ref } from 'vue';
import GoodsIssueScansCard from '@/Pages/GoodsIssue/Partials/GoodsIssueScansCard.vue';
import { trans as $t } from 'laravel-vue-i18n';

const props = defineProps({
    params: {
        type: Object,
        required: true,
    },
});

const confirm = inject('confirm');

const orderDataLoading = ref(true);
const order = ref(null);
const scans = ref([]);
onMounted(async () => {
    const response = await axios.get(
        route('api.goods-issue.show', {
            sales_shipment_header: props.params.data.delivery_note_number,
        }),
    );
    orderDataLoading.value = false;
    order.value = response.data.order;
    scans.value = response.data.scans;
    console.log(response.data);
});

const allScanned = computed(() => {
    console.log(
        'allScanned',
        !scans.value.some((scan) => scan.scanned_at === null),
        scans.value,
    );
    return !scans.value.some((scan) => scan.scanned_at === null);
});
const anyScanned = computed(() => {
    return scans.value.some((scan) => scan.scanned_at !== null);
});

const resettingShipment = ref(false);
const reset = async () => {
    const reset = await confirm.value(
        $t('reset-shipment.confirmation', {
            shipment_number: scans.value[0].shipment_number,
        }),
        $t('Reset Shipment'),
    );
    resettingShipment.value = true;
    if (!reset) {
        return;
    }

    const { data } = await axios.put(
        route('api.goods-issue.reset', {
            shipment_number: scans.value[0].shipment_number,
        }),
    );

    scans.value = data.scans;
    order.value = data.order;

    resettingShipment.value = false;
};

const confirmingShipment = ref(false);
const confirmShipment = async () => {
    if (allScanned.value) {
        return;
    }

    confirmingShipment.value = true;
    const { data } = await axios.put(
        route('api.goods-issue.confirm', {
            shipment_number: scans.value[0].code,
        }),
    );

    scans.value = data.scans;
    order.value = data.order;

    confirmingShipment.value = false;
};
</script>

<template>
    <div class="tw-p-2">
        <GoodsIssueScansCard
            v-if="order !== null"
            :scans="scans"
            :order="order"
        >
            <template v-if="scans.length > 0" #button>
                <v-btn
                    color="primary"
                    class="tw-mr-2"
                    :loading="confirmingShipment"
                    :disabled="allScanned"
                    @click="confirmShipment"
                    >{{ $t('Scanned') }}</v-btn
                >
                <v-btn
                    color="danger"
                    :loading="resettingShipment"
                    :disabled="!anyScanned"
                    @click="reset"
                    >{{ $t('Reset') }}</v-btn
                >
            </template>
        </GoodsIssueScansCard>
        <div v-else class="tw-flex tw-h-64 tw-items-center tw-justify-center">
            <v-progress-circular
                indeterminate
                color="primary"
            ></v-progress-circular>
        </div>
    </div>
</template>

<style scoped></style>
