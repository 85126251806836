<script setup>
import { ref } from 'vue';
import { Head, useForm } from '@inertiajs/vue3';
import AuthenticationCard from '@/Components/AuthenticationCard.vue';
import TextField from '@/Components/TextField.vue';

const form = useForm({
    password: '',
});

const passwordInput = ref(null);

const submit = () => {
    form.post(route('password.confirm'), {
        onError: () => {
            form.reset();
            passwordInput.value.focus();
        },
    });
};
</script>

<template>
    <Head :title="$t('Secure Area')" />

    <AuthenticationCard>
        <div class="tw-mb-4 tw-text-sm tw-text-gray-600">
            {{
                $t(
                    'This is a secure area of the application. Please confirm your password before continuing.'
                )
            }}
        </div>

        <v-form @submit.prevent="submit">
            <TextField
                ref="passwordInput"
                v-model="form.password"
                type="password"
                :error-messages="form.errors.password"
                :label="$t('Password')"
                autocomplete="current-password"
                required
            ></TextField>
            <div class="tw-flex tw-justify-end tw-mt-4">
                <v-btn color="primary" type="submit" :loading="form.processing">
                    {{ $t('Confirm') }}
                </v-btn>
            </div>
        </v-form>
    </AuthenticationCard>
</template>
