<script setup>
import { AgGridVue } from 'ag-grid-vue3';
import defaultColumnDefinitions from '@/lib/ag-grid/default-column-definitions';
import { trans as $t } from 'laravel-vue-i18n';
import { computed } from 'vue';
import { undot } from '@/lib/helpers';
import { usePage } from '@inertiajs/vue3';

defineProps({
    rowData: {
        type: Array,
        required: true,
    },
});
const page = usePage();

const columnDefs = computed(() => [
    {
        headerName: $t('Article'),
        valueGetter(params) {
            if (params.data.sku) {
                return (
                    params.data.sku +
                    (params.data.item_number
                        ? ` (${params.data.item_number})`
                        : '')
                );
            } else {
                return params.data.item_number;
            }
        },
    },
    {
        headerName: $t('Description'),
        field: 'description',
    },
    {
        headerName: $t('Quantity'),
        type: 'rightAligned',
        field: 'quantity',
    },
    {
        headerName: $t('Unit'),
        field: 'unit',
    },
]);

const lineErrors = computed(() => {
    if (page.props.errorBags.default) {
        const errors = undot(page.props.errorBags.default);
        return errors.lines ?? {};
    }
    return {};
});
</script>

<template>
    <ag-grid-vue
        class="ag-theme-material"
        dom-layout="autoHeight"
        :default-col-def="defaultColumnDefinitions"
        :column-defs="columnDefs"
        :row-data="rowData"
    />
    <div
        v-if="Object.keys(lineErrors).length > 0"
        class="tw-rounded-lg tw-border tw-border-solid tw-border-gray-300 tw-bg-gray-100 tw-p-4"
    >
        <p
            v-for="line_key in Object.keys(lineErrors)"
            :key="line_key"
            class="tw-text-sm tw-italic tw-text-red-500"
        >
            <template
                v-for="(error, index) in lineErrors[line_key]['No_']"
                :key="`${line_key}_${index}`"
            >
                <span> {{ error }} </span><br />
            </template>
        </p>
    </div>
</template>

<style scoped></style>
