<script setup>
import TextField from '@/Components/TextField.vue';
import { computed, reactive, ref, watch } from 'vue';
import {
    dateValidation,
    eanValidation,
    micValidation,
    serialNumberValidation,
    skuValidation,
    upcValidation,
} from '@/lib/utilities/pack-serial-number-validations';

const props = defineProps({
    line: {
        type: Object,
        required: true,
    },
});
const emit = defineEmits(['scan']);

const serialScanTemplate = {
    sku: null,
    serial_number: null,
    ean: null,
    upc: null,
    date: null,
    mic1: null,
    mic2: null,
};
const project = computed(() => {
    return props.line?.item?.project;
});
const currentScan = ref({ ...serialScanTemplate });

const inputRefs = reactive({
    sku_input: {
        field: 'sku',
        ref: null,
        disabled: false,
        nextInput: 'ean_input',
        validator: skuValidation,
    },
    serial_input: {
        field: 'serial_number',
        ref: null,
        disabled: false,
        nextInput: 'date_input',
        validator: serialNumberValidation,
    },
    ean_input: {
        field: 'ean',
        ref: null,
        disabled: false,
        nextInput: 'upc_input',
        validator: eanValidation,
    },
    upc_input: {
        field: 'upc',
        ref: null,
        disabled: false,
        nextInput: 'serial_input',
        validator: upcValidation,
    },
    date_input: {
        field: 'date',
        ref: null,
        disabled: false,
        nextInput: 'mic1_input',
        validator: dateValidation,
    },
    mic1_input: {
        field: 'mic1',
        ref: null,
        disabled: false,
        nextInput: project.value === 'SN6' ? null : 'mic2_input',
        validator: micValidation,
    },
    mic2_input: {
        field: 'mic2',
        ref: null,
        disabled: project.value === 'SN6',
        nextInput: null,
        validator: micValidation,
    },
});

const completed = computed(() => {
    return Object.keys(inputRefs).every((key) => {
        return inputRefs[key].ref === null || inputRefs[key].disabled;
    });
});

watch(completed, (value) => {
    if (value) {
        saveSingleScan();
    }
});

const validateInput = (input) => {
    if (input.validator.validate(currentScan.value[input.field], props.line)) {
        if (input.nextInput) {
            inputRefs[input.nextInput].ref.focus();
        }
        input.disabled = true;
    } else {
        currentScan.value[input.field] = null;
    }
};

const focusFirstInput = () => {
    inputRefs.sku_input.ref.focus();
};

const saveSingleScan = () => {
    emit('scan', { ...currentScan.value });
    reset();
};

const reset = () => {
    currentScan.value = {
        ...serialScanTemplate,
    };

    Object.keys(inputRefs).forEach((key) => {
        inputRefs[key].disabled = false;
    });
};

defineExpose({
    focusFirstInput,
    reset,
});
</script>

<template>
    <v-window-item value="single">
        <TextField
            :ref="(el) => (inputRefs.sku_input.ref = el)"
            v-model="currentScan.sku"
            density="compact"
            label="SKU"
            hide-details
            autofocus
            :disabled="inputRefs.sku_input.disabled"
            @keydown.enter="validateInput(inputRefs.sku_input)"
        ></TextField>
        <TextField
            :ref="(el) => (inputRefs.ean_input.ref = el)"
            v-model="currentScan.ean"
            hide-details
            density="compact"
            label="EAN"
            :disabled="inputRefs.ean_input.disabled"
            @keydown.enter="validateInput(inputRefs.ean_input)"
        ></TextField>
        <TextField
            :ref="(el) => (inputRefs.upc_input.ref = el)"
            v-model="currentScan.upc"
            hide-details
            density="compact"
            label="UPC"
            :disabled="inputRefs.upc_input.disabled"
            @keydown.enter="validateInput(inputRefs.upc_input)"
        ></TextField>
        <TextField
            :ref="(el) => (inputRefs.serial_input.ref = el)"
            v-model="currentScan.serial_number"
            density="compact"
            label="S/N"
            hide-details
            :disabled="inputRefs.serial_input.disabled"
            @keydown.enter="validateInput(inputRefs.serial_input)"
        ></TextField>
        <TextField
            :ref="(el) => (inputRefs.date_input.ref = el)"
            v-model="currentScan.date"
            hide-details
            density="compact"
            :label="$t('Date')"
            :disabled="inputRefs.date_input.disabled"
            @keydown.enter="validateInput(inputRefs.date_input)"
        ></TextField>
        <TextField
            :ref="(el) => (inputRefs.mic1_input.ref = el)"
            v-model="currentScan.mic1"
            hide-details
            density="compact"
            :disabled="inputRefs.mic1_input.disabled"
            label="MIC 1"
            @keydown.enter="validateInput(inputRefs.mic1_input)"
        ></TextField>
        <TextField
            v-if="project === 'SN7'"
            :ref="(el) => (inputRefs.mic2_input.ref = el)"
            v-model="currentScan.mic2"
            hide-details
            density="compact"
            label="MIC 2"
            :disabled="inputRefs.mic2_input.disabled"
            @keydown.enter="validateInput(inputRefs.mic2_input)"
        ></TextField>
    </v-window-item>
</template>

<style scoped></style>
