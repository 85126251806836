export default {
    textColumnFilter: {
        filter: 'agTextColumnFilter',
        filterParams: {
            filterOptions: ['contains'],
            maxNumConditions: 1,
        },
        floatingFilter: true,
        suppressFloatingFilterButton: true,
    },
    dateColumnFilter: {
        filter: 'agDateColumnFilter',
        filterParams: {
            filterOptions: [
                {
                    displayKey: 'equals',
                    displayName: 'Equals',
                    predicate() {},
                },
                {
                    displayKey: 'greaterThan',
                    displayName: 'After',
                    predicate() {},
                },
                {
                    displayKey: 'lessThan',
                    displayName: 'Before',
                    predicate() {},
                },
                {
                    displayKey: 'inRange',
                    displayName: 'In Range',
                    numberOfInputs: 2,
                    predicate() {},
                },
            ],
            maxNumConditions: 1,
        },
        floatingFilter: true,
    },
    numberColumnFilter: {
        headerClass: 'ag-right-aligned-header',
        cellClass: 'ag-right-aligned-cell',
        filter: 'agNumberColumnFilter',
        filterParams: {
            filterOptions: [
                {
                    displayKey: 'equals',
                    displayName: 'Equals',
                    predicate() {},
                },
                {
                    displayKey: 'greaterThan',
                    displayName: 'Greater Than',
                    predicate() {},
                },
                {
                    displayKey: 'lessThan',
                    displayName: 'Less Than',
                    predicate() {},
                },
                {
                    displayKey: 'inRange',
                    displayName: 'In Range',
                    numberOfInputs: 2,
                    predicate() {},
                },
            ],
            maxNumConditions: 1,
        },
        floatingFilter: true,
    },
    actionColumn: {
        sortable: false,
        cellClass: 'tw-px-0',
        minWidth: 55,
    },
};
