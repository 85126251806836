import { createInjectionState } from '@vueuse/core';
import { ref } from 'vue';
const [useProvideColloStore, useColloStore] = createInjectionState(() => {
    const colloDetails = ref({});
    const savedDimensions = ref({});

    const addDimensions = (colloId, dimensions) => {
        savedDimensions.value = {
            ...savedDimensions.value,
            [colloId]: dimensions,
        };
    };

    return { colloDetails, savedDimensions, addDimensions };
});

export { useProvideColloStore, useColloStore };
