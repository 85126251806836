<script setup>
import { useStockStats } from '@/lib/services/dashboard-service';
import { mdiMail, mdiPhone, mdiTruck } from '@mdi/js';
import { usePage } from '@inertiajs/vue3';
import DashboardCard from '@/Pages/Dashboard/Widgets/DashboardCard.vue';

const page = usePage();

const { data: stockStats } = useStockStats();
</script>

<template>
    <DashboardCard>
        <template #title>
            <v-icon :icon="mdiTruck" class="tw-mr-2"></v-icon>
            {{ $t('Status') }}
        </template>
        <v-card-text v-if="stockStats">
            <p>
                {{ $t('dashboard.status.term1') }}
                <b>{{ stockStats.article_count.toLocaleString() }}</b>
                {{ $t('dashboard.status.term2') }}
            </p>
            <p :class="stockStats.open_orders > 0 ? 'tw-mb-2' : 'tw-mb-4'">
                {{ $t('dashboard.status.term3') }}
                <b>{{ stockStats.article_quantity.toLocaleString() }}</b>
                {{ $t('dashboard.status.term4') }}
            </p>
            <p v-if="stockStats.open_orders > 0" class="tw-mb-4">
                {{ $t('dashboard.order-status.term1') }}
                <b>{{ stockStats.open_orders.toLocaleString() }}</b>
                {{ $t('dashboard.order-status.term2') }}
            </p>
            <p class="tw-mb-2">
                {{ $t('dashboard.status.term5') }}
            </p>
            <p class="tw-mb-2 tw-leading-6">
                <v-icon :icon="mdiMail" class="tw-mr-2"></v-icon>
                <a :href="'mailto:' + page.props.logisticsEmail">{{
                    page.props.logisticsEmail
                }}</a>
                <br />
                <v-icon :icon="mdiPhone"></v-icon
                ><a :href="'tel:' + page.props.phone">{{ page.props.phone }}</a>
            </p>
            <p>{{ $t('dashboard.greeting') }}</p>
        </v-card-text>
        <v-card-text v-else class="tw-h-[240px] tw-p-2">
            <v-skeleton-loader
                type="paragraph,paragraph,sentences"
            ></v-skeleton-loader>
        </v-card-text>
    </DashboardCard>
</template>

<style scoped></style>
