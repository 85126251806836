<script setup>
import { Head, router, usePage, useRemember } from '@inertiajs/vue3';
import AgTableCard from '@/Components/AgTableCard.vue';
import { mdiFileExcel, mdiMagnify, mdiPackageVariant } from '@mdi/js';
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import columnTypes from '@/lib/ag-grid/column-types';
import defaultColumnDefinitions from '@/lib/ag-grid/default-column-definitions';
import { trans as $t } from 'laravel-vue-i18n';
import { AgGridVue } from 'ag-grid-vue3';
import {
    columnStateEvents,
    getColumnState,
} from '@/lib/ag-grid/save-column-state';
import StockCellRenderer from '@/Pages/WarehouseManagement/Partials/StockCellRenderer.vue';
import { watchDebounced } from '@vueuse/core';
import LinkVuetify from '@/Components/LinkVuetify.vue';
import WarehouseItemModal from '@/Pages/WarehouseManagement/Partials/WarehouseItemModal.vue';

const page = usePage();

const items = useRemember({ rows: page.props.items });
delete page.props.items;

const defaultWarehouseLocation = computed(
    () => page.props.warehouse?.default_warehouse_location,
);

watch(
    () => page.props.items,
    () => {
        if (page.props.items && router.activeVisit?.completed) {
            items.value.rows = page.props.items;
            delete page.props.items;
        }
    },
);

const requestingItems = ref(false);
const reloadItems = async function () {
    if (
        requestingItems.value ||
        (router.activeVisit && !router.activeVisit.completed)
    ) {
        return;
    }
    requestingItems.value = true;
    const response = await axios.get(route('api.warehouse.index'));
    requestingItems.value = false;
    items.value.rows = response.data;
};
let reloadInterval = null;
onMounted(() => (reloadInterval = setInterval(reloadItems, 25000)));
onUnmounted(() => clearInterval(reloadInterval));

const standardColumns = [
    {
        headerName: $t('Maillog SKU'),
        field: 'item_number',
        headerTooltip: $t('Maillog SKU'),
    },
    {
        headerName: $t('SKU'),
        field: 'sku',
        headerTooltip: $t('SKU'),
    },
    {
        headerName: $t('Description'),
        field: 'description',
        headerTooltip: $t('Description'),
    },
];

const getLocationValue = function (locationId) {
    return (params) => {
        const total =
            params.data.stock[locationId] - params.data.reserved[locationId];
        return !isNaN(total) ? total : null;
    };
};

const locationColumns = page.props.locations.map((location) => ({
    headerName: location.description,
    cellRenderer: StockCellRenderer,
    cellRendererParams: {
        locationId: location.id,
    },
    cellClass:
        defaultWarehouseLocation.value === location.id ? 'tw-bg-green-100' : '',
    sortable: false,
    valueGetter: getLocationValue(location.id),
}));

const maillogColumn = {
    headerName: 'Maillog',
    cellRenderer: StockCellRenderer,
    cellRendererParams: {
        locationId: 'maillog',
    },
    sortable: false,
    valueGetter: getLocationValue('maillog'),
};

const columnDefs = computed(() => [
    ...standardColumns,
    ...locationColumns,
    maillogColumn,
]);

const gridApi = ref(null);
const stateResponse = getColumnState('warehouse');
const stateLoaded = ref(false);
const onGridReady = async (params) => {
    gridApi.value = params.api;
    const columnState = await stateResponse;
    if (columnState) {
        params.columnApi.applyColumnState({
            state: columnState,
            applyOrder: true,
        });
    }
    stateLoaded.value = true;
};
const getRowId = (params) => params.data.id;

const articleSearch = ref(null);
const doesExternalFilterPass = (node) => {
    const strings = [
        node.data.item_number !== null ? String(node.data.item_number) : '',
        node.data.sku,
        node.data.description,
    ];

    const terms = articleSearch.value
        ?.split(' ')
        ?.map((term) => term.toLowerCase());

    return terms.every((term) =>
        strings.some((string) => string?.toLowerCase()?.includes(term)),
    );
};
watchDebounced(
    articleSearch,
    () => {
        gridApi.value.onFilterChanged();
    },
    {
        debounce: 300,
    },
);

const modalItemId = ref(null);
const showItemModal = ref(false);
const onRowClicked = (params) => {
    modalItemId.value = Number(params.data.id);
    showItemModal.value = true;
};
const onModalClosed = () => {
    modalItemId.value = null;
    showItemModal.value = false;
};

const exportExcel = () => {
    gridApi.value.exportDataAsExcel({
        fileName: 'stock.xlsx',
        sheetName: 'Stock',
    });
};
</script>

<template>
    <Head :title="$page.props.title"></Head>
    <WarehouseItemModal
        :item-id="modalItemId"
        :locations="page.props.locations"
        :show="showItemModal"
        @close="onModalClosed"
    />
    <AgTableCard :loading="!stateLoaded">
        <template #actions>
            <v-row>
                <v-col
                    cols="12"
                    lg="6"
                    class="tw-flex tw-items-center tw-py-2 lg:tw-pb-3"
                >
                    <v-text-field
                        v-model="articleSearch"
                        class="tw-mr-8"
                        variant="solo"
                        color="primary"
                        density="comfortable"
                        :append-icon="mdiMagnify"
                        :label="$t('Search')"
                        single-line
                        hide-details
                        dense
                        clearable
                    ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    lg="6"
                    class="tw-flex tw-items-center tw-justify-center tw-pb-4 tw-pt-0 lg:tw-justify-end lg:tw-pt-3"
                >
                    <LinkVuetify
                        v-if="
                            !page.props.auth.canShipOrders &&
                            page.props.auth.canCreateOrders
                        "
                        class="tw-mr-2"
                        size="small"
                        color="info"
                        :href="route('order.create')"
                        >{{ $t('Create Reservation') }}
                    </LinkVuetify>
                    <LinkVuetify
                        v-if="page.props.auth.canAcceptWarehouseDeliveries"
                        size="small"
                        class="tw-mr-2"
                        color="primary"
                        :href="route('warehouse.delivery.index')"
                        >{{ $t('Accept Delivery') }}
                    </LinkVuetify>
                    <LinkVuetify
                        v-if="page.props.auth.canCreateWarehouseOrder"
                        size="small"
                        class="tw-mr-2"
                        color="black"
                        :href="route('warehouse.order.create')"
                        >{{ $t('Issue Order') }}
                    </LinkVuetify>
                    <!-- <LinkVuetify
                        v-if="page.props.auth.canViewWarehouseReservation"
                        size="small"
                        class="tw-mr-2"
                        color="info"
                        :href="route('warehouse.reservation.index')"
                        >{{ $t('Reservations') }}
                    </LinkVuetify> -->
                    <LinkVuetify
                        v-if="$page.props.auth.canEditWarehouseItems"
                        size="small"
                        class="tw-mr-2"
                        color="surface"
                        :href="route('warehouse.items')"
                        :prepend-icon="mdiPackageVariant"
                        >{{ $t('Articles') }}
                    </LinkVuetify>
                    <v-btn
                        size="small"
                        class="tw-mr-2 tw-text-green-700"
                        :prepend-icon="mdiFileExcel"
                        @click="exportExcel"
                        >{{ $t('Excel') }}
                    </v-btn>
                </v-col>
            </v-row>
        </template>
        <ag-grid-vue
            class="ag-theme-material tw-h-full"
            :row-height="50"
            :column-types="columnTypes"
            :default-col-def="defaultColumnDefinitions"
            :column-defs="columnDefs"
            :row-data="items.rows"
            :is-external-filter-present="
                () => articleSearch !== null && articleSearch.trim().length > 0
            "
            :does-external-filter-pass="doesExternalFilterPass"
            :get-row-id="getRowId"
            suppress-cell-focus
            enable-cell-change-flash
            @row-clicked="onRowClicked"
            @grid-ready="onGridReady"
            v-on="columnStateEvents"
        ></ag-grid-vue>
    </AgTableCard>
</template>

<style scoped>
:deep(.ag-row-level-0) {
    cursor: pointer;
}
</style>
