<script setup>
import { useVModel } from '@vueuse/core';
import CheckboxField from '@/Components/CheckboxField.vue';
import { usePage } from '@inertiajs/vue3';

const props = defineProps({
    modelValue: {
        type: Boolean,
        required: true,
    },
    shippingAgents: {
        type: Array,
        required: true,
    },
});
const emit = defineEmits(['update:modelValue', 'update:shippingAgents']);
const page = usePage();

const selectedShippingAgents = useVModel(props, 'shippingAgents', emit);
const hasShippingAgents = useVModel(props, 'modelValue', emit);
</script>

<template>
    <CheckboxField
        v-model="hasShippingAgents"
        :label="$t('Has shipping agent options')"
    ></CheckboxField>
    <v-combobox
        v-if="hasShippingAgents"
        v-model="selectedShippingAgents"
        color="primary"
        :label="$t('Selectable shipping agents')"
        :items="page.props.shippingAgents"
        item-title="code"
        item-value="code"
        :return-object="false"
        multiple
        chips
        clearable
    ></v-combobox>
</template>
