<script setup>
import { computed, ref, watch } from 'vue';
import { router as Inertia, useForm, usePage } from '@inertiajs/vue3';
import ActionSection from '@/Components/ActionSection.vue';
import ConfirmsPassword from '@/Components/ConfirmsPassword.vue';
import TextField from '@/Components/TextField.vue';

const props = defineProps({
    requiresConfirmation: Boolean,
});

const enabling = ref(false);
const confirming = ref(false);
const disabling = ref(false);
const qrCode = ref(null);
const setupKey = ref(null);
const recoveryCodes = ref([]);

const confirmationForm = useForm({
    code: '',
});

const twoFactorEnabled = computed(
    () => !enabling.value && usePage().props.auth.user?.two_factor_enabled
);

watch(twoFactorEnabled, () => {
    if (!twoFactorEnabled.value) {
        confirmationForm.reset();
        confirmationForm.clearErrors();
    }
});

const enableTwoFactorAuthentication = () => {
    enabling.value = true;

    Inertia.post(
        '/user/two-factor-authentication',
        {},
        {
            preserveScroll: true,
            onSuccess: () =>
                Promise.all([
                    showQrCode(),
                    showSetupKey(),
                    showRecoveryCodes(),
                ]),
            onFinish: () => {
                enabling.value = false;
                confirming.value = props.requiresConfirmation;
            },
        }
    );
};

const showQrCode = () => {
    return axios.get('/user/two-factor-qr-code').then((response) => {
        qrCode.value = response.data.svg;
    });
};

const showSetupKey = () => {
    return axios.get('/user/two-factor-secret-key').then((response) => {
        setupKey.value = response.data.secretKey;
    });
};

const showRecoveryCodes = () => {
    return axios.get('/user/two-factor-recovery-codes').then((response) => {
        recoveryCodes.value = response.data;
    });
};

const confirmTwoFactorAuthentication = () => {
    confirmationForm.post('/user/confirmed-two-factor-authentication', {
        errorBag: 'confirmTwoFactorAuthentication',
        preserveScroll: true,
        preserveState: true,
        onSuccess: () => {
            confirming.value = false;
            qrCode.value = null;
            setupKey.value = null;
        },
    });
};

const regenerateRecoveryCodes = () => {
    axios
        .post('/user/two-factor-recovery-codes')
        .then(() => showRecoveryCodes());
};

const disableTwoFactorAuthentication = () => {
    disabling.value = true;

    Inertia.delete('/user/two-factor-authentication', {
        preserveScroll: true,
        onSuccess: () => {
            disabling.value = false;
            confirming.value = false;
        },
    });
};
</script>

<template>
    <ActionSection>
        <template #title>
            {{ $t('Two Factor Authentication') }}
        </template>

        <template #description>
            {{
                $t(
                    'Add additional security to your account using two factor authentication.'
                )
            }}
        </template>

        <template #content>
            <h3
                v-if="twoFactorEnabled && !confirming"
                class="tw-text-lg tw-font-medium tw-text-gray-900"
            >
                {{ $t('You have enabled two factor authentication.') }}
            </h3>

            <h3
                v-else-if="twoFactorEnabled && confirming"
                class="tw-text-lg tw-font-medium tw-text-gray-900"
            >
                {{ $t('Finish enabling two factor authentication.') }}
            </h3>

            <h3 v-else class="tw-text-lg tw-font-medium tw-text-gray-900">
                {{ $t('You have not enabled two factor authentication.') }}
            </h3>

            <div class="tw-mt-3 tw-max-w-xl tw-text-sm tw-text-gray-600">
                <p>
                    {{
                        $t(
                            "When two factor authentication is enabled, you will be prompted for a secure, random token during authentication. You may retrieve this token from your phone's Google Authenticator application."
                        )
                    }}
                </p>
            </div>

            <div v-if="twoFactorEnabled">
                <div v-if="qrCode">
                    <div class="tw-mt-4 tw-text-sm tw-text-gray-600">
                        <p v-if="confirming" class="tw-font-semibold">
                            {{
                                $t(
                                    "To finish enabling two factor authentication, scan the following QR code using your phone's authenticator application or enter the setup key and provide the generated OTP code."
                                )
                            }}
                        </p>

                        <p v-else>
                            {{
                                $t(
                                    "Two factor authentication is now enabled. Scan the following QR code using your phone's authenticator application or enter the setup key."
                                )
                            }}
                        </p>
                    </div>
                    <!-- eslint-disable-next-line vue/no-v-html-->
                    <div class="tw-mt-4" v-html="qrCode" />

                    <div
                        v-if="setupKey"
                        class="tw-mt-4 tw-text-sm tw-text-gray-600"
                    >
                        <p class="tw-font-semibold">
                            {{ $t('Setup Key') }}:
                            <!-- eslint-disable-next-line vue/no-v-html-->
                            <span v-html="setupKey"></span>
                        </p>
                    </div>

                    <div v-if="confirming" class="tw-mt-4">
                        <TextField
                            v-model="confirmationForm.code"
                            type="text"
                            inputmode="numeric"
                            autofocus
                            autocomplete="one-time-code"
                            :label="$t('Password')"
                            :error-messages="confirmationForm.errors.code"
                            @keyup.enter="confirmTwoFactorAuthentication"
                        />
                    </div>
                </div>

                <div v-if="recoveryCodes.length > 0 && !confirming">
                    <div
                        class="tw-mt-4 tw-max-w-xl tw-text-sm tw-text-gray-600"
                    >
                        <p class="tw-font-semibold">
                            {{
                                $t(
                                    'Store these recovery codes in a secure password manager. They can be used to recover access to your account if your two factor authentication device is lost.'
                                )
                            }}
                        </p>
                    </div>

                    <div
                        class="tw-grid tw-gap-1 tw-max-w-xl tw-mt-4 tw-px-4 tw-py-4 tw-font-mono tw-text-sm tw-bg-gray-100 tw-rounded-lg"
                    >
                        <div v-for="code in recoveryCodes" :key="code">
                            {{ code }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="tw-mt-5">
                <div v-if="!twoFactorEnabled">
                    <ConfirmsPassword
                        @confirmed="enableTwoFactorAuthentication"
                    >
                        <v-btn
                            color="primary"
                            type="button"
                            :loading="enabling"
                        >
                            {{ $t('Enable') }}
                        </v-btn>
                    </ConfirmsPassword>
                </div>

                <div v-else>
                    <ConfirmsPassword
                        @confirmed="confirmTwoFactorAuthentication"
                    >
                        <v-btn
                            v-if="confirming"
                            :loading="enabling"
                            class="tw-mr-2"
                            color="primary"
                            type="button"
                        >
                            {{ $t('Confirm') }}
                        </v-btn>
                    </ConfirmsPassword>

                    <ConfirmsPassword @confirmed="regenerateRecoveryCodes">
                        <v-btn
                            v-if="recoveryCodes.length > 0 && !confirming"
                            class="tw-mr-2"
                        >
                            {{ $t('Regenerate Recovery Codes') }}
                        </v-btn>
                    </ConfirmsPassword>

                    <ConfirmsPassword @confirmed="showRecoveryCodes">
                        <v-btn
                            v-if="recoveryCodes.length === 0 && !confirming"
                            class="tw-mr-2"
                        >
                            {{ $t('Show Recovery Codes') }}
                        </v-btn>
                    </ConfirmsPassword>

                    <ConfirmsPassword
                        @confirmed="disableTwoFactorAuthentication"
                    >
                        <v-btn v-if="confirming" :loading="disabling">
                            {{ $t('Cancel') }}
                        </v-btn>
                    </ConfirmsPassword>

                    <ConfirmsPassword
                        @confirmed="disableTwoFactorAuthentication"
                    >
                        <v-btn
                            v-if="!confirming"
                            color="danger"
                            :loading="disabling"
                        >
                            {{ $t('Disable') }}
                        </v-btn>
                    </ConfirmsPassword>
                </div>
            </div>
        </template>
    </ActionSection>
</template>
