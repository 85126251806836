<script setup>
import AgTableCard from '@/Components/AgTableCard.vue';
import {
    columnStateEvents,
    getColumnState,
    onGridReady,
    stateApplied,
} from '@/lib/ag-grid/save-column-state.js';
import defaultColumnDefinitions from '@/lib/ag-grid/default-column-definitions.js';
import { AgGridVue } from 'ag-grid-vue3';
import { router, usePage } from '@inertiajs/vue3';
import { trans as $t } from 'laravel-vue-i18n';
import { ref } from 'vue';
import ActionCellRenderer from '@/Pages/ShippingAdvice/Partials/ActionCellRenderer.vue';
import { mdiPlus } from '@mdi/js';
import LinkVuetify from '@/Components/LinkVuetify.vue';

const page = usePage();
getColumnState('shipping_advice');

const columnDefs = ref([
    {
        field: 'id',
    },
    {
        field: 'customer_number',
        headerName: $t('Customer Number'),
    },
    {
        field: 'reference',
        headerName: $t('Reference'),
    },
    {
        field: 'arrival_date',
        headerName: $t('Arrival Date'),
    },
    {
        field: 'quantity',
        headerName: $t('Quantity'),
    },
    {
        field: 'package_type',
        headerName: $t('Package Type'),
    },
    {
        field: 'status',
        headerName: $t('Status'),
    },
    {
        headerName: $t('Actions'),
        cellRenderer: ActionCellRenderer,
        cellRendererParams: {
            idField: 'id',
            buttonEvents: {
                onShow: (id) => {
                    console.log(id);
                },
                onEdit: (id) => {
                    router.visit(
                        route('shipping-advice.edit', { shipping_advice: id }),
                    );
                },
            },
        },
    },
]);
</script>

<template>
    <AgTableCard :loading="!stateApplied">
        <template #actions
            ><LinkVuetify
                :href="route('shipping-advice.create')"
                color="black"
                size="small"
                :prepend-icon="mdiPlus"
                >{{ $t('Create Shipping Advice') }}</LinkVuetify
            ></template
        >
        <ag-grid-vue
            class="ag-theme-material tw-h-full"
            :row-data="page.props.shipping_advices"
            :default-column-defs="defaultColumnDefinitions"
            :column-defs="columnDefs"
            @grid-ready="onGridReady"
            v-on="columnStateEvents"
        >
        </ag-grid-vue>
    </AgTableCard>
</template>

<style scoped></style>
