<script setup>
import TextField from '@/Components/TextField.vue';
import { inject, ref, watch } from 'vue';
import { trans as $t } from 'laravel-vue-i18n';
import useBarcodeScanner from '@/lib/composables/barcode-scanner.js';
import SaveButton from '@/Components/SaveButton.vue';
import { useDebounceFn } from '@vueuse/core';
import GoodsIssueScansCard from '@/Pages/GoodsIssue/Partials/GoodsIssueScansCard.vue';

const order = ref(null);
const scans = ref([]);
const flash = inject('flash');
const confirm = inject('confirm');
const handleGoodsIssueResponseData = (data) => {
    if (order.value?.order_number !== data.order.order_number) {
        order.value = data.order;
    }
    scans.value = data.scans;
};

const { stringBuffer, onEnterKey, onBarcodeScanned } = useBarcodeScanner({
    validate: false,
});
const eanField = ref(null);
const manualKeyDownEnter = () => {
    onEnterKey();
};
const savingScan = ref(false);
onBarcodeScanned(
    async (barcode) => {
        if (savingScan.value && scans.value.length === 0) {
            return;
        }

        savingScan.value = true;
        showCheck.value = false;
        try {
            const { data } = await axios.put(
                route('api.goods-issue.scan', {
                    code: barcode,
                }),
                {
                    include: 'order',
                },
            );
            handleGoodsIssueResponseData(data);
            showCheck.value = true;
        } catch (e) {
            flash($t('Invalid Barcode.'), 'danger', 5000);
        }

        savingScan.value = false;
    },
    () => {
        flash($t('Invalid Barcode.'), 'danger', 5000);
    },
);

// show Checkmark and debounced hide
const showCheck = ref(false);
watch(showCheck, (value) => {
    if (value) {
        hideCheck();
    }
});
const hideCheck = useDebounceFn(() => {
    showCheck.value = false;
}, 2500);

const resettingShipment = ref(false);
const resetShipment = async () => {
    const reset = await confirm.value(
        $t('reset-shipment.confirmation', {
            shipment_number: scans.value[0].shipment_number,
        }),
        $t('Reset Shipment'),
    );

    if (!reset) {
        return;
    }

    resettingShipment.value = true;
    const { data } = await axios.put(
        route('api.goods-issue.reset', {
            shipment_number: scans.value[0].shipment_number,
        }),
    );
    handleGoodsIssueResponseData(data);
    resettingShipment.value = false;
};
</script>

<template>
    <v-container>
        <v-row>
            <v-col>
                <TextField
                    ref="eanField"
                    v-model="stringBuffer"
                    :loading="savingScan"
                    clearable
                    label="EAN"
                    :hint="$t('Press Enter to submit.')"
                    @keydown.stop.enter="manualKeyDownEnter"
                >
                    <template #append>
                        <SaveButton
                            :loading="savingScan"
                            type="button"
                            @click="onEnterKey"
                            >{{ $t('Submit') }}
                        </SaveButton>
                    </template>
                </TextField>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <GoodsIssueScansCard
                    class="tw-py-2"
                    :scans="scans"
                    :order="order"
                    :show-check="showCheck"
                >
                    <template #button>
                        <v-btn
                            size="small"
                            :loading="resettingShipment"
                            color="danger"
                            @click="resetShipment"
                            >{{ $t('Reset') }}
                        </v-btn>
                    </template>
                </GoodsIssueScansCard>
            </v-col>
        </v-row>
    </v-container>
</template>

<style scoped>
td,
th {
    text-align: left;
}
</style>
