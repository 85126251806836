<script setup>
import { computed, reactive, ref } from 'vue';
import DialogModal from '@/Components/DialogModal.vue';
import TextField from '@/Components/TextField.vue';
import { trans as $t } from 'laravel-vue-i18n';
import { usePage } from '@inertiajs/vue3';
import { useColloStore } from '@/lib/composables/collo-state.js';
import SelectField from '@/Components/SelectField.vue';

const props = defineProps({
    colloIndexes: {
        type: Object,
        required: true,
    },
});
const page = usePage();

// Handle Dimensions
const showDimensionModal = ref(false);
const dimensionForm = reactive({
    length: null,
    width: null,
    height: null,
    weight: null,
    parent_collo_id: null,
});

let dimResolve = null;
let dimReject = null;
const currentCollo = ref(null);
const errors = ref(null);
const promptDimensions = (collo, scans, force = false) => {
    if (!page.props.customer.settings.true_package_dimensions) {
        return;
    }

    const hasPallet =
        scans.findIndex((scan) =>
            page.props.pallet_item_numbers.includes(scan.data.item_number),
        ) !== -1;

    // If collo has pallet and no dimensions, show modal
    if (!force && (!hasPallet || collo.length || collo.width || collo.height))
        return;

    errors.value = null;
    currentCollo.value = collo;
    showDimensionModal.value = true;

    const dimensions = savedDimensions.value?.[currentCollo.value.id];
    if (dimensions) {
        dimensionForm.weight = dimensions.weight / 1000;
        dimensionForm.height = dimensions.height;
        dimensionForm.length = dimensions.length;
        dimensionForm.width = dimensions.width;
        dimensionForm.parent_collo_id = dimensions.parent_collo_id;
    } else {
        dimensionForm.length = collo.length ?? 120;
        dimensionForm.width = collo.width ?? 80;
        dimensionForm.height = collo.height;
        dimensionForm.weight = collo.weight === 0 ? null : collo.weight / 1000;
        dimensionForm.parent_collo_id = collo.parent_collo_id;
    }

    return new Promise((resolve, reject) => {
        dimResolve = resolve;
        dimReject = reject;
    });
};
const submittingDimensions = ref(false);

const { savedDimensions, addDimensions } = useColloStore();
const submitDimensions = async () => {
    submittingDimensions.value = true;
    try {
        const weight =
            dimensionForm.weight === null ||
            dimensionForm.weight === 0 ||
            dimensionForm.weight === ''
                ? null
                : dimensionForm.weight * 1000;

        const response = await axios.put(
            route('api.pack.collo.update', {
                pack_process: currentCollo.value.pack_process_id,
                collo: currentCollo.value.id,
            }),
            { ...dimensionForm, weight: weight },
        );

        addDimensions(currentCollo.value.id, response.data.data);

        dimResolve();
        close();
    } catch (e) {
        if (e?.response?.status === 422) {
            errors.value = e.response.data.errors;
            submittingDimensions.value = false;
        } else {
            dimReject(e);
            console.error(e);
            close();
        }
    }
};

const parentColliOptions = computed(() =>
    page.props.pack_process.colli.filter(
        (collo) => collo.id !== currentCollo.value.id,
    ),
);

const close = () => {
    submittingDimensions.value = false;
    showDimensionModal.value = false;
};
const cancelDimensions = () => {
    dimReject();
    showDimensionModal.value = false;
};

const getColloTitle = (params) => {
    return `Collo #${props.colloIndexes.get(params.id)}`;
};

defineExpose({
    promptDimensions,
});
</script>

<template>
    <DialogModal :closeable="false" :show="showDimensionModal">
        <template #toolbar
            ><v-toolbar-title> Dimensionen Collo </v-toolbar-title></template
        >
        <template #content>
            <div class="tw-mx-auto tw-max-w-lg">
                <v-row class="tw-mt-2">
                    <TextField
                        v-model="dimensionForm.length"
                        class="tw-mr-2 tw-max-w-40"
                        type="number"
                        density="compact"
                        hide-details="auto"
                        :label="'Länge'"
                        :error-messages="errors?.length"
                    >
                        <template #append-inner
                            ><div class="tw-h-full tw-pt-2 tw-text-gray-500">
                                cm
                            </div></template
                        >
                    </TextField>

                    <TextField
                        v-model="dimensionForm.width"
                        class="tw-mr-2 tw-max-w-36"
                        type="number"
                        density="compact"
                        hide-details="auto"
                        :label="'Breite'"
                        :error-messages="errors?.width"
                    >
                        <template #append-inner
                            ><div class="tw-h-full tw-pt-2 tw-text-gray-500">
                                cm
                            </div></template
                        >
                    </TextField>

                    <TextField
                        v-model="dimensionForm.height"
                        autofocus
                        class="tw-mr-2 tw-max-w-36"
                        type="number"
                        density="compact"
                        hide-details="auto"
                        :label="'Höhe'"
                        :error-messages="errors?.height"
                    >
                        <template #append-inner
                            ><div class="tw-h-full tw-pt-2 tw-text-gray-500">
                                cm
                            </div></template
                        >
                    </TextField>
                </v-row>
                <v-row>
                    <TextField
                        v-model="dimensionForm.weight"
                        class="tw-mr-2 tw-max-w-36"
                        type="number"
                        density="compact"
                        hide-details="auto"
                        :label="'Gewicht'"
                        :error-messages="errors?.weight"
                    >
                        <template #append-inner
                            ><div class="tw-h-full tw-pt-2 tw-text-gray-500">
                                kg
                            </div></template
                        >
                    </TextField>
                </v-row>
                <v-row v-if="parentColliOptions.length > 0">
                    <SelectField
                        v-model="dimensionForm.parent_collo_id"
                        density="compact"
                        label="Parent Collo"
                        hide-details="auto"
                        clearable
                        :error-messages="errors?.parent_collo_id"
                        :items="parentColliOptions"
                        :item-title="getColloTitle"
                        item-value="id"
                    >
                        <!--                        <template #item="{ props: itemProps, item }">
                            <v-list-item v-bind="itemProps">
                                <v-list-item-title
                                    >Collo #{{
                                        props.colloIndexes.get(item.raw.id)
                                    }}</v-list-item-title
                                >
                            </v-list-item>
                        </template>-->
                    </SelectField>
                </v-row>
            </div>
        </template>
        <template #footer>
            <v-btn :disabled="submittingDimensions" @click="cancelDimensions">{{
                $t('Cancel')
            }}</v-btn>
            <v-btn :loading="submittingDimensions" @click="submitDimensions">{{
                $t('Submit')
            }}</v-btn>
        </template>
    </DialogModal>
</template>

<style scoped></style>
