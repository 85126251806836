<script setup>
import { computed, ref } from 'vue';

const props = defineProps({
    rows: {
        type: Array,
        required: true,
    },
    scans: {
        type: Array,
        required: true,
    },
    itemsByItemNumber: {
        type: Object,
        required: true,
    },
    unitsByItemNumber: {
        type: Object,
        required: true,
    },
});

const scanCountFor = (itemNumber, batch, unit) => {
    return props.scans.filter((scan) => {
        return (
            scan.item_number === itemNumber &&
            scan.data.batch === batch &&
            scan.unit === unit
        );
    }).length;
    // .map((scan) => props.unitsByItemNumber[scan.item_number][scan.unit])
    // .reduce((a, b) => a + b, 0);
};

// Scroll to div with item key and 100 px offset
const table = ref(null);
const scrollToItemKey = (itemKey) => {
    const row = document.querySelector(`[data-item-key="${itemKey}"]`);
    if (row) {
        row.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'nearest',
        });
    }
};

const lastScan = computed(() => props.scans[0] ?? null);

defineExpose({
    scrollToItemKey,
});
</script>

<template>
    <v-card class="tw-h-[calc(100vh-160px)] tw-grow tw-overflow-auto">
        <table
            ref="table"
            :fixed-header="false"
            class="tw-w-full tw-border-spacing-0 tw-text-xl"
        >
            <thead>
                <tr>
                    <th
                        class="tw-p-2 tw-pl-4 tw-text-left tw-font-light tw-uppercase"
                    >
                        {{ $t('Item Number') }}
                    </th>
                    <th class="tw-p-2 tw-text-left tw-font-light tw-uppercase">
                        {{ $t('Description') }}
                    </th>
                    <th class="tw-p-2 tw-text-left tw-font-light tw-uppercase">
                        {{ $t('Batch') }}
                    </th>
                    <th class="tw-p-2 tw-text-left tw-font-light tw-uppercase">
                        {{ $t('Unit') }}
                    </th>
                    <th class="tw-p-2 tw-text-right tw-font-light tw-uppercase">
                        {{ $t('Shipping Advice') }}
                    </th>
                    <th class="tw-p-2 tw-text-right tw-font-light tw-uppercase">
                        {{ $t('Scanned') }}
                    </th>
                    <th
                        class="tw-p-2 tw-pr-4 tw-text-right tw-font-light tw-uppercase"
                    >
                        {{ $t('Open') }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="row in rows"
                    :key="row.id"
                    :data-item-key="row.item_number + row.batch + row.unit"
                    :class="{
                        'tw-bg-sky-200':
                            lastScan !== null &&
                            lastScan.item_number === row.item_number &&
                            lastScan.data.batch === row.batch &&
                            lastScan.unit === row.unit,
                        'tw-bg-green-100':
                            row.quantity -
                                scanCountFor(
                                    row.item_number,
                                    row.batch,
                                    row.unit,
                                ) <=
                            0,
                    }"
                >
                    <td
                        class="tw-border-0 tw-border-b tw-border-solid tw-border-gray-300 tw-p-1 tw-pl-4"
                    >
                        {{ row.item_number }}
                    </td>
                    <td
                        class="tw-border-0 tw-border-b tw-border-solid tw-border-gray-300 tw-p-1"
                    >
                        {{
                            props.itemsByItemNumber[row.item_number].Description
                        }}
                    </td>
                    <td
                        class="tw-border-0 tw-border-b tw-border-solid tw-border-gray-300 tw-p-1"
                    >
                        {{ row.batch }}
                    </td>
                    <td
                        class="tw-border-0 tw-border-b tw-border-solid tw-border-gray-300 tw-p-1"
                    >
                        {{ row.unit }}
                    </td>
                    <td
                        class="tw-border-0 tw-border-b tw-border-solid tw-border-gray-300 tw-p-1 tw-text-right"
                    >
                        {{ row.quantity }}
                    </td>
                    <td
                        class="tw-border-0 tw-border-b tw-border-solid tw-border-gray-300 tw-p-1 tw-text-right"
                    >
                        {{ scanCountFor(row.item_number, row.batch, row.unit) }}
                    </td>
                    <td
                        class="tw-border-0 tw-border-b tw-border-solid tw-border-gray-300 tw-p-1 tw-pr-4 tw-text-right tw-font-bold"
                    >
                        <v-chip
                            size="large"
                            class="tw-bg-surface"
                            :color="
                                row.quantity -
                                    scanCountFor(
                                        row.item_number,
                                        row.batch,
                                        row.unit,
                                    ) >
                                0
                                    ? 'danger'
                                    : 'primary'
                            "
                        >
                            {{
                                row.quantity -
                                scanCountFor(
                                    row.item_number,
                                    row.batch,
                                    row.unit,
                                )
                            }}</v-chip
                        >
                    </td>
                </tr>
            </tbody>
        </table>
    </v-card>
</template>

<style scoped></style>
