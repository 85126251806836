<script setup>
import { usePage } from '@inertiajs/vue3';
import { trans as $t } from 'laravel-vue-i18n';
import LinkVuetify from '@/Components/LinkVuetify.vue';

const page = usePage();

defineProps({
    params: {
        type: Object,
        required: true,
    },
});

/*const showReservation = computed(
    () =>
        page.props.auth.canCreateWarehouseReservation &&
        (props.params.data.location_id !== 'maillog' ||
            props.params.data.item_number !== null),
);*/
</script>

<template>
    <div class="tw-flex tw-h-full tw-items-center">
        <!--        <v-btn
            v-if="showReservation"
            class="tw-mr-2"
            color="info"
            density="comfortable"
            @click="params.onReserve(params.data)"
            >{{ $t('Reserve') }}</v-btn
        >-->
        <v-btn
            v-if="
                page.props.auth.canCreateWarehouseTransaction &&
                params.data.location !== 'Maillog'
            "
            color="secondary"
            density="comfortable"
            @click="params.onInOut(params.data)"
            >{{ $t('In/Out') }}</v-btn
        >
        <LinkVuetify
            v-if="
                page.props.auth.canCreateWarehouseOrder &&
                params.data.location === 'Maillog'
            "
            density="comfortable"
            color="black"
            :href="route('warehouse.order.create')"
            class="tw-mr-2"
            >{{ $t('Issue Order') }}
        </LinkVuetify>
    </div>
</template>

<style scoped></style>
