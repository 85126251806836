<script setup>
import FileInputField from '@/Components/FileInputField.vue';
import { nextTick, ref, watch } from 'vue';
import { mdiFilePdfBox, mdiTrashCan } from '@mdi/js';
import { useVModel } from '@vueuse/core';
import rules from '@/lib/utilities/rules';

const props = defineProps({
    modelValue: { type: Array, required: true },
    required: { type: Boolean, default: false },
    order: { type: Object, default: null },
});
const emit = defineEmits(['update:modelValue']);

const documents = useVModel(props, 'modelValue', emit);

const formData = new FormData();

const uploading = ref(false);
const uploadFile = async (file) => {
    if (file !== undefined) {
        uploading.value = true;
        formData.set('file', file);
        await nextTick();
        try {
            const response = await axios.post(
                route('api.document.temp.store'),
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                },
            );

            documents.value.push({
                id: response.data.id,
                filename: response.data.filename,
                data: response.data.data,
            });
        } catch (e) {
            console.log(e);
        } finally {
            uploading.value = false;
        }
    }
};

const deleteDocument = async (documentId) => {
    documents.value = documents.value.filter(
        (document) => document.id !== documentId,
    );
};

const fileInputField = ref(null);
watch(
    () => documents.value.length,
    () =>
        nextTick(() => {
            fileInputField.value.reset();
            fileInputField.value.validate();
        }),
);
</script>

<template>
    <v-row>
        <v-col cols="12" md="12" class="tw-pb-0">
            <FileInputField
                ref="fileInputField"
                accept="application/pdf"
                :loading="uploading"
                :rules="required ? [rules.requiredDocuments(documents)] : []"
                @input="uploadFile($event.target.files[0])"
            ></FileInputField>
        </v-col>
    </v-row>
    <v-row>
        <v-col class="tw-pt-0">
            <v-list class="tw-py-0" density="compact">
                <v-list-item
                    v-for="document in documents"
                    :key="document.id"
                    target="_blank"
                    class="tw-p-0"
                >
                    <template #title>
                        <a
                            :href="
                                order?.order_number
                                    ? route('order.document.show', {
                                          order: order.order_number,
                                          document: document.id,
                                      })
                                    : null
                            "
                            class="tw-mr-2 tw-flex tw-h-10 tw-items-center tw-rounded-md tw-text-inherit tw-no-underline hover:tw-bg-gray-50"
                            :target="order ? '_blank' : '_self'"
                        >
                            <v-icon
                                class="tw-ml-1 tw-mr-2"
                                :icon="mdiFilePdfBox"
                                color="danger"
                            ></v-icon>
                            <span>{{ document.filename }}</span>
                        </a>
                    </template>
                    <template #append>
                        <v-btn
                            size="x-small"
                            color="danger"
                            :icon="mdiTrashCan"
                            @click="deleteDocument(document.id)"
                        ></v-btn>
                    </template>
                </v-list-item>
            </v-list>
        </v-col>
    </v-row>
</template>
