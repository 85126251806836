<script setup>
import { useForm, usePage } from '@inertiajs/vue3';
import FormSection from '@/Components/FormSection.vue';
import SaveButton from '@/Components/SaveButton.vue';
import SelectField from '@/Components/SelectField.vue';
import SectionBorder from '@/Components/SectionBorder.vue';
import { watch } from 'vue';

const props = defineProps({
    user: {
        type: Object,
        required: true,
    },
    warehouseTenant: {
        type: [Number, null],
        required: true,
    },
});

const page = usePage();

const form = useForm({
    _method: 'PUT',
    warehouse_tenants: props.warehouseTenant,
    default_warehouse_location: props.user.settings.default_warehouse_location,
});

const submitUpdateWarehouseSettingsForm = () => {
    form.post(
        route('user.warehouse-settings.update', { user: props.user.id }),
        {
            preserveScroll: true,
            preserveState: false,
        },
    );
};

watch(
    () => form.warehouse_tenants,
    () => {
        form.default_warehouse_location = null;
    },
);
</script>

<template>
    <SectionBorder />
    <FormSection @submitted="submitUpdateWarehouseSettingsForm">
        <template #title>
            {{ $t('Warehouse Settings') }}
        </template>
        <template #description>
            {{ $t('Here you can change the warehouse settings for the user.') }}
        </template>
        <template #form>
            <SelectField
                v-if="page.props.auth.canUpdateTenants"
                v-model="form.warehouse_tenants"
                clearable
                :items="page.props.warehouseTenants"
                item-title="name"
                item-value="id"
                :error-messages="form.errors.warehouse_tenants"
                :label="$t('Warehouse Tenants')"
            ></SelectField>
            <SelectField
                v-if="
                    page.props.auth.canUpdateWarehouseSettings &&
                    warehouseTenant !== null &&
                    warehouseTenant === form.warehouse_tenants
                "
                v-model="form.default_warehouse_location"
                clearable
                :items="page.props.warehouseLocations"
                item-title="description"
                item-value="id"
                :error-messages="form.errors.default_warehouse_location"
                :label="$t('Default Warehouse Location')"
            ></SelectField>
        </template>
        <template #actions>
            <SaveButton
                :loading="form.processing"
                :disabled="!form.isDirty"
            ></SaveButton>
        </template>
    </FormSection>
</template>

<style scoped></style>
