<script setup>
import { usePage } from '@inertiajs/vue3';
import { computed, inject, ref } from 'vue';
import useBarcodeScanner from '@/lib/composables/barcode-scanner.js';
import { collect } from 'collect.js';
import LinkVuetify from '@/Components/LinkVuetify.vue';
import { join_names } from '@/lib/helpers.js';
import moment from 'moment';
import { mdiTrashCanOutline } from '@mdi/js';
import GoodsArrivalSummary from '@/Pages/GoodsArrival/Partials/GoodsArrivalSummary.vue';
import GoodsArrivalLinesTable from '@/Pages/GoodsArrival/Partials/GoodsArrivalLinesTable.vue';
import GoodsArrivalDiffCard from '@/Pages/GoodsArrival/Partials/GoodsArrivalDiffCard.vue';
import PalletCard from '@/Pages/GoodsArrival/Partials/PalletCard.vue';
import AlarmModal from '@/Components/AlarmModal.vue';
import { trans } from 'laravel-vue-i18n';

const page = usePage();
const flash = inject('flash');

const newScans = ref([]);
const deletedScans = ref([]);

const scans = computed(() =>
    [...page.props.goods_arrival.scans, ...newScans.value]
        .filter((scan) => !deletedScans.value.includes(scan.id))
        .reverse(),
);

const itemsByItemNumber = computed(() => {
    return collect(page.props.items).keyBy('No_').all();
});
const unitsByItemNumber = computed(() => {
    return collect(page.props.items)
        .keyBy('No_')
        .map((item) =>
            collect(item.units_of_measure)
                .mapWithKeys((unit) => [
                    unit.Code,
                    unit['Qty_ per Unit of Measure'],
                ])
                .all(),
        )
        .all();
});

// could remove this and use page.props.goods_arrival.lines directly
const lines = computed(() => {
    return page.props.goods_arrival.lines.map((line) => {
        return line;
    });
});

const goodsArrivalLinesTable = ref(null);
const scanning = ref(false);
const palletScans = ref([]);
const palletArticleLock = ref(null);
const showAlarmModal = ref(false);
const alarmModalMessage = ref('');
const onCodeScanned = async (code) => {
    if (code === '__NEW_PAL' || code === '??NEW?PAL') {
        palletScans.value = [];
        palletArticleLock.value = null;
        return;
    }

    // If scanned code is different from other ones on pallet
    if (palletArticleLock.value !== null && palletArticleLock.value !== code) {
        showAlarmModal.value = true;
        alarmModalMessage.value = trans('Pallet contains different articles.');
    }

    if (palletArticleLock.value === null) {
        palletArticleLock.value = code;
    }

    scanning.value = true;
    try {
        const response = await axios.post(
            route('api.goods-arrival.scans.store', {
                goods_arrival: page.props.goods_arrival.id,
            }),
            {
                code,
            },
        );
        palletScans.value.push(response.data.scan);
        goodsArrivalLinesTable.value.scrollToItemKey(
            response.data.item.No_ +
                response.data.scan.data.batch +
                response.data.scan.unit,
        );

        // Check if itemsByNumber contains key response.data.No_
        if (
            !Object.prototype.hasOwnProperty.call(
                itemsByItemNumber.value,
                response.data.item.No_,
            )
        ) {
            page.props.items.push(response.data.item);
        }

        newScans.value.push(response.data.scan);
    } catch (e) {
        flash(e.response?.data?.message ?? 'ERROR', 'danger', 5000);
    }

    scanning.value = false;
};
useBarcodeScanner({
    validate: false,
    onScanned: onCodeScanned,
});

const deletingScanIds = ref([]);
const deleting = computed(() => deletingScanIds.value.length > 0);
const deleteScan = async (scanId) => {
    if (deleting.value) {
        return;
    }
    deletingScanIds.value.push(scanId);
    await axios.delete(
        route('api.goods-arrival.scans.destroy', {
            goods_arrival: page.props.goods_arrival.id,
            scan: scanId,
        }),
    );
    deletingScanIds.value = deletingScanIds.value.filter((id) => id !== scanId);

    deletedScans.value.push(scanId);
};
</script>

<template>
    <v-container fluid class="tw-h-full">
        <AlarmModal
            v-model="showAlarmModal"
            :message="alarmModalMessage"
        ></AlarmModal>
        <v-row class="tw-max-h-[calc(100vh-64px)]">
            <v-col cols="9" class="tw-flex tw-h-full tw-flex-col">
                <v-row class="tw-grow-0"
                    ><v-col class="tw-flex">
                        <GoodsArrivalSummary
                            class="tw-ml-4"
                            :goods-arrival="page.props.goods_arrival"
                        ></GoodsArrivalSummary>
                        <v-spacer />
                        <LinkVuetify
                            method="put"
                            color="primary"
                            :href="
                                route('goods-arrival.complete', {
                                    goods_arrival: page.props.goods_arrival.id,
                                })
                            "
                            >{{ $t('Complete') }}</LinkVuetify
                        >
                        <LinkVuetify
                            :href="
                                route('goods-arrival.reload', {
                                    goods_arrival: page.props.goods_arrival.id,
                                })
                            "
                            class="tw-ml-4"
                            method="put"
                            color="info"
                            >{{ $t('Reload') }}</LinkVuetify
                        >
                    </v-col>
                </v-row>
                <v-row class="tw-flex tw-grow tw-items-stretch">
                    <v-col class="tw-flex tw-overflow-hidden">
                        <GoodsArrivalLinesTable
                            ref="goodsArrivalLinesTable"
                            :scans="scans"
                            :rows="lines"
                            :items-by-item-number="itemsByItemNumber"
                            :units-by-item-number="unitsByItemNumber"
                        />
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="3" class="tw-flex tw-flex-col">
                <v-card
                    :loading="deleting || scanning"
                    class="tw-mb-4 tw-h-[350px] tw-overflow-auto"
                >
                    <v-card-text>
                        <div
                            v-for="scan in scans"
                            :key="scan.id"
                            class="tw-flex tw-w-full tw-items-center tw-justify-between tw-p-2 first:tw-border first:tw-border-solid even:tw-bg-gray-100"
                        >
                            <div>
                                <div>
                                    {{
                                        itemsByItemNumber[scan.item_number].No_
                                    }}
                                    {{
                                        join_names(
                                            itemsByItemNumber[scan.item_number]
                                                .Description,
                                            itemsByItemNumber[scan.item_number][
                                                'Description 2'
                                            ],
                                        )
                                    }}
                                    -
                                    {{ scan.unit }}
                                    -
                                    {{ scan.data.batch }}
                                </div>
                                <div
                                    class="tw-text-xs tw-font-normal tw-text-gray-400"
                                >
                                    {{
                                        moment(scan.created_at).format(
                                            'DD.MM.YYYY HH:mm:ss',
                                        )
                                    }}
                                </div>
                            </div>
                            <v-btn
                                :disabled="deleting"
                                :loading="deletingScanIds.includes(scan.id)"
                                density="comfortable"
                                :icon="mdiTrashCanOutline"
                                @click="deleteScan(scan.id)"
                            ></v-btn>
                        </div>
                    </v-card-text>
                </v-card>
                <PalletCard
                    class="tw-mb-4"
                    :pallet-scans="palletScans"
                ></PalletCard>
                <GoodsArrivalDiffCard
                    class="tw-h-[270px] tw-w-full tw-grow tw-text-xl"
                    :rows="lines"
                    :scans="scans"
                    :items-by-item-number="itemsByItemNumber"
                    :units-by-item-number="unitsByItemNumber"
                ></GoodsArrivalDiffCard>
            </v-col>
        </v-row>
    </v-container>
</template>

<style scoped></style>
