<script setup>
import { computed, ref } from 'vue';
import { trans as $t } from 'laravel-vue-i18n';
import { router } from '@inertiajs/vue3';
import createQueryRequestParams from '@/lib/ag-grid/create-query-request-params';
import columnTypes from '@/lib/ag-grid/column-types';
import defaultColDef from '@/lib/ag-grid/default-column-definitions';
import { AgGridVue } from 'ag-grid-vue3';
import SavedOrderTableActionButtons from '@/Pages/Order/Partials/SavedOrderTableActionButtons.vue';
import {
    columnStateEvents,
    getColumnState,
    gridApi,
    onGridReady,
    stateApplied,
} from '@/lib/ag-grid/save-column-state';
import TableLoading from '@/Components/TableLoading.vue';

const props = defineProps({
    savedOrders: {
        type: Array,
        required: true,
    },
    totalSaved: {
        type: Number,
        required: true,
    },
    initiallySelected: {
        type: Array,
        default: () => [],
    },
});
const emit = defineEmits(['onShow', 'onEdit', 'onSelect']);

const initialOrderLoad = ref(true);
const lastRequest = ref(null);
const dataSource = {
    getRows({ request, success, fail }) {
        if (initialOrderLoad.value) {
            initialOrderLoad.value = false;
            success({
                rowData: props.savedOrders,
                rowCount: props.totalSaved,
            });
            if (props.initiallySelected.length > 0) {
                select(props.initiallySelected);
            }
        } else {
            const params = createQueryRequestParams(request);

            axios
                .get(route('api.order.index'), { params })
                .then((response) => {
                    success({
                        rowData: response.data.data,
                        rowCount: response.data.meta.total,
                    });
                })
                .catch(() => fail());
        }

        lastRequest.value = request;
    },
};

const reload = (ids) => {
    gridApi.value?.showLoadingOverlay();
    gridApi.value?.refreshServerSide(lastRequest.value);
    onSelectionChanged(ids);
};
const onModelUpdated = () => {
    gridApi.value?.hideOverlay();
};

const getRowId = (params) => params.data.id;
const onOrderEdit = (order_number) => {
    router.visit(route('order.edit', { order: order_number }));
};

const onOrderShow = (order_number) => {
    emit('onShow', order_number);
};

getColumnState('saved_order');

const columnDefs = computed(() => [
    {
        headerName: $t('Reference'),
        field: 'reference',
        type: 'textColumnFilter',
        headerTooltip: $t('Reference'),
        minWidth: 100,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        showDisabledCheckboxes: true,
    },
    {
        headerName: $t('Customer Number'),
        field: 'customer_number',
        type: 'textColumnFilter',
        hide: false,
        headerTooltip: $t('Customer Number'),
    },
    {
        headerName: $t('Order Number'),
        hide: true,
        field: 'order_number',
        type: 'textColumnFilter',
        headerTooltip: $t('Order Number'),
    },
    {
        headerName: $t('Created Date'),
        field: 'created_at',
        type: 'dateColumnFilter',
        valueFormatter: (params) => {
            return params.value
                ? moment(params.value).format('DD.MM.YYYY')
                : '';
        },
        sort: 'desc',
        minWidth: 130,
        headerTooltip: $t('Created Date'),
    },
    {
        headerName: $t('Name'),
        field: 'name',
        valueGetter: (params) => {
            return (
                params.data.name +
                (params.data.name2 ? ' ' + params.data.name2 : '')
            );
        },
        type: 'textColumnFilter',
        headerTooltip: $t('Name'),
    },
    {
        headerName: $t('Address'),
        field: 'address',
        valueGetter: (params) => {
            return (
                params.data.address +
                (params.data.address2 ? ' ' + params.data.address2 : '')
            );
        },
        type: 'textColumnFilter',
        headerTooltip: $t('Address'),
    },
    {
        headerName: $t('Postal Code'),
        field: 'postal_code',
        type: 'textColumnFilter',
        headerTooltip: $t('Postal Code'),
    },
    {
        headerName: $t('City'),
        field: 'city',
        type: 'textColumnFilter',
        headerTooltip: $t('City'),
    },
    {
        headerName: $t('Country'),
        field: 'country',
        type: 'textColumnFilter',
        maxWidth: 80,
        headerTooltip: $t('Country'),
    },
    {
        colId: 'actions',
        cellRenderer: SavedOrderTableActionButtons,
        cellClass: 'ag-cell-no-border',
        sortable: false,
        cellRendererParams: {
            onEdit: onOrderEdit,
            onShow: onOrderShow,
        },
        minWidth: 70,
        headerTooltip: $t('Actions'),
        flex: 0,
        resizable: true,
    },
]);

// takes a parameter id. the order with that id will be omitted from the selection result,
// now and in the future (through deletedOrderIds)
const deletedOrderIds = ref([]);
const onSelectionChanged = (orderIds = []) => {
    deletedOrderIds.value.push(...orderIds);
    const selectionState = gridApi.value.getServerSideSelectionState();
    let selectedRowNodeIds = null;
    let selectedRows = [];

    if (selectionState.selectAll) {
        gridApi.value.forEachNode((node) => {
            !deletedOrderIds.value.includes(node.data.id) &&
                !selectionState.toggledNodes.includes(String(node.data.id)) &&
                selectedRows.push(node.data);
        });
    } else {
        selectedRowNodeIds = selectionState.toggledNodes.filter((id) => {
            return !deletedOrderIds.value.includes(Number(id));
        });

        selectedRows = selectedRowNodeIds.map((id) => {
            const order = gridApi.value.getRowNode(id)?.data;
            return order !== undefined ? order : null;
        });
    }

    emit(
        'onSelect',
        selectedRows.filter((order) => order !== null),
    );
};
const select = (orderIds = []) => {
    gridApi.value?.deselectAll();
    gridApi.value?.setServerSideSelectionState({
        selectAll: false,
        toggledNodes: orderIds.map((id) => id.toString()),
    });
};

defineExpose({ reload, select });
// const columnBreakpoints = {
//     xl: {
//         breakpoint: 1200,
//         visibleColumns: [
//             'reference',
//             'order_number',
//             'created_at',
//             'name',
//             'address',
//             'postal_code',
//             'city',
//             'country',
//             'actions',
//         ]
//     },
// }
</script>

<template>
    <v-card class="tw-h-full">
        <TableLoading :loading="!stateApplied">
            <ag-grid-vue
                class="ag-theme-material tw-h-full"
                :column-types="columnTypes"
                :default-col-def="defaultColDef"
                :column-defs="columnDefs"
                :get-row-id="getRowId"
                row-model-type="serverSide"
                :block-load-debounce-millis="200"
                :server-side-datasource="dataSource"
                :enable-range-selection="true"
                row-selection="multiple"
                :suppress-row-click-selection="true"
                :checkbox-selection="true"
                @grid-ready="onGridReady"
                @model-updated="onModelUpdated"
                @selection-changed="onSelectionChanged()"
                v-on="columnStateEvents"
            />
        </TableLoading>
    </v-card>
</template>

<style scoped></style>
