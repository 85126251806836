<script setup>
import { ref } from 'vue';
import { watchDebounced } from '@vueuse/core';
import colors from 'tailwindcss/colors';

const delivery_note = defineModel({
    type: [Object, null],
    required: true,
});

const selectLoading = ref(false);
const search = ref('');
const items = ref([]);
watchDebounced(
    search,
    async () => {
        if (search.value.length >= 3) {
            const response = await axios.get(
                route('api.maillog.delivery_note.index', {
                    filter: { delivery_note_number: search.value },
                }),
            );

            items.value = response.data;
        }
    },
    { debounce: 300 },
);
</script>

<template>
    <v-autocomplete
        v-model="delivery_note"
        v-model:search="search"
        color="primary"
        :loading="selectLoading ? 'primary' : false"
        :items
        item-title="delivery_note_number"
        item-value="delivery_note_number"
        return-object
        :bg-color="colors.gray[100]"
    ></v-autocomplete>
</template>

<style scoped></style>
