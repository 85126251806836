<script setup>
import { useVModel } from '@vueuse/core';
import { useForm } from '@inertiajs/vue3';
import TextField from '@/Components/TextField.vue';
import { ref, watch } from 'vue';
import SaveButton from '@/Components/SaveButton.vue';
import {
    mdiPackageVariantClosedMinus,
    mdiPackageVariantClosedPlus,
} from '@mdi/js';

const props = defineProps({
    modelValue: {
        type: Boolean,
        default: false,
    },
    warehouseLocation: {
        type: Object,
        default: null,
    },
    warehouseItem: {
        type: Object,
        default: null,
    },
});
const emit = defineEmits(['update:modelValue', 'submitted']);

const showModal = useVModel(props, 'modelValue', emit);
watch(showModal, (show) => {
    if (show) {
        inOutForm.warehouse_location_id = props.warehouseLocation.id;
        inOutForm.warehouse_item_id = props.warehouseItem.id;
        inOutForm.defaults();
    } else {
        inOutForm.reset('quantity');
        incoming.value = null;
        outgoing.value = null;
    }
});

const incoming = ref(null);
const outgoing = ref(null);
const inOutForm = useForm({
    warehouse_location_id: props.warehouseLocation?.id,
    warehouse_item_id: props.warehouseItem?.id,
    quantity: 0,
    note: null,
});

watch(incoming, (value) => {
    if (value) {
        outgoing.value = null;
        const quantity = Number(value);
        if (quantity > 0) {
            inOutForm.quantity = quantity;
        } else {
            inOutForm.quantity = 0;
        }
    }
});

watch(outgoing, (value) => {
    if (value) {
        incoming.value = null;
        const quantity = Number(value);
        if (quantity > 0) {
            inOutForm.quantity = -quantity;
        } else {
            inOutForm.quantity = 0;
        }
    }
});

watch([incoming, outgoing], () => {
    if (!incoming.value && !outgoing.value) {
        inOutForm.reset('quantity');
    }
});

const submitTransactionForm = () => {
    inOutForm.post(route('warehouse.transaction.store'), {
        onSuccess() {
            inOutForm.reset();
            emit('submitted');
            showModal.value = false;
        },
    });
};
</script>

<template>
    <v-dialog v-model="showModal" content-class="tw-max-w-lg">
        <v-card>
            <template #title>
                <div class="tw-uppercase">
                    {{ $t('Warehouse transaction for') }}
                    <b>{{ warehouseLocation.description }}</b>
                </div>
                <div class="tw-mb-2 tw-text-base tw-font-light tw-uppercase">
                    {{ $t('For item') }}:
                    <span class="tw-font-black">
                        {{ warehouseItem.description }}
                    </span>
                </div>
            </template>
            <template #text>
                <TextField
                    v-model="incoming"
                    :disabled="!!outgoing"
                    type="number"
                    min="0"
                    :label="$t('Incoming')"
                    :prepend-icon="mdiPackageVariantClosedPlus"
                    hide-details="auto"
                    :error-messages="!outgoing ? inOutForm.errors.quantity : []"
                ></TextField>
                <TextField
                    v-model="outgoing"
                    :disabled="!!incoming"
                    min="0"
                    type="number"
                    :label="$t('Outgoing')"
                    :prepend-icon="mdiPackageVariantClosedMinus"
                    hide-details="auto"
                    :error-messages="!incoming ? inOutForm.errors.quantity : []"
                ></TextField>
            </template>
            <v-toolbar height="50" class="tw-py-1">
                <v-spacer></v-spacer>
                <div class="tw-flex tw-h-full tw-items-center">
                    <v-btn
                        color="danger"
                        variant="elevated"
                        class="tw-mr-2"
                        @click="showModal = false"
                        >{{ $t('Cancel') }}
                    </v-btn>
                    <SaveButton
                        :disabled="!inOutForm.isDirty"
                        :loading="inOutForm.processing"
                        class="tw-mr-4"
                        type="button"
                        @click="submitTransactionForm"
                        >{{ $t('Submit') }}
                    </SaveButton>
                </div>
            </v-toolbar>
        </v-card>
    </v-dialog>
</template>

<style scoped></style>
