<script setup>
import { mdiMagnify, mdiPencil } from '@mdi/js';

const props = defineProps({
    // eslint-disable-next-line vue/require-default-prop
    params: {
        type: Object,
        required: true,
    },
});

// eslint-disable-next-line vue/no-setup-props-destructure
const { onShow, onEdit } = props.params;
</script>

<template>
    <div class="tw-flex tw-items-center tw-h-full tw-justify-center">
        <v-btn
            class="tw-mr-2"
            color="info"
            density="comfortable"
            size="small"
            :icon="mdiMagnify"
            @click.prevent="onShow(props.params.data.id)"
        >
        </v-btn>
        <v-btn
            size="small"
            density="comfortable"
            color="surface"
            :icon="mdiPencil"
            @click.prevent="onEdit(props.params.data.id)"
        />
    </div>
</template>
