<script setup>
import {
    getColumnState,
    stateApplied,
    onGridReady,
} from '@/lib/ag-grid/save-column-state.js';
import AgTableCard from '@/Components/AgTableCard.vue';
import { AgGridVue } from 'ag-grid-vue3';
import { router, usePage } from '@inertiajs/vue3';
import defaultColumnDefinitions from '@/lib/ag-grid/default-column-definitions.js';
import { ref } from 'vue';
import { trans as $t } from 'laravel-vue-i18n';
import GoodsArrivalTableActionButtons from '@/Pages/GoodsArrival/Partials/GoodsArrivalTableActionButtons.vue';

const page = usePage();
getColumnState('goods_arrival');

const columnDefs = ref([
    {
        field: 'id',
    },
    {
        field: 'customer_number',
        headerName: $t('Customer Number'),
    },
    {
        field: 'reference',
        headerName: $t('Reference'),
    },
    {
        field: 'arrival_date',
        headerName: $t('Arrival Date'),
    },
    {
        field: 'quantity',
        headerName: $t('Quantity'),
    },
    {
        field: 'package_type',
        headerName: $t('Package Type'),
    },
    {
        field: 'status',
        headerName: $t('Status'),
    },
    {
        field: 'actions',
        headerName: $t('Actions'),
        cellRenderer: GoodsArrivalTableActionButtons,
    },
]);

/**
 * @param {RowClickedEvent} event
 */
const onRowClicked = (event) => {
    router.post(
        route('goods-arrival.store-from-shipping-advice', {
            shipping_advice: event.data.id,
        }),
    );
};
</script>

<template>
    <AgTableCard :loading="!stateApplied">
        <ag-grid-vue
            class="ag-theme-material tw-h-full"
            :row-data="page.props.shipping_advices"
            :default-column-defs="defaultColumnDefinitions"
            :column-defs="columnDefs"
            @grid-ready="onGridReady"
            @row-clicked="onRowClicked"
        ></ag-grid-vue>
    </AgTableCard>
</template>

<style scoped>
:deep(.ag-theme-material) .ag-cell {
    cursor: pointer;
}
</style>
