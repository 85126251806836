<script setup>
import ActionSection from '@/Components/ActionSection.vue';
import { ref } from 'vue';
import SelectField from '@/Components/SelectField.vue';
import { usePage } from '@inertiajs/vue3';

const page = usePage();

const command = ref(page.props.commands[0]);
const running = ref(false);
const lastOutput = ref('');
const exitCode = ref(0);
const run = async () => {
    running.value = true;
    let response = null;
    try {
        response = await axios.post('/api/development/run-command', {
            command: command.value,
        });
        lastOutput.value = response.data.output;
        exitCode.value = response.data.exitCode;
    } catch (e) {
        lastOutput.value = e.response.data.message;
    }

    running.value = false;
};
</script>

<template>
    <v-container class="tw-h-full">
        <div>
            <ActionSection>
                <template #title>
                    {{ $t('Commands') }}
                </template>
                <template #description>
                    {{ $t('Run artisan commands from the browser.') }}
                </template>
                <template #content>
                    <v-row>
                        <v-col>
                            <SelectField
                                v-model="command"
                                :items="page.props.commands"
                            >
                                <template #append>
                                    <v-btn
                                        :loading="running"
                                        color="primary"
                                        @click="run"
                                        >Execute
                                    </v-btn>
                                </template>
                            </SelectField>
                        </v-col>
                    </v-row>
                    <v-sheet
                        class="tw-mt-4 tw-flex tw-p-2 tw-pb-4"
                        rounded
                        min-height="50"
                        :color="exitCode ? 'danger' : null"
                    >
                        <pre
                            v-if="lastOutput"
                            class="tw-w-full tw-whitespace-break-spaces"
                            v-text="lastOutput"
                        ></pre>
                        <div
                            v-else
                            class="tw-flex tw-w-full tw-items-center tw-justify-center tw-self-stretch tw-pt-2"
                        >
                            No output...
                        </div>
                    </v-sheet>
                </template>
            </ActionSection>
        </div>
    </v-container>
</template>

<style scoped></style>
