import './bootstrap';
import 'vuetify/styles';
import '../scss/app.scss';
import '../scss/tailwind.scss';
import '@fontsource-variable/nunito';

import { createApp, defineAsyncComponent, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { ZiggyVue } from '../../vendor/tightenco/ziggy';
import vuetify from '@/plugins/vuetify';
import { i18nVue } from 'laravel-vue-i18n';
import laravelI18nOptions from '@/plugins/laravel-i18n-options';
import VWave from 'v-wave';
import { VList } from 'vuetify/components/VList';
import RootLayout from '@/Layouts/RootLayout.vue';
import AppMain from '@/Layouts/AppMain.vue';
import { InertiaRouterStates } from '@/plugins/inertia-router-states.js';

const AppLayout = defineAsyncComponent(() => import('@/Layouts/AppLayout.vue'));

const appName =
    window.document.getElementsByTagName('title')[0]?.innerText || 'My Maillog';

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => {
        const pages = import.meta.glob('./Pages/**/*.vue', { eager: true });
        let page = pages[`./Pages/${name}.vue`];
        page.default.layout =
            page.default.layout === undefined
                ? (h, page) =>
                      h(RootLayout, () =>
                          h(AppLayout, () => h(AppMain, () => page)),
                      )
                : page.default.layout;
        return page;
    },
    setup({ el, App, props, plugin }) {
        return (
            createApp({ render: () => h(App, props) })
                .use(plugin)
                .use(ZiggyVue)
                .use(vuetify)
                .use(VWave)
                // v-list for draggable component resolution
                .component('v-list', VList)
                .use(i18nVue, laravelI18nOptions)
                .use(InertiaRouterStates)
                .mount(el)
        );
    },
    progress: { color: '#558B2F' },
});
