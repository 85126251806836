<script>
import { ref } from 'vue';
import CustomerSelect from '@/Components/CustomerSelect.vue';

export default {
    components: { CustomerSelect },
    props: {
        params: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        const combobox = ref(null);
        const value = ref(props.params.value);

        const getValue = () => {
            return value.value;
        };
        const focusIn = () => {
            combobox.value.focus();
        };
        const menuProps = {
            attach: document.querySelector('#menus'),
        };

        return {
            value,
            getValue,
            focusIn,
            combobox,
            menuProps,
        };
    },
    mounted() {
        if (this.$props.params.cellStartedEdit) {
            this.$nextTick(() => {
                this.combobox.focus();
            });
        }
    },
};
</script>

<template>
    <CustomerSelect
        v-model="value"
        variant="underlined"
        single-line
        hide-details
        density="compact"
        :menu-props="menuProps"
        :selected-customer="params.data.customer"
        clearable
    ></CustomerSelect>
</template>

<style scoped></style>
