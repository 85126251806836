<script setup>
import FormSection from '@/Components/FormSection.vue';
import { useForm } from '@inertiajs/vue3';
import TextField from '@/Components/TextField.vue';
import SaveButton from '@/Components/SaveButton.vue';

const props = defineProps({
    item: {
        type: Object,
        required: true,
    },
});

const form = useForm({
    ...props.item,
});

const submitCreateArticleForm = () => {
    if (form.id) form.put(route('warehouse.items.update', { item: form.id }));
    else form.post(route('warehouse.items.store'));
};
</script>

<template>
    <v-container class="tw-min-h-full">
        <FormSection @submitted="submitCreateArticleForm">
            <template v-if="!item.id" #title>{{
                $t('Create Article')
            }}</template>
            <template v-else #title>{{ $t('Edit Article') }}</template>
            <template v-if="!item.id" #description>{{
                $t('Create new Warehouse Articles')
            }}</template>
            <template v-else #description>{{
                $t('Edit Warehouse Articles')
            }}</template>
            <template #form>
                <TextField v-model="form.sku" :label="$t('SKU')"></TextField>
                <TextField
                    v-model="form.description"
                    :label="$t('Description')"
                ></TextField>
            </template>
            <template #actions>
                <SaveButton
                    :loading="form.processing"
                    :disabled="!form.isDirty"
                ></SaveButton>
            </template>
        </FormSection>
    </v-container>
</template>

<style scoped></style>
