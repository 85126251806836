<script setup>
import { useVModel, watchIgnorable } from '@vueuse/core';
import { ref, watch } from 'vue';

const props = defineProps({
    modelValue: {
        type: Number,
        required: true,
    },
    size: {
        type: Number,
        required: true,
    },
    pagingData: {
        type: Object,
        required: true,
    },
});
const emit = defineEmits(['update:modelValue']);

const page = useVModel(props, 'modelValue', emit);
const currentPage = ref(props.pagingData.current_page);

const { ignoreUpdates: ignoreCurrentPage } = watchIgnorable(
    currentPage,
    (newPage, oldPage) => {
        ignorePage(() => {
            page.value = newPage;
        });
        ignoreCurrentPage(() => (currentPage.value = oldPage));
    }
);

const { ignoreUpdates: ignorePage } = watchIgnorable(page, () => {
    currentPage.value = page.value;
});

watch(
    () => props.pagingData,
    () =>
        ignoreCurrentPage(
            () => (currentPage.value = props.pagingData.current_page)
        )
);
</script>

<template>
    <v-pagination
        v-model="currentPage"
        class="tw-w-full"
        :size="size"
        color="primary"
        :length="pagingData.last_page"
    >
    </v-pagination>
</template>

<style scoped></style>
