<script setup>
import { useVModel } from '@vueuse/core';
import ActionSection from '@/Components/ActionSection.vue';
import { mdiClose, mdiMagnify, mdiPlus, mdiTable } from '@mdi/js';
import { nextTick, ref, watch } from 'vue';
import DialogModal from '@/Components/DialogModal.vue';
import { usePage } from '@inertiajs/vue3';
import ItemTable from '@/Components/ItemTable.vue';
import ValidationErrorCard from '@/Components/ValidationErrorCard.vue';
import ItemSelectFormRow from '@/Components/ItemSelectFormRow.vue';

const props = defineProps({
    modelValue: { type: Array, required: true },
    customerNumber: { type: String, required: true },
    withBatch: { type: Boolean, required: true },
    manualBatch: { type: Boolean, default: false },
    tableAddItems: { type: Boolean, default: false },
    showAllItems: { type: Boolean, default: false },
});
const emit = defineEmits(['update:modelValue']);
const page = usePage();

const lines = useVModel(props, 'modelValue', emit);
const maxId = ref(
    lines.value.length > 0
        ? lines.value.reduce((a, b) => {
              return a.id > b.id ? a : b;
          }).id
        : 1,
);
const createItem = function () {
    lines.value.push({
        id: 'new_' + maxId.value++,
        sku: null,
        item_number: '',
        quantity: 1,
        batch: '',
        unit: null,
    });
};

const removeItem = function (index) {
    lines.value.splice(index, 1);
};

const showModal = ref(false);
const modalItems = ref([]);
const articleSearch = ref(null);
const openOrderLineModal = async () => {
    try {
        showModal.value = true;
        if (modalItems.value.length === 0) {
            const response = await axios.get(route('api.maillog.item.stock'), {
                params: {
                    filter: {
                        customer_number: props.customerNumber,
                    },
                },
            });
            const groupedItems = response.data.reduce(
                (accumulator, currentValue) => {
                    const key = `${currentValue.item_number}-${currentValue.unit}`;
                    if (!accumulator.has(key)) {
                        delete currentValue.batch;
                        delete currentValue.location;
                        delete currentValue.bin;
                        accumulator.set(key, currentValue);
                    } else {
                        accumulator.get(key).quantity += currentValue.quantity;
                    }
                    return accumulator;
                },
                new Map(),
            );
            modalItems.value = Array.from(groupedItems.values());

            if (!props.showAllItems) {
                modalItems.value = modalItems.value.filter(
                    (item) =>
                        page.props.auth.canOrderMoreThanStock ||
                        item.quantity > 0,
                );
            }

            modalItems.value = modalItems.value.map((item) => ({
                ...item,
                line_quantity: 0,
            }));
        } else {
            modalItems.value.forEach((item) => (item.line_quantity = 0));
        }

        // Dont fill in quantity if we are adding rows through the table (not replacing)
        if (!props.tableAddItems) {
            modalItems.value.forEach((item) => {
                const line = lines.value.find(
                    (line) =>
                        line.item_number === item.item_number &&
                        line.unit === item.unit,
                );
                if (line) {
                    item.line_quantity += Number(line.quantity) || 0;
                } else {
                    item.line_quantity = 0;
                }
            });
        }
    } catch (e) {
        console.log(e);
    }
};

const orderLineTableElement = ref(null);
const closeModal = async () => {
    orderLineTableElement.value.gridApi.stopEditing(false);
    await nextTick();
    page.props.errors = {};
    showModal.value = false;
    if (modalItems.value.length > 0) {
        const items = modalItems.value
            .filter((item) => item.line_quantity > 0)
            .map((item) => ({
                id: 'new_' + maxId.value++,
                sku: item.sku,
                item_number: item.item_number,
                quantity: item.line_quantity,
                batch: null,
                unit: item.unit,
                item: item.item,
            }));

        if (!props.tableAddItems) {
            lines.value = items;
        } else {
            lines.value = lines.value.concat(items);
        }
    }
};

watch(
    () => props.customerNumber,
    () => {
        lines.value = [];
        maxId.value = 1;
        modalItems.value = [];
        articleSearch.value = null;
    },
);
</script>

<template>
    <DialogModal
        :toolbar-props="{ color: 'info' }"
        full-height
        :show="showModal"
        @close="closeModal"
    >
        <template #toolbar>
            <v-toolbar-title>{{ $t('Order Items') }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <div class="tw-flex tw-items-center">
                    <v-text-field
                        v-model="articleSearch"
                        class="tw-w-64"
                        density="comfortable"
                        :append-icon="mdiMagnify"
                        :label="$t('Article Search')"
                        :single-line="true"
                        hide-details
                        dense
                        clearable=""
                    ></v-text-field>
                </div>
            </v-toolbar-items>
            <v-btn variant="text" :icon="mdiClose" @click="closeModal"></v-btn>
        </template>
        <template #content>
            <ItemTable
                ref="orderLineTableElement"
                :search="articleSearch"
                :row-data="modalItems"
            ></ItemTable>
        </template>
        <template #footer>
            <v-spacer></v-spacer>
            <v-btn @click="closeModal">{{ $t('Close') }}</v-btn>
        </template>
    </DialogModal>
    <ActionSection v-bind="$attrs">
        <template #title> <slot name="title"></slot></template>
        <template #description>
            <slot name="description" />
        </template>
        <template #content>
            <div>
                <v-btn
                    class="tw-mr-2"
                    size="small"
                    color="black"
                    :prepend-icon="mdiPlus"
                    @click="createItem"
                    >{{ $t('Create Item') }}
                </v-btn>
                <v-btn
                    size="small"
                    color="info"
                    :prepend-icon="mdiTable"
                    @click="openOrderLineModal"
                >
                    {{ $t('List Entry') }}
                </v-btn>
            </div>
            <div v-if="page.props.errors.lines" class="tw-mt-4">
                <ValidationErrorCard
                    :errors="[page.props.errors.lines]"
                ></ValidationErrorCard>
            </div>
            <v-table v-if="lines.length > 0">
                <thead>
                    <tr>
                        <th class="tw-min-w-[200px]">{{ $t('Article') }}</th>
                        <th>{{ $t('Quantity') }}</th>
                        <th v-if="withBatch">{{ $t('Batch') }}</th>
                        <th>{{ $t('Unit') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <ItemSelectFormRow
                        v-for="(line, index) in lines"
                        :key="line.id"
                        v-model="lines[index]"
                        :index="index"
                        :customer-number="props.customerNumber"
                        :with-batch="props.withBatch"
                        :show-all-items="props.showAllItems"
                        manual-batch
                        @delete="removeItem(index)"
                    />
                </tbody>
            </v-table>
        </template>
    </ActionSection>
</template>

<style scoped></style>
