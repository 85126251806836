<script setup>
import { computed } from 'vue';
import { mdiNotebookEditOutline, mdiPackageVariantClosed } from '@mdi/js';

const props = defineProps({
    params: {
        type: Object,
        required: true,
    },
});

const stock = computed(() => props.params.data.stock[props.params.locationId]);
const reserved = computed(
    () => props.params.data.reserved[props.params.locationId],
);
</script>
<template>
    <div
        v-if="params.getValue() !== null"
        class="tw-inline-block tw-h-full tw-w-full tw-align-middle"
    >
        <div class="tw-text-center tw-leading-tight">
            <span class="tw-text-lg tw-font-light">
                {{ params.getValue() }}
            </span>
        </div>
        <div class="tw-text-center tw-leading-none">
            <v-icon
                class="tw-mb-1 tw-inline-block"
                size="x-small"
                color="secondary"
                :icon="mdiNotebookEditOutline"
            ></v-icon>
            <div class="tw-inline-block">
                <span class="tw-mr-2">{{ reserved }} </span>
            </div>
            <v-icon
                class="tw-mb-1 tw-inline-block"
                color="alert"
                size="x-small"
                :icon="mdiPackageVariantClosed"
            ></v-icon>
            <div class="tw-inline-block">
                <span>
                    {{ stock }}
                </span>
            </div>
        </div>
    </div>
</template>

<style scoped></style>
