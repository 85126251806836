<script setup>
import { ref } from 'vue';
import { useForm } from '@inertiajs/vue3';
import TextField from '@/Components/TextField.vue';
import ActionSection from '@/Components/ActionSection.vue';
import DialogModal from '@/Components/DialogModal.vue';
import ActionMessage from '@/Components/ActionMessage.vue';

defineProps({
    sessions: { type: Array, default: () => [] },
});

const confirmingLogout = ref(false);
const passwordInput = ref(null);

const form = useForm({
    password: '',
});

const confirmLogout = () => {
    confirmingLogout.value = true;

    setTimeout(() => passwordInput.value.focus(), 250);
};

const logoutOtherBrowserSessions = () => {
    form.delete(route('other-browser-sessions.destroy'), {
        preserveScroll: true,
        onSuccess: () => closeModal(),
        onError: () => passwordInput.value.focus(),
        onFinish: () => form.reset(),
    });
};

const closeModal = () => {
    confirmingLogout.value = false;
    form.reset();
};
</script>

<template>
    <ActionSection>
        <template #title>
            {{ $t('Browser Sessions') }}
        </template>

        <template #description>
            {{
                $t(
                    'Manage and log out your active sessions on other browsers and devices.'
                )
            }}
        </template>

        <template #content>
            <div class="tw-max-w-xl tw-text-sm tw-text-gray-600">
                {{
                    $t(
                        'If necessary, you may log out of all of your other browser sessions across all of your devices. Some of your recent sessions are listed below; however, this list may not be exhaustive. If you feel your account has been compromised, you should also update your password.'
                    )
                }}
            </div>

            <!-- Other Browser Sessions -->
            <div v-if="sessions.length > 0" class="tw-mt-5 tw-space-y-6">
                <div
                    v-for="(session, i) in sessions"
                    :key="i"
                    class="tw-flex tw-items-center"
                >
                    <div>
                        <svg
                            v-if="session.agent.is_desktop"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            class="tw-w-8 tw-h-8 tw-text-gray-500"
                        >
                            <path
                                d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                            />
                        </svg>

                        <svg
                            v-else
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            stroke-width="2"
                            stroke="currentColor"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="tw-w-8 tw-h-8 tw-text-gray-500"
                        >
                            <path d="M0 0h24v24H0z" stroke="none" />
                            <rect x="7" y="4" width="10" height="16" rx="1" />
                            <path d="M11 5h2M12 17v.01" />
                        </svg>
                    </div>

                    <div class="tw-ml-3">
                        <div class="tw-text-sm tw-text-gray-600">
                            {{
                                session.agent.platform
                                    ? session.agent.platform
                                    : 'Unknown'
                            }}
                            -
                            {{
                                session.agent.browser
                                    ? session.agent.browser
                                    : 'Unknown'
                            }}
                        </div>

                        <div>
                            <div class="tw-text-xs tw-text-gray-500">
                                {{ session.ip_address }},

                                <span
                                    v-if="session.is_current_device"
                                    class="tw-text-green-500 tw-font-semibold"
                                    >{{ $t('This device') }}</span
                                >
                                <span v-else>{{
                                    $t('Last active :last_active', {
                                        last_active: session.last_active,
                                    })
                                }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tw-flex tw-items-center tw-mt-5">
                <v-btn color="primary" @click="confirmLogout">{{
                    $t('Log Out Other Browser Sessions')
                }}</v-btn>
                <ActionMessage :on="form.recentlySuccessful" class="tw-mx-2">{{
                    $t('Done.')
                }}</ActionMessage>
            </div>

            <!-- Log Out Other Devices Confirmation Modal -->
            <DialogModal :show="confirmingLogout" @close="closeModal">
                <template #title>
                    {{ $t('Log Out Other Browser Sessions') }}
                </template>

                <template #content>
                    <p class="tw-mb-2">
                        {{
                            $t(
                                'Please enter your password to confirm you would like to log out of your other browser sessions across all of your devices.'
                            )
                        }}
                    </p>
                    <TextField
                        ref="passwordInput"
                        v-model="form.password"
                        class="tw-mb-0"
                        type="password"
                        :label="$t('Password')"
                        :error-messages="form.errors.password"
                        @keyup.enter="logoutOtherBrowserSessions"
                    />
                </template>

                <template #footer>
                    <v-spacer></v-spacer>
                    <v-btn @click="closeModal">
                        {{ $t('Cancel') }}
                    </v-btn>

                    <v-btn
                        variant="elevated"
                        color="primary"
                        :loading="form.processing"
                        @click="logoutOtherBrowserSessions"
                    >
                        {{ $t('Log Out Other Browser Sessions') }}
                    </v-btn>
                </template>
            </DialogModal>
        </template>
    </ActionSection>
</template>
