<script setup>
import { computed, nextTick, onUnmounted, ref, watch } from 'vue';
import { mdiClose } from '@mdi/js';
import { AgGridVue } from 'ag-grid-vue3';
import { trans as $t } from 'laravel-vue-i18n';
import ItemTableActionCellRenderer from '@/Pages/WarehouseManagement/Partials/ItemTableActionCellRenderer.vue';
import InOutModal from '@/Pages/WarehouseManagement/Partials/InOutModal.vue';
import MaterialEditableCellRenderer from '@/Components/CellRenderers/MaterialEditableCellRenderer.vue';
import { usePage } from '@inertiajs/vue3';

const props = defineProps({
    locations: {
        type: Array,
        required: true,
    },
    itemId: {
        type: Number,
        default: null,
    },
    show: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['close']);
const page = usePage();

const showModal = ref(false);
const itemLoading = ref(false);
const item = ref(null);
const selectedLocation = ref(null);

/*const showReservationModal = ref(false);
const onReserve = (data) => {
    if (data.location_id === 'maillog') {
        selectedLocation.value = {
            id: 'maillog',
            description: 'Maillog',
        };
    } else {
        selectedLocation.value = props.locations.find((location) => {
            return location.id === data.location_id;
        });
    }

    showReservationModal.value = true;
};*/

const showInOutModal = ref(false);
const onInOut = (data) => {
    selectedLocation.value = props.locations.find((location) => {
        return location.id === data.location_id;
    });
    showInOutModal.value = true;
};

watch(
    () => props.show,
    async (show) => {
        if (show && props.itemId !== null) {
            itemLoading.value = true;
            try {
                const response = await axios.get(
                    route('api.warehouse.item.show', props.itemId),
                );
                item.value = response.data;
            } catch (e) {
                emit('close');
            } finally {
                itemLoading.value = false;
            }
        }
        showModal.value = show;
    },
);

const defaultColDef = {
    resizable: true,
};
const columnDefs = computed(() => [
    {
        headerName: $t('Location'),
        field: 'location',
        minWidth: 130,
    },
    {
        headerName: $t('Stock'),
        type: 'numericColumn',
        field: 'stock',
    },
    {
        headerName: $t('Reserved'),
        type: 'numericColumn',
        field: 'reserved',
    },
    {
        headerName: $t('Total'),
        type: 'numericColumn',
        field: 'total',
    },
    {
        headerName: $t('Minimum Stock'),
        type: 'numericColumn',
        editable: page.props.auth.canEditWarehouseItems,
        cellRenderer: page.props.auth.canEditWarehouseItems
            ? MaterialEditableCellRenderer
            : null,
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
            min: 0,
            precision: 0,
            step: 1,
            showStepperButtons: true,
        },
        field: 'minimum_stock',
    },
    {
        headerName: '',
        cellRenderer: ItemTableActionCellRenderer,
        cellRendererParams: {
            // onReserve,
            onInOut,
        },
        minWidth: 150,
    },
]);

const rowData = ref([]);
const computedRows = computed(() => {
    const rows = [];

    if (!item.value) {
        return rows;
    }

    props.locations.forEach((location) => {
        rows.push({
            location_id: location.id,
            location: location.description,
            item_number: item.value.item_number,
            stock: item.value.stock[location.id],
            reserved: item.value.reserved[location.id],
            total:
                item.value.stock[location.id] -
                item.value.reserved[location.id],
            minimum_stock: item.value.minimum_stock[location.id],
            minimum_stock_loading: false,
        });
    });

    if (item.value.item_number) {
        rows.push({
            location_id: 'maillog',
            location: 'Maillog',
            item_number: item.value.item_number,
            stock: item.value.stock['maillog'],
            reserved: item.value.reserved['maillog'],
            total: item.value.stock['maillog'] - item.value.reserved['maillog'],
            minimum_stock: item.value.minimum_stock['maillog'],
            minimum_stock_loading: false,
        });
    }

    return rows;
});

watch(computedRows, async (value) => {
    await nextTick();
    rowData.value = value;
});

const onCellValueChanged = (params) => {
    axios.put(
        route('api.warehouse.item.update.minimumStock', {
            warehouse_item: props.itemId,
        }),
        {
            warehouse_location_id: params.data.location_id,
            quantity: params.data.minimum_stock,
        },
    );
};

const onFirstDataRendered = (params) => {
    params.api.sizeColumnsToFit();
};
const onTransactionCompleted = () => {
    emit('close');
};
onUnmounted(() => {
    emit('close');
});
</script>

<template>
    <v-overlay
        :model-value="itemLoading"
        :scrim="true"
        :persistent="true"
        class="tw-items-center tw-justify-center"
    >
        <v-progress-circular
            color="primary"
            indeterminate
            size="x-large"
        ></v-progress-circular>
    </v-overlay>
    <v-dialog
        :fullscreen="true"
        :scrim="false"
        :model-value="showModal"
        transition="dialog-bottom-transition"
        @close="emit('close')"
    >
        <v-card>
            <v-toolbar color="info">
                <v-toolbar-title v-if="item" class="tw-grow">
                    <div>{{ item.description }}</div>
                    <div
                        class="tw-text-base tw-font-extralight tw-text-gray-200"
                    >
                        <span class="tw-mr-2">{{ item.sku }}</span
                        ><span v-if="item.item_number"
                            >({{ item.item_number }})</span
                        >
                    </div>
                </v-toolbar-title>
                <v-btn icon dark @click="emit('close')">
                    <v-icon :icon="mdiClose"></v-icon> </v-btn
            ></v-toolbar>
            <v-card-item>
                <ag-grid-vue
                    class="ag-theme-material"
                    :default-col-def="defaultColDef"
                    :column-defs="columnDefs"
                    :row-data="rowData"
                    dom-layout="autoHeight"
                    single-click-edit
                    stop-editing-when-cells-lose-focus
                    @first-data-rendered="onFirstDataRendered"
                    @cell-value-changed="onCellValueChanged"
                >
                </ag-grid-vue>
            </v-card-item>
        </v-card>
    </v-dialog>
    <!--    <ReservationModal
        v-model="showReservationModal"
        :locations="locations"
        :warehouse-item="item"
        :warehouse-location="selectedLocation"
        @reserved="onTransactionCompleted"
    ></ReservationModal>-->
    <InOutModal
        v-model="showInOutModal"
        :warehouse-item="item"
        :warehouse-location="selectedLocation"
        @submitted="onTransactionCompleted"
    ></InOutModal>
</template>

<style scoped></style>
