<script setup>
import { computed, ref } from 'vue';
import TextField from '@/Components/TextField.vue';
import DialogModal from '@/Components/DialogModal.vue';

const props = defineProps({
    scans: {
        type: Object,
        required: true,
    },
});

const show = ref(false);
const input = ref(null);
const serial = ref(null);
const line = ref(null);
const submitting = ref(false);

let promiseResolve = null;
let promiseReject = null;
let scanTemplate = null;
let scannerLines = ref(null);

const scannedSerialNumbers = computed(() =>
    props.scans
        .filter((scan) => scan.data.serial_number)
        .pluck('data.serial_number')
        .toArray(),
);

const scan = (scanData, delivery_note_line, scanLines) => {
    show.value = true;
    line.value = delivery_note_line;
    scannerLines.value = scanLines;
    scanTemplate = JSON.parse(JSON.stringify(scanData));

    return new Promise((resolve, reject) => {
        promiseResolve = resolve;
        promiseReject = reject;
    });
};

const submitSmaxtecSerial = async () => {
    submitting.value = true;

    if (await validate()) {
        scanTemplate.id = Date.now();
        scanTemplate.quantity = 1;
        scanTemplate.data.serial_number = serial.value;
        scannerLines.value.push(scanTemplate);
        promiseResolve(true);

        show.value = false;
        serial.value = null;
    }

    submitting.value = false;
};

const errors = ref({});
const validate = async () => {
    submitting.value = true;
    if (scannedSerialNumbers.value.includes(serial.value)) {
        errors.value = {
            serial_number: ['Diese Seriennummer wurde bereits gescannt.'],
        };
        return false;
    }

    try {
        await axios.post(route('api.pack.validate-smaxtec-serial'), {
            serial_number: serial.value,
            sku: line.value.sku,
        });
        return true;
    } catch (e) {
        errors.value = e.response.data.errors;
        return false;
    }
};

const close = () => {
    show.value = false;
    serial.value = null;
    line.value = null;
    errors.value = {};
    submitting.value = false;
    promiseReject();
};

defineExpose({
    scan,
});
</script>

<template>
    <DialogModal :show="show" :persistent="false" @close="close">
        <template #title>Smaxtec Seriennummer</template>
        <template #content>
            <TextField
                ref="input"
                v-model="serial"
                autofocus
                label="Seriennummer"
                :error-messages="errors.serial_number"
                @keydown.enter="submitSmaxtecSerial"
            ></TextField>
        </template>
        <template #footer>
            <v-spacer></v-spacer>
            <v-btn color="danger" @click="close">{{ $t('Cancel') }}</v-btn>
            <v-btn
                :loading="submitting"
                color="primary"
                @click="submitSmaxtecSerial"
                >{{ $t('Submit') }}</v-btn
            >
        </template>
    </DialogModal>
</template>

<style scoped></style>
