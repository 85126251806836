<script setup>
import DialogModal from '@/Components/DialogModal.vue';
import { computed, ref, watch } from 'vue';
import { is_empty_or_null } from '@/lib/helpers.js';

const show = ref(false);
const serial = ref(null);
const input = ref(null);
let promiseResolve = null;
let promiseReject = null;
let scanTemplate = null;
let scannerLines = ref(null);

watch(
    input,
    (value) => {
        if (value) {
            value.focus();
        }
    },
    { immediate: true },
);
const scan = (scanData, scanLines) => {
    show.value = true;
    scannerLines.value = scanLines;
    scanTemplate = JSON.parse(JSON.stringify(scanData));

    return new Promise((resolve, reject) => {
        promiseResolve = resolve;
        promiseReject = reject;
    });
};
const close = () => {
    show.value = false;
    serial.value = null;
    promiseResolve = null;
    promiseReject = null;
};
const cancel = () => {
    promiseReject('Cancelled');
    close();
};

const confirmSerial = () => {
    if (is_empty_or_null(serial.value) && !validSerial.value) {
        return;
    }

    scanTemplate.id = Date.now();
    scanTemplate.quantity = 1;
    scanTemplate.data.serial_number = serial.value;

    scannerLines.value.push(scanTemplate);
    promiseResolve(true);
    close();
};

const validSerial = computed(() => {
    return (
        !is_empty_or_null(serial.value) &&
        serial.value.length === 13 &&
        serial.value.startsWith('S')
    );
});

defineExpose({
    scan,
});
</script>

<template>
    <DialogModal :show="show" :persistent="false">
        <template #title>ONT Seriennummer</template>
        <template #content>
            <VTextField
                ref="input"
                v-model="serial"
                label="Seriennummer"
            ></VTextField>
        </template>
        <template #footer>
            <v-spacer></v-spacer>
            <v-btn
                color="danger"
                size="x-large"
                variant="elevated"
                @click="cancel"
                >{{ $t('Cancel') }}</v-btn
            >
            <v-btn
                size="x-large"
                variant="elevated"
                :disabled="!validSerial"
                color="success"
                @click="confirmSerial"
                >{{ $t('Confirm') }}
            </v-btn>
        </template>
    </DialogModal>
</template>

<style scoped></style>
