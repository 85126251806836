<script setup>
import DialogModal from '@/Components/DialogModal.vue';
import { mdiAlert } from '@mdi/js';
import { nextTick, ref, watch } from 'vue';
import { router } from '@inertiajs/vue3';

defineProps({
    maxWidth: {
        type: String,
        default: '2xl',
    },
    closeable: {
        type: Boolean,
        default: false,
    },
    toolbarProps: {
        type: Object,
        default: () => ({ color: 'danger' }),
    },
    toolbarIcon: {
        type: String,
        default: mdiAlert,
    },
});

const show = ref(false);
const cancelButton = ref(true);
const titleText = ref('');
const messageText = ref('');
const isHtml = ref(false);
let resolve = null;
const confirm = function (
    message,
    title = '',
    html = false,
    showCancel = true,
) {
    messageText.value = message;
    titleText.value = title;
    isHtml.value = html;
    show.value = true;
    cancelButton.value = showCancel;
    return new Promise((res) => {
        resolve = res;
    });
};

const accept = () => {
    resolve(true);
    reset();
};
const cancel = () => {
    resolve(false);
    reset();
};

const reset = async () => {
    show.value = false;
    await nextTick();
    titleText.value = '';
    messageText.value = '';
    isHtml.value = false;
    resolve = null;
};

const stopKeydownPropagation = (e) => {
    console.log('stopKeydownPropagation');
    e.stopPropagation();
};
watch(show, (val) => {
    if (val) {
        window.addEventListener('keydown', stopKeydownPropagation, {
            capture: true,
        });
    } else {
        window.removeEventListener('keydown', stopKeydownPropagation, {
            capture: true,
        });
    }
});

router.on('navigate', reset);

defineExpose({
    confirm,
});
</script>

<template>
    <DialogModal
        :show="show"
        :persistent="!closeable"
        :close-on-back="false"
        :toolbar-props="toolbarProps"
        @close="cancel"
    >
        <template #toolbar>
            <v-toolbar-title>
                <v-icon :icon="toolbarIcon" class="tw-mr-2"></v-icon>
                <span v-if="!isHtml">{{ titleText }}</span>
                <!-- eslint-disable-next-line vue/no-v-html -->
                <span v-else v-html="titleText"></span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
        </template>
        <template #content>
            <span v-if="!isHtml">{{ messageText }}</span>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span v-else v-html="messageText"></span>
        </template>
        <template #append>
            <v-toolbar height="50">
                <v-spacer></v-spacer>
                <v-btn tabindex="2" color="primary" @click="accept">{{
                    $t('Accept')
                }}</v-btn>
                <v-btn
                    v-if="cancelButton"
                    tabindex="3"
                    color="danger"
                    @click="cancel"
                    >{{ $t('Cancel') }}</v-btn
                >
            </v-toolbar>
        </template>
    </DialogModal>
</template>
