<script setup>
import FormSection from '@/Components/FormSection.vue';
import { useForm, usePage } from '@inertiajs/vue3';
import CustomerSelect from '@/Components/CustomerSelect.vue';
import DeliveryNoteSelect from '@/Components/DeliveryNoteSelect.vue';
import { computed, ref, watch } from 'vue';
import { collect } from 'collect.js';
import SelectField from '@/Components/SelectField.vue';
import SaveButton from '@/Components/SaveButton.vue';
import AutocompleteField from '@/Components/AutocompleteField.vue';
import SubtypeCard from '@/Pages/Ticket/Partials/SubtypeCard.vue';

const page = usePage();

const typesById = computed(() => {
    return collect(page.props.lists.types).keyBy('id').all();
});

const createTicketForm = useForm({
    ticket_type_id: null,
    customer_number: null,
    delivery_note_number: null,
    responsible: null,
    content_lines: [],
});

const currentType = computed(() => {
    return typesById.value[createTicketForm.ticket_type_id] ?? null;
});
const defaultSubtypes = computed(() => {
    if (currentType.value === null) {
        return [];
    }
    return collect(currentType.value.subtypes)
        .filter((subtype) => subtype.pivot.has_by_default)
        .toArray();
});

watch(currentType, (newType, oldType) => {
    if (newType?.id === oldType?.id) {
        return;
    }

    createTicketForm.content_lines = [];
    for (let i = 0; i < defaultSubtypes.value.length; i++) {
        if (createTicketForm.content_lines[i] === undefined) {
            createTicketForm.content_lines.push({
                subtype_id: defaultSubtypes.value[i].id,
                note: null,
                item_number_1: null,
                item_number_2: null,
                external_shipment_id: null,
                quantity: null,
                text: null,
                attachments: [],
            });
        }
    }
});

const delivery_note = ref(null);
watch(delivery_note, (value) => {
    createTicketForm.delivery_note_number = value?.delivery_note_number ?? null;
    if (value !== null) {
        createTicketForm.customer_number = value.customer_number;
    }
});
watch(
    () => createTicketForm.customer_number,
    (value) => {
        if (delivery_note?.value?.customer_number !== value) {
            delivery_note.value = null;
        }
    },
);

const resetCreateTicketForm = () => {
    createTicketForm.reset();
    createTicketForm.clearErrors();
};
const submitCreateTicketForm = () => {
    createTicketForm.post(route('ticket.store'), {
        preserveState: true,
    });
};
/*const statusList = ref(
    collect(page.props.lists.status)
        .map((status) => ({
            value: status,
            name: wTrans('app.states.ticket_status.' + status),
        }))
        .toArray(),
);*/
</script>

<template>
    <v-container>
        <FormSection @submitted="submitCreateTicketForm">
            <template #title>Create Ticket</template>
            <template #description>Create a new ticket</template>

            <template #form>
                <v-row>
                    <v-col>
                        <AutocompleteField
                            v-model="createTicketForm.ticket_type_id"
                            :label="$t('Type')"
                            :items="page.props.lists.types"
                            item-title="name"
                            item-value="id"
                            :error-messages="
                                createTicketForm.errors['ticket_type_id']
                            "
                        ></AutocompleteField>
                    </v-col>
                </v-row>
                <template v-if="currentType">
                    <v-row>
                        <v-col cols="12" sm="6">
                            <DeliveryNoteSelect
                                v-model="delivery_note"
                                :label="$t('Delivery Note')"
                                hide-details="auto"
                                :error-messages="
                                    createTicketForm.errors[
                                        'delivery_note_number'
                                    ]
                                "
                            ></DeliveryNoteSelect>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <CustomerSelect
                                v-model="createTicketForm.customer_number"
                                :label="$t('Customer')"
                                hide-details="auto"
                                clearable
                                :error-messages="
                                    createTicketForm.errors['customer_number']
                                "
                            />
                        </v-col>

                        <v-col cols="12" sm="6">
                            <SelectField
                                v-model="createTicketForm.responsible"
                                :label="$t('Responsible')"
                                :items="page.props.lists.responsibles"
                                item-title="name"
                                item-value="value"
                                hide-details="auto"
                                :error-messages="
                                    createTicketForm.errors['responsible']
                                "
                            ></SelectField>
                        </v-col>
                    </v-row>
                    <v-row
                        v-for="(subtype, index) in defaultSubtypes"
                        :key="subtype.id"
                    >
                        <v-col cols="12">
                            <SubtypeCard
                                v-model="createTicketForm.content_lines[index]"
                                :create-ticket-form="createTicketForm"
                                :subtype
                                :index="index"
                            ></SubtypeCard>
                        </v-col>
                    </v-row>
                </template>
            </template>
            <template #actions>
                <v-btn
                    class="tw-mr-2"
                    color="danger"
                    variant="elevated"
                    :disabled="!createTicketForm.isDirty"
                    @click="resetCreateTicketForm"
                    >{{ $t('Reset') }}</v-btn
                >
                <SaveButton
                    :loading="createTicketForm.processing"
                    :disabled="!createTicketForm.isDirty"
                ></SaveButton>
            </template>
        </FormSection>
    </v-container>
</template>

<style scoped></style>
