<script setup>
import { useArticleStats } from '@/lib/services/dashboard-service';
import { mdiTrophyVariant } from '@mdi/js';
import { computed } from 'vue';
import DashboardCard from '@/Pages/Dashboard/Widgets/DashboardCard.vue';

const { data: articleStats } = useArticleStats();

const articles = computed(() => {
    if (articleStats.value !== undefined && articleStats.value.length > 0) {
        const maxQuantity = articleStats.value[0].Quantity;
        return articleStats.value.map((article) => {
            const percentage = article.Quantity / (maxQuantity / 100);
            return {
                ...article,
                percentage,
            };
        });
    } else {
        return [];
    }
});
</script>

<template>
    <DashboardCard>
        <template #title>
            <v-icon class="tw-mr-2" :icon="mdiTrophyVariant"></v-icon>
            Top
            {{ $t('Articles') }}
            {{ $t('for the last :days days', { days: 90 }) }}
        </template>
        <v-list v-if="articles.length > 0" class="tw-h-[270px]">
            <v-list-item
                v-for="(article, index) in articles"
                :key="index"
                :height="50"
            >
                <v-progress-linear
                    :model-value="article.percentage"
                    color="alert-lighten-1"
                    height="20"
                    rounded
                >
                </v-progress-linear>
                <div class="tw-text-center tw-text-sm tw-font-medium">
                    {{ article.Description }}
                </div>
                <template #prepend>
                    <span class="tw-mr-6">{{ index + 1 }}.</span>
                </template>
                <template #append>
                    <span class="tw-mx-2 tw-font-medium">{{
                        article.Quantity.toLocaleString()
                    }}</span>
                </template>
            </v-list-item>
        </v-list>
        <v-card-text v-else class="tw-h-[270px] tw-p-2">
            <v-skeleton-loader
                type="list-item-avatar-two-line@4"
            ></v-skeleton-loader>
        </v-card-text>
    </DashboardCard>
</template>

<style scoped></style>
