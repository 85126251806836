<script setup>
import FormSection from '@/Components/FormSection.vue';
import SectionBorder from '@/Components/SectionBorder.vue';
import LinkVuetify from '@/Components/LinkVuetify.vue';
import { mdiPencil, mdiPlus, mdiTrashCan } from '@mdi/js';
import { reactive } from 'vue';

defineProps({
    tenant: {
        type: Object,
        required: true,
    },
});

const deleteLoading = reactive({});
</script>

<template>
    <SectionBorder></SectionBorder>
    <FormSection>
        <template #title>
            {{ $t('Warehouse Locations') }}
        </template>
        <template #description>
            {{ $t('warehouse.tenant_locations_description') }}
        </template>
        <template #form>
            <LinkVuetify
                class="tw-mb-2"
                :prepend-icon="mdiPlus"
                size="small"
                :href="
                    route('warehouse.tenants.locations.create', {
                        tenant: tenant.id,
                    })
                "
                color="info"
            >
                {{ $t('Create Warehouse Location') }}
            </LinkVuetify>
            <v-table>
                <tbody>
                    <tr v-for="location in tenant.locations" :key="location.id">
                        <td class="tw-font-black">
                            {{ location.description }}
                        </td>
                        <td class="tw-text-right">
                            <LinkVuetify
                                class="tw-mr-2"
                                :href="
                                    route('warehouse.locations.edit', {
                                        location: location.id,
                                    })
                                "
                                size="small"
                                color="secondary"
                            >
                                <v-icon :icon="mdiPencil" size="large"></v-icon>
                            </LinkVuetify>
                            <LinkVuetify
                                size="small"
                                color="danger"
                                :href="
                                    route('warehouse.locations.destroy', {
                                        location: location.id,
                                    })
                                "
                                :loading="deleteLoading[location.id]"
                                @start="deleteLoading[location.id] = true"
                                @finish="deleteLoading[location.id] = false"
                            >
                                <v-icon
                                    :icon="mdiTrashCan"
                                    size="large"
                                ></v-icon>
                            </LinkVuetify>
                        </td>
                    </tr>
                </tbody>
            </v-table>
        </template>
    </FormSection>
</template>

<style scoped></style>
