<script setup>
import { computed } from 'vue';
import { trans as $t } from 'laravel-vue-i18n';

const props = defineProps({
    errors: {
        type: Object,
        required: true,
    },
    title: {
        type: String,
        default: () => $t('Validation Errors'),
    },
});

const errors = computed(() => {
    return Object.values(props.errors).flat();
});
</script>

<template>
    <div
        class="tw-rounded-md tw-border tw-border-solid tw-border-red-600 tw-bg-red-100 tw-p-4"
    >
        <h5 class="tw-font-medium tw-text-red-600">
            {{ $t('Validation Errors') }}
        </h5>
        <ul>
            <li
                v-for="(error, index) in errors"
                :key="index"
                class="tw-list-none tw-text-sm"
            >
                {{ error }}
            </li>
        </ul>
    </div>
</template>

<style scoped></style>
