<script setup>
import { useVModel } from '@vueuse/core';

const props = defineProps({
    modelValue: {
        type: Array,
        default: () => [],
    },
});
const emit = defineEmits(['update:modelValue']);

const permissions = useVModel(props, 'modelValue', emit);
</script>

<template>
    <v-checkbox
        v-model="permissions"
        :multiple="true"
        hide-details
        value="set order add_email field"
        :label="$t('Set order add_email field')"
    ></v-checkbox>
</template>

<style scoped></style>
