<script setup>
import { Head, useForm, usePage } from '@inertiajs/vue3';

import FormSection from '@/Components/FormSection.vue';
import TextField from '@/Components/TextField.vue';
import CustomerCombobox from '@/Components/CustomerCombobox.vue';
import { computed, watch } from 'vue';
import SelectField from '@/Components/SelectField.vue';
import UpdatePermissionsForm from '@/Pages/Profile/Partials/UpdatePermissionsForm.vue';
import { collect } from 'collect.js';

const page = usePage();

const form = useForm({
    __method: 'POST',
    username: '',
    email: '',
    first_name: '',
    last_name: '',
    gender: 0,
    customers: [],
    roles:
        page.props.roles.length > 1
            ? []
            : page.props.roles.map((role) => role.name),
    permissions: [],
    settings: collect(page.props.settings)
        .mapWithKeys((setting) => [setting.value, setting.default])
        .all(),
    send_invite: false,
    password: '',
    password_confirmation: '',
});

watch(
    () => form.send_invite,
    (value) => {
        if (value) {
            form.reset('password', 'password_confirmation');
        }
    },
);

const submitCreateUserForm = function () {
    form.post(route('user.store'), {
        onError() {
            form.reset('password', 'password_confirmation');
        },
    });
};

const roleErrorMessages = computed(() => {
    return Object.keys(form.errors).filter((field) => /roles/.test(field))
        .length > 0
        ? ['Ausgewählte Rollen sind ungültig.']
        : [];
});

const customerErrorMessages = computed(() => {
    return Object.keys(form.errors).filter((field) => /customers/.test(field))
        .length > 0
        ? ['Ausgewählte Kunden sind ungültig.']
        : [];
});
</script>

<template>
    <v-container>
        <Head :title="$t('New User')" />
        <form-section class="tw-mt-4">
            <template #title>{{ $t('User Information') }}</template>
            <template #description>{{
                $t('Enter the information to create a new user.')
            }}</template>
            <template #form>
                <TextField
                    v-model.trim="form.username"
                    :label="$t('Username')"
                    :error-messages="form.errors.username"
                />
                <TextField
                    v-model.trim="form.email"
                    :label="$t('Email')"
                    :error-messages="form.errors.email"
                    type="email"
                ></TextField>
                <TextField
                    v-model.trim="form.first_name"
                    :label="$t('First Name')"
                    :error-messages="form.errors.first_name"
                ></TextField>
                <TextField
                    v-model.trim="form.last_name"
                    :label="$t('Last Name')"
                    :error-messages="form.errors.last_name"
                ></TextField>
                <SelectField
                    v-model="form.gender"
                    :label="$t('Gender')"
                    :items="$page.props.genders"
                    item-title="name"
                    :error-messages="form.errors.gender"
                    color="primary"
                />
                <CustomerCombobox
                    v-if="$page.props.auth.canViewCustomers"
                    v-model="form.customers"
                    :selected="[]"
                    :error-messages="customerErrorMessages"
                ></CustomerCombobox>
                <SelectField
                    v-model="form.roles"
                    :error-messages="roleErrorMessages"
                    :label="$t('Roles')"
                    multiple
                    item-title="name"
                    item-value="name"
                    chips
                    :items="$page.props.roles"
                />
            </template>
        </form-section>
        <UpdatePermissionsForm
            v-if="
                $page.props.canUpdateRoles || $page.props.settings?.length > 0
            "
            v-model="form"
            :settings="$page.props.settings"
            :can-update-roles="$page.props.canUpdateRoles"
            :submit="false"
        />
        <form-section class="tw-mt-4" @submitted="submitCreateUserForm">
            <template #title>{{ $t('Password') }}</template>
            <template #description>{{
                $t(
                    'Create a password for the user that is as secure as possible.',
                )
            }}</template>
            <template #form>
                <v-checkbox
                    v-model="form.send_invite"
                    :label="$t('Send Invitation Link')"
                ></v-checkbox>
                <TextField
                    v-model.trim="form.password"
                    :disabled="form.send_invite"
                    :label="$t('Password')"
                    type="password"
                    autocomplete="new-password"
                    :error-messages="form.errors.password"
                ></TextField>
                <TextField
                    v-model.trim="form.password_confirmation"
                    :disabled="form.send_invite"
                    :label="$t('Confirm Password')"
                    type="password"
                    autocomplete="new-password"
                    :error-messages="form.errors.password_confirmation"
                ></TextField>
            </template>
            <template #actions>
                <v-btn
                    type="submit"
                    variant="elevated"
                    color="primary"
                    :loading="form.processing"
                    >{{ $t('Save') }}</v-btn
                >
            </template>
        </form-section>
    </v-container>
</template>

<style scoped></style>
