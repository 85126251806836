<script setup>
import DialogModal from '@/Components/DialogModal.vue';
import { reactive, ref } from 'vue';
import TextField from '@/Components/TextField.vue';

const quantity = defineModel({
    type: Number,
    default: 1,
});

const show = ref(false);
let promiseResolve = null;
let promiseReject = null;

const data = reactive({
    scanData: null,
    line: null,
    maxQuantity: null,
    missingScans: null,
});
const scan = (scanData, line, maxQuantity, missingScans) => {
    show.value = true;
    data.scanData = scanData;
    data.line = line;
    data.maxQuantity = maxQuantity;
    data.missingScans = missingScans;
    return new Promise((resolve, reject) => {
        promiseResolve = resolve;
        promiseReject = reject;
    });
};

const confirmQuantity = () => {
    if (Number(quantity.value) > data.maxQuantity) {
        return;
    }

    promiseResolve(Number(quantity.value));
    close();
};
const cancel = () => {
    promiseReject('Cancelled');
    close();
};
const close = () => {
    quantity.value = 1;
    data.scanData = null;
    data.line = null;
    data.maxQuantity = null;
    data.missingScans = null;
    promiseResolve = null;
    promiseReject = null;
    show.value = false;
};

defineExpose({
    scan,
});
</script>

<template>
    <DialogModal :show="show" @close="cancel">
        <template #title>{{ $t('Quantity') }}</template>
        <template #content>
            <v-col>
                <v-row>
                    <TextField v-model="quantity" type="number"></TextField>
                </v-row>
            </v-col>
        </template>
        <template #footer>
            <v-spacer></v-spacer>
            <v-btn
                color="danger"
                size="x-large"
                variant="elevated"
                @click="cancel"
                >{{ $t('Cancel') }}</v-btn
            >
            <v-btn
                size="x-large"
                variant="elevated"
                :disabled="Number(quantity) > data.maxQuantity"
                color="success"
                @click="confirmQuantity"
                >{{ $t('Confirm') }}
            </v-btn>
        </template>
    </DialogModal>
</template>

<style scoped></style>
