<script setup>
import { mdiAlertOutline, mdiTrashCanOutline } from '@mdi/js';
import { computed } from 'vue';

const props = defineProps({
    params: {
        type: Object,
        required: true,
    },
});

const onDeleteClick = () => {
    props.params.onDelete(props.params);
};

const disabled = computed(() => props.params.disabled);
</script>

<template>
    <div
        class="tw-flex tw-h-full tw-items-center tw-justify-end"
        @dblclick.prevent.stop
    >
        <v-tooltip
            v-if="!!params.data.error"
            theme="errorTooltip"
            location="top"
        >
            <template #activator="{ props: tooltipProps }">
                <v-icon
                    color="danger"
                    class="tw-mr-2 tw-animate-pulse"
                    :icon="mdiAlertOutline"
                    v-bind="tooltipProps"
                ></v-icon>
            </template>
            <span>{{ params.data.error }}</span>
        </v-tooltip>
        <v-btn
            :disabled="!!params.data.error || disabled"
            :loading="!!params.data.deleting || !!params.data.saving"
            size="small"
            density="comfortable"
            :color="params.data.saving ? 'success' : 'red'"
            :icon="mdiTrashCanOutline"
            @click="onDeleteClick"
            @click.prevent
        ></v-btn>
    </div>
</template>

<style scoped></style>
