<script setup>
import { computed, reactive, ref, watch } from 'vue';
import { watchDebounced } from '@vueuse/core';
import { useDisplay, useLayout } from 'vuetify';
import TableTextField from '@/Components/TableTextField.vue';
import { mdiArrowDown, mdiArrowUp, mdiMagnify } from '@mdi/js';

const props = defineProps({
    columns: {
        type: Array,
        required: true,
    },
    rows: {
        type: Array,
        default: () => [],
    },
    loading: {
        type: Boolean,
        default: false,
    },
    debounce: {
        type: Number,
        default: 300,
    },
    space: {
        type: Number,
        default: 32,
    },
});

const emit = defineEmits(['stateChange']);

const cardHeight = computed(() => {
    const height = useDisplay().height.value;
    const appBarHeight = useLayout().mainRect.value.top;
    return height - appBarHeight - props.space;
});
const tableFooterHeight = 42;
const hasSearch = computed(
    () => props.columns.find((column) => column.search === true) !== undefined,
);

let filter = ref({});

watch(
    () => props.columns,
    () => {
        filter.value = reactive({});
        props.columns.forEach((column) => {
            if (column.search) filter[column.field] = null;
        });
    },
    { immediate: true },
);

const resetFilters = () => {
    let reset = false;
    for (const key in filter.value) {
        let value = filter.value[key];
        if (value !== null && value.trim() !== '') reset = true;
    }
    if (reset) filter.value = reactive({});
};

let sort = reactive({
    field: null,
    direction: 'asc',
});

const sortColumn = (value) => {
    if (sort.field === value) {
        if (sort.direction === 'asc') sort.direction = 'desc';
        else if (sort.direction === 'desc') sort.direction = null;
        else sort.direction = 'asc';
    } else {
        sort.field = value;
        sort.direction = 'asc';
    }
};

watchDebounced(
    [filter, sort],
    ([filter, sort]) => {
        emit('stateChange', {
            filter,
            sort:
                sort.direction === 'asc'
                    ? sort.field
                    : sort.direction === 'desc'
                    ? '-' + sort.field
                    : null,
        });
    },
    { debounce: props.debounce, deep: true },
);
</script>

<template>
    <v-card :loading="props.loading">
        <div
            :style="{
                'padding-bottom': tableFooterHeight + 20 + 'px',
                height: cardHeight + 'px',
            }"
        >
            <v-table
                :hover="true"
                class="tw-max-h-full tw-max-w-full tw-overflow-auto"
                density="compact"
            >
                <thead>
                    <tr>
                        <v-hover
                            v-for="column in columns"
                            :key="column.field + 'th'"
                            v-slot="{ isHovering, props: properties }"
                        >
                            <th
                                class="tw-cursor-pointer tw-px-2 first:tw-pl-4 last:tw-pr-4"
                                v-bind="properties"
                                :class="{
                                    'on-surface':
                                        column.sort !== false && isHovering,
                                }"
                                :style="{
                                    maxWidth: column.maxWidth + 'px' ?? null,
                                }"
                                @click="
                                    column.sort !== false &&
                                        sortColumn(column.field)
                                "
                            >
                                <div class="tw-flex tw-items-center">
                                    <span>{{
                                        column.label ?? column.field
                                    }}</span>
                                    <v-icon
                                        v-show="
                                            sort.direction &&
                                            sort.field === column.field
                                        "
                                        class="tw-ml-2"
                                        :icon="
                                            sort.direction === 'asc'
                                                ? mdiArrowUp
                                                : mdiArrowDown
                                        "
                                        size="small"
                                    ></v-icon>
                                </div>
                            </th>
                        </v-hover>
                        <th
                            v-if="$slots.actions || hasSearch"
                            class="tw-text-right"
                        >
                            {{ $t('Actions') }}
                        </th>
                    </tr>
                    <tr v-if="hasSearch">
                        <td
                            v-for="column in columns"
                            :key="column.field + 's'"
                            class="tw-px-2 first:tw-pl-4 last:tw-pr-4"
                        >
                            <TableTextField
                                v-if="column.search"
                                v-model.trim="filter[column.field]"
                                type="search"
                                :prepend-inner-icon="mdiMagnify"
                                :label="column.label ?? column.field"
                            />
                        </td>
                        <td class="tw-text-right">
                            <v-btn size="small" @click="resetFilters">{{
                                $t('Reset Filters')
                            }}</v-btn>
                        </td>
                    </tr>
                </thead>
                <tbody v-if="rows.length > 0">
                    <tr v-for="(row, index) in rows" :key="index">
                        <td
                            v-for="column in columns"
                            :key="column.field + index"
                            class="tw-px-2 first:tw-pl-4 last:tw-pr-4"
                            :style="{
                                maxWidth: column.maxWidth + 'px' ?? null,
                            }"
                        >
                            {{
                                !column.hasOwnProperty('valueGetter')
                                    ? row[column.field]
                                    : column.valueGetter(row)
                            }}
                        </td>
                        <td
                            v-if="$slots.actions || hasSearch"
                            class="tw-whitespace-nowrap tw-text-right"
                        >
                            <slot :row="row" name="actions"></slot>
                        </td>
                    </tr>
                </tbody>
            </v-table>
            <div
                v-if="rows.length === 0"
                class="tw-flex tw-h-full tw-items-center tw-justify-center"
            >
                <div class="tw-uppercase">{{ $t('No data available...') }}</div>
            </div>
            <v-divider v-if="$slots.footer"></v-divider>
            <v-toolbar
                v-if="$slots.footer"
                :absolute="true"
                class="tw-bottom-0 tw-flex tw-w-full tw-justify-center"
                :height="tableFooterHeight + 20"
                color="surface"
            >
                <slot name="footer" :footer-height="tableFooterHeight"></slot>
            </v-toolbar>
        </div>
    </v-card>
</template>

<style scoped>
:deep(.v-table__wrapper) {
    display: inline-flex;
    min-width: 100%;
    overflow: visible;
}
</style>
