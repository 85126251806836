<script setup>
import { usePage } from '@inertiajs/vue3';
import { useVModel } from '@vueuse/core';
import CheckboxField from '@/Components/CheckboxField.vue';

const props = defineProps({
    modelValue: {
        type: Object,
        required: true,
    },
});
const emit = defineEmits(['update:modelValue']);
const page = usePage();

const settings = useVModel(props, 'modelValue', emit);
</script>

<template>
    <div>
        <CheckboxField
            v-for="setting in page.props.settings.filter(
                (setting) => setting.type === 'checkbox',
            )"
            :key="setting.value"
            v-model="settings[setting.value]"
            :label="setting.name"
        />
    </div>
</template>

<style scoped></style>
