<script setup>
import GoodsIssueTable from '@/Pages/GoodsIssue/Partials/GoodsIssueTable.vue';
import { usePage } from '@inertiajs/vue3';

const page = usePage();
const locations = page.props.locations;
</script>

<template>
    <v-container>
        <v-row>
            <v-col>
                <GoodsIssueTable :locations></GoodsIssueTable>
            </v-col>
        </v-row>
    </v-container>
</template>

<style scoped></style>
