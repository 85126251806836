<script setup>
import ApiTokenManager from '@/Pages/API/Partials/ApiTokenManager.vue';
import { Head } from '@inertiajs/vue3';

const props = defineProps({
    tokens: { type: Array, default: () => [] },
    auth: { type: Object, required: true },
    availablePermissions: { type: Array, default: () => [] },
    defaultPermissions: { type: Array, default: () => [] },
});
</script>

<template>
    <Head :title="$t('API Tokens')"></Head>
    <v-container class="tw-h-full">
        <ApiTokenManager
            class="tw-mt-4"
            :tokens="tokens"
            :user="props.auth.user"
            :store-route="route('api-tokens.store')"
            :available-permissions="availablePermissions"
            :default-permissions="defaultPermissions"
        />
    </v-container>
</template>
