<script setup>
import { Head, useForm } from '@inertiajs/vue3';
import SelectField from '@/Components/SelectField.vue';
import { computed, ref, watch } from 'vue';
import { AgGridVue } from 'ag-grid-vue3';
import { trans as $t } from 'laravel-vue-i18n';
import defaultColumnDefinitions from '@/lib/ag-grid/default-column-definitions';
import columnTypes from '@/lib/ag-grid/column-types';
import MaterialEditableCellRenderer from '@/Components/CellRenderers/MaterialEditableCellRenderer.vue';
import { mdiInformation, mdiMagnify, mdiPackageVariantClosed } from '@mdi/js';
import SaveButton from '@/Components/SaveButton.vue';
import WarehouseOrderSummary from '@/Pages/WarehouseManagement/Partials/WarehouseOrderSummary.vue';
import {
    columnStateEvents,
    getColumnState,
} from '@/lib/ag-grid/save-column-state';
import TableLoading from '@/Components/TableLoading.vue';

const props = defineProps({
    items: {
        type: Array,
        required: true,
    },
    locations: {
        type: Array,
        required: true,
    },
    warehouse: {
        type: Object,
        required: true,
    },
});

// eslint-disable-next-line vue/no-setup-props-destructure
const orderForm = useForm({
    warehouse_location_id: 'maillog',
    for_warehouse_location_id:
        props.warehouse.default_warehouse_location ?? null,
    lines: [],
});

const locationValueGetter = (params) => {
    return params.data[params.colDef.field][
        orderForm.for_warehouse_location_id
    ];
};
const totalValueGetter = (params) => {
    return (
        params.data.stock[orderForm.for_warehouse_location_id] -
        params.data.reserved[orderForm.for_warehouse_location_id]
    );
};
const orderLineValueGetter = (params) => {
    return (
        orderForm.lines.find((line) => params.data.id === line.item_id)
            ?.quantity ?? 0
    );
};
const orderLineValueSetter = (params) => {
    const line = orderForm.lines.find(
        (line) => params.data.id === line.item_id,
    );

    if (
        (params.newValue === 0 || params.newValue === null) &&
        line !== undefined
    ) {
        orderForm.lines.splice(orderForm.lines.indexOf(line), 1);
        return true;
    }

    if (line !== undefined) {
        line.quantity = params.newValue;
        return true;
    }

    orderForm.lines.push({
        item_id: params.data.id,
        item_number: params.data.item_number,
        sku: params.data.sku,
        description: params.data.description,
        quantity: params.newValue,
    });

    return true;
};
const getRowId = (params) => params.data.id;

const stateResponse = getColumnState('warehouse_order');
const stateLoaded = ref(false);
const gridApi = ref(null);
const onGridReady = async (params) => {
    gridApi.value = params.api;
    const columnState = await stateResponse;
    if (columnState) {
        params.columnApi.applyColumnState({
            state: columnState,
            applyOrder: true,
        });
    }
    stateLoaded.value = true;
};
const columnDefs = computed(() => {
    let fromLocation = selectLocations.value.find(
        (location) => location.id === orderForm.warehouse_location_id,
    );

    return [
        {
            headerName: $t('Maillog SKU'),
            field: 'item_number',
            headerTooltip: $t('Maillog SKU'),
        },
        {
            headerName: $t('SKU'),
            field: 'sku',
            headerTooltip: $t('SKU'),
        },
        {
            headerName: $t('Description'),
            field: 'description',
            headerTooltip: $t('Description'),
        },
        {
            headerName: $t('Stock'),
            type: 'numericColumn',
            field: 'stock',
            valueGetter: locationValueGetter,
        },
        {
            headerName: $t('Reserved'),
            type: 'numericColumn',
            field: 'reserved',
            valueGetter: locationValueGetter,
        },
        {
            headerName: $t('Total'),
            type: 'numericColumn',
            valueGetter: totalValueGetter,
        },
        {
            headerName: fromLocation.description + ' ' + $t('Stock'),
            type: 'numericColumn',
            field: 'stock.' + fromLocation.id,
        },
        {
            headerName: $t('Minimum Stock'),
            type: 'numericColumn',
            field: 'minimum_stock',
            valueGetter: locationValueGetter,
        },
        {
            headerName: $t('Order Quantity'),
            cellRenderer: MaterialEditableCellRenderer,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0,
                precision: 0,
                step: 1,
                showStepperButtons: true,
            },
            type: 'numericColumn',
            editable: true,
            valueGetter: orderLineValueGetter,
            valueSetter: orderLineValueSetter,
        },
    ];
});

const items = computed(() => {
    return props.items.filter((item) => {
        return (
            item.stock[orderForm.for_warehouse_location_id] >=
            item.minimum_stock[orderForm.for_warehouse_location_id]
        );
    });
});
watch(
    () => orderForm.for_warehouse_location_id,
    () => {
        gridApi.value?.refreshCells();
    },
);

const articleSearch = ref(null);
const externalFilterPresent = computed(() => {
    return articleSearch.value !== null && articleSearch.value.trim() !== '';
});
const doesExternalFilterPass = (node) => {
    const strings = [
        node.data.item_number,
        node.data.sku,
        node.data.description,
    ];
    const terms = articleSearch.value
        ?.split(' ')
        ?.map((term) => term.toLowerCase());

    return terms.every((term) =>
        strings.some((string) => string?.toLowerCase()?.includes(term)),
    );
};
watch(articleSearch, () => {
    gridApi.value?.onFilterChanged();
});

const canSubmitMaillogOrder = computed(() => {
    return (
        orderForm.lines.length > 0 &&
        orderForm.lines.every((line) => line.item_number !== null)
    );
});

// Remove maillog location from select if order is not possible and select first location
const selectLocations = computed(() => {
    const locations = [...props.locations];
    if (canSubmitMaillogOrder.value || orderForm.lines.length === 0) {
        locations.push({
            id: 'maillog',
            description: 'Maillog',
        });
    }
    return locations;
});
watch(selectLocations, () => {
    if (
        selectLocations.value.find(
            (location) => location.id === orderForm.warehouse_location_id,
        ) === undefined
    ) {
        orderForm.warehouse_location_id = selectLocations.value[0].id;
    }
});

watch(canSubmitMaillogOrder, () => {
    if (!canSubmitMaillogOrder.value) {
        orderForm.warehouse_location_id = props.locations[0].id;
    }
});
const submitOrderForm = () => {
    orderForm.post(route('warehouse.order.store'), {
        onSuccess() {
            orderForm.reset();
        },
    });
};
</script>

<template>
    <Head :title="$page.props.title"></Head>
    <v-container class="tw-flex tw-min-h-full tw-flex-col">
        <div class="tw-mb-4 tw-flex tw-shrink">
            <v-col>
                <SelectField
                    v-model="orderForm.for_warehouse_location_id"
                    bg-color="surface"
                    variant="solo"
                    :items="locations"
                    item-value="id"
                    item-title="description"
                    hide-details
                    :label="$t('Warehouse Location')"
                >
                    <template #prepend> {{ $t('For Location') }}:</template>
                </SelectField>
            </v-col>
            <v-col>
                <v-text-field
                    v-model="articleSearch"
                    variant="solo"
                    :append-icon="mdiMagnify"
                    :label="$t('Article Search')"
                    single-line
                    hide-details
                    dense
                    clearable=""
                ></v-text-field>
            </v-col>
        </div>
        <div class="tw-mb-8 tw-flex tw-grow tw-items-stretch">
            <v-card class="tw-flex tw-min-h-[300px] tw-w-full tw-flex-col">
                <v-toolbar density="comfortable" color="info">
                    <template #prepend>
                        <v-icon :icon="mdiInformation"></v-icon>
                    </template>
                    <v-toolbar-title>{{ $t('Articles') }} </v-toolbar-title>
                    <v-toolbar-items>
                        <v-spacer></v-spacer>
                        <div class="tw-mr-2 tw-flex tw-h-full tw-items-center">
                            <v-icon
                                class="tw-mr-1"
                                :icon="mdiPackageVariantClosed"
                            ></v-icon>
                            <span>{{ items.length }}</span>
                        </div>
                    </v-toolbar-items>
                </v-toolbar>
                <TableLoading
                    class="tw-h-auto tw-grow tw-items-stretch"
                    :loading="!stateLoaded"
                >
                    <ag-grid-vue
                        style="height: 100%"
                        class="ag-theme-material"
                        :single-click-edit="true"
                        :get-row-id="getRowId"
                        :column-types="columnTypes"
                        :default-col-def="defaultColumnDefinitions"
                        :column-defs="columnDefs"
                        :row-data="items"
                        :is-external-filter-present="
                            () => externalFilterPresent
                        "
                        :stop-editing-when-cells-lose-focus="true"
                        :does-external-filter-pass="doesExternalFilterPass"
                        @grid-ready="onGridReady"
                        v-on="columnStateEvents"
                    ></ag-grid-vue>
                </TableLoading>
            </v-card>
        </div>

        <div class="tw-flex tw-shrink">
            <v-col cols="8">
                <WarehouseOrderSummary
                    v-if="orderForm.for_warehouse_location_id !== null"
                    v-model="orderForm.lines"
                    :location="
                        locations.find(
                            (location) =>
                                location.id ===
                                orderForm.for_warehouse_location_id,
                        )
                    "
                ></WarehouseOrderSummary>
            </v-col>
            <v-col cols="4">
                <div>
                    <SelectField
                        v-model="orderForm.warehouse_location_id"
                        :items="selectLocations"
                        item-title="description"
                        item-value="id"
                    >
                        <template #prepend>
                            {{ $t('From Location') }}:</template
                        >
                    </SelectField>
                    <div class="tw-mb-2 tw-flex">
                        <SaveButton
                            class="tw-mr-2"
                            :loading="orderForm.processing"
                            :disabled="
                                !canSubmitMaillogOrder ||
                                orderForm.warehouse_location_id !== 'maillog'
                            "
                            type="button"
                            @click="submitOrderForm"
                            >{{ $t('Maillog Order') }}
                        </SaveButton>
                        <SaveButton
                            :loading="orderForm.processing"
                            :disabled="
                                orderForm.lines.length === 0 ||
                                orderForm.warehouse_location_id === 'maillog'
                            "
                            type="button"
                            @click="submitOrderForm"
                            >{{ $t('E-Mail') }}
                        </SaveButton>
                    </div>
                </div>
            </v-col>
        </div>
    </v-container>
</template>

<style scoped></style>
