<script setup>
import { computed, useSlots } from 'vue';

defineProps({
    fullWidth: {
        type: Boolean,
        default: false,
    },
});
defineEmits(['submitted']);

const hasActions = computed(() => !!useSlots().actions);
</script>

<template>
    <v-row>
        <v-col cols="12" md="4" class="tw-flex tw-justify-between tw-px-4">
            <div class="md:tw-px-0">
                <h3 class="tw-text-lg tw-font-medium tw-text-gray-900">
                    <slot name="title"></slot>
                </h3>
                <p class="tw-mt-1 tw-text-sm tw-text-gray-600">
                    <slot name="description"></slot>
                </p>
            </div>
            <div class="tw-px-4 md:tw-px-0">
                <slot name="aside" />
            </div>
        </v-col>
        <v-col cols="12" md="8">
            <v-card>
                <v-form @submit.prevent="$emit('submitted')">
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" :md="fullWidth ? 12 : 10">
                                <slot name="form" />
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-toolbar v-if="hasActions" class="tw-flex tw-w-full">
                        <v-spacer></v-spacer>
                        <slot name="actions"> </slot>
                    </v-toolbar>
                </v-form>
            </v-card>
        </v-col>
    </v-row>
</template>

<style scoped></style>
