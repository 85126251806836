import { computed, onMounted, reactive, ref, watch } from 'vue';
import { useDebounceFn, useLocalStorage, watchIgnorable } from '@vueuse/core';
import { collect } from 'collect.js';
import { usePage, useRemember } from '@inertiajs/vue3';
import { AxiosError } from 'axios';

export function usePackScans() {
    const page = usePage();

    const loading = ref(false);

    const serverScans = useRemember([...page.props.pack_process.scans]);

    const initialScanTransitionValue = {
        timestamp: null,
        current_collo_id: page.props.pack_process.current_collo_id,
        newScans: [],
        updatedScans: [],
        deletedScans: [],
    };

    const scanTransactions =
        page.props.pack_process.status === 'open'
            ? useLocalStorage(
                  'pack_process_scans_' + page.props.pack_process.id,
                  initialScanTransitionValue,
                  {
                      mergeDefaults: true,
                  },
              )
            : ref(initialScanTransitionValue);
    const savingScans = reactive({
        timestamp: null,
        current_collo_id: null,
        newScans: [],
        updatedScans: [],
        deletedScans: [],
    });

    const currentColloId = computed({
        get: () => scanTransactions.value?.current_collo_id ?? null,
        set: (value) => (scanTransactions.value.current_collo_id = value),
    });

    currentColloId.value = page.props.pack_process.current_collo_id;
    // TODO:
    //  unwatch because of inertia page navigation
    //  watch doesn't unwatch automatically (maybe because not called in root of
    //  script setup)
    watch(
        () => page.props.pack_process?.current_collo_id,
        (value) => {
            currentColloId.value ??= value;
        },
    );

    const scans = computed(() => {
        if (scanTransactions.value?.timestamp === undefined) {
            return collect(serverScans.value);
        }

        const updatedScans = [
            ...scanTransactions.value.updatedScans,
            ...savingScans.updatedScans,
        ];

        const deletedScans = [
            ...scanTransactions.value.deletedScans,
            ...savingScans.deletedScans,
        ];

        return collect(
            serverScans.value
                .concat(scanTransactions.value.newScans)
                .concat(savingScans.newScans),
        )
            .map((scan) => {
                if (updatedScans.length > 0) {
                    const updatedScan = updatedScans.find((updatedScan) => {
                        return updatedScan.old.id === scan.id;
                    });
                    if (updatedScan) {
                        updatedScans.splice(
                            updatedScans.indexOf(updatedScan),
                            1,
                        );
                        return updatedScan.new;
                    }
                }

                return scan;
            })
            .filter((scan) => !deletedScans.includes(scan.id));
    });

    const submit = async () => {
        if (transactionsPending.value === false) {
            return;
        }

        if (loading.value) {
            // TODO: check if works
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve(submit());
                }, 300);
            });
        }

        console.log(
            'saving scans',
            JSON.parse(JSON.stringify(scanTransactions.value)),
        );

        savingScans.newScans = scanTransactions.value.newScans;
        savingScans.updatedScans = scanTransactions.value.updatedScans;
        savingScans.deletedScans = scanTransactions.value.deletedScans;
        savingScans.timestamp = scanTransactions.value.timestamp;
        savingScans.current_collo_id = scanTransactions.value.current_collo_id;
        scanTransactions.value.newScans = [];
        scanTransactions.value.updatedScans = [];
        scanTransactions.value.deletedScans = [];

        loading.value = true;

        try {
            const response = await axios.post(
                route('api.pack.scans', {
                    pack_process: page.props.pack_process.id,
                }),
                savingScans,
            );
            serverScans.value.push(...response.data.data.inserted);
            serverScans.value.forEach((scan) => {
                const updated = response.data.data.updated.find(
                    (updatedScan) => {
                        return updatedScan.id === scan.id;
                    },
                );
                if (updated) {
                    scan.pack_process_collo_id = updated.pack_process_collo_id;
                }
            });

            serverScans.value = serverScans.value.filter((scan) => {
                return !response.data.data.deleted.includes(scan.id);
            });
            currentColloId.value = response.data.data.current_collo_id;
        } catch (e) {
            if (e instanceof AxiosError) {
                logtail.error('Scan Saving Exception', {
                    error: e,
                    response: e?.response,
                    serverScans: serverScans.value,
                });
            }

            scanTransactions.value.timestamp = savingScans.timestamp;
            scanTransactions.value.current_collo_id =
                savingScans.current_collo_id;

            // TODO: Handle different error cases.
            scanTransactions.value.newScans = savingScans.newScans;
            scanTransactions.value.updatedScans = savingScans.updatedScans;
            scanTransactions.value.deletedScans = savingScans.deletedScans;
            if (e.response?.status === 422) {
                scanTransactions.value.newScans = [];
                scanTransactions.value.updatedScans = [];
                scanTransactions.value.deletedScans = [];
            }
        }
        loading.value = false;
        savingScans.timestamp = null;
        savingScans.newScans = [];
        savingScans.updatedScans = [];
        savingScans.deletedScans = [];
    };
    const submitScans = useDebounceFn(submit, 1000);

    const transactionsPending = computed(() => {
        return (
            scanTransactions.value.newScans.length +
                scanTransactions.value.updatedScans.length +
                scanTransactions.value.deletedScans.length +
                savingScans.newScans.length +
                savingScans.updatedScans.length +
                savingScans.deletedScans.length >
            0
        );
    });

    const newScans = (scanData) => {
        if (!Array.isArray(scanData)) {
            scanData = [scanData];
        }

        scanTransactions.value.newScans.push(...scanData);
        scanTransactions.value.timestamp = Date.now();
    };

    const updateScan = (oldScanData, newScanData) => {
        const newScanIndex = scanTransactions.value.newScans.findIndex(
            (scan) => scan.id === oldScanData.id,
        );
        if (newScanIndex !== -1) {
            scanTransactions.value.newScans[newScanIndex] = newScanData;
        } else {
            if (typeof oldScanData.id === 'string') {
                return;
            }

            scanTransactions.value.updatedScans.push({
                old: oldScanData,
                new: newScanData,
            });
            scanTransactions.value.timestamp = Date.now();
        }
    };

    const deleteScan = (scanData) => {
        const newScanIndex = scanTransactions.value.newScans.findIndex(
            (scan) => scan.id === scanData.id,
        );
        if (newScanIndex !== -1) {
            scanTransactions.value.newScans.splice(newScanIndex, 1);
        } else {
            if (typeof scanData.id === 'string') {
                return;
            }

            scanTransactions.value.deletedScans.push(scanData.id);
            scanTransactions.value.timestamp = Date.now();
        }
    };

    onMounted(() => transactionsPending.value && submitScans());

    const { ignoreUpdates: ignoreTransactionUpdates } = watchIgnorable(
        () => scanTransactions.value?.timestamp,
        () => {
            if (scanTransactions.value?.timestamp === undefined) {
                return;
            }

            submitScans();
        },
    );
    const submitScansAfter = (cb) => {
        ignoreTransactionUpdates(cb);
        return submit();
    };

    const reset = () => {
        scanTransactions.value = null;
    };

    return {
        loading,
        scans,
        newScans,
        updateScan,
        deleteScan,
        submitScansAfter,
        transactionsPending,
        currentColloId,
        reset,
    };
}
