<script setup>
import { AgGridVue } from 'ag-grid-vue3';
import { trans as $t } from 'laravel-vue-i18n';

import TableLoading from '@/Components/TableLoading.vue';
import {
    getColumnState,
    stateApplied,
    onGridReady,
    columnStateEvents,
} from '@/lib/ag-grid/save-column-state.js';
import defaultColDef from '@/lib/ag-grid/default-column-definitions.js';
import createQueryRequestParams from '@/lib/ag-grid/create-query-request-params.js';
import columnTypes from '@/lib/ag-grid/column-types.js';
import GoodsIssueDetailCellRenderer from '@/Pages/GoodsIssue/Partials/GoodsIssueDetailCellRenderer.vue';

getColumnState('goods_issue', true);

const props = defineProps({
    locations: {
        type: Array,
        required: true,
    },
});

const dataSource = {
    getRows({ request, success, fail }) {
        const params = createQueryRequestParams(request);
        axios
            .get(route('api.goods-issue.index'), { params })
            .then((response) => {
                success({
                    rowData: response.data.data,
                    rowCount: response.data.total,
                });
            })
            .catch(() => fail("Couldn't load data"));
    },
};
const columnDefs = [
    {
        headerName: $t('Order Number'),
        field: 'order_number',
        cellRenderer: 'agGroupCellRenderer',
        type: 'textColumnFilter',
    },
    {
        headerName: $t('Delivery Note Number'),
        field: 'delivery_note_number',
        type: 'textColumnFilter',
    },
    {
        headerName: $t('Customer Number'),
        field: 'customer_number',
        type: 'textColumnFilter',
    },
    {
        headerName: $t('Customer'),
        field: 'customer_name',
        type: 'textColumnFilter',
    },
    {
        headerName: $t('Location'),
        field: 'location',
        filter: 'agSetColumnFilter',
        floatingFilter: true,
        filterParams: {
            defaultToNothingSelected: true,
            values: props.locations.map((location) => location.Code),
        },
    },
    {
        field: 'shipping_agent',
        headerName: $t('Shipping Agent'),
        type: 'textColumnFilter',
    },
    {
        headerName: $t('Requested Delivery Date'),
        field: 'requested_delivery_date',
    },
];
</script>

<template>
    <v-card>
        <TableLoading :loading="!stateApplied">
            <ag-grid-vue
                class="ag-theme-material ag-theme-material-dense tw-h-[calc(100vh-100px)]"
                :default-col-def="defaultColDef"
                :column-types="columnTypes"
                row-model-type="serverSide"
                :column-defs="columnDefs"
                :server-side-datasource="dataSource"
                :get-row-id="
                    (params) =>
                        params.data.order_number !== ''
                            ? params.data.order_number
                            : params.data.delivery_note_number
                "
                :master-detail="true"
                :detail-cell-renderer="GoodsIssueDetailCellRenderer"
                detail-row-auto-height
                @grid-ready="onGridReady"
                v-on="columnStateEvents"
            ></ag-grid-vue>
        </TableLoading>
    </v-card>
</template>

<style scoped></style>
