import { ref } from 'vue';
import { router } from '@inertiajs/vue3';

let vueApp = null;
export const InertiaRouterStates = {
    install(app) {
        vueApp = app;
        app.config.globalProperties.$posting = ref(false);
    },
};

router.on('start', () => {
    switch (router.activeVisit?.method) {
        case 'post':
        case 'put':
        case 'patch':
        case 'delete':
            vueApp.config.globalProperties.$posting.value = true;
    }
});

router.on('finish', () => {
    if (vueApp.config.globalProperties.$posting.value) {
        vueApp.config.globalProperties.$posting.value = false;
    }
});
