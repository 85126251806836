<script setup>
import { useWindowSize } from '@vueuse/core';
import { mdiAlert } from '@mdi/js';

defineProps({
    message: {
        type: String,
        default: '',
    },
});

const show = defineModel({
    type: Boolean,
    default: true,
});

const { height } = useWindowSize();
</script>

<template>
    <v-dialog
        v-model="show"
        close-on-content-click
        :height="height - 64 + 'px'"
        class="tw-mx-auto tw-w-full tw-max-w-screen-md"
    >
        <v-card color="danger" variant="flat" class="tw-h-full tw-w-full">
            <v-card-text class="tw-animate-pulse">
                <div class="tw-mx-auto tw-max-w-xl">
                    <v-icon size="100%" :icon="mdiAlert"></v-icon>
                </div>
            </v-card-text>
            <v-card-text
                class="tw-mb-4 tw-text-center tw-text-6xl tw-font-light"
            >
                {{ message }}
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<style scoped></style>
