<script setup>
import { mdiMicrosoftExcel } from '@mdi/js';

defineProps({
    content: {
        type: Object,
        required: true,
    },
});

const getUri = (attachment) => {
    return route('ticket.attachment.show', {
        filename: attachment.replace('tickets/', ''),
    });
};

const getName = (attachment) => {
    return attachment.split('_').slice(2).join('_');
};
</script>

<template>
    <v-col class="tw-overflow-x-hidden">
        <ul class="tw-list-none">
            <li
                v-for="attachment in content.attachments"
                :key="attachment"
                class="tw-whitespace-nowrap"
            >
                <v-icon :icon="mdiMicrosoftExcel" class="tw-mr-2"></v-icon
                ><a
                    class="tw-text-primary"
                    :href="getUri(attachment)"
                    target="_blank"
                    >{{ getName(attachment) }}</a
                >
            </li>
        </ul>
    </v-col>
</template>

<style scoped></style>
