<script setup>
import { onMounted } from 'vue';
import { mdiImage } from '@mdi/js';

const props = defineProps({
    params: {
        type: Object,
        required: true,
    },
});

onMounted(() => {
    props.params;
});
</script>

<template>
    <div class="tw-flex tw-items-center tw-overflow-ellipsis">
        <a
            v-if="params.data.ItemHasPicture"
            class="tw-flex tw-cursor-pointer tw-items-center tw-flex-shrink-0 tw-text-black hover:tw-text-gray-600 active:tw-text-black visited:tw-text-black"
            @click="
                params.showImage(
                    route('api.maillog.item.image', {
                        item_number: params.data['MaillogSKU'],
                    })
                )
            "
        >
            <v-icon :icon="mdiImage" class="tw-mr-2" />
        </a>
        <span class="tw-overflow-x-hidden tw-overflow-ellipsis">{{
            params.value
        }}</span>
    </div>
</template>

<style scoped></style>
