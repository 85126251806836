import { onMounted, onUnmounted, ref } from 'vue';
import { Draggable } from '@shopify/draggable';
import { watchArray } from '@vueuse/core';

export function useDraggable(
    containerRefs,
    draggableSelector = '.draggable',
    filter = null,
    draggableOptions = {},
) {
    const draggable = ref(null);

    const draggingElement = ref(null);
    const fromContainer = ref(null);
    const toContainer = ref(null);

    const getRef = (el) => {
        return containerRefs.value.find((ref) => (ref.$el ?? ref) === el);
    };

    let onDragStopCallback = null;
    const onDragStop = (callback) => {
        onDragStopCallback = callback;
    };

    onMounted(() => {
        draggable.value = new Draggable(
            containerRefs.value.map((el) => el.$el ?? el),
            {
                draggable: draggableSelector,
                mirror: {
                    constrainDimensions: true,
                    appendTo: 'body',
                },
                classes: {
                    mirror: ['draggable-mirror', 'tw-z-50'],
                },
                ...draggableOptions,
            },
        );

        draggable.value.on('drag:start', (event) => {
            const sourceContainerRef = getRef(event.data.sourceContainer);

            // Cancel event on button click
            const buttons = Array.prototype.slice.call(
                event.originalSource.getElementsByTagName('button'),
            );
            if (
                buttons.length > 0 &&
                buttons.some((el) => el.contains(event.sensorEvent.target))
            ) {
                event.cancel();
                return;
            }

            if (filter && filter(sourceContainerRef)) {
                event.cancel();
                return;
            }

            draggingElement.value = event.data.originalSource;
            fromContainer.value = sourceContainerRef;
        });

        draggable.value.on('drag:over:container', (event) => {
            toContainer.value = getRef(event.data.overContainer);
        });

        draggable.value.on('drag:out:container', () => {
            toContainer.value = null;
        });

        draggable.value.on('drag:stop', (event) => {
            onDragStopCallback && onDragStopCallback(event);
        });
    });

    watchArray(
        containerRefs.value,
        (value, oldValue, added, removed) => {
            draggable.value.addContainer(...added.map((el) => el.$el ?? el));
            draggable.value.removeContainer(
                ...removed.map((el) => el.$el ?? el),
            );
        },
        {
            deep: true,
        },
    );

    onUnmounted(() => {
        draggable.value.destroy();
    });

    return {
        draggable,
        draggingElement,
        fromContainer,
        toContainer,
        onDragStop,
    };
}
