<script setup>
import { ref } from 'vue';
import { useVModel, watchDebounced } from '@vueuse/core';
import colors from 'tailwindcss/colors';

const props = defineProps({
    modelValue: {
        type: String,
        default: null,
    },
    selectedCustomer: {
        type: Object,
        default: null,
    },
    bgColor: {
        type: String,
        default: colors.gray['100'],
    },
});
const emit = defineEmits(['update:modelValue']);

const selected = useVModel(props, 'modelValue', emit);

const combobox = ref(null);
const search = ref('');
const items = ref(props.selectedCustomer ? [props.selectedCustomer] : []);

const loading = ref(false);
watchDebounced(
    search,
    async () => {
        if (search.value.length >= 3 && search.value !== selected.value) {
            loading.value = true;
            try {
                const response = await axios.get(
                    route('api.maillog.customer.index'),
                    {
                        params: {
                            filter: {
                                search: search.value,
                            },
                        },
                    },
                );

                items.value = response.data;
            } finally {
                loading.value = false;
            }
        }
    },
    { debounce: 300 },
);

const customerTitle = (item) => {
    return item ? item.number : null;
};
const filter = (value, search, item) => {
    return (item.raw.number + '_' + item.raw.name)
        .toLowerCase()
        .includes(search.toLowerCase());
};
</script>

<template>
    <v-autocomplete
        ref="combobox"
        v-model="selected"
        v-model:search="search"
        :loading="loading ? 'primary' : false"
        color="primary"
        :bg-color="bgColor"
        :multiple="false"
        :items="items"
        :custom-filter="filter"
        item-value="number"
        :item-title="customerTitle"
    >
        <template
            #item="{
                item,
                /* eslint-disable-next-line vue/no-template-shadow */
                props,
            }"
        >
            <v-list-item
                v-bind="props"
                :title="item.raw.number + ' - ' + item.raw.name"
            >
            </v-list-item>
        </template>
    </v-autocomplete>
</template>

<style scoped></style>
