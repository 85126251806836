<script setup>
import { mdiCheckCircleOutline } from '@mdi/js';
import { trans as $t } from 'laravel-vue-i18n';
import { join_names } from '@/lib/helpers.js';
import ScannerCheckmark from '@/Components/ScannerCheckmark.vue';
import { computed } from 'vue';

const props = defineProps({
    order: {
        type: [Object, null],
        required: true,
    },
    scans: {
        type: Array,
        required: true,
    },
    showCheck: {
        type: Boolean,
        default: false,
    },
});

const scanned = computed(() => {
    return props.scans.filter((scan) => scan.scanned_at !== null).length;
});
</script>

<template>
    <v-card v-if="order !== null">
        <v-card-title class="tw-flex tw-justify-between"
            >{{ order.delivery_note_number }}
            <v-spacer></v-spacer>
            <slot name="button"> </slot>
        </v-card-title>
        <v-card-subtitle
            >{{ $t('Shipment') }}:
            {{ scans[0]?.shipment_number ?? order.shipping_agent_code }}
        </v-card-subtitle>
        <v-card-text>
            <v-row>
                <v-col>
                    <v-row>
                        <v-col cols="12">
                            <p class="tw-font-bold">
                                {{ join_names(order.name, order.name2) }}
                            </p>
                            <p>
                                {{ join_names(order.address, order.address2) }}
                            </p>
                            <p>
                                {{ order.country }}-{{ order.postal_code }}
                                {{ order.city }}
                            </p>
                        </v-col>
                        <v-col cols="12">
                            <table class="tw-border-spacing-0">
                                <thead>
                                    <tr>
                                        <th class="tw-pr-4 tw-text-left">
                                            Colli
                                        </th>
                                        <th>{{ $t('Scanned') }}</th>
                                        <th class="tw-pr-4"></th>
                                    </tr>
                                </thead>
                                <tbody v-if="scans.length > 0">
                                    <tr
                                        v-for="scan in scans"
                                        :key="scan.code"
                                        class="even:tw-bg-gray-100"
                                    >
                                        <td class="tw-pr-4">
                                            {{ scan.code }}
                                        </td>
                                        <td class="tw-text-right">
                                            <v-icon
                                                v-if="scan.scanned_at !== null"
                                                color="primary"
                                                :icon="mdiCheckCircleOutline"
                                            ></v-icon>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td>KEINE LABEL VORHANDEN</td>
                                    </tr>
                                </tbody>
                            </table>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col
                    order="3"
                    order-sm="2"
                    cols="12"
                    sm="auto"
                    class="tw-flex tw-items-end tw-justify-center sm:tw-max-w-[150px]"
                >
                    <div
                        class="tw-relative tw-h-[130px] tw-w-[150px] tw-overflow-hidden"
                    >
                        <v-fade-transition :hide-on-leave="true">
                            <div
                                v-if="!showCheck"
                                class="tw-absolute tw-left-1/2 tw-top-1/2 -tw-translate-x-1/2 -tw-translate-y-1/4 tw-rounded tw-border tw-border-solid tw-border-primary tw-p-4 tw-text-3xl tw-font-light tw-text-primary"
                            >
                                <div class="tw-whitespace-nowrap">
                                    {{ scanned }} /
                                    {{ scans.length }}
                                </div>
                            </div>
                        </v-fade-transition>
                        <ScannerCheckmark
                            class="tw-absolute tw-left-1/2 tw-top-1/2 tw-h-[120px] -tw-translate-x-1/2 -tw-translate-y-1/2 tw-text-center"
                            :show="showCheck"
                        ></ScannerCheckmark>
                    </div>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<style scoped></style>
