<script setup>
import { Head, router, usePage } from '@inertiajs/vue3';
import { computed, nextTick, ref } from 'vue';
import { trans as $t } from 'laravel-vue-i18n';
import { join_names } from '@/lib/helpers';
import TableCard from '@/Components/TableCard.vue';
import { mdiCheckCircle, mdiTrashCan } from '@mdi/js';

const page = usePage();
const columns = computed(() => [
    { field: 'customer_number', label: $t('Customer Number') },
    { field: 'order_number', label: $t('Order Number') },
    {
        field: 'name',
        label: $t('Name'),
        valueGetter: (row) => join_names(row['name'], row['name2'], ', '),
    },
    {
        field: 'address',
        label: $t('Address'),
        valueGetter: (row) => join_names(row['address'], row['address2'], ', '),
    },
]);

const rowDeleting = ref(null);
const onRowDelete = async (row) => {
    if (rowDeleting.value === null && rowConfirming.value === null) {
        rowDeleting.value = row;
        await nextTick();
        router.delete(route('cancelled-order.destroy', row.order_number), {
            onSuccess() {
                rowDeleting.value = null;
            },
        });
    }
};
const rowConfirming = ref(null);
const onRowConfirm = async (row) => {
    if (rowConfirming.value === null && rowDeleting.value === null) {
        rowConfirming.value = row;
        await nextTick();
        router.put(route('cancelled-order.confirm', row.order_number), {
            onSuccess() {
                rowConfirming.value = null;
            },
        });
    }
};
</script>

<template>
    <v-container>
        <Head :title="page.props.title"> </Head>
        <div>
            <TableCard :columns="columns" :rows="page.props.orders">
                <template #actions="{ row }">
                    <v-btn
                        class="tw-mr-2"
                        size="small"
                        color="primary"
                        :loading="
                            rowConfirming?.order_number === row.order_number
                        "
                        :disabled="
                            (rowConfirming !== null &&
                                rowConfirming.order_number !==
                                    row.order_number) ||
                            rowDeleting !== null
                        "
                        @click="onRowConfirm(row)"
                    >
                        {{ $t('Cancelled') }}
                        <v-icon class="tw-ml-2" :icon="mdiCheckCircle"></v-icon>
                    </v-btn>
                    <v-btn
                        size="small"
                        color="danger"
                        :loading="
                            rowDeleting?.order_number === row.order_number
                        "
                        :disabled="
                            (rowDeleting !== null &&
                                rowDeleting.order_number !==
                                    row.order_number) ||
                            rowConfirming !== null
                        "
                        @click="onRowDelete(row)"
                    >
                        <v-icon :icon="mdiTrashCan"></v-icon>
                    </v-btn>
                </template>
            </TableCard>
        </div>
    </v-container>
</template>

<style scoped></style>
