import validbarcode from 'barcode-validator';

export const skuValidation = {
    length: 11,
    validate: function (value, line) {
        if (value.length !== this.length) {
            return false;
        }

        return value === line.item.sku;
    },
};

export const eanValidation = {
    length: 13,
    validate: function (value) {
        if (value?.length !== this.length) {
            return false;
        }
        return validbarcode(value);
    },
};

export const upcValidation = {
    length: 12,
    validate: function (value) {
        if (value?.length !== this.length) {
            return false;
        }
        return validbarcode(value);
    },
};

export const serialNumberValidation = {
    length: 12,
    validate: function (value) {
        if (value?.length !== this.length) {
            return false;
        }
        return /\d{3}-\d{8}/.test(value);
    },
};

export const dateValidation = {
    validate: function (value) {
        return /yyww.*:.*\d{4}/.test(value);
    },
};

export const micValidation = {
    length: 8,
    validate: function (value) {
        if (value?.length !== this.length) {
            return false;
        }
        return /\d{8}/.test(value);
    },
};
