<script setup>
import { computed, inject, ref } from 'vue';
import LinkVuetify from '@/Components/LinkVuetify.vue';
import { mdiFileEdit, mdiMagnify, mdiTrashCan } from '@mdi/js';
import { router } from '@inertiajs/vue3';

const confirm = inject('confirm');

const props = defineProps({
    params: {
        type: Object,
        required: true,
    },
});

const data = computed(() => props.params.data);

const deleting = ref(false);
const deleteTicket = async () => {
    if (
        await confirm.value(
            'Are you sure you want to delete this ticket?',
            'Delete Ticket',
        )
    ) {
        router.delete(route('ticket.destroy', { ticket: data.value.id }), {
            onStart: () => {
                deleting.value = true;
            },
            onFinish: () => {
                deleting.value = false;
            },
            errorBag: 'deleteTicket',
        });
    }
};
</script>

<template>
    <div
        class="tw-flex tw-h-full tw-w-full tw-items-center tw-px-2"
        @mousedown.stop.prevent
        @dblclick.stop.prevent
    >
        <LinkVuetify
            v-if="params.data.permissions.canUpdateTicket"
            class="tw-mr-1"
            :href="route('ticket.edit', { ticket: data.id })"
            color="secondary"
            size="x-small"
            variant="plain"
            density="comfortable"
            :icon="mdiFileEdit"
        ></LinkVuetify>
        <LinkVuetify
            v-if="params.data.permissions.canViewTicket"
            class="tw-mr-1"
            :href="route('ticket.show', { ticket: data.id })"
            color="info"
            size="x-small"
            variant="plain"
            density="comfortable"
            :icon="mdiMagnify"
        ></LinkVuetify>
        <v-btn
            v-if="params.data.permissions.canDeleteTicket"
            color="danger"
            :loading="deleting"
            size="x-small"
            variant="plain"
            density="comfortable"
            :icon="mdiTrashCan"
            @click="deleteTicket"
        ></v-btn>
    </div>
</template>

<style scoped></style>
