<script setup>
import { computed, defineAsyncComponent, provide, ref, watch } from 'vue';
import { Head, Link } from '@inertiajs/vue3';
import NavigationDrawer from '@/Components/NavigationDrawer.vue';
import { useDisplay } from 'vuetify';
import { mdiAlert, mdiClock } from '@mdi/js';
import ConfirmationPromiseModal from '@/Components/ConfirmationPromiseModal.vue';
import AppFooter from '@/Layouts/AppFooter.vue';
import InfoBanner from '@/Components/InfoBanner.vue';
import colors from 'tailwindcss/colors';

defineProps({
    title: {
        type: String,
        default: null,
    },
    header: {
        type: Boolean,
        default: false,
    },
});

const { lgAndUp } = useDisplay();
const drawer = ref(null);
// const visitPage = function (page) {
//     router.visit(route(page));
// };

const toggleDrawer = function () {
    drawer.value = !drawer.value;
};

const banner = ref(null);
const flash = (message, style = 'primary', timeout = null) => {
    banner.value.flash(message, style, timeout);
};
const flashClose = () => {
    banner.value.close();
};

const confirmationModal = ref(null);
const confirm = ref(null);
watch(confirmationModal, (modal) => {
    confirm.value = modal?.confirm;
});

provide('confirm', confirm);
provide('flash', flash);
provide('flashClose', flashClose);

const clock = ref(null);
const clockIsRunning = computed(() => clock.value?.isRunning);
const showClockDialog = ref(false);
const ClockDialog = defineAsyncComponent(
    () => import('@/Components/ClockModal/ClockDialog.vue'),
);

const pauseCaptureKeydown = computed(() => {
    return showClockDialog.value;
});
provide('pauseCaptureKeydown', pauseCaptureKeydown);
</script>

<template>
    <v-app>
        <ClockDialog
            v-if="$page.props.auth.canCreateClockEntries"
            ref="clock"
            :show="showClockDialog"
            @close="showClockDialog = false"
        ></ClockDialog>
        <ConfirmationPromiseModal ref="confirmationModal" />
        <Head :title="$page.props.title ?? null"></Head>
        <NavigationDrawer v-model="drawer" />
        <v-app-bar :color="$page.props.danger ? 'danger' : undefined">
            <v-app-bar-nav-icon
                v-if="!lgAndUp"
                @click="toggleDrawer"
            ></v-app-bar-nav-icon>
            <v-toolbar-title class="tw-text-lg tw-font-light tw-uppercase"
                >{{ $page.props.title }}
            </v-toolbar-title>
            <v-spacer />
            <!-- eslint-disable-next-line tailwindcss/no-custom-classname -->
            <v-toolbar-items class="fill-height">
                <v-list-item
                    v-if="$page.props.danger"
                    class="tw-mr-6 tw-text-2xl tw-font-bold tw-uppercase"
                >
                    <v-icon :icon="mdiAlert" class="tw-mr-1"></v-icon>
                    DANGER
                </v-list-item>
                <div
                    id="nav-actions"
                    class="tw-mr-4 tw-flex tw-h-full tw-items-center"
                ></div>
            </v-toolbar-items>
            <Link
                v-if="!$page.props.auth.canCreateClockEntries"
                :href="route('dashboard')"
            >
                <v-icon icon="$logo" size="x-large" class="tw-ml-2 tw-mr-3" />
            </Link>
            <v-btn v-else :icon="true" @click="showClockDialog = true">
                <v-icon
                    :color="!clockIsRunning ? 'primary' : colors.blue['500']"
                    :icon="mdiClock"
                />
            </v-btn>
        </v-app-bar>
        <div class="tw-sticky tw-top-[65px] tw-z-[60] tw-grow-0">
            <InfoBanner ref="banner" />
        </div>
        <slot></slot>
        <AppFooter></AppFooter>
    </v-app>
</template>
