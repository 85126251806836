<script setup>
import TableCard from '@/Components/TableCard.vue';
import { ref, watch } from 'vue';
import { router } from '@inertiajs/vue3';
import LaravelPaginator from '@/Components/LaravelPaginator.vue';

const props = defineProps({
    routeName: { type: String, required: true },
    data: { type: Object, required: true },
    columns: { type: Array, required: true },
    only: { type: Array, default: () => [] },
    space: { type: Number, default: 32 },
});

const currentPage = ref(props.data.meta.current_page);
const loading = ref(false);
const filterSort = ref({});

watch([currentPage, filterSort], ([newPage, newFilterSort]) => {
    const query_params = Object.assign({ page: newPage }, newFilterSort);
    const visitOptions = {
        preserveScroll: true,
        preserveState: true,
        onSuccess: () => {
            loading.value = false;
        },
    };

    if (props.only.length > 0) {
        visitOptions.only = props.only;
    }

    loading.value = true;

    router.visit(route(props.routeName, query_params), visitOptions);
});
</script>

<template>
    <TableCard
        :loading="loading"
        :rows="data.data"
        :columns="columns"
        :space="space"
        @state-change="
            filterSort = $event;
            currentPage = 1;
        "
    >
        <template v-if="$slots.actions" #actions="{ row }">
            <slot :row="row" name="actions"></slot>
        </template>
        <template #footer="{ footerHeight }">
            <LaravelPaginator
                v-model="currentPage"
                :paging-data="data.meta"
                :size="footerHeight"
            >
            </LaravelPaginator>
        </template>
    </TableCard>
</template>

<style scoped></style>
