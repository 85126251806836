<script setup>
import { Head, router, usePage } from '@inertiajs/vue3';
import { mdiPlus, mdiTrashCan } from '@mdi/js';
import { computed, inject, nextTick, onMounted, reactive, ref } from 'vue';
import OrderModal from '@/Pages/Order/Partials/OrderModal.vue';
import LinkVuetify from '@/Components/LinkVuetify.vue';
import OrderTable from '@/Pages/Order/Partials/OrderTable.vue';
import SavedOrderTable from '@/Pages/Order/Partials/SavedOrderTable.vue';
import SavedOrderModal from '@/Pages/Order/Partials/SavedOrderModal.vue';
import SquareIconButton from '@/Pages/Order/SquareIconButton.vue';
import { AxiosError } from 'axios';
import ConfirmationModal from '@/Components/ConfirmationModal.vue';
import { downloadFile } from '@/lib/helpers';
import ExcelButton from '@/Components/ExcelButton.vue';
import { watchOnce } from '@vueuse/core';

const page = usePage();
const flash = inject('flash');

const orderData = ref(null);

const showOrderModal = ref(false);
const onOrderShow = async (orderNumber) => {
    orderData.value = null;
    showOrderModal.value = true;
    if (!Number.isInteger(orderNumber)) {
        orderData.value = (
            await axios.get(route('api.maillog.order.show', orderNumber))
        ).data;
    } else {
        orderData.value = (
            await axios.get(route('api.order.show', orderNumber))
        ).data;
    }
};

onMounted(() => {
    if (page.props.open !== null) {
        onOrderShow(page.props.open);
    }
});

const showSavedOrderModal = ref(false);
const selectedOrders = ref([]);

const onSavedOrderDelete = (ids) => {
    savedOrderTableRef.value.reload(ids);
};
const onOrderSelect = (orders) => {
    selectedOrders.value = orders;
};
onMounted(() => {
    if (page.props.selectedOrders.length > 0) {
        watchOnce(selectedOrders, (value) => {
            if (value.length > 0) {
                showSavedOrderModal.value = true;
            }
        });
    }
});
const previewSavedOrders = () => {
    showSavedOrderModal.value = true;
};

const deleteLoading = ref(false);
const deleteSavedOrders = async () => {
    deleteLoading.value = true;

    await nextTick();
    const orderIds = selectedOrders.value.map((order) => order.id);
    try {
        const response = await axios.delete(
            route('api.order.batchDestroy', { order_ids: orderIds }),
        );
        router.reload({
            only: ['totalSavedOrders'],
            onFinish: () => {
                onSavedOrderDelete(orderIds);
                flash(response.data.message, 'success');
            },
        });
    } catch (e) {
        if (e instanceof AxiosError && e.response.status === 422) {
            flash(e.response.data.message, 'danger');
        }
    } finally {
        deleteLoading.value = false;
    }
};

const currentTab = ref(page.props.currentTab);
const savedOrderTableRef = ref(null);
const badgeVisible = computed(() => page.props.totalSavedOrders > 0);

const downloadingExcel = ref(false);
const queryParams = ref(null);
const showExportConfirmation = ref(false);
const downloadExcel = async () => {
    try {
        delete queryParams.value.params.page;
        downloadingExcel.value = true;
        await downloadFile(route('order.export'), queryParams.value.params);
    } catch (e) {
        console.log(e);
    } finally {
        downloadingExcel.value = false;
    }
};

const confirmDownload = () => {
    if (queryParams.value.total > 1000) {
        showExportConfirmation.value = true;
    } else {
        downloadExcel();
    }
};

const acceptDownload = () => {
    showExportConfirmation.value = false;
    downloadExcel();
};

const cancelledOrders = reactive([]);
const cancelOrder = function (orderNumber) {
    cancelledOrders.push(orderNumber);
};

const orderTableRef = ref(null);
const completeOrder = () => {
    orderTableRef.value.gridApi.refreshServerSide();
};
</script>

<template>
    <Head :title="$t('Orders')" />

    <ConfirmationModal
        :show="showExportConfirmation"
        @close="showExportConfirmation = false"
    >
        <template #title>Large Export</template>
        <template #content>
            {{ $t('order.export_warning.term1') }} {{ queryParams.total }}
            {{ $t('order.export_warning.term2') }} <b>1000</b>
            {{ $t('order.export_warning.term3') }}
        </template>
        <template #footer>
            <v-btn color="danger" @click="showExportConfirmation = false"
                >Cancel</v-btn
            >
            <v-btn color="primary" @click="acceptDownload">Accept</v-btn>
        </template>
    </ConfirmationModal>

    <OrderModal
        v-model="showOrderModal"
        :order="orderData"
        @cancelled="cancelOrder"
        @action:complete="completeOrder"
    ></OrderModal>
    <SavedOrderModal
        v-model="showSavedOrderModal"
        :error-messages="page.props.errorMessages"
        :orders="selectedOrders"
        @deleted="onSavedOrderDelete"
        @close="page.props.errorMessages = []"
    />

    <div class="tw-flex tw-h-full tw-flex-col tw-p-4">
        <div
            class="tw-mb-4 tw-flex tw-flex-col tw-items-center tw-justify-between md:tw-mb-2 md:tw-flex-row"
        >
            <v-tabs
                v-model="currentTab"
                class="tw-mb-2 tw-inline-block md:tw-mb-0"
            >
                <v-tab value="booked" class="tw-text-sm tw-font-medium">
                    {{ $t('Booked Orders') }}
                </v-tab>
                <v-tab value="saved">
                    <v-badge
                        v-model="badgeVisible"
                        :offset-y="-8"
                        :offset-x="page.props.totalSavedOrders > 9 ? 2 : -5"
                        color="primary"
                        :content="page.props.totalSavedOrders"
                        ><span>{{ $t('Saved Orders') }}</span></v-badge
                    >
                </v-tab>
            </v-tabs>
            <div class="tw-flex">
                <div
                    v-if="selectedOrders.length > 0 && currentTab === 'saved'"
                    class="tw-mt-2 sm:tw-mt-0"
                >
                    <SquareIconButton
                        class="tw-mr-2"
                        :icon="mdiTrashCan"
                        :loading="deleteLoading"
                        color="danger"
                        @click="deleteSavedOrders"
                    />
                    <v-btn
                        class="tw-mr-2"
                        color="primary"
                        @click="previewSavedOrders"
                    >
                        {{ $t('Book') }}
                    </v-btn>
                </div>
                <div class="tw-mt-2 sm:tw-mt-0">
                    <ExcelButton
                        v-if="currentTab === 'booked'"
                        class="tw-mr-2"
                        :disabled="!queryParams"
                        :loading="downloadingExcel"
                        @click="confirmDownload"
                    >
                    </ExcelButton>
                    <LinkVuetify
                        v-if="page.props.auth.canCreateOrders"
                        color="black"
                        :prepend-icon="mdiPlus"
                        :href="route('order.create')"
                        >{{ $t('Create Order') }}
                    </LinkVuetify>
                </div>
            </div>
        </div>
        <v-window v-model="currentTab" class="tw-h-full">
            <v-window-item class="tw-h-full" value="booked">
                <OrderTable
                    ref="orderTableRef"
                    :cancelled-orders="cancelledOrders"
                    @on-show="onOrderShow"
                    @on-request="queryParams = $event"
                />
            </v-window-item>
            <v-window-item class="tw-h-full" value="saved">
                <SavedOrderTable
                    ref="savedOrderTableRef"
                    :saved-orders="page.props.savedOrders"
                    :total-saved="page.props.totalSavedOrders"
                    :initially-selected="page.props.selectedOrders"
                    @on-show="onOrderShow"
                    @on-select="onOrderSelect"
                />
            </v-window-item>
        </v-window>
    </div>
</template>
