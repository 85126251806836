<script setup></script>

<template>
    <v-sheet rounded="lg" class="tw-border tw-border-primary/50 tw-p-4">
        <span
            class="tw-absolute -tw-m-4 -tw-translate-y-1/2 tw-translate-x-3 tw-bg-surface tw-px-1 tw-text-xs tw-text-primary"
        >
            <slot name="title"></slot>
        </span>
        <slot></slot>
    </v-sheet>
</template>

<style scoped></style>
