<script setup>
import { trans as $t } from 'laravel-vue-i18n';
import {
    columnStateEvents,
    getColumnState,
} from '@/lib/ag-grid/save-column-state';
import { computed, ref } from 'vue';
import defaultColDef from '@/lib/ag-grid/default-column-definitions';
import { AgGridVue } from 'ag-grid-vue3';
import columnTypes from '@/lib/ag-grid/column-types';
import AgTableCard from '@/Components/AgTableCard.vue';
import { mdiPlus, mdiSync } from '@mdi/js';
import LinkVuetify from '@/Components/LinkVuetify.vue';
import TableEditButtonCellRenderer from '@/Pages/WarehouseManagement/Item/Partials/TableEditButtonCellRenderer.vue';
import { Head, useForm } from '@inertiajs/vue3';

defineProps({
    items: {
        type: Array,
        required: true,
    },
});

const columnDefs = computed(() => [
    {
        headerName: $t('Maillog SKU'),
        field: 'item_number',
        type: 'textColumnFilter',
        headerTooltip: $t('Maillog SKU'),
    },
    {
        headerName: $t('SKU'),
        field: 'sku',
        type: 'textColumnFilter',
        headerTooltip: $t('SKU'),
    },
    {
        headerName: $t('Description'),
        field: 'description',
        type: 'textColumnFilter',
        headerTooltip: $t('Description'),
    },
    {
        cellRenderer: TableEditButtonCellRenderer,
    },
]);

const stateResponse = getColumnState('warehouse_item');
const stateLoaded = ref(false);
const onGridReady = async (params) => {
    const columnState = await stateResponse;
    if (columnState) {
        params.columnApi.applyColumnState({
            state: columnState,
            applyOrder: true,
        });
    }
    stateLoaded.value = true;
};

const syncItemsForm = useForm({});
const syncItems = () => {
    syncItemsForm.post(route('warehouse.sync-items'));
};
</script>

<template>
    <Head :title="$page.props.title"></Head>
    <AgTableCard :loading="!stateLoaded">
        <template #actions>
            <v-btn
                :prepend-icon="mdiSync"
                :loading="syncItemsForm.processing"
                @click="syncItems()"
                >Sync</v-btn
            >
            <v-spacer />
            <LinkVuetify
                :prepend-icon="mdiPlus"
                :href="route('warehouse.items.create')"
                color="black"
            >
                {{ $t('Create Article') }}
            </LinkVuetify>
        </template>
        <ag-grid-vue
            class="ag-theme-material tw-h-full"
            :column-types="columnTypes"
            :default-col-def="defaultColDef"
            :column-defs="columnDefs"
            :row-data="items"
            v-on="columnStateEvents"
            @grid-ready="onGridReady"
        ></ag-grid-vue>
    </AgTableCard>
</template>

<style scoped></style>
