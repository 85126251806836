<script setup>
import { Head } from '@inertiajs/vue3';
import { ref } from 'vue';
import ExcelButton from '@/Components/ExcelButton.vue';
import { downloadFile } from '@/lib/helpers';
import StockModal from '@/Pages/Stock/Partials/StockModal.vue';
import StockTable from '@/Pages/Stock/Partials/StockTable.vue';
import AppBarActionsTeleport from '@/Components/AppBarActionsTeleport.vue';

const showImageDialog = ref(false);

const image = ref(null);
const imageUrl = ref(null);
const imageWidth = ref(null);

const showImage = (url) => {
    showImageDialog.value = true;
    imageUrl.value = url;
};

const onResize = () => {
    if (image.value.image) {
        imageWidth.value = null;
        const interval = setInterval(() => {
            if (image.value.image.offsetHeight !== 0) {
                clearInterval(interval);
                imageWidth.value =
                    (image.value.naturalWidth / image.value.naturalHeight) *
                    image.value.image.offsetHeight;
            }
        }, 100);
    }
};

const onClose = () => {
    showImageDialog.value = false;
    imageWidth.value = null;
};

const downloadingExcel = ref(false);
const queryParams = ref(null);
const downloadExcel = async () => {
    downloadingExcel.value = true;
    try {
        await downloadFile(route('stock.export'), queryParams.value);
    } finally {
        downloadingExcel.value = false;
    }
};

const showStockModal = ref(false);
const openItem = ref(null);
const onStockShow = (item) => {
    openItem.value = item;
    showStockModal.value = true;
};
const stockTableRef = ref(null);
const onModalClose = (item) => {
    stockTableRef.value.updateItem(item);
};

const belowMinimumStock = ref(false);
</script>

<template>
    <Head :title="$t('Stock')" />
    <AppBarActionsTeleport>
        <v-btn
            :variant="belowMinimumStock ? 'elevated' : 'outlined'"
            elevation="2"
            color="info"
            class="tw-mr-2"
            @click="belowMinimumStock = !belowMinimumStock"
        >
            {{ $t('Below Minimum Stock') }}
        </v-btn>
        <ExcelButton
            :loading="downloadingExcel"
            @click="downloadExcel"
        ></ExcelButton>
    </AppBarActionsTeleport>
    <StockModal
        v-model="showStockModal"
        :item="openItem"
        @close="onModalClose"
    ></StockModal>

    <v-dialog
        v-model="showImageDialog"
        content-class=""
        :width="imageWidth"
        @close="onClose"
    >
        <v-progress-circular
            v-if="image?.state === 'loading'"
            class="tw-self-center"
            indeterminate
            :size="61"
            :width="7"
        ></v-progress-circular>
        <v-img
            v-show="image?.state !== 'loading'"
            ref="image"
            v-resize="onResize"
            class="tw-flex-1"
            :src="imageUrl"
            @load="onResize"
        ></v-img>
    </v-dialog>
    <div class="tw-flex tw-h-full tw-flex-col tw-p-4">
        <StockTable
            ref="stockTableRef"
            :below-minimum-stock="belowMinimumStock"
            @show-image="showImage"
            @on-show="onStockShow"
            @on-request="queryParams = $event"
        ></StockTable>
    </div>
</template>

<style scoped></style>
