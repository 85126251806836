<script setup>
import { Head } from '@inertiajs/vue3';
import CustomerInformationForm from '@/Pages/Customer/Partials/CustomerInformationForm.vue';
import UserListForm from '@/Pages/Customer/Partials/UserListForm.vue';

const props = defineProps({
    customer: {
        type: Object,
        required: true,
    },
    serviceLevels: {
        type: Array,
        required: true,
    },
});
</script>

<template>
    <v-container class="tw-h-full">
        <Head :title="$t('Edit Customer')"></Head>
        <CustomerInformationForm
            :customer="props.customer"
            :service-levels="props.serviceLevels"
        />

        <UserListForm
            :customer-id="customer.id"
            :users="props.customer.users"
        ></UserListForm>
    </v-container>
</template>
