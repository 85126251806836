<script setup>
import FormSection from '@/Components/FormSection.vue';
import { useForm, usePage } from '@inertiajs/vue3';
import TextField from '@/Components/TextField.vue';
import CustomerCombobox from '@/Components/CustomerCombobox.vue';
import SaveButton from '@/Components/SaveButton.vue';
import WarehouseLocationsForm from '@/Pages/WarehouseManagement/Tenants/Partials/WarehouseLocationsForm.vue';

const page = usePage();

const form = useForm({
    ...page.props.tenant,
}).transform((data) => {
    if (data.id === null) {
        delete data.id;
    }
    return data;
});

const submitCreateTenantForm = () => {
    if (form.id !== null) {
        form.put(route('warehouse.tenants.update', { tenant: form.id }));
    } else {
        form.post(route('warehouse.tenants.store'), {
            onSuccess() {
                form.defaults({ ...page.props.tenant });
                form.reset();
            },
        });
    }
};
</script>

<template>
    <v-container class="tw-min-h-full">
        <FormSection @submitted="submitCreateTenantForm">
            <template v-if="form.id === null" #title>
                {{ $t('Create Warehouse Tenant') }}
            </template>
            <template v-else #title>
                {{ $t('Edit Warehouse Tenant') }}
            </template>
            <template v-if="form.id === null" #description>
                {{ $t('warehouse.create_tenant_description') }}
            </template>
            <template v-else #description>
                {{ $t('warehouse.edit_tenant_description') }}
            </template>
            <template #form>
                <TextField
                    v-model="form.name"
                    :label="$t('Name')"
                    :error-messages="form.errors.name"
                ></TextField>
                <CustomerCombobox
                    v-model="form.maillog_customer_numbers"
                    :selected="
                        form.id !== null ? page.props.maillog_customers : []
                    "
                    clearable
                    :max-selection="1"
                    :error-messages="form.errors.maillog_customer_numbers"
                ></CustomerCombobox>
            </template>
            <template #actions>
                <SaveButton
                    :loading="form.processing"
                    :disabled="!form.isDirty"
                ></SaveButton>
            </template>
        </FormSection>
        <WarehouseLocationsForm
            v-if="form.id !== null"
            :tenant="page.props.tenant"
        ></WarehouseLocationsForm>
    </v-container>
</template>

<style scoped></style>
