<script setup>
import { ref } from 'vue';
import { useForm } from '@inertiajs/vue3';
import FormSection from '@/Components/FormSection.vue';
import TextField from '@/Components/TextField.vue';

const props = defineProps({
    userId: {
        type: [null, Number],
        default: null,
    },
    needsCurrent: {
        type: Boolean,
        default: true,
    },
});

const passwordInput = ref(null);
const currentPasswordInput = ref(null);

const form = useForm({
    current_password: '',
    password: '',
    password_confirmation: '',
});

const updatePassword = () => {
    form.put(
        props.userId === null
            ? route('user-password.update')
            : route('user.update-password', { user: props.userId }),
        {
            errorBag: 'updatePassword',
            preserveScroll: true,
            onSuccess: () => form.reset(),
            onError: () => {
                if (form.errors.password) {
                    form.reset('password', 'password_confirmation');
                    passwordInput.value.focus();
                }

                if (form.errors.current_password) {
                    form.reset('current_password');
                    currentPasswordInput.value.focus();
                }
            },
        },
    );
};
</script>

<template>
    <FormSection @submitted="updatePassword">
        <template #title>
            {{ $t('Update Password') }}
        </template>

        <template #description>
            {{
                $t(
                    'Ensure your account is using a long, random password to stay secure.',
                )
            }}
        </template>

        <template #form>
            <TextField
                v-if="needsCurrent"
                ref="currentPasswordInput"
                v-model="form.current_password"
                :label="$t('Current Password')"
                type="password"
                autocomplete="current-password"
                :error-messages="form.errors.current_password"
            ></TextField>
            <TextField
                ref="passwordInput"
                v-model="form.password"
                :label="$t('New Password')"
                type="password"
                autocomplete="new-password"
                :error-messages="form.errors.password"
            ></TextField>
            <TextField
                v-model="form.password_confirmation"
                :label="$t('Confirm Password')"
                type="password"
                autocomplete="new-password"
                :error-messages="form.errors.password_confirmation"
            ></TextField>
        </template>

        <template #actions>
            <v-btn
                :loading="form.processing"
                :disabled="form.processing"
                variant="elevated"
                type="submit"
                color="primary"
                >{{ $t('Save') }}</v-btn
            >
        </template>
    </FormSection>
</template>
