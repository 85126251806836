<script setup>
import { computed } from 'vue';
import { usePage } from '@inertiajs/vue3';
import { trans as $t } from 'laravel-vue-i18n';

import { AgGridVue } from 'ag-grid-vue3';
import {
    columnStateEvents,
    getColumnState,
    onGridReady,
    stateApplied,
} from '@/lib/ag-grid/save-column-state';
import defaultColDef from '@/lib/ag-grid/default-column-definitions';
import TableLoading from '@/Components/TableLoading.vue';

getColumnState('arrival');

const autoGroupColumnDef = {
    headerName: 'Registering Date',
    cellRendererParams: {
        suppressCount: true,
    },
};

const columnDefs = computed(() => {
    return [
        {
            headerName: $t('Arrival Date'),
            headerTooltip: $t('Arrival Date'),
            valueGetter(params) {
                return moment(params.data['Registering Date']).format(
                    'DD.MM.YYYY',
                );
            },
            sortable: true,
            comparator(valueA, valueB) {
                const dateA = moment(valueA, 'DD.MM.YYYY');
                const dateB = moment(valueB, 'DD.MM.YYYY');
                if (dateA.isBefore(dateB)) {
                    return -1;
                }
                if (dateA.isAfter(dateB)) {
                    return 1;
                }
                return 0;
            },
            rowGroup: true,
            minWidth: 110,
            hide: false,
            colId: 'Date',
        },
        {
            headerName: 'Maillog SKU',
            headerTooltip: 'Maillog SKU',
            field: 'Item No_',
            minWidth: 100,
        },
        {
            headerName: 'SKU',
            headerTooltip: 'SKU',
            field: 'Vendor Item No_',
            minWidth: 100,
        },
        {
            headerName: $t('Description'),
            headerTooltip: 'Beschreibung',
            valueGetter(params) {
                return params.data !== undefined
                    ? params.data.Description +
                          ' ' +
                          params.data['Description 2']
                    : null;
            },
            colId: 'description',
        },
        {
            headerName: $t('Batch'),
            headerTooltip: $t('Batch'),
            field: 'Lot No_',
        },
        {
            headerName: $t('Quantity'),
            headerTooltip: $t('Quantity'),
            field: 'Quantity',
            sortable: true,
        },
        {
            headerName: $t('Unit'),
            headerTooltip: $t('Unit'),
            field: 'Unit of Measure Code',
            minWidth: 90,
        },
    ];
});
const rowData = usePage().props.arrivals;
</script>

<template>
    <v-card class="tw-h-full">
        <TableLoading :loading="!stateApplied">
            <ag-grid-vue
                class="ag-theme-material tw-h-full"
                :default-col-def="defaultColDef"
                :auto-group-column-def="autoGroupColumnDef"
                :row-data="rowData"
                :column-defs="columnDefs"
                group-display-type="groupRows"
                :enable-range-selection="true"
                :tooltip-show-delay="300"
                :group-default-expanded="1"
                @grid-ready="onGridReady"
                v-on="columnStateEvents"
            />
        </TableLoading>
    </v-card>
</template>

<style scoped>
:deep(.ag-row.ag-row-level-0) {
    background-color: #f5f5f5;
}
</style>
