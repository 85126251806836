<script setup>
import { usePage } from '@inertiajs/vue3';
import { computed } from 'vue';
import { collect } from 'collect.js';
import moment from 'moment/moment.js';

defineProps({
    order: {
        type: Object,
        required: true,
    },
});

const page = usePage();
const statuses = computed(() =>
    collect(page.props.fulfillment_statuses)
        .mapWithKeys((status) => [status.status, status])
        .all(),
);
const formatDate = (date) => {
    return moment(date).format('DD.MM.YYYY HH:mm');
};

const truncateBoth = computed(
    () => true,
    // ['DVD'].includes(collect(props.order.status_events).last().status_code),
);
</script>

<template>
    <v-timeline align="start" :truncate-line="!truncateBoth ? 'start' : 'both'">
        <v-timeline-item
            v-for="(status, index) in order.status_events"
            :key="status.id"
            :dot-color="index % 2 === 0 ? 'primary' : 'secondary'"
            size="small"
        >
            <template #opposite>
                <div :class="{ 'tw-text-right': index % 2 === 0 }">
                    <h4
                        :class="
                            index % 2 === 0
                                ? 'tw-text-primary'
                                : 'tw-text-secondary'
                        "
                    >
                        {{ status.status_code }}
                    </h4>
                    <p>
                        {{ statuses[status.status_code].description }}
                    </p>
                </div>
            </template>

            <div :class="{ 'tw-text-right': index % 2 !== 0 }">
                {{ formatDate(status.updated_at) }}
            </div>
        </v-timeline-item>
    </v-timeline>
</template>

<style scoped></style>
