<script setup>
import SaveButton from '@/Components/SaveButton.vue';
import { useForm } from '@inertiajs/vue3';
import TextField from '@/Components/TextField.vue';
import LinkVuetify from '@/Components/LinkVuetify.vue';
import ActionSection from '@/Components/ActionSection.vue';

const props = defineProps({
    location: {
        type: Object,
        required: true,
    },
    tenant: {
        type: Object,
        default: null,
    },
});

const form = useForm({
    ...props.location,
}).transform((data) => {
    delete data.id;
    return data;
});

const isEdit = form.id !== null;
const submitCreateLocationForm = () => {
    if (isEdit) {
        form.put(
            route('warehouse.locations.update', {
                location: form.id,
            }),
        );
    } else {
        form.post(
            route('warehouse.tenants.locations.store', {
                tenant: props.tenant.id,
            }),
        );
    }
};
</script>

<template>
    <v-container class="tw-min-h-full">
        <ActionSection>
            <template v-if="!isEdit" #title>{{
                $t('Create Warehouse Location')
            }}</template>
            <template v-else #title>{{
                $t('Edit Warehouse Location')
            }}</template>
            <template v-if="!isEdit" #description>{{
                $t('warehouse.create_location_description')
            }}</template>
            <template v-else #description>{{
                $t('warehouse.edit_location_description')
            }}</template>
            <template #content>
                <v-row>
                    <v-col cols="12">
                        <TextField
                            v-model="form.description"
                            :error-messages="form.errors.description"
                            :label="$t('Description')"
                            hide-details="auto"
                        ></TextField>
                    </v-col>
                    <v-col class="tw-pb-0" cols="12" md="6">
                        <TextField
                            v-model="form.name"
                            :error-messages="form.errors.name"
                            :label="$t('Name')"
                            hide-details="auto"
                        ></TextField>
                    </v-col>
                    <v-col class="tw-pb-0" cols="12" md="6">
                        <TextField
                            v-model="form.name2"
                            :error-messages="form.errors.name2"
                            :label="$t('Name 2')"
                            hide-details="auto"
                        ></TextField>
                    </v-col>
                    <v-col class="tw-pb-0" cols="12" md="6">
                        <TextField
                            v-model="form.address"
                            :error-messages="form.errors.address"
                            :label="$t('Address')"
                            hide-details="auto"
                        ></TextField>
                    </v-col>
                    <v-col class="tw-pb-0" cols="12" md="6">
                        <TextField
                            v-model="form.address2"
                            :error-messages="form.errors.address2"
                            :label="$t('Address 2')"
                            hide-details="auto"
                        ></TextField>
                    </v-col>
                    <v-col class="tw-pb-0" cols="12" md="6">
                        <TextField
                            v-model="form.postal_code"
                            :error-messages="form.errors.postal_code"
                            :label="$t('Postal Code')"
                            hide-details="auto"
                        ></TextField>
                    </v-col>
                    <v-col class="tw-pb-0" cols="12" md="6">
                        <TextField
                            v-model="form.city"
                            :error-messages="form.errors.city"
                            :label="$t('City')"
                            hide-details="auto"
                        ></TextField>
                    </v-col>
                    <v-col class="tw-pb-0" cols="12" md="6">
                        <TextField
                            v-model="form.country"
                            :error-messages="form.errors.country"
                            :label="$t('Country')"
                            hide-details="auto"
                        ></TextField>
                    </v-col>
                    <v-col class="tw-pb-0" cols="12" md="6">
                        <TextField
                            v-model="form.contact"
                            :error-messages="form.errors.contact"
                            :label="$t('Contact')"
                            hide-details="auto"
                        ></TextField>
                    </v-col>
                    <v-col class="tw-pb-0" cols="12" md="6">
                        <TextField
                            v-model="form.phone"
                            :error-messages="form.errors.phone"
                            :label="$t('Phone')"
                            hide-details="auto"
                        ></TextField>
                    </v-col>
                </v-row>
            </template>
            <template #actions>
                <LinkVuetify
                    :href="
                        route('warehouse.tenants.edit', {
                            tenant: tenant?.id ?? form.warehouse_tenant_id,
                        })
                    "
                    color="danger"
                    >{{ $t('Cancel') }}</LinkVuetify
                >
                <SaveButton
                    :loading="form.processing"
                    :disabled="!form.isDirty"
                    type="button"
                    @click="submitCreateLocationForm"
                ></SaveButton>
            </template>
        </ActionSection>
    </v-container>
</template>

<style scoped></style>
