<script setup>
defineProps({
    icon: {
        type: String,
        required: true,
    },
});

const emit = defineEmits(['click']);
</script>

<template>
    <v-btn class="tw-min-w-0" @click="emit('click')">
        <v-icon :icon="icon"></v-icon>
    </v-btn>
</template>
