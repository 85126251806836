<script setup>
import { Head, useForm } from '@inertiajs/vue3';
import logo from '@/../svg/maillog_logo.svg?url';
import TextField from '@/Components/TextField.vue';

const props = defineProps({
    email: { type: String, required: true },
    token: { type: String, required: true },
});

const form = useForm({
    token: props.token,
    password: '',
    password_confirmation: '',
});

const submit = () => {
    form.post(route('invite.accept', props.token), {
        onFinish: () => form.reset('password', 'password_confirmation'),
    });
};
</script>

<script>
export default { layout: null };
</script>

<template>
    <Head :title="$t('Accept Invitation')" />
    <div class="tw-min-h-screen tw-flex tw-items-center">
        <v-container>
            <v-card
                class="tw-block tw-mx-auto"
                max-width="600"
                width="100%"
                elevation="4"
            >
                <v-toolbar height="80" flat>
                    <v-img
                        :src="logo"
                        width="50px"
                        class="flex-grow-0 ms-5"
                    ></v-img>
                    <v-spacer></v-spacer>
                    <v-toolbar-title>{{
                        $t('Accept Invitation')
                    }}</v-toolbar-title>
                </v-toolbar>
                <v-divider></v-divider>
                <v-card-text>
                    <!-- eslint-disable vue/no-v-html -->
                    <div
                        class="tw-rounded-lg tw-border tw-mb-4 tw-border-solid tw-border-gray-200 tw-text-gray-500 tw-font-sans tw-italic tw-text-md tw-text-light tw-p-2"
                        v-html="$t('accept.invite', { email: email })"
                    ></div>
                    <!-- eslint-enable -->
                    <v-form @submit.prevent="submit">
                        <TextField
                            v-model="form.password"
                            type="password"
                            :error-messages="form.errors.password"
                            :label="$t('Password')"
                            required
                            autocomplete="new-password"
                        ></TextField>
                        <TextField
                            v-model="form.password_confirmation"
                            type="password"
                            :error-messages="form.errors.password_confirmation"
                            :label="$t('Confirm Password')"
                            required
                            autocomplete="new-password"
                        ></TextField>

                        <div
                            class="tw-flex tw-items-center tw-justify-end tw-mt-4"
                        >
                            <v-btn
                                type="submit"
                                color="primary"
                                :loading="form.processing"
                            >
                                {{ $t('Accept Invitation') }}
                            </v-btn>
                        </div>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-container>
    </div>
</template>
