<script setup>
import { useMounted } from '@vueuse/core';

const isMounted = useMounted();
</script>

<template>
    <teleport v-if="isMounted" to="#nav-actions">
        <slot></slot>
    </teleport>
</template>

<style scoped></style>
