<script setup>
import { mdiMagnify, mdiPencil } from '@mdi/js';

const props = defineProps({
    params: {
        type: Object,
        required: true,
    },
});

console.log(props);
</script>

<template>
    <div
        class="tw-flex tw-h-full tw-items-center tw-justify-end"
        @dblclick.prevent.stop
    >
        <v-btn
            v-if="
                params.data[params.idField] &&
                params.buttonEvents.onShow !== undefined
            "
            class="tw-mr-2"
            color="info"
            density="comfortable"
            size="small"
            :icon="mdiMagnify"
            @click.prevent="
                params.buttonEvents.onShow(params.data[params.idField])
            "
        >
        </v-btn>
        <v-btn
            v-if="
                params.data[params.idField] &&
                params.buttonEvents.onEdit !== undefined
            "
            size="small"
            density="comfortable"
            :icon="mdiPencil"
            color="surface"
            @click.prevent="
                params.buttonEvents.onEdit(params.data[params.idField])
            "
        ></v-btn>
    </div>
</template>

<style scoped></style>
