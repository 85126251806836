<script setup>
import { ref } from 'vue';
import colors from 'tailwindcss/colors';
import { useVModel } from '@vueuse/core';

const input = ref(null);

const props = defineProps({
    modelValue: { type: Array, default: () => [] },
    label: { type: String, default: null },
    color: {
        type: String,
        default: 'primary',
    },
    bgColor: {
        type: String,
        default: colors.gray['100'],
    },
    multiple: {
        type: Boolean,
        default: false,
    },
    errorMessages: {
        type: [Array, String],
        default: () => [],
    },
});

const emit = defineEmits(['update:modelValue', 'input']);

const value = useVModel(props, 'modelValue', emit);

defineExpose({
    focus: () => input.value.focus(),
    validate: () => input.value.validate(),
    reset: () => input.value.reset(),
});
</script>

<template>
    <v-file-input
        ref="input"
        v-model="value"
        class="tw-mb-2"
        :bg-color="bgColor"
        :multiple="multiple"
        :error-messages="errorMessages"
        :color="color"
        :label="label"
        show-size
        @input="$emit('input', $event)"
    >
        <template v-if="$slots.details" #details>
            <slot name="details" />
        </template>
    </v-file-input>
</template>
