<script setup></script>

<template>
    <v-text-field
        class="tw--ml-4"
        single-line
        hide-details
        clearable
        :variant="null"
    ></v-text-field>
</template>
