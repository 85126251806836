<script setup>
import FormSection from '@/Components/FormSection.vue';
import FileInputField from '@/Components/FileInputField.vue';
import { useForm, usePage } from '@inertiajs/vue3';
import { computed, ref, watch } from 'vue';
import { undot } from '@/lib/helpers';
import SectionBorder from '@/Components/SectionBorder.vue';
import ValidationErrorCard from '@/Components/ValidationErrorCard.vue';
import { trans as $t } from 'laravel-vue-i18n';

const page = usePage();

const form = useForm({
    customer_number: page.props.customers[0]?.number,
    file: null,
    import: true,
});

const files = ref([]);
const uploadFieldErrors = computed(() => {
    return (
        page.props.errorBags?.default?.file ||
        page.props.errorBags?.default?.rows ||
        []
    );
});

const orderErrors = computed(() => {
    if (!page.props.errorBags.default) return [];

    const errors = undot(page.props.errorBags.default);
    if (errors.orders !== undefined) {
        return Object.keys(errors.orders).map((key) => {
            return Object.keys(errors.orders[key].fields[0]).map((field) => {
                return (
                    $t('Order') +
                    ` ${key} - ${errors.orders[key].fields[0][field]}`
                );
            });
        });
    }

    return [];
});
const submitExcelImportForm = () => {
    form.file = files.value[0];
    form.post(route('order.import.store'), {
        preserveScroll: true,
        onSuccess: () => {
            form.reset();
        },
        onError: () => {
            files.value = [];
        },
    });
};

watch(files, (files) => {
    if (files.length > 0) {
        form.file = files[0];
    } else {
        form.file = null;
    }
});
</script>

<template>
    <v-container class="tw-h-full tw-pt-8">
        <FormSection @submit.prevent="submitExcelImportForm">
            <template #title> {{ $t('Order') }} - {{ $t('Import') }} </template>
            <template #description>
                {{ $t('Import orders from an Excel file.') }}
            </template>
            <template #form>
                <v-select
                    v-if="page.props.customers.length > 1"
                    v-model="form.customer_number"
                    :label="$t('Customer')"
                    :items="page.props.customers"
                    item-title="name"
                    item-value="number"
                    :error-messages="form.errors['customer_number']"
                />
                <FileInputField
                    v-model="files"
                    hide-details="auto"
                    :error-messages="uploadFieldErrors"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    label="Excel"
                    :max-errors="100"
                >
                    <template v-if="uploadFieldErrors.length === 0" #details>
                        <span
                            >{{ $t('order.excel-import.details.term1') }}
                            <a :href="route('order.import.excel-template')">{{
                                $t('order.excel-import.details.link')
                            }}</a>
                            {{ $t('order.excel-import.details.term2') }}
                        </span>
                    </template>
                </FileInputField>
                <v-checkbox
                    v-model="form.import"
                    color="primary"
                    :label="$t('Book Order')"
                />
                <section-border v-if="orderErrors.length > 0"></section-border>
                <ValidationErrorCard
                    v-if="orderErrors.length > 0"
                    :errors="orderErrors"
                ></ValidationErrorCard>
            </template>
            <template #actions>
                <v-spacer></v-spacer>
                <v-btn
                    :loading="form.processing"
                    :disabled="!form.isDirty || !form.file"
                    data-action="import"
                    color="primary"
                    variant="elevated"
                    type="submit"
                >
                    {{ $t('Import') }}
                </v-btn>
            </template>
        </FormSection>
    </v-container>
</template>

<style scoped></style>
