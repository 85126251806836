<script setup>
import { Head, useForm } from '@inertiajs/vue3';
import AuthenticationCard from '@/Components/AuthenticationCard.vue';
import TextField from '@/Components/TextField.vue';

const props = defineProps({
    email: { type: String, required: true },
    token: { type: String, required: true },
});

const form = useForm({
    token: props.token,
    email: props.email,
    password: '',
    password_confirmation: '',
});

const submit = () => {
    form.post(route('password.update'), {
        onFinish: () => form.reset('password', 'password_confirmation'),
    });
};
</script>

<script>
export default { layout: null };
</script>

<template>
    <Head :title="$t('Reset Password')" />

    <AuthenticationCard>
        <v-form @submit.prevent="submit">
            <TextField
                v-model="form.email"
                :error-messages="form.errors.email"
                label="E-Mail"
                required
            ></TextField>
            <TextField
                v-model="form.password"
                type="password"
                :error-messages="form.errors.password"
                :label="$t('Password')"
                required
                autocomplete="new-password"
            ></TextField>
            <TextField
                v-model="form.password_confirmation"
                type="password"
                :error-messages="form.errors.password_confirmation"
                :label="$t('Confirm Password')"
                required
                autocomplete="new-password"
            ></TextField>

            <div class="tw-flex tw-items-center tw-justify-end tw-mt-4">
                <v-btn type="submit" color="primary" :loading="form.processing">
                    {{ $t('Reset Password') }}
                </v-btn>
            </div>
        </v-form>
    </AuthenticationCard>
</template>
