<script setup>
import { mdiClose, mdiPencil, mdiTrashCan } from '@mdi/js';
import { useVModel } from '@vueuse/core';
import DialogModal from '@/Components/DialogModal.vue';
import OrderSummarySection from '@/Pages/Order/Partials/OrderSummarySection.vue';
import colors from 'tailwindcss/colors';
import SectionBorder from '@/Components/SectionBorder.vue';
import LinkVuetify from '@/Components/LinkVuetify.vue';
import { computed, nextTick, reactive } from 'vue';
import ErrorCard from '@/Components/ValidationErrorCard.vue';
import { useForm } from '@inertiajs/vue3';

const props = defineProps({
    modelValue: {
        type: Boolean,
        default: false,
    },
    orders: {
        type: Array,
        default: () => [],
    },
    errorMessages: {
        type: Array,
        default: () => [],
    },
});

const hasErrors = computed(() => {
    return (
        props.errorMessages.length > 0 ||
        props.orders.some((order) => {
            return (
                Object.prototype.hasOwnProperty.call(
                    order,
                    'validation_errors',
                ) && Object.keys(order.validation_errors).length > 0
            );
        })
    );
});
const emit = defineEmits(['update:modelValue', 'close', 'deleted']);

const show = useVModel(props, 'modelValue', emit);
const onClose = () => {
    if (someLoading.value) {
        return;
    }
    show.value = false;
    emit('close');
};

const deleteLoading = reactive({});
const someLoading = computed(() => {
    return (
        batchImportForm.processing ||
        Object.values(deleteLoading).some((value) => value)
    );
});
const onDeleteStart = (id) => {
    deleteLoading[id] = true;
};

const onDeleteFinish = async (id) => {
    emit('deleted', [id]);
    deleteLoading[id] = false;
    await nextTick();
    if (props.orders.length === 0) {
        onClose();
    }
};

const showCustomerNumber = computed(() => {
    return (
        [...new Set(props.orders.map((order) => order.customer_number))]
            .length > 1
    );
});

const batchImportForm = useForm({
    order_ids: [],
});
const batchImportOrders = function () {
    batchImportForm.order_ids = props.orders.map((order) => order.id);
    batchImportForm.post(route('order.import.batch'), {
        preserveState: false,
    });
};
</script>

<template>
    <DialogModal
        v-model="show"
        :card-color="colors.gray[100]"
        class="tw-max-w-6xl"
        :persistent="someLoading"
        @close="onClose"
    >
        <template #title>
            <div class="tw-mt-2 tw-flex tw-justify-between">
                <h6 class="tw-font-sans tw-text-lg">
                    {{ $t('Order Details') }}
                </h6>
                <v-icon :icon="mdiClose" @click="onClose"></v-icon>
            </div>
        </template>
        <template v-if="orders.length > 0" #content>
            <v-row v-if="errorMessages.length > 0">
                <v-col cols="12">
                    <ErrorCard
                        :title="$t('Import Errors')"
                        :errors="errorMessages"
                    ></ErrorCard>
                </v-col>
            </v-row>
            <v-row v-for="(order, index) in orders" :key="order.id">
                <v-col cols="12" md="2" class="tw-flex md:tw-flex-col">
                    <div>
                        <h3
                            class="tw-mr-3 tw-text-lg tw-font-medium tw-text-gray-900"
                        >
                            {{ index + 1 }} / {{ orders.length }}
                        </h3>
                    </div>
                    <div>
                        <LinkVuetify
                            class="tw-mr-1"
                            density="comfortable"
                            size="small"
                            color="info"
                            icon
                            :href="route('order.edit', order.id)"
                        >
                            <v-icon :icon="mdiPencil" />
                        </LinkVuetify>
                        <LinkVuetify
                            density="comfortable"
                            size="small"
                            color="danger"
                            icon
                            method="delete"
                            preserve-state
                            :only="[
                                'savedOrders',
                                'totalSavedOrders',
                                'errors',
                                'errorBags',
                            ]"
                            :href="route('order.destroy', order.id)"
                            :loading="deleteLoading[order.id]"
                            @start="onDeleteStart(order.id)"
                            @finish="onDeleteFinish(order.id)"
                        >
                            <v-icon :icon="mdiTrashCan" />
                        </LinkVuetify>
                    </div>
                </v-col>
                <v-col cols="12" md="10">
                    <OrderSummarySection
                        class="tw-grow"
                        :order="order"
                        :show-customer-number="showCustomerNumber"
                    >
                    </OrderSummarySection>
                </v-col>
                <SectionBorder></SectionBorder>
            </v-row>
        </template>
        <template v-else #content>
            <div class="tw-w-full tw-py-24 tw-text-center">
                <v-progress-circular indeterminate></v-progress-circular>
            </div>
        </template>
        <template #footer>
            <v-spacer></v-spacer>
            <v-btn variant="elevated" color="danger" @click="onClose"
                >{{ $t('Cancel') }}
            </v-btn>
            <v-btn
                :disabled="hasErrors"
                :loading="batchImportForm.processing"
                variant="elevated"
                color="primary"
                @click="batchImportOrders"
                >{{ $t('Book Orders') }}
            </v-btn>
        </template>
    </DialogModal>
</template>

<style scoped></style>
