<script setup>
import { mdiMagnify, mdiTrashCan } from '@mdi/js';
import { router, useForm } from '@inertiajs/vue3';

const props = defineProps({
    params: {
        type: Object,
        required: true,
    },
});

const deleteItemForm = useForm({});

const submitDeleteItemForm = () => {
    deleteItemForm.delete(
        route('warehouse.items.destroy', { item: props.params.data.id }),
    );
};

const onEdit = () => {
    router.visit(route('warehouse.items.edit', { item: props.params.data.id }));
};
</script>

<template>
    <div>
        <v-btn
            :icon="mdiMagnify"
            class="tw-mr-2"
            color="info"
            density="comfortable"
            size="small"
            :disabled="deleteItemForm.processing"
            @click.prevent="onEdit"
        ></v-btn>
        <v-btn
            v-if="!params.data.item_number"
            :icon="mdiTrashCan"
            class="tw-mr-2"
            color="danger"
            density="comfortable"
            size="small"
            :loading="deleteItemForm.processing"
            @click="submitDeleteItemForm"
        ></v-btn>
    </div>
</template>

<style scoped></style>
