<script setup>
import AsyncCombobox from '@/Components/AsyncCombobox.vue';
import { nextTick, ref } from 'vue';
import { watchArray } from '@vueuse/core';

const props = defineProps({
    modelValue: {
        type: Array,
        default: () => [],
    },
    selected: {
        type: Array,
        default: () => [],
    },
});
const emit = defineEmits(['update:modelValue']);

const selectedUsers = ref(props.selected);
watchArray(selectedUsers, (value) => {
    emit(
        'update:modelValue',
        value.map((item) => item.id)
    );
});

const combobox = ref(null);
const reset = () => {
    selectedUsers.value = props.selected;
    nextTick(combobox.value.reset);
};

defineExpose({ reset });
</script>

<template>
    <AsyncCombobox
        ref="combobox"
        v-model="selectedUsers"
        :label="$t('Users')"
        filter-column="search"
        item-value="id"
        item-title="username"
        chip-title="username"
        :search-url="route('api.user.index')"
    />
</template>

<style scoped></style>
