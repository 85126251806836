<script setup>
import DialogModal from '@/Components/DialogModal.vue';
import { mdiAlert } from '@mdi/js';

const emit = defineEmits(['close']);

defineProps({
    show: {
        type: Boolean,
        default: false,
    },
    maxWidth: {
        type: String,
        default: '2xl',
    },
    closeable: {
        type: Boolean,
        default: true,
    },
    toolbarProps: {
        type: Object,
        default: () => ({ color: 'danger' }),
    },
    toolbarIcon: {
        type: String,
        default: mdiAlert,
    },
});

const close = () => {
    emit('close');
};
</script>

<template>
    <DialogModal
        :show="show"
        :persistent="!closeable"
        :toolbar-props="toolbarProps"
        @close="close"
    >
        <template #toolbar>
            <v-toolbar-title>
                <v-icon :icon="toolbarIcon" class="tw-mr-2"></v-icon>
                <slot name="title"></slot>
            </v-toolbar-title>
            <v-spacer></v-spacer>
        </template>
        <template #content>
            <slot name="content"></slot>
        </template>
        <template #append>
            <v-toolbar height="50">
                <v-spacer></v-spacer>
                <slot name="footer"></slot>
            </v-toolbar>
        </template>
    </DialogModal>
</template>
