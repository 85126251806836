<script setup>
import TextField from '@/Components/TextField.vue';
import { computed, reactive, ref, watch } from 'vue';
import { serialNumberValidation } from '@/lib/utilities/pack-serial-number-validations';

const props = defineProps({
    line: {
        type: Object,
        required: true,
    },
});
const emit = defineEmits(['scan']);

const serialScanTemplate = {
    sku: null,
    serial_number: null,
    ean: null,
    upc: null,
    date: null,
    mic1: null,
    mic2: null,
};
const currentScan = ref({ ...serialScanTemplate });

const inputRefs = reactive({
    serial_input: {
        field: 'serial_number',
        ref: null,
        disabled: false,
        nextInput: false,
        validator: serialNumberValidation,
    },
});

const completed = computed(() => {
    return Object.keys(inputRefs).every((key) => {
        return inputRefs[key].ref === null || inputRefs[key].disabled;
    });
});

watch(completed, (value) => {
    if (value) {
        saveSingleScan();
    }
});

const validateInput = (input) => {
    if (input.validator.validate(currentScan.value[input.field], props.line)) {
        if (input.nextInput) {
            inputRefs[input.nextInput].ref.focus();
        }
        input.disabled = true;
    } else {
        currentScan.value[input.field] = null;
    }
};

const focusFirstInput = () => {
    inputRefs.sku_input.ref.focus();
};

const saveSingleScan = () => {
    emit('scan', { ...currentScan.value });
    reset();
};

const reset = () => {
    currentScan.value = {
        ...serialScanTemplate,
    };

    Object.keys(inputRefs).forEach((key) => {
        inputRefs[key].disabled = false;
    });
};

defineExpose({
    focusFirstInput,
    reset,
});
</script>

<template>
    <TextField
        :ref="(el) => (inputRefs.serial_input.ref = el)"
        v-model="currentScan.serial_number"
        density="compact"
        label="S/N"
        hide-details
        :disabled="inputRefs.serial_input.disabled"
        @keydown.enter="validateInput(inputRefs.serial_input)"
    ></TextField>
</template>

<style scoped></style>
