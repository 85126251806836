<script setup>
import CheckboxField from '@/Components/CheckboxField.vue';

const settings = defineModel({
    type: Object,
    required: true,
});
</script>

<template>
    <div>
        <CheckboxField
            v-model="settings.has_packaging_list"
            class="tw-mb-0"
            :label="$t('app.settings.has_packaging_list')"
        ></CheckboxField>
        <v-row v-if="settings.has_packaging_list">
            <v-col>
                <v-card>
                    <template #title>
                        <span class="tw-text-sm">Multi Collo</span>
                    </template>
                    <CheckboxField
                        v-model="settings.packaging_lists.kep"
                        class="tw-ml-6"
                        density="compact"
                        :label="$t('app.settings.packaging_lists.kep')"
                    ></CheckboxField>
                    <CheckboxField
                        v-model="settings.packaging_lists.sped"
                        class="tw-ml-6"
                        density="compact"
                        :label="$t('app.settings.packaging_lists.sped')"
                    ></CheckboxField>
                    <CheckboxField
                        v-model="settings.packaging_lists.pickup"
                        class="tw-ml-6"
                        density="compact"
                        :label="$t('app.settings.packaging_lists.pickup')"
                    ></CheckboxField>
                </v-card>
            </v-col>
            <v-col>
                <v-card>
                    <template #title>
                        <span class="tw-text-sm">Single Collo</span>
                    </template>
                    <CheckboxField
                        v-model="settings.packaging_lists.kep_single"
                        class="tw-ml-6"
                        density="compact"
                        :label="$t('app.settings.packaging_lists.kep')"
                    ></CheckboxField>
                    <CheckboxField
                        v-model="settings.packaging_lists.sped_single"
                        class="tw-ml-6"
                        density="compact"
                        :label="$t('app.settings.packaging_lists.sped')"
                    ></CheckboxField>
                    <CheckboxField
                        v-model="settings.packaging_lists.pickup_single"
                        class="tw-ml-6"
                        density="compact"
                        :label="$t('app.settings.packaging_lists.pickup')"
                    ></CheckboxField>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<style scoped></style>
