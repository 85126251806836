<script>
export default {
    props: {
        params: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        const getValue = () => {
            return props.params.value;
        };

        return {
            getValue,
        };
    },
};
</script>

<template>
    <div class="tw-flex tw-h-full tw-items-center">
        {{ params.formattedValue ?? params.value }}
    </div>
</template>

<style scoped></style>
