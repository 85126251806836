<script setup>
import { computed, inject, provide, reactive, ref } from 'vue';
import { router, useForm, usePage } from '@inertiajs/vue3';
import { collect } from 'collect.js';
import { trans as $t } from 'laravel-vue-i18n';
import useBarcodeScanner from '@/lib/composables/barcode-scanner';
import ColloPackagingExpansionPanel from '@/Pages/Pack/Partials/ColloPackagingExpansionPanel.vue';
import LinkVuetify from '@/Components/LinkVuetify.vue';
import SerialNumberDialog from '@/Pages/Pack/Partials/SerialNumberDialog.vue';
import ColloNoPackagingExpansionPanel from '@/Pages/Pack/Partials/ColloNoPackagingExpansionPanel.vue';
import { usePackScans } from '@/lib/composables/pack-scans';
import AppBarActionsTeleport from '@/Components/AppBarActionsTeleport.vue';
import {
    mdiArrowLeft,
    mdiArrowRightBold,
    mdiCheckCircle,
    mdiRestart,
} from '@mdi/js';
import SaveButton from '@/Components/SaveButton.vue';
import DialogModal from '@/Components/DialogModal.vue';
import TextField from '@/Components/TextField.vue';
import LabelExpansionPanel from '@/Pages/Pack/Partials/LabelExpansionPanel.vue';
import RootLayout from '@/Layouts/RootLayout.vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import { useSetting } from '@/lib/composables/settings.js';
import PackTypes from '@/lib/enums/pack-types.js';
import ScanQuantityDialog from '@/Pages/Pack/Partials/ScanQuantityDialog.vue';
import PackagingTableBody from '@/Pages/Pack/Partials/PackagingTableBody.vue';
import ONTDialog from '@/Pages/Pack/Partials/ONTDialog.vue';
import ColloDimensionModal from '@/Pages/Pack/Partials/ColloDimentionModal.vue';
import { useProvideColloStore } from '@/lib/composables/collo-state.js';
import SmaxtecSerialNumber from '@/Pages/Pack/Partials/SmaxtecSerialNumber.vue';

defineOptions({
    layout: (h, page) => h(RootLayout, () => h(AppLayout, () => page)),
});
const page = usePage();
const confirm = inject('confirm');
const vWaveTriggerRefs = reactive({});

const deliveryNote = computed(() => {
    return page.props.pack_process.delivery_note;
});
const customer = computed(() => {
    return page.props.customer;
});

// Some settings
const autoCompletion = useSetting(
    'auto_completion',
    page.props.auto_completion,
);

const withBinCode = useSetting(
    'pack_without_bin_code',
    page.props.without_bin_code,
);

const {
    scans,
    newScans,
    updateScan,
    transactionsPending,
    submitScansAfter,
    loading,
    deleteScan,
    reset: resetScanStorage,
    currentColloId,
} = usePackScans();

const scansByLine = computed(() => {
    return scans.value.mapToGroups((scan) => [scan.line_number, scan]).all();
});
const scansLeft = computed(() => {
    return collect(deliveryNote.value.lines)
        .mapWithKeys((line) => [
            line.line_number,
            line.quantity -
                collect(scansByLine.value[line.line_number]).sum('quantity'),
        ])
        .all();
});
const scansByColloId = computed(() => {
    return collect(page.props.pack_process.colli)
        .mapWithKeys((collo) => [collo.id, []])
        .merge(
            scans.value
                .groupBy('pack_process_collo_id')
                .map((scans) => scans.all())
                .all(),
        )
        .all();
});
const missingScans = computed(() => {
    return collect(deliveryNote.value.lines).mapWithKeys((line) => [
        line.line_number,
        line.quantity -
            collect(scansByLine.value[line.line_number]).sum('quantity'),
    ]);
});
const missingScanQuantity = computed(() => {
    return missingScans.value.sum();
});

const everyColloHasPackaging = computed(() => {
    // When multi_collo is disabled, it only checks if there is at least one packaging scan
    if (!completePackForm.multi_collo) {
        return (
            collect(scans.value).first(
                (scan) => scan.data.type === 'packaging',
                null,
            ) !== null
        );
    }
    return collect(scansByColloId.value).every((scans) =>
        scans.some((scan) => scan.data.type === 'packaging'),
    );
});

const everyColloHasLines = computed(() => {
    return collect(scansByColloId.value).every((scans) =>
        scans.some((scan) => scan.data.type === 'line'),
    );
});

const canCompletePack = computed(() => {
    return (
        missingScanQuantity.value === 0 &&
        everyColloHasPackaging.value &&
        // if not multi_collo, not every collo needs lines
        (!completePackForm.multi_collo || everyColloHasLines.value) &&
        (!isClosed.value || completePackForm.processing) &&
        !transactionsPending.value
    );
});

const canCreateCollo = computed(() => {
    return (
        missingScanQuantity.value !== 0 &&
        everyColloHasPackaging.value &&
        everyColloHasLines.value &&
        !transactionsPending.value &&
        !isClosed.value
    );
});

const isClosed = computed(() => {
    return (
        page.props.pack_process.status === 'closed' ||
        completePackForm.processing
    );
});

const colloIndexes = computed(() => {
    return collect(page.props.pack_process.colli).mapWithKeys(
        (collo, index) => [collo.id, collo.external_collo_id ?? index + 1],
    );
});

const onColloAdded = () => {
    currentColloId.value = null;
};
const onColloSelected = (colloId) => {
    currentColloId.value = colloId;
};

const validateSelect = (data) => {
    if (scansLeft.value[data.line_number] <= 0) {
        confirm.value(
            $t(
                'Achtung! Dieser Artikel wurde zuviel gescannt. Bitte die Menge prüfen.',
            ),
            $t('Zu viele scans').toLocaleUpperCase(),
            false,
            false,
        );
        return false;
    }

    return true;
};

const createPackProcessForm = useForm({
    delivery_note: null,
});

const palletBarcode = Object.keys(page.props.packaging_barcodes).find(
    (barcode) =>
        page.props.pallet_item_numbers.includes(
            page.props.packaging_barcodes[barcode].item_number,
        ),
);
const onEKeyPressed = () => {
    onBarcodeScanned(palletBarcode);
};
const onBarcodeScanned = (barcode) => {
    if (completePackForm.processing) {
        return;
    }

    if (page.props.pack_process.status === 'closed') {
        createPackProcessForm.delivery_note = barcode;
        createPackProcessForm.post(route('pack.store'), {
            errorBag: 'pack_process_form',
            preserveState: false,
            preserveScroll: false,
        });
        return;
    }

    let batch = null;
    if (barcode.length === 25) {
        batch = barcode.substring(18);
        barcode = barcode.substring(3, 16);
    }

    switch (true) {
        case page.props.barcodes[barcode] !== undefined &&
            page.props.barcodes[barcode] !== null: {
            let lineNumbers = null;
            if (batch === null) {
                lineNumbers = page.props.barcodes[barcode];
            } else {
                lineNumbers = collect(
                    page.props.pack_process.delivery_note.lines,
                )
                    .filter((line) => {
                        return (
                            page.props.barcodes[barcode].includes(
                                line.line_number,
                            ) && line.batch === batch
                        );
                    })
                    .pluck('line_number')
                    .toArray();
            }

            let lineNumber = null;
            // Take array of lineNumbers and scan the first one that is not complete
            // or the last one if all are complete
            for (let i = 0; i < lineNumbers.length; i++) {
                if (
                    scansLeft.value[lineNumbers[i]] === 0 &&
                    i !== lineNumbers.length - 1
                ) {
                    continue;
                }

                lineNumber = lineNumbers[i];
                break;
            }

            const pointerdownEvent = new PointerEvent('pointerdown');
            const pointerupEvent = new PointerEvent('pointerup');
            const triggerElement = vWaveTriggerRefs[lineNumber];

            triggerElement.dispatchEvent(pointerdownEvent);
            document.dispatchEvent(pointerupEvent);

            pack(
                deliveryNote.value.lines.filter(
                    (line) => line.line_number === lineNumber,
                )[0],
                false,
                true,
            );
            break;
        }
        case page.props.packaging_barcodes[barcode] !== undefined: {
            pack(page.props.packaging_barcodes[barcode], true, true);
            break;
        }
        default: {
            confirm.value(
                $t(
                    '<b>Achtung!</b> Es wurde ein falscher Artikel gescannt. Bitte Artikel überprüfen.',
                ),
                $t('Ungültiger Barcode').toLocaleUpperCase(),
                true,
                false,
            );
        }
    }
};

const pauseCaptureKeydown = ref(false);
const globalPauseCaptureKeydown = inject('pauseCaptureKeydown');
const pauseBarcodeScanner = computed(() => {
    return pauseCaptureKeydown.value || globalPauseCaptureKeydown.value;
});

const {
    onBarcodeScanned: barcodeScanned,
    stringBuffer,
    onEnterKey,
} = useBarcodeScanner({
    pauseEventListener: pauseBarcodeScanner,
    validate: false,
    keyEventListeners: {
        KeyE: onEKeyPressed,
    },
});
barcodeScanned(onBarcodeScanned);

const pack = async (
    data,
    packaging = false,
    physicalScan = false,
    customQuantity = false,
) => {
    if (page.props.pack_process.status === 'closed') {
        return;
    }

    // Scan data template
    const scanData = {
        id: 'new_' + String(Date.now()),
        pack_process_collo_id: currentColloId.value,
        data: {
            item_number: !packaging ? null : data.item_number,
            physical: physicalScan,
            type: !packaging ? 'line' : 'packaging',
            serial_numbers: null,
            serial_number: null,
        },
        line_number: !packaging ? data.line_number : null,
        quantity: customQuantity !== false ? null : 1,
    };

    if (!packaging && validateSelect(data)) {
        const scannerLines = [];

        // scan serial numbers and add scans to scannerLines

        pauseCaptureKeydown.value = true;

        try {
            await scanSerialNumbers(scanData, data, scannerLines);
            await scanONTArticle(scanData, data, scannerLines);
            await scanSmaxtecSerialNumbers(scanData, data, scannerLines);

            if (customQuantity !== false && scannerLines.length === 0) {
                let quantity = await selectQuantity(
                    scanData,
                    data,
                    customQuantity,
                );

                pushScannerLines(scannerLines, {
                    ...scanData,
                    quantity,
                });
            }

            pauseCaptureKeydown.value = false;
        } catch (e) {
            pauseCaptureKeydown.value = false;
            throw e;
        }

        if (scannerLines.length === 0 && customQuantity === false) {
            let quantity = 1;
            // if the scan was pressed with finger (not physical article) then
            // we need to multiply the scan by 10 or 100, depending on how many scans left
            if (!packaging && !physicalScan) {
                let toScan = scansLeft.value[scanData.line_number];
                switch (true) {
                    case toScan >= 10 && toScan < 100: {
                        quantity = 10;
                        break;
                    }
                    case toScan >= 100: {
                        quantity = 100;
                        break;
                    }
                }
            }

            scanData.quantity = quantity;
            pushScannerLines(scannerLines, scanData);
        }
        await scanOAIDArticles(scanData, data, scannerLines);
        newScans(scannerLines);
    } else if (packaging) {
        if (autoCompletion.value && completePackForm.multi_collo) {
            completePackForm.collo_count = null;

            submitScansAfter(() => {
                newScans(scanData);
            }).then(() => {
                if (canCompletePack.value) {
                    submitCompletePackForm();
                }
            });
        } else {
            newScans(scanData);
        }
    }
};

const pushScannerLines = (scannerLines, scanData) => {
    // Validate if scans need to be split over multiple lines (e.g. when
    // consolidating multiple lines into one (withoutBinCode)

    if (!withBinCode.value) {
        let quantity = scanData.quantity;

        const line = deliveryNote.value.lines.find(
            (line) => line.line_number === scanData.line_number,
        );

        const lines = collect(deliveryNote.value.lines).filter(
            (l) =>
                l.item_number === line.item_number &&
                l.batch === line.batch &&
                l.unit === line.unit,
        );

        lines.each((line) => {
            const scansLeftForLine = scansLeft.value[line.line_number];

            if (quantity === 0 || scansLeftForLine === 0) {
                return;
            }

            if (scansLeftForLine >= quantity) {
                scannerLines.push({
                    ...scanData,
                    id: 'new_' + String(Date.now()),
                    line_number: line.line_number,
                    quantity,
                });

                quantity = 0;
            } else {
                scannerLines.push({
                    ...scanData,
                    id: 'new_' + String(Date.now()),
                    quantity: scansLeftForLine,
                });

                quantity -= scansLeftForLine;
            }
        });

        return;
    }

    scannerLines.push({
        ...scanData,
    });
};
const onSelect = (data, customQuantity = false) => {
    pack(data, false, false, customQuantity);
};

/* <editor-fold desc="component references and promises for checking and scanning serial numbers"> */
const serialNumberDialogRef = ref(null);
const scanSerialNumbers = async (scanData, line, scannerLines) => {
    if (
        !(
            customer.value.settings.has_serial_number_items &&
            line.item.project.startsWith('SN')
        )
    ) {
        return;
    }

    await serialNumberDialogRef.value.scan(
        scanData,
        line,
        scannerLines,
        missingScans.value.get(line.line_number),
    );
};
/*</editor-fold>*/

const ontDialogRef = ref(null);
const scanONTArticle = async (scanData, line, scannerLines) => {
    if (
        // TODO: check if the item is an OTO item as non hardcoded value
        line.item_number !== '1760015'
    ) {
        return;
    }

    await ontDialogRef.value.scan(scanData, scannerLines);
};
const scanOAIDArticles = async (scanData, line, scannerLines) => {
    if (
        !(
            customer.value.settings.oaid_items &&
            customer.value.settings.oaid_items.includes(line.item_number)
        )
    ) {
        return;
    }

    if (
        missingScans.value.get(line.line_number) ===
        collect(scannerLines).sum('quantity')
    ) {
        await axios.post(
            route('api.pack.oaid.print', {
                pack_process: page.props.pack_process.id,
            }),
            {
                item_number: line.item_number,
            },
        );
    }
};

const smaxtecSerialDialogRef = ref(null);
const isSmaxtec = computed(
    () => customer.value.number === page.props.smaxtec_customer_number,
);
const scanSmaxtecSerialNumbers = async (scanData, line, scannerLines) => {
    if (
        isSmaxtec.value &&
        page.props.smaxtec_serial_items.includes(line.item_number)
    ) {
        await smaxtecSerialDialogRef.value.scan(scanData, line, scannerLines);
    }
};

// Chose quantity for long press
const quantityDialogRef = ref(null);
const selectQuantity = async (scanData, line, scannerLines) => {
    if (scannerLines.length > 0) {
        return;
    }

    return await quantityDialogRef.value.scan(
        scanData,
        line,
        scannerLines,
        missingScans.value.get(line.line_number),
    );
};

// Function checks if scan for line number can be removed for given collo
// while still keeping it valid and returns scan object, false if not allowed.
const canRemoveScan = (lineNumber, colloId, packaging = false) => {
    const scans = scansByColloId.value[colloId];

    let scan;
    if (!packaging) {
        scan = scans.find((scan) => scan.line_number === lineNumber);
    } else {
        scan = scans.find((scan) => scan.data.item_number === lineNumber);
    }

    return scans.filter((colloScan) => colloScan.data.type === scan.data.type)
        .length >= 2
        ? scan
        : false;
};

const update = (lineNumber, oldColloId, newColloId) => {
    // pick random scan
    const scan = canRemoveScan(lineNumber, oldColloId);

    if (scan) {
        updateScan(scan, {
            ...scan,
            pack_process_collo_id: newColloId,
        });
    }
};

const _delete = async (lineNumber, colloId, isPackaging = false) => {
    const scan = canRemoveScan(lineNumber, colloId, isPackaging);

    if (scan) {
        deleteScan(scan);
    }
};

const confirmReset = async () => {
    return await confirm.value($t('pack.reset_confirm'), $t('Reset'));
};

const completePackForm = useForm({
    printer_name: page.props.settings.label_printer ?? null,
    // TODO: maybe create enums in javascript in the future
    multi_collo:
        page.props.pack_process.pack_process_type === PackTypes.MultiCollo,
    collo_count: null,
    scanData: null,
});

const showLabelCountDialog = ref(false);
const onCompleteButtonClicked = () => {
    if (completePackForm.multi_collo) {
        completePackForm.collo_count = null;
        submitCompletePackForm();
    } else {
        completePackForm.collo_count = 1;
        showLabelCountDialog.value = true;
    }
};
const submitCompletePackForm = async () => {
    showLabelCountDialog.value = false;

    try {
        const lastCollo = page.props.pack_process.colli.slice(-1)[0];
        const lastColloHeight =
            lastCollo.height ??
            savedDimensions.value?.[lastCollo.id]?.height ??
            null;
        if (lastColloHeight === null && completePackForm.multi_collo) {
            await promptDimensions(
                lastCollo,
                scansByColloId.value[lastCollo.id] ?? [],
            );
        }
    } catch (e) {
        console.log(e);
        return;
    }

    completePackForm.put(
        route('pack.complete', {
            pack_process: page.props.pack_process.id,
        }),
        {
            errorBag: 'complete_pack_form',
            onSuccess() {
                setTimeout(() => {
                    router.visit(route('pack.create'));
                }, 3000);
            },
        },
    );
};

const packagingScanCount = computed(() => {
    return scans.value.filter((scan) => scan.data.type === 'packaging').count();
});

const toggleMultiCollo = () => {
    completePackForm.multi_collo = !completePackForm.multi_collo;
};

// Handle Dimensions
const { savedDimensions } = useProvideColloStore();
const dimensionModal = ref(null);
const promptDimensions = async (collo, scans, force = false) => {
    pauseCaptureKeydown.value = true;
    try {
        await dimensionModal.value?.promptDimensions(collo, scans, force);
    } finally {
        pauseCaptureKeydown.value = false;
    }
};
provide('promptDimensions', promptDimensions);
</script>

<template>
    <v-main class="tw-flex tw-min-h-full tw-flex-col tw-bg-gray-100">
        <div class="tw-grow">
            <v-container class="tw-h-full">
                <DialogModal :show="showLabelCountDialog">
                    <template #content>
                        <p class="tw-mb-4 tw-mt-2">
                            {{ $t('How many labels do you want to print?') }}
                        </p>
                        <TextField
                            v-model="completePackForm.collo_count"
                            autofocus
                            type="number"
                            :label="$t('Labels')"
                        />
                    </template>
                    <template #footer>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="danger"
                            @click="showLabelCountDialog = false"
                            >{{ $t('Cancel') }}
                        </v-btn>
                        <SaveButton
                            :disabled="completePackForm.collo_count <= 0"
                            type="button"
                            @click="submitCompletePackForm"
                            >{{ $t('Complete') }}
                        </SaveButton>
                    </template>
                </DialogModal>
                <SerialNumberDialog
                    ref="serialNumberDialogRef"
                ></SerialNumberDialog>
                <ScanQuantityDialog
                    ref="quantityDialogRef"
                ></ScanQuantityDialog>
                <ONTDialog ref="ontDialogRef"></ONTDialog>
                <SmaxtecSerialNumber
                    v-if="isSmaxtec"
                    ref="smaxtecSerialDialogRef"
                    :scans
                ></SmaxtecSerialNumber>
                <ColloDimensionModal ref="dimensionModal" :collo-indexes>
                </ColloDimensionModal>
                <v-row
                    class="tw-sticky tw-top-[65px] tw-z-50 -tw-mt-[14px] tw-bg-gray-100"
                >
                    <v-col>
                        <v-expansion-panels @keydown.prevent>
                            <ColloPackagingExpansionPanel
                                v-if="completePackForm.multi_collo"
                                :packaging-scan-count="packagingScanCount"
                                :process-closed="isClosed"
                                :scans-by-collo-id="scansByColloId"
                                :can-create-collo="canCreateCollo"
                                :current-collo-id="currentColloId"
                                :collo-indexes
                                @collo:added="onColloAdded"
                                @collo:selected="onColloSelected"
                                @scan:delete="_delete"
                                @scan:update="update"
                                @settings:multi-collo="toggleMultiCollo"
                            ></ColloPackagingExpansionPanel>
                            <ColloNoPackagingExpansionPanel
                                v-else
                                :packaging-scan-count="packagingScanCount"
                                :scans="scans.all()"
                                @scan:delete="_delete"
                                @settings:multi-collo="toggleMultiCollo"
                            ></ColloNoPackagingExpansionPanel>
                        </v-expansion-panels>
                    </v-col>
                </v-row>
                <v-row
                    v-if="page.props.pack_process.manufacturing_instructions"
                >
                    <v-col>
                        <v-card color="info">
                            <template #subtitle>{{
                                $t('Fertigungsanweisung')
                            }}</template>
                            <template #text>
                                <span class="tw-text-lg">{{
                                    page.props.pack_process
                                        .manufacturing_instructions
                                }}</span>
                            </template>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row v-if="deliveryNote">
                    <!--<editor-fold desc="vwave triggers">-->
                    <div>
                        <div
                            v-for="line in deliveryNote.lines"
                            :ref="
                                (el) => {
                                    if (el === null) {
                                        delete vWaveTriggerRefs[
                                            line.line_number
                                        ];
                                    } else {
                                        vWaveTriggerRefs[line.line_number] = el;
                                    }
                                }
                            "
                            :key="line.line_number"
                            v-wave-trigger:[`row-${line.line_number}`]
                        ></div>
                    </div>
                    <!--</editor-fold>-->
                    <v-col>
                        <v-card>
                            <v-toolbar
                                height="100"
                                :border="missingScanQuantity === 0"
                                rounded
                                class="tw-border-lime-700"
                                :class="{
                                    ' tw-bg-lime-700 tw-bg-opacity-10':
                                        missingScanQuantity === 0,
                                }"
                            >
                                <template #title>
                                    <div
                                        class="tw-mb-2"
                                        :class="{
                                            ' tw-text-lime-700':
                                                missingScanQuantity === 0,
                                        }"
                                    >
                                        <span
                                            class="tw-text-2xl tw-font-bold"
                                            >{{
                                                deliveryNote.delivery_note_number
                                            }}</span
                                        >
                                        ({{ deliveryNote.order_number }})
                                    </div>
                                    <div
                                        class="tw-text-base tw-font-medium tw-text-gray-700"
                                    >
                                        {{ deliveryNote.name }},
                                        {{ deliveryNote.city }}
                                        ({{ deliveryNote.country }})
                                    </div>
                                </template>
                                <span
                                    v-if="isClosed"
                                    class="tw-mr-4 tw-rounded-xl tw-border-4 tw-border-solid tw-border-white tw-bg-red-600 tw-p-4 tw-text-lg tw-font-bold tw-uppercase tw-text-white"
                                    >{{ $t('Completed') }}</span
                                >
                                <v-progress-circular
                                    v-if="loading"
                                    color="primary"
                                    class="tw-mr-4 tw-shrink"
                                    indeterminate
                                ></v-progress-circular>
                                <div
                                    v-if="completePackForm.multi_collo"
                                    class="tw-mr-4 tw-flex tw-flex-col tw-items-center"
                                >
                                    <v-switch
                                        v-model="withBinCode"
                                        color="primary"
                                        hide-details
                                    ></v-switch>
                                    <span class="-tw-mt-2 tw-text-xs">{{
                                        $t('Zeige Lagerplätze')
                                    }}</span>
                                </div>
                                <AppBarActionsTeleport>
                                    <LinkVuetify
                                        v-if="
                                            page.props.auth.canDeletePackProcess
                                        "
                                        class="tw-order-1 tw-mr-2"
                                        :href="
                                            route('pack.reset', {
                                                pack_process:
                                                    page.props.pack_process.id,
                                            })
                                        "
                                        method="put"
                                        variant="elevated"
                                        color="danger"
                                        show-loading
                                        :confirm="confirmReset"
                                        @before="resetScanStorage"
                                        ><!--{{ $t('Reset') }}-->
                                        <v-icon size="x-large">{{
                                            mdiRestart
                                        }}</v-icon>
                                    </LinkVuetify>
                                </AppBarActionsTeleport>
                            </v-toolbar>
                            <v-table>
                                <!--                                <thead>
                                    <tr>
                                        <th>Menge</th>
                                        <th>Artikel</th>
                                        <th>Charge</th>
                                        <th class="tw-text-right">Status</th>
                                    </tr>
                                </thead>-->
                                <PackagingTableBody
                                    :delivery-note="deliveryNote"
                                    :scans-by-line="scansByLine"
                                    :without-bin-code="!withBinCode"
                                    @select="onSelect"
                                >
                                </PackagingTableBody>
                            </v-table>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-expansion-panels @keydown.prevent>
                            <LabelExpansionPanel
                                v-model="completePackForm.printer_name"
                                :errors="completePackForm.errors"
                                :readonly="!isClosed"
                                :printers="page.props.label_printers"
                                :pack-process="page.props.pack_process"
                            ></LabelExpansionPanel>
                        </v-expansion-panels>
                    </v-col>
                </v-row>
                <!--</editor-fold>-->
            </v-container>
        </div>
    </v-main>
    <v-container>
        <v-toolbar
            elevation="0"
            height="80"
            class="tw-flex tw-items-center tw-border-b tw-bg-gray-100 tw-px-4"
        >
            <LinkVuetify
                variation="elevated"
                :href="route('pack.create')"
                color="secondary"
                :prepend-icon="mdiArrowLeft"
            >
                Pack
            </LinkVuetify>
            <v-spacer></v-spacer>
            <TextField
                v-model="stringBuffer"
                density="comfortable"
                class="!tw-mb-0"
                hide-details="auto"
                :error-messages="
                    page.props.errorBags.pack_process_form?.delivery_note
                "
                label="EAN"
                @keydown.stop.enter="onEnterKey"
                @keydown.stop
            >
                <template #append-inner>
                    <v-btn
                        v-if="stringBuffer.length > 0"
                        density="comfortable"
                        rounded="rounded"
                        color="info"
                        :icon="mdiArrowRightBold"
                        @click="onEnterKey"
                    >
                    </v-btn>
                </template>
            </TextField>
            <AppBarActionsTeleport>
                <v-btn
                    v-if="canCompletePack"
                    :loading="completePackForm.processing"
                    class="tw-mx-2 tw-shrink"
                    size="x-large"
                    variant="elevated"
                    color="primary"
                    :prepend-icon="mdiCheckCircle"
                    @click="onCompleteButtonClicked"
                >
                    {{ $t('Complete') }}
                </v-btn>
                <div
                    v-if="completePackForm.multi_collo"
                    class="tw-mr-4 tw-flex tw-flex-col tw-items-center"
                >
                    <v-switch
                        v-model="autoCompletion"
                        density="comfortable"
                        color="primary"
                        hide-details
                        inset
                    ></v-switch>
                    <span class="-tw-mt-2 tw-text-xs">{{
                        $t('Auto Label')
                    }}</span>
                </div>
            </AppBarActionsTeleport>
        </v-toolbar>
    </v-container>
</template>

<style scoped></style>
