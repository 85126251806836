<script setup>
import { computed, inject, ref, watch, watchEffect } from 'vue';
import { collect } from 'collect.js';
import {
    mdiAlert,
    mdiButtonPointer,
    mdiDrag,
    mdiNoteEdit,
    mdiRulerSquare,
    mdiTrashCanOutline,
} from '@mdi/js';
import { usePage } from '@inertiajs/vue3';
import { useColloStore } from '@/lib/composables/collo-state.js';
import { watchImmediate } from '@vueuse/core';

const props = defineProps({
    index: {
        type: [Number, null],
        default: null,
    },
    scans: {
        type: Array,
        required: true,
    },
    collo: {
        type: Object,
        required: true,
    },
    linesByLineNumber: {
        type: Object,
        required: true,
    },
    packagingByItemNumber: {
        type: Object,
        required: true,
    },
    card: {
        type: Boolean,
        default: true,
    },
    canMove: {
        type: Boolean,
        default: true,
    },
    canEdit: {
        type: Boolean,
        default: true,
    },
    editing: {
        type: Boolean,
        default: false,
    },
    selected: {
        type: Boolean,
        default: false,
    },
    colloIndexes: {
        type: Object,
        default: null,
    },
});
const page = usePage();
const colloErrors = ref(null);

watchEffect(() => {
    colloErrors.value =
        page.props.errors.complete_pack_form?.[
            'colli.' + props.collo.id + '.weight'
        ];
});

const emit = defineEmits([
    'edit:start',
    'edit:stop',
    'selected',
    'delete',
    'delete:packaging',
    'collo:weight',
]);

const isColloDetail = computed(() => props.index !== null);

const lineScans = computed(() =>
    collect(props.scans)
        .filter((scan) => scan.data.type === 'line')
        .groupBy('line_number')
        .map((scans) => scans.sum('quantity')),
);
const packagingScans = computed(() =>
    props.scans.filter((scan) => scan.data.type === 'packaging'),
);
const { colloDetails, savedDimensions } = useColloStore();
const calculatedWeight = ref(props.collo.weight);
watchEffect(() => {
    if (savedDimensions.value?.[props.collo.id]) {
        if (savedDimensions.value[props.collo.id].weight) {
            calculatedWeight.value =
                savedDimensions.value[props.collo.id].weight;
            return;
        }
    } else {
        if (props.collo.weight !== null) {
            calculatedWeight.value = props.collo.weight;
            return;
        }
    }

    const line_weight = lineScans.value
        .map((quantity, line_number) => {
            return (
                quantity * props.linesByLineNumber[line_number].weight_per_item
            );
        })
        .sum();
    const packaging_weight = collect(packagingScans.value)
        .map(
            (scan) => props.packagingByItemNumber[scan.data.item_number].weight,
        )
        .sum();

    calculatedWeight.value = line_weight + packaging_weight;
});

const isDraggable = computed(() => {
    return props.canMove && props.editing;
});

watch(calculatedWeight, () => {
    page.props.errors.complete_pack_form = null;
});
watchImmediate(calculatedWeight, (weight) => {
    colloDetails.value[props.collo.id] = weight;
});

const dimensions = computed(() => savedDimensions.value[props.collo.id]);

const promptDimensions = inject('promptDimensions');
const prompt = () => {
    try {
        promptDimensions(props.collo, props.scans, true);
    } catch (e) {
        console.error(e);
    }
};

const parentColloNumber = computed(() => {
    if (props.colloIndexes === null) {
        return null;
    }

    if (savedDimensions.value[props.collo.id]) {
        return props.colloIndexes.get(
            savedDimensions.value[props.collo.id].parent_collo_id,
        );
    } else {
        return props.colloIndexes.get(props.collo.parent_collo_id) ?? null;
    }
});
</script>

<template>
    <v-col :cols="isColloDetail ? 6 : 12">
        <v-card
            :elevation="!isColloDetail ? 0 : null"
            :variant="selected || editing ? 'elevated' : 'tonal'"
            class="tw-h-full"
        >
            <v-toolbar height="75">
                <v-toolbar-title class="tw-grow">
                    <div
                        v-if="isColloDetail"
                        class="tw-align-middle tw-text-base"
                    >
                        <span v-if="collo.external_collo_id === null"
                            >Collo {{ index }}</span
                        >
                        <span v-else class="tw-font-bold"
                            >Collo {{ collo.external_collo_id }}</span
                        >
                    </div>
                    <div v-if="parentColloNumber" class="tw-text-xs">
                        Subcollo von {{ parentColloNumber }}
                    </div>
                    <div class="tw-text-xs tw-text-gray-500">
                        {{ $t('Weight') }}: {{ calculatedWeight }} g
                    </div>
                    <div
                        v-if="
                            dimensions ||
                            collo.length ||
                            collo.width ||
                            collo.height
                        "
                        class="tw-text-xs tw-text-gray-500"
                    >
                        {{ dimensions?.length ?? collo.length }} x
                        {{ dimensions?.width ?? collo.width }} x
                        {{ dimensions?.height ?? collo.height }} cm
                    </div>
                </v-toolbar-title>
                <v-toolbar-items>
                    <v-btn
                        v-if="!selected"
                        color="primary"
                        :icon="mdiButtonPointer"
                        @click="emit('selected', collo.id)"
                    ></v-btn>
                    <template v-if="canEdit">
                        <v-btn
                            v-if="
                                page.props.customer.settings
                                    .true_package_dimensions
                            "
                            :icon="mdiRulerSquare"
                            color="alert"
                            @click="prompt(collo, scans, true)"
                        ></v-btn>
                        <v-btn
                            v-if="!editing"
                            color="secondary"
                            :icon="mdiNoteEdit"
                            @click="emit('edit:start', collo.id)"
                        ></v-btn>
                        <v-btn
                            v-else
                            color="success"
                            variant="elevated"
                            :icon="mdiNoteEdit"
                            @click="emit('edit:stop')"
                        ></v-btn>
                    </template>
                </v-toolbar-items>
            </v-toolbar>
            <v-toolbar v-if="colloErrors" height="36" color="danger">
                <template #prepend><v-icon :icon="mdiAlert"></v-icon></template>
                <span class="tw-ml-1 tw-text-xs">{{ colloErrors }}</span>
            </v-toolbar>
            <v-card-text>
                <h4>{{ $t('Articles') }}</h4>
                <div class="tw-mb-2">
                    <v-list
                        :density="isDraggable ? null : 'compact'"
                        :variant="isDraggable ? 'outlined' : null"
                        :base-color="isDraggable ? 'primary' : null"
                        :data-collo-id="collo.id"
                        :class="{ 'tw-p-0': !isDraggable }"
                    >
                        <v-list-item
                            v-for="(quantity, lineNumber) in lineScans.all()"
                            :key="lineNumber"
                            :data-line-number="lineNumber"
                            rounded="lg"
                            class="draggable"
                            :class="{
                                'tw-p-0': !isDraggable,
                                'tw-mb-2': isDraggable,
                            }"
                        >
                            <template v-if="isDraggable" #prepend
                                ><v-icon :icon="mdiDrag" size="large"></v-icon
                            ></template>
                            <v-list-item-title
                                class="tw-whitespace-break-spaces tw-text-xl"
                                :class="{
                                    'tw-text-sm': !isDraggable,
                                }"
                                >({{
                                    linesByLineNumber[lineNumber].item.number
                                }})
                                {{
                                    linesByLineNumber[
                                        lineNumber
                                    ].item.description.trim()
                                }}
                            </v-list-item-title>
                            <template #append
                                ><v-chip
                                    class="tw-ml-2 tw-text-xl tw-font-semibold"
                                    :class="{
                                        'tw-text-sm': !isDraggable,
                                        'tw-text-base': isDraggable,
                                    }"
                                    >{{ quantity }}</v-chip
                                >
                                <v-btn
                                    v-if="editing"
                                    class="tw-ml-4"
                                    color="surface"
                                    size="small"
                                    density="comfortable"
                                    :icon="mdiTrashCanOutline"
                                    @click="$emit('delete', Number(lineNumber))"
                                ></v-btn>
                            </template>
                        </v-list-item>
                    </v-list>
                </div>
                <div v-if="packagingScans.length > 0">
                    <h4>{{ $t('Packaging') }}</h4>
                    <div
                        v-for="scan in packagingScans"
                        :key="scan.id"
                        class="tw-flex tw-items-center tw-justify-between tw-text-xl"
                        :class="{ 'tw-py-2': editing, 'tw-text-base': editing }"
                    >
                        <span>
                            ({{ scan.data.item_number }})
                            {{
                                packagingByItemNumber[scan.data.item_number]
                                    .description
                            }}
                        </span>
                        <v-btn
                            v-if="editing"
                            class="tw-ml-4"
                            color="surface"
                            size="small"
                            density="comfortable"
                            :icon="mdiTrashCanOutline"
                            @click="
                                $emit('delete:packaging', scan.data.item_number)
                            "
                        ></v-btn>
                    </div>
                </div>
            </v-card-text>
        </v-card>
    </v-col>
</template>

<style scoped></style>
