<script setup>
import { computed } from 'vue';
import { collect } from 'collect.js';
import { join_names } from '@/lib/helpers.js';

const props = defineProps({
    rows: {
        type: Array,
        required: true,
    },
    scans: {
        type: Array,
        required: true,
    },
    itemsByItemNumber: {
        type: Object,
        required: true,
    },
    unitsByItemNumber: {
        type: Object,
        required: true,
    },
});

const rowKeys = computed(() => {
    return props.rows.map((row) => row.item_number + row.batch);
});

const orphanedScans = computed(() => {
    return props.scans.filter((scan) => {
        return !rowKeys.value.includes(scan.item_number + scan.data.batch);
    });
});

const orphanedRows = computed(() => {
    return collect(orphanedScans.value)
        .mapToGroups((scan) => [
            scan.item_number + scan.data.batch + scan.unit,
            scan,
        ])
        .map((scans) => {
            const item = props.itemsByItemNumber[scans[0].item_number];

            return {
                item_number: scans[0].item_number,
                description: join_names(
                    item.Description,
                    item['Description 2'],
                    ' - ',
                ),
                batch: scans[0].data.batch,
                unit: scans[0].unit,
                quantity: scans.length,
                // .map((scan) => {
                //     return props.unitsByItemNumber[scan.item_number][
                //         scan.unit
                //     ];
                // })
                // .reduce((a, b) => a + b, 0),
            };
        })
        .values()
        .all();
});

const scanCountFor = (itemNumber, batch, unit) => {
    return props.scans.filter((scan) => {
        return (
            scan.item_number === itemNumber &&
            scan.data.batch === batch &&
            scan.unit === unit
        );
    }).length;
    // .map((scan) => props.unitsByItemNumber[scan.item_number][scan.unit])
    // .reduce((a, b) => a + b, 0);
};
const missingRows = computed(() => {
    return props.rows.map((row) => {
        const item = props.itemsByItemNumber[row.item_number];

        const line = { ...row };

        line.quantity =
            scanCountFor(line.item_number, line.batch, line.unit) -
            line.quantity;
        line.description = join_names(
            item.Description,
            item['Description 2'],
            ' - ',
        );
        return line;
    });
});
</script>

<template>
    <v-card class="tw-overflow-auto">
        <v-card-title class="tw-uppercase">
            {{ $t('Difference to Shipping Notice') }}
        </v-card-title>
        <v-card-text>
            <div v-if="orphanedRows.length > 0">
                {{ $t('Scan Without Notice') }}
            </div>
            <div
                v-for="scan in orphanedRows"
                :key="scan.id"
                class="tw-flex tw-w-full tw-items-center tw-justify-between tw-p-2 even:tw-bg-gray-100"
            >
                <div>
                    {{ scan.item_number }}
                    {{ scan.description }}
                    -
                    <span v-if="scan.batch">- {{ scan.batch }}</span>
                    -
                    {{ scan.unit }}
                </div>
                <div>{{ scan.quantity }}</div>
            </div>
            <div class="tw-mt-2">{{ $t('Missing') }}</div>
            <template v-for="row in missingRows" :key="row.id">
                <div
                    v-if="row.quantity < 0"
                    class="tw-flex tw-w-full tw-items-center tw-justify-between tw-p-2 even:tw-bg-gray-100"
                >
                    <div>
                        {{ row.item_number }}
                        {{ row.description }}
                        <span v-if="row.batch">- {{ row.batch }}</span>
                        - {{ row.unit }}
                    </div>
                    <div>{{ row.quantity }}</div>
                </div>
            </template>
        </v-card-text>
    </v-card>
</template>

<style scoped></style>
