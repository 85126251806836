<script setup>
import { useAxios } from '@vueuse/integrations/useAxios';
import { computed, ref, watch } from 'vue';
import TextField from '@/Components/TextField.vue';
import colors from 'tailwindcss/colors';
import DialogModal from '@/Components/DialogModal.vue';
import { useForm } from '@inertiajs/vue3';
import SaveButton from '@/Components/SaveButton.vue';

const props = defineProps({
    ticket: {
        type: Object,
        required: true,
    },
    edit: {
        type: Boolean,
        default: false,
    },
});

const show = defineModel({
    type: Boolean,
    default: false,
});

const claim = ref(null);
const { data, isFinished } = useAxios(
    route('api.ticket.claim.showCreate', { ticket: props.ticket.id }),
);

watch(data, (value) => {
    if (value) {
        claim.value = useForm({
            amount_damage:
                value.amount_damage === null
                    ? null
                    : String(value.amount_damage),
            amount_forwarded:
                value.amount_forwarded === null
                    ? null
                    : String(value.amount_forwarded),
            amount_received:
                value.amount_received === null
                    ? null
                    : String(value.amount_received),
            external_claims_number: value.external_claims_number,
            forwarded_at: value.forwarded_at,
            received_at: value.received_at,
            submitted_at: value.submitted_at,
            submitted_to: value.submitted_to,
            status: value.status,
        });
    }
});
const loading = ref(false);
const errors = ref({});
const submitUpdateClaimForm = async () => {
    loading.value = true;
    try {
        const response = await axios.put(
            route('api.ticket.claim.update', { ticket: props.ticket.id }),
            claim.value.data(),
        );

        claim.value.defaults(response.data.data);
        claim.value.reset();
    } catch (e) {
        if (e.response.status === 422) {
            errors.value = e.response.data.errors;
        } else {
            console.error(e);
        }
    }
    loading.value = false;
};
const reset = () => {
    claim.value.reset();
    errors.value = {};
};
const close = () => {
    errors.value = {};
    show.value = false;
};

const variant = computed(() => (props.edit ? 'filled' : 'plain'));
const readonly = computed(() => !props.edit);
const bgColor = computed(() => (props.edit ? colors.gray[100] : 'surface'));
</script>

<template>
    <DialogModal :show="show" @close="close">
        <template #title>
            <span class="tw-font-light tw-uppercase">Schadenseinreichung</span>
        </template>
        <template #content>
            <div
                v-if="!isFinished"
                class="tw-flex tw-h-64 tw-items-center tw-justify-center"
            >
                <v-progress-circular
                    indeterminate
                    size="large"
                    color="primary"
                ></v-progress-circular>
            </div>

            <div v-else class="tw-min-h-96">
                <v-table
                    v-if="claim"
                    :class="{
                        'tw-max-w-[800px]': edit,
                        'tw-max-w-[400px]': !edit,
                    }"
                >
                    <tr>
                        <td class="tw-pr-1 tw-align-top">
                            <TextField
                                v-model="claim.submitted_at"
                                :variant
                                :readonly
                                :bg-color
                                :class="{ 'v-field--focused': !edit }"
                                type="datetime-local"
                                hide-details="auto"
                                :error-messages="errors.submitted_at"
                            >
                                <template #label>
                                    <span :class="{ 'tw-text-primary': !edit }"
                                        >Wann</span
                                    >
                                </template>
                            </TextField>
                        </td>
                        <td class="tw-align-top">
                            <TextField
                                v-model="claim.submitted_to"
                                :variant
                                :readonly
                                :bg-color
                                :class="{ 'v-field--focused': !edit }"
                                hide-details="auto"
                                :error-messages="errors.submitted_to"
                            >
                                <template #label>
                                    <span :class="{ 'tw-text-primary': !edit }"
                                        >Bei wem</span
                                    >
                                </template>
                            </TextField>
                        </td>
                    </tr>
                    <tr>
                        <td class="tw-pr-1 tw-align-top">
                            <TextField
                                v-model="claim.external_claims_number"
                                :variant
                                :readonly
                                :bg-color
                                :class="{ 'v-field--focused': !edit }"
                                hide-details="auto"
                                :error-messages="errors.external_claims_number"
                            >
                                <template #label>
                                    <span :class="{ 'tw-text-primary': !edit }"
                                        >Ext. Schadensnummer</span
                                    >
                                </template>
                            </TextField>
                        </td>
                        <td class="tw-align-top">
                            <TextField
                                v-model="claim.amount_damage"
                                :variant
                                :readonly
                                :bg-color
                                :class="{ 'v-field--focused': !edit }"
                                hide-details="auto"
                                type="number"
                                step="0.01"
                                :error-messages="errors.amount_damage"
                            >
                                <template #label>
                                    <span :class="{ 'tw-text-primary': !edit }"
                                        >Wert S.Rechnung</span
                                    >
                                </template>
                            </TextField>
                        </td>
                    </tr>
                    <tr>
                        <td class="tw-pr-1 tw-align-top">
                            <TextField
                                v-model="claim.amount_received"
                                :variant
                                :readonly
                                :bg-color
                                :class="{ 'v-field--focused': !edit }"
                                hide-details="auto"
                                type="number"
                                step="0.01"
                                :error-messages="errors.amount_received"
                            >
                                <template #label>
                                    <span :class="{ 'tw-text-primary': !edit }"
                                        >Wert erhalten</span
                                    >
                                </template>
                            </TextField>
                        </td>
                        <td class="tw-align-top">
                            <TextField
                                v-model="claim.received_at"
                                :variant
                                :readonly
                                :bg-color
                                :class="{ 'v-field--focused': !edit }"
                                hide-details="auto"
                                type="datetime-local"
                                :error-messages="errors.received_at"
                            >
                                <template #label>
                                    <span :class="{ 'tw-text-primary': !edit }"
                                        >Wann Wert erhalten</span
                                    >
                                </template>
                            </TextField>
                        </td>
                    </tr>
                    <tr>
                        <td class="tw-pr-1 tw-align-top">
                            <TextField
                                v-model="claim.amount_forwarded"
                                :variant
                                :readonly
                                :bg-color
                                :class="{ 'v-field--focused': !edit }"
                                hide-details="auto"
                                type="number"
                                step="0.01"
                                :error-messages="errors.amount_forwarded"
                            >
                                <template #label>
                                    <span :class="{ 'tw-text-primary': !edit }"
                                        >Wert weitergegeben</span
                                    >
                                </template>
                            </TextField>
                        </td>
                        <td class="tw-align-top">
                            <TextField
                                v-model="claim.forwarded_at"
                                :variant
                                :readonly
                                :bg-color
                                :class="{ 'v-field--focused': !edit }"
                                hide-details="auto"
                                type="datetime-local"
                                :error-messages="errors.forwarded_at"
                            >
                                <template #label>
                                    <span :class="{ 'tw-text-primary': !edit }"
                                        >Wert weitergegeben wann</span
                                    >
                                </template>
                            </TextField>
                        </td>
                    </tr>
                </v-table>
            </div>
        </template>
        <template v-if="isFinished" #footer>
            <v-spacer></v-spacer>
            <v-btn
                :disabled="!claim.isDirty || loading"
                variant="elevated"
                color="danger"
                @click="reset"
                >{{ $t('Reset') }}</v-btn
            >
            <SaveButton
                type="button"
                :loading="loading"
                :disabled="!claim.isDirty"
                @click="submitUpdateClaimForm"
            ></SaveButton>
        </template>
    </DialogModal>
</template>

<style scoped></style>
