<script setup></script>

<template>
    <v-main class="tw-flex tw-min-h-full tw-flex-col tw-bg-gray-100">
        <div class="tw-grow">
            <slot />
        </div>
    </v-main>
</template>

<style scoped></style>
