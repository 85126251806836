<script setup>
import { mdiEmail, mdiPlus } from '@mdi/js';
import ReservationFromLocationTable from '@/Pages/WarehouseManagement/Reservations/Partials/ReservationFromLocationTable.vue';
import { useForm } from '@inertiajs/vue3';
import { computed, ref } from 'vue';
import { collect } from 'collect.js';
import ConfirmationModal from '@/Components/ConfirmationModal.vue';
import SaveButton from '@/Components/SaveButton.vue';

const props = defineProps({
    locations: {
        type: Array,
        required: true,
    },
    locationsById: {
        type: Object,
        required: true,
    },
    forLocationId: {
        type: String,
        required: true,
    },
    fromLocationId: {
        type: String,
        required: true,
    },
    itemReservations: {
        type: Object,
        required: true,
    },
    itemsById: {
        type: Object,
        required: true,
    },
});

const isMaillogOrder = computed(() => props.fromLocationId === 'maillog');

const itemQuantities = computed(() => {
    return collect(props.itemReservations)
        .groupBy('warehouse_item_id')
        .map((reservations) => reservations.sum('quantity'));
});

const reservationIds = computed(() =>
    collect(props.itemReservations).pluck('id'),
);

const form = useForm({
    warehouse_location_id: !isMaillogOrder.value
        ? Number(props.fromLocationId)
        : 'maillog',
    for_warehouse_location_id: Number(props.forLocationId),
    warehouse_reservation_ids: reservationIds.value,
    lines: itemQuantities.value.map((quantity, itemId) => ({
        item_id: itemId,
        item_number: props.itemsById[itemId].item_number,
        quantity: quantity,
    })),
});

const submitOrderForm = () => {
    form.post(route('warehouse.reservation.to-order'));
};

const openConfirmationModal = () => {
    showConfirmationModal.value = true;
};

const showConfirmationModal = ref(false);
</script>

<template>
    <v-card>
        <ConfirmationModal
            :show="showConfirmationModal"
            @close="showConfirmationModal = false"
        >
            <template #title>
                {{ $t('warehouse.reservations.order-confirmation.title') }}
            </template>
            <template #content>
                {{ $t('warehouse.reservations.order-confirmation.content') }}
            </template>
            <template #footer>
                <SaveButton
                    :loading="form.processing"
                    class="tw-mr-2"
                    type="button"
                    @click="submitOrderForm"
                ></SaveButton>
                <v-btn
                    color="danger"
                    :loading="form.processing"
                    @click="showConfirmationModal = false"
                    >{{ $t('Cancel') }}</v-btn
                >
            </template>
        </ConfirmationModal>
        <v-card-title>
            {{
                $t(':location wants from :from_location', {
                    location: locationsById[forLocationId].description,
                    from_location: locationsById[fromLocationId].description,
                })
            }}
        </v-card-title>
        <v-card-text>
            <ReservationFromLocationTable
                :locations-by-id="locationsById"
                :rows="itemQuantities"
                :items-by-id="itemsById"
            >
            </ReservationFromLocationTable>
        </v-card-text>
        <v-card-actions class="tw-justify-between">
            <v-btn
                class="tw-mr-4"
                :prepend-icon="isMaillogOrder ? mdiPlus : mdiEmail"
                @click="openConfirmationModal"
                >{{ isMaillogOrder ? $t('Create Order') : $t('E-Mail') }}</v-btn
            >
        </v-card-actions>
    </v-card>
</template>

<style scoped></style>
