<script setup>
import { Head } from '@inertiajs/vue3';
import ActionSection from '@/Components/ActionSection.vue';
import { collect } from 'collect.js';
import { computed } from 'vue';
import ReservationFromLocationCard from '@/Pages/WarehouseManagement/Reservations/Partials/ReservationFromLocationCard.vue';

const props = defineProps({
    title: {
        type: String,
        required: true,
    },
    reservations: {
        type: Array,
        required: true,
    },
    locations: {
        type: Array,
        required: true,
    },
    items: {
        type: Array,
        required: true,
    },
});

const locationsById = computed(() => {
    return collect(props.locations)
        .keyBy('id')
        .put('maillog', { id: 'maillog', description: 'Maillog' })
        .all();
});
const itemsById = computed(() => {
    return collect(props.items).keyBy('id').all();
});

/*const summaryByForLocation = computed(() => {
    return collect(props.reservations)
        .filter((reservation) => reservation.quantity > 0)
        .map((reservation) => ({
            ...reservation,
            warehouse_location_id:
                reservation.warehouse_location_id ?? 'maillog',
        }))
        .groupBy('for_warehouse_location_id')
        .mapWithKeys((reservations, key) => [
            key,
            reservations
                .groupBy('warehouse_item_id')
                .map((reservations) =>
                    reservations
                        .groupBy('warehouse_location_id')
                        .map((reservations) => reservations.sum('quantity')),
                ),
        ])
        .all();
});*/

const summaryByForLocation = computed(() => {
    return collect(props.reservations)
        .filter((reservation) => reservation.quantity > 0)
        .map((reservation) => ({
            ...reservation,
            warehouse_location_id:
                reservation.warehouse_location_id ?? 'maillog',
        }))
        .groupBy(
            (reservation) =>
                reservation.warehouse_location_id +
                '_' +
                reservation.for_warehouse_location_id,
        )
        .sortKeys()
        .all();
});
</script>

<template>
    <v-container>
        <Head :title="props.title"></Head>
        <ActionSection>
            <template #title>
                {{ props.title }}
            </template>
            <template #description>
                {{ $t('warehouse.reservations.index.overview-description') }}
            </template>
            <template #content>
                <v-row>
                    <v-col
                        v-for="(
                            itemReservations, locationIds
                        ) in summaryByForLocation"
                        :key="locationIds"
                        cols="12"
                    >
                        <ReservationFromLocationCard
                            :from-location-id="locationIds.split('_')[0]"
                            :for-location-id="locationIds.split('_')[1]"
                            :locations-by-id="locationsById"
                            :locations="locations"
                            :item-reservations="itemReservations"
                            :items-by-id="itemsById"
                        />
                    </v-col>
                </v-row>
            </template>
        </ActionSection>
    </v-container>
</template>

<style scoped></style>
