<script setup>
import { AgGridVue } from 'ag-grid-vue3';
import defaultColDef from '@/lib/ag-grid/default-column-definitions';
import MaterialEditableCellRenderer from '@/Components/CellRenderers/MaterialEditableCellRenderer.vue';
import { ref, watch } from 'vue';
import { trans as $t } from 'laravel-vue-i18n';
import { watchDebounced } from '@vueuse/core';

const props = defineProps({
    rowData: {
        type: Array,
        required: true,
    },
    search: {
        type: String,
        default: '',
    },
});

const gridApi = ref(null);
const onGridReady = (params) => {
    gridApi.value = params.api;
    if (props.rowData.length === 0) {
        gridApi.value.showLoadingOverlay();
    }
};

const columnDefs = [
    {
        headerName: 'Maillog SKU',
        field: 'item_number',
    },
    {
        headerName: 'SKU',
        field: 'sku',
    },
    {
        headerName: $t('Description'),
        field: 'description',
    },
    {
        headerName: $t('Stock'),
        field: 'quantity',
        valueGetter: (params) => {
            return (
                params.data.item.total_stock - params.data.item.total_reserved
            );
        },
    },
    {
        headerName: $t('Order Quantity'),
        field: 'line_quantity',
        editable: true,
        cellRenderer: MaterialEditableCellRenderer,
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
            min: 0,
            precision: 0,
            step: 1,
            showStepperButtons: true,
        },
        type: 'rightAligned',
    },
    {
        headerName: $t('Unit'),
        field: 'unit',
    },
];

watch(
    () => props.rowData,
    (rows) => {
        if (rows.length > 0 && gridApi.value) {
            gridApi.value.hideOverlay();
        }
    },
);

watchDebounced(
    () => props.search,
    () => {
        gridApi.value.onFilterChanged();
    },
    {
        debounce: 300,
    },
);

const doesExternalFilterPass = (node) => {
    const strings = [
        node.data.item_number,
        node.data.sku,
        node.data.description,
    ];

    const terms = props.search
        ?.split(' ')
        ?.map((term) => term.toLowerCase().trim());

    return terms.every((term) =>
        strings.some((string) => string.toLowerCase().includes(term)),
    );
};

defineExpose({
    gridApi,
});
</script>

<template>
    <ag-grid-vue
        class="ag-theme-material tw-h-full"
        :default-col-def="defaultColDef"
        :column-defs="columnDefs"
        :row-data="rowData"
        :single-click-edit="true"
        :is-external-filter-present="
            () => props.search !== null && props.search.trim().length > 0
        "
        :does-external-filter-pass="doesExternalFilterPass"
        @grid-ready="onGridReady"
    >
    </ag-grid-vue>
</template>

<style scoped></style>
