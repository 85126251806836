<script setup>
import { useVModel } from '@vueuse/core';
import ItemSelectFormSection from '@/Components/ItemSelectFormSection.vue';

const props = defineProps({
    modelValue: { type: Array, required: true },
    customerNumber: { type: String, required: true },
});
const emit = defineEmits(['update:modelValue']);
const lines = useVModel(props, 'modelValue', emit);
</script>

<template>
    <ItemSelectFormSection
        v-model="lines"
        :customer-number="props.customerNumber"
        :with-batch="false"
    >
        <template #title> {{ $t('Articles') }}</template>
        <template #description>
            {{ $t('Enter the order items for the order.') }}
        </template>
    </ItemSelectFormSection>
</template>

<style scoped></style>
