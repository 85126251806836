<script setup>
import { nextTick, ref } from 'vue';
import { watchArray } from '@vueuse/core';

import AsyncCombobox from '@/Components/AsyncCombobox.vue';

const props = defineProps({
    customerNumber: {
        type: String,
        default: null,
    },
    modelValue: {
        type: [Array],
        default: () => [],
    },
    selected: {
        type: Array,
        default: () => [],
    },
    maxSelection: {
        type: Number,
        default: null,
    },
});

const emit = defineEmits(['update:modelValue']);

const selectedItems = ref(props.selected);
watchArray(selectedItems, (value, oldValue) => {
    if (props.maxSelection !== null && value.length > props.maxSelection) {
        selectedItems.value = oldValue;
        return;
    }

    emit(
        'update:modelValue',
        value.map((item) => item.number),
    );
});
const itemTitle = (item) => {
    return item.number + (item.description ? ' - ' + item.description : '');
};

const combobox = ref(null);
const reset = function () {
    selectedItems.value = props.selected;
    nextTick(combobox.value.reset);
};

defineExpose({
    reset,
});
</script>

<template>
    <AsyncCombobox
        ref="combobox"
        v-model="selectedItems"
        :label="$t('Articles')"
        filter-column="search"
        :filter="itemTitle"
        item-value="number"
        :item-title="itemTitle"
        :params="{
            filter: { customer_number: customerNumber },
        }"
        :search-url="route('api.maillog.item.index')"
    ></AsyncCombobox>
</template>

<style scoped></style>
