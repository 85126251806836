<script setup>
import { computed, nextTick, ref } from 'vue';
import TextField from '@/Components/TextField.vue';
import { micValidation } from '@/lib/utilities/pack-serial-number-validations';
import SaveButton from '@/Components/SaveButton.vue';

const props = defineProps({
    line: {
        type: Object,
        required: true,
    },
    remainingScanQuantity: {
        type: Number,
        required: true,
    },
});
const emit = defineEmits(['scan']);

const inputRefs = [];
const assignRefs = (el, index) => {
    if (!el) {
        delete inputRefs[index];
        return;
    }
    inputRefs[index] = el;
};
const serialScanTemplate = {
    serial_number: null,
    ean: null,
    upc: null,
    date: null,
    mic1: null,
    mic2: null,
};

const currentScans = ref([{ ...serialScanTemplate }]);
const add = () => {
    currentScans.value.push({ ...serialScanTemplate });
};
// eslint-disable-next-line no-unused-vars
const remove = (index) => {
    currentScans.value.splice(index, 1);
};
const nextLine = async (index) => {
    add();
    await nextTick();
    inputRefs[index + 1].focus();
};
const validateScan = (index) => {
    if (micValidation.validate(currentScans.value[index].mic1)) {
        if (currentScans.value.length === props.remainingScanQuantity) {
            saveScans();
            return;
        }
        nextLine(index);
    } else {
        currentScans.value[index].mic1 = null;
    }
};

const nonEmptyScans = computed(() => {
    return currentScans.value.filter((scan) => scan.mic1);
});
const saveScans = () => {
    if (nonEmptyScans.value.length === 0) {
        return;
    }

    emit('scan', nonEmptyScans.value);
};

const reset = () => {
    currentScans.value = [{ ...serialScanTemplate }];
};

const focusFirstInput = () => {
    inputRefs[0].focus();
};

defineExpose({
    reset,
    focusFirstInput,
});
</script>

<template>
    <v-window-item value="bulk">
        <TextField
            v-for="(scan, index) in currentScans"
            :key="index"
            :ref="(el) => assignRefs(el, index)"
            v-model="scan.mic1"
            hide-details
            density="compact"
            :label="'MIC ' + (index + 1)"
            :disabled="index !== currentScans.length - 1"
            @keydown.enter="validateScan(index)"
        ></TextField>
        <div class="tw-w-full tw-text-right">
            <SaveButton
                :disabled="nonEmptyScans.length === 0"
                type="button"
                @click="saveScans"
                >{{ $t('Save') }}</SaveButton
            >
        </div>
    </v-window-item>
</template>

<style scoped></style>
