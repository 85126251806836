<script setup>
import ItemCombobox from '@/Components/ItemCombobox.vue';
import { useVModel } from '@vueuse/core';
import CheckboxField from '@/Components/CheckboxField.vue';
import { ref, watch } from 'vue';

const props = defineProps({
    customerNumber: {
        type: String,
        required: true,
    },
    modelValue: {
        type: Array,
        default: () => [],
    },
});
const emit = defineEmits(['update:modelValue']);

const hasOaids = ref(props.modelValue.length > 0);
const selectedItems = useVModel(props, 'modelValue', emit);

watch(hasOaids, (value) => {
    if (!value) {
        selectedItems.value = [];
    }
});
</script>

<template>
    <CheckboxField
        v-model="hasOaids"
        :label="$t('Has OAID Articles')"
    ></CheckboxField>
    <ItemCombobox
        v-if="hasOaids"
        v-model="selectedItems"
        :selected="selectedItems.map((item) => ({ number: item }))"
        :customer-number="customerNumber"
    ></ItemCombobox>
</template>

<style scoped></style>
