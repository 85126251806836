<script>
import { computed, ref, watch } from 'vue';

export default {
    props: {
        params: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        const menu = ref(false);
        const value = ref(props.params.value);
        const selectField = ref(null);

        const getValue = () => {
            return value.value;
        };
        const focusIn = () => {
            selectField.value.focus();
        };

        const isCancelBeforeStart = () => {
            return false;
        };

        const isCancelAfterEnd = () => {
            // our editor will reject any value greater than 1000
            return value.value > 1000;
        };

        const items = computed(() => {
            if (typeof props.params.items === 'function')
                return props.params.items(props.params);
            return props.params.items;
        });

        const onUpdate = (value) => {
            if (typeof props.params.onValueUpdated === 'function') {
                props.params.onValueUpdated(value);
            }
        };

        watch(items, () => {
            if (
                !items.value ||
                items.value.find((item) => item.code === value.value)
            )
                return;
            value.value = null;
        });

        const menuProps = computed(() => {
            return props.params.menuProps ?? {};
        });

        return {
            menu,
            value,
            selectField,
            getValue,
            focusIn,
            isCancelBeforeStart,
            isCancelAfterEnd,
            items,
            onUpdate,
            menuProps,
        };
    },
    mounted() {
        if (this.$props.params.cellStartedEdit) {
            this.$nextTick(() => {
                this.selectField.focus();
            });
        }
    },
};
</script>

<template>
    <v-autocomplete
        ref="selectField"
        v-model="value"
        v-model:menu="menu"
        :items="items"
        :item-title="params.itemTitle"
        :item-value="params.itemValue"
        :chips="params.chips ?? false"
        :clearable="params.clearable ?? false"
        hide-details
        color="primary"
        bg-color="transparent"
        variant="underlined"
        density="compact"
        single-line
        :menu-props="menuProps"
        @update:model-value="onUpdate"
    ></v-autocomplete>
</template>

<style scoped></style>
