<script setup>
import { Head, useForm } from '@inertiajs/vue3';
import AuthenticationCard from '@/Components/AuthenticationCard.vue';
import TextField from '@/Components/TextField.vue';

defineProps({
    status: { type: String, default: null },
});

const form = useForm({
    email: '',
});

const submit = () => {
    form.post(route('password.email'));
};
</script>

<script>
export default { layout: null };
</script>

<template>
    <Head :title="$t('Forgot Password')" />

    <AuthenticationCard>
        <div
            class="tw-rounded-lg tw-border tw-mb-4 tw-border-solid tw-border-gray-200 tw-text-gray-500 tw-font-sans tw-italic tw-text-md tw-text-light tw-p-2"
        >
            {{
                $t(
                    'Forgot your password? No problem. Just let us know your email address and we will email you a password reset link that will allow you to choose a new one.'
                )
            }}
        </div>

        <div
            v-if="status"
            class="tw-mb-4 tw-font-medium tw-text-sm text-success"
        >
            {{ status }}
        </div>

        <v-form @submit.prevent="submit">
            <TextField
                v-model="form.email"
                :label="$t('Email')"
                type="email"
                required
                autofocus
                :error-messages="form.errors.email"
            ></TextField>

            <div class="tw-flex tw-items-center tw-justify-end tw-mt-4">
                <v-btn color="primary" :loading="form.processing" type="submit">
                    {{ $t('Email Password Reset Link') }}
                </v-btn>
            </div>
        </v-form>
    </AuthenticationCard>
</template>
