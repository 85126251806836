<script setup>
import { computed, watch } from 'vue';
import { AgGridVue } from 'ag-grid-vue3';
import { router, usePage } from '@inertiajs/vue3';

import columnTypes from '@/lib/ag-grid/column-types';
import defaultColDef from '@/lib/ag-grid/default-column-definitions';
import createQueryRequestParams from '@/lib/ag-grid/create-query-request-params';
import OrderTableActionButtons from '@/Pages/Order/Partials/OrderTableActionButtons.vue';
import { trans as $t } from 'laravel-vue-i18n';
import {
    columnStateEvents,
    getColumnState,
    gridApi,
    onGridReady,
    stateApplied,
} from '@/lib/ag-grid/save-column-state';
import TableLoading from '@/Components/TableLoading.vue';
import OrderLinkCellRenderer from '@/Pages/Order/Partials/OrderLinkCellRenderer.vue';
import { mdiInformation } from '@mdi/js';
import { collect } from 'collect.js';

const emit = defineEmits(['onRequest', 'onShow']);
const page = usePage();
const props = defineProps({
    cancelledOrders: {
        type: Array,
        default: () => [],
    },
});

const dataSource = {
    getRows({ request, success, fail }) {
        const params = createQueryRequestParams(request);

        axios
            .get(route('api.maillog.order.index'), { params })
            .then((response) => {
                success({
                    rowData: response.data.data,
                    rowCount: response.data.meta.total,
                });
                emit('onRequest', { params, total: response.data.meta.total });
            })
            .catch(() => fail());
    },
};

const onOrderEdit = (orderNumber) => {
    router.visit(route('order.edit', { order: orderNumber }));
};
const onOrderShow = (orderNumber) => {
    emit('onShow', orderNumber);
};

getColumnState('order');

const statuses = computed(() => {
    return collect(page.props.fulfillment_statuses)
        .mapWithKeys((status) => [status.status, status])
        .all();
});
const context = {
    statuses,
};

const columnDefs = computed(() => [
    {
        headerName: $t('Customer Number'),
        field: 'customer_number',
        type: 'textColumnFilter',
        hide: true,
        headerTooltip: $t('Customer Number'),
    },
    {
        headerName: $t('Reference'),
        field: 'reference',
        type: 'textColumnFilter',
        headerTooltip: $t('Reference'),
        minWidth: 100,
    },
    {
        headerName: $t('Order Number'),
        field: 'order_number',
        type: 'textColumnFilter',
        cellRenderer: OrderLinkCellRenderer,
        headerTooltip: $t('Order Number'),
    },
    {
        headerName: $t('Delivery Note Number'),
        field: 'delivery_note_number',
        type: 'textColumnFilter',
        headerTooltip: $t('Deliver Note Number'),
    },
    {
        headerName: $t('Order Date'),
        field: 'document_date',
        type: 'dateColumnFilter',
        valueFormatter: (params) => {
            return params.value
                ? moment(params.value).format('DD.MM.YYYY')
                : '';
        },
        sort: 'desc',
        minWidth: 130,
        headerTooltip: $t('Order Date'),
    },
    {
        headerName: $t('Name'),
        field: 'name',
        valueGetter: (params) => {
            return (
                params.data.name +
                (params.data.name2 ? ' ' + params.data.name2 : '')
            );
        },
        type: 'textColumnFilter',
        headerTooltip: $t('Name'),
    },
    {
        headerName: $t('Address'),
        field: 'address',
        valueGetter: (params) => {
            return (
                params.data.address +
                (params.data.address2 ? ' ' + params.data.address2 : '')
            );
        },
        type: 'textColumnFilter',
        headerTooltip: $t('Address'),
    },
    {
        headerName: $t('Postal Code'),
        field: 'postal_code',
        type: 'textColumnFilter',
        headerTooltip: $t('Postal Code'),
    },
    {
        headerName: $t('City'),
        field: 'city',
        type: 'textColumnFilter',
        headerTooltip: $t('City'),
    },
    {
        headerName: $t('Country'),
        field: 'country',
        type: 'textColumnFilter',
        maxWidth: 80,
        headerTooltip: $t('Country'),
    },
    // {
    //     headerName: $t('Status'),
    //     field: 'delivery_status',
    //     sortable: true,
    //     type: 'textColumnFilter',
    //     headerTooltip: $t('Status'),
    // },
    {
        headerName: $t('Status'),
        field: 'fulfillment_status_code',
        filter: 'agSetColumnFilter',
        floatingFilter: true,
        filterParams: {
            values: page.props.fulfillment_statuses.map(
                (status) => status.status,
            ),
            comparator: (a, b) => {
                const a_sequence = statuses.value[a]?.sequence ?? 0;
                const b_sequence = statuses.value[b]?.sequence ?? 0;

                if (a_sequence === b_sequence) return 0;
                return a_sequence > b_sequence ? 1 : -1;
            },
            valueFormatter: (params) =>
                params.value +
                ' - ' +
                statuses.value[params.value]?.description,
            showTooltips: true,
            defaultToNothingSelected: true,
        },
        headerTooltip: $t('Fulfillment Status'),
        hide: false,
        headerComponentParams: {
            template: `<div class='ag-cell-label-container' role='presentation'>
              <span ref='eMenu' class='ag-header-icon ag-header-cell-menu-button'></span>
              <span ref='eFilterButton' class='ag-header-icon ag-header-cell-filter-button'></span>
              <div ref='eLabel' class='ag-header-cell-label' role='presentation'>
                <span ref='eText' class='ag-header-cell-text' role='columnheader'></span>
                <a onclick='event.stopPropagation()' href='https://www.maillog-fulfillment.at/wp-content/uploads/2024/04/FulfillmentStatus2.pdf'><svg class='tw-ml-2 tw-mt-2' xmlns='http://www.w3.org/2000/svg' viewbox='0 0 24 24' style='width: 20px; height: 20px; fill: rgb(var(--v-theme-secondary))' '>
                <path d='${mdiInformation}'></path>
                 </svg>
                 </a>
                <span ref='eFilter' class='ag-header-icon ag-filter-icon'></span>
                <span ref='eSortOrder' class='ag-header-icon ag-sort-order'></span>
                <span ref='eSortAsc' class='ag-header-icon ag-sort-ascending-icon'></span>
                <span ref='eSortDesc' class='ag-header-icon ag-sort-descending-icon'></span>
                <span ref='eSortNone' class='ag-header-icon ag-sort-none-icon'></span>
              </div>
            </div>`,
        },
    },
    {
        headerName: 'Status ' + $t('Description'),
        valueGetter(params) {
            return statuses.value[params.data.fulfillment_status_code]
                ?.description;
        },
        sortable: false,
        hide: false,
    },
    {
        colId: 'actions',
        cellRenderer: OrderTableActionButtons,
        cellClass: 'ag-cell-range-no-border',
        sortable: false,
        cellRendererParams: {
            onEdit: onOrderEdit,
            onShow: onOrderShow,
        },
        minWidth: 55,
        headerTooltip: $t('Actions'),
        flex: 0,
        resizable: false,
        width: 60,
    },
]);

watch(props.cancelledOrders, (value) => {
    if (gridApi.value) {
        value.forEach((orderNumber) => {
            const node = gridApi.value.getRowNode(orderNumber);
            if (node !== undefined) {
                const updated = node.data;
                updated.is_cancelled = true;
                node.updateData(updated);
                gridApi.value.refreshCells({
                    force: true,
                    rowNodes: [node],
                });
            }
        });
    }
});

defineExpose({
    gridApi,
});
</script>

<template>
    <v-card class="tw-h-full">
        <TableLoading :loading="!stateApplied">
            <ag-grid-vue
                class="ag-theme-material tw-h-full"
                :column-types="columnTypes"
                :default-col-def="defaultColDef"
                :column-defs="columnDefs"
                row-model-type="serverSide"
                :block-load-debounce-millis="200"
                :server-side-datasource="dataSource"
                :enable-range-selection="true"
                :get-row-id="
                    (params) =>
                        params.data.order_number +
                        '_' +
                        params.data.delivery_note_number
                "
                :context="context"
                :tooltip-show-delay="100"
                @grid-ready="onGridReady"
                v-on="columnStateEvents"
            />
        </TableLoading>
    </v-card>
</template>
<style></style>
