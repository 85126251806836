<script setup>
import { useVModel, watchDebounced } from '@vueuse/core';
import { useAxios } from '@vueuse/integrations/useAxios';
import { computed, ref } from 'vue';
import colors from 'tailwindcss/colors';

const props = defineProps({
    modelValue: {
        type: Array,
        default: () => [],
    },
    searchUrl: {
        type: String,
        default: '',
    },
    filterColumn: {
        type: String,
        default: '',
    },
    customFilter: {
        type: [Function, null],
        default: null,
    },
    itemValue: {
        type: String,
        default: 'value',
    },
    chipTitle: {
        type: String,
        default: null,
    },
    debounce: {
        type: Number,
        default: 300,
    },
    color: {
        type: String,
        default: 'primary',
    },
    bgColor: {
        type: String,
        default: colors.gray['100'],
    },
    params: {
        type: Object,
        default: () => ({}),
    },
});

const emit = defineEmits(['update:modelValue', 'update:search']);

const selected = useVModel(props, 'modelValue', emit);
const search = ref('');

const request = useAxios(
    props.searchUrl,
    { method: 'get' },
    { immediate: false },
);

watchDebounced(
    search,
    (value) => {
        if (value.length >= 3) {
            const params = _.merge(
                { filter: { [props.filterColumn]: value } },
                props.params,
            );
            request.execute({
                params,
            });
        }
    },
    { debounce: props.debounce },
);

const items = computed(() => {
    if (!request.data.value) {
        return props.modelValue;
    }

    return selected.value.concat(
        request.data.value.filter(
            (item) =>
                !selected.value
                    .map((selected) => selected[props.itemValue])
                    .includes(item[props.itemValue]),
        ),
    );
});

const reset = function () {
    items.value = selected.value;
};

defineExpose({
    reset,
});
</script>

<template>
    <v-autocomplete
        v-model="selected"
        v-model:search="search"
        no-filter
        :loading="request.isLoading.value ? 'primary' : false"
        :items="items"
        return-object
        chips
        :custom-filter="customFilter"
        :bg-color="bgColor"
        :color="color"
        multiple
    >
        <template #chip="chip">
            <v-chip
                v-bind="chip.props"
                :text="
                    props.chipTitle
                        ? chip.item.raw[chipTitle]
                        : chip.item.raw[props.itemValue]
                "
            ></v-chip>
        </template>
    </v-autocomplete>
</template>

<style scoped></style>
