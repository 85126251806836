<script setup>
import { useVModel } from '@vueuse/core';

const props = defineProps({
    modelValue: {
        type: [Boolean, Array],
        required: true,
    },
});

const emit = defineEmits(['update:modelValue']);

const value = useVModel(props, 'modelValue', emit);
</script>

<template>
    <v-checkbox v-model="value" color="primary" hide-details>
        <slot></slot>
    </v-checkbox>
</template>

<style scoped></style>
