<script setup>
import ItemSelect from '@/Components/ItemSelect.vue';
import TextField from '@/Components/TextField.vue';
import TextareaField from '@/Pages/Order/TextareaField.vue';
import FileInputField from '@/Components/FileInputField.vue';
import { computed } from 'vue';

const props = defineProps({
    subtype: {
        type: Object,
        default: null,
    },
    index: {
        type: Number,
        default: null,
    },
    createTicketForm: {
        type: Object,
        required: true,
    },
});

const form = defineModel({
    type: Object,
    required: true,
});

const errorKeyPrefix = computed(() => {
    if (props.index === null) {
        return '';
    }
    return `content_lines.${props.index}.`;
});
</script>

<template>
    <v-card v-if="createTicketForm" class="tw-p-4">
        <v-row v-if="subtype">
            <v-col cols="12">
                <span
                    v-html="
                        $t('ticket.content_description', {
                            subtype: subtype.name,
                        })
                    "
                ></span>
            </v-col>
        </v-row>
        <v-row v-if="subtype">
            <v-col cols="12">
                <v-row>
                    <v-col v-if="subtype.text_label !== null" cols="6">
                        <TextField
                            v-model="form.text"
                            :label="subtype.text_label"
                            hide-details="auto"
                            :error-messages="
                                createTicketForm.errors[errorKeyPrefix + 'text']
                            "
                        ></TextField>
                    </v-col>
                    <v-col
                        v-if="subtype.external_shipment_id_label !== null"
                        cols="6"
                    >
                        <TextField
                            v-model="form.external_shipment_id"
                            :label="subtype.external_shipment_id_label"
                            hide-details="auto"
                            :error-messages="
                                createTicketForm.errors[
                                    errorKeyPrefix + 'external_shipment_id'
                                ]
                            "
                        ></TextField>
                    </v-col>
                    <v-col v-if="subtype.item_number_1_label !== null" cols="4">
                        <ItemSelect
                            v-model="form.item_number_1"
                            :label="subtype.item_number_1_label"
                            hide-details="auto"
                            :error-messages="
                                createTicketForm.errors[
                                    errorKeyPrefix + 'item_number_1'
                                ]
                            "
                        ></ItemSelect>
                    </v-col>
                    <v-col v-if="subtype.item_number_2_label !== null" cols="4">
                        <ItemSelect
                            v-model="form.item_number_2"
                            :label="subtype.item_number_2_label"
                            hide-details="auto"
                            :error-messages="
                                createTicketForm.errors[
                                    errorKeyPrefix + 'item_number_2'
                                ]
                            "
                        ></ItemSelect>
                    </v-col>
                    <v-col v-if="subtype.quantity_label !== null" cols="3">
                        <TextField
                            v-model="form.quantity"
                            :label="subtype.quantity_label"
                            hide-details="auto"
                            type="number"
                            :error-messages="
                                createTicketForm.errors[
                                    errorKeyPrefix + 'quantity'
                                ]
                            "
                        ></TextField>
                    </v-col>
                    <v-col v-if="subtype.note_label !== null" cols="12">
                        <TextareaField
                            v-model="form.note"
                            :label="subtype.note_label"
                            hide-details="auto"
                            :error-messages="
                                createTicketForm.errors[errorKeyPrefix + 'note']
                            "
                        ></TextareaField>
                    </v-col>
                    <v-col v-if="subtype.attachments_label !== null" cols="12">
                        <FileInputField
                            v-model="form.attachments"
                            :label="subtype.attachments_label"
                            multiple
                            :error-messages="
                                createTicketForm.errors[
                                    errorKeyPrefix + 'attachments'
                                ]
                            "
                        ></FileInputField>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-card>
</template>

<style scoped></style>
