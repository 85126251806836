<script setup>
import colors from 'tailwindcss/colors';
import { useVModel } from '@vueuse/core';

const props = defineProps({
    modelValue: {
        type: [String, null],
        required: true,
    },
});
const emit = defineEmits(['update:modelValue']);

const value = useVModel(props, 'modelValue', emit);
</script>

<template>
    <v-textarea
        v-model="value"
        class="tw-mb-2"
        :bg-color="colors.gray['100']"
        color="primary"
        rows="2"
    ></v-textarea>
</template>
