import { trans as $t } from 'laravel-vue-i18n';

export default {
    required(attributeName) {
        return (value) =>
            !!value || $t('validation.required', { Attribute: attributeName });
    },
    requiredDocuments(documents) {
        return () => {
            return (
                documents.length > 0 || $t('app.validation.documents_required')
            );
        };
    },
};
