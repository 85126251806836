<script setup>
import { nextTick, reactive, ref } from 'vue';
import DialogModal from './DialogModal.vue';
import TextField from '@/Components/TextField.vue';

const emit = defineEmits(['confirmed']);

defineProps({
    title: {
        type: String,
        default: 'Confirm Password',
    },
    content: {
        type: String,
        default: 'For your security, please confirm your password to continue.',
    },
    button: {
        type: String,
        default: 'Confirm',
    },
});

const confirmingPassword = ref(false);

const form = reactive({
    password: '',
    error: '',
    processing: false,
});

const passwordInput = ref(null);

const startConfirmingPassword = () => {
    axios.get(route('password.confirmation')).then((response) => {
        if (response.data.confirmed) {
            emit('confirmed');
        } else {
            confirmingPassword.value = true;

            setTimeout(() => passwordInput.value.focus(), 250);
        }
    });
};

const confirmPassword = () => {
    form.processing = true;

    axios
        .post(route('password.confirm'), {
            password: form.password,
        })
        .then(() => {
            form.processing = false;

            closeModal();
            nextTick().then(() => emit('confirmed'));
        })
        .catch((error) => {
            form.processing = false;
            form.error = error.response.data.errors.password[0];
            passwordInput.value.focus();
        });
};

const closeModal = () => {
    confirmingPassword.value = false;
    form.password = '';
    form.error = '';
};
</script>

<template>
    <span>
        <span @click="startConfirmingPassword">
            <slot />
        </span>

        <DialogModal :show="confirmingPassword" @close="closeModal">
            <template #title>
                {{ title }}
            </template>

            <template #content>
                {{ content }}

                <div class="tw-mt-4">
                    <TextField
                        ref="passwordInput"
                        v-model="form.password"
                        class="tw-mb-0"
                        type="password"
                        :label="$t('Password')"
                        :error-messages="form.error"
                        @keyup.enter="confirmPassword"
                    />
                </div>
            </template>

            <template #footer>
                <v-spacer></v-spacer>
                <v-btn @click="closeModal">
                    {{ $t('Cancel') }}
                </v-btn>

                <v-btn
                    color="primary"
                    variant="elevated"
                    :loading="form.processing"
                    @click="confirmPassword"
                >
                    {{ button }}
                </v-btn>
            </template>
        </DialogModal>
    </span>
</template>
