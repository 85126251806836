<script setup>
import { Head } from '@inertiajs/vue3';
import { mdiArchiveEditOutline } from '@mdi/js';
import LinkVuetify from '@/Components/LinkVuetify.vue';
import LaravelQueryTable from '@/Components/LaravelQueryTable.vue';

defineProps({
    maillogCustomers: { type: Object, required: true },
});

const columns = [
    { field: 'name', label: 'Name', search: true },
    { field: 'number', label: 'Number', search: true },
    {
        field: 'servicelevel',
        label: 'Service Levels',
        search: false,
        sort: false,
        valueGetter(value) {
            return value.servicelevels.join(', ');
        },
        maxWidth: 300,
    },
];
</script>

<template>
    <v-container>
        <Head :title="$t('Customers')"></Head>

        <!--        <div class="tw-mb-2">-->
        <!--            <LinkVuetify-->
        <!--                :href="route('user.create')"-->
        <!--                size="small"-->
        <!--                color="black"-->
        <!--                :prepend-icon="mdiPlus"-->
        <!--                >{{ $t('Create Customer') }}-->
        <!--            </LinkVuetify>-->
        <!--        </div>-->
        <LaravelQueryTable
            :data="maillogCustomers"
            :columns="columns"
            :space="36"
            route-name="customer.index"
        >
            <template #actions="{ row }">
                <LinkVuetify
                    :href="route('customer.edit', { customer: row.id })"
                    size="small"
                    color="surface"
                >
                    <v-icon
                        size="x-large"
                        :icon="mdiArchiveEditOutline"
                    ></v-icon>
                </LinkVuetify>
            </template>
        </LaravelQueryTable>
    </v-container>
</template>

<style scoped></style>
