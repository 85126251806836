<script setup>
import { Head } from '@inertiajs/vue3';
import Logo from '../../svg/maillog_logo_white.svg?component';
import backgroundImage from '@/../image/maillog_background.jpg';
import { computed } from 'vue';
import LinkVuetify from '@/Components/LinkVuetify.vue';
import { mdiArrowRightBold } from '@mdi/js';

defineProps({
    canLogin: Boolean,
    canRegister: Boolean,
});

const backgroundStyle = computed(() => ({
    'background-image': 'url("' + backgroundImage + '")',
    'background-position': 'center',
    'background-size': 'cover',
}));
</script>

<script>
export default { layout: null };
</script>

<template>
    <Head :title="$t('Index')" />

    <div
        :style="backgroundStyle"
        class="tw-flex items-top tw-justify-center tw-h-screen tw-w-screen tw-items-center"
    >
        <!--        <div-->
        <!--            v-if="canLogin"-->
        <!--            class="tw-absolute tw-top-0 tw-right-0 tw-px-6 tw-py-4 tw-block"-->
        <!--        >-->
        <!--            <Link-->
        <!--                v-if="$page.props.user"-->
        <!--                :href="route('dashboard')"-->
        <!--                class="tw-text-sm tw-text-gray-700 tw-underline"-->
        <!--                >{{ $t('Dashboard') }}</Link-->
        <!--            >-->

        <!--            <template v-else>-->
        <!--                <Link-->
        <!--                    :href="route('login')"-->
        <!--                    class="tw-text-sm tw-text-gray-100 tw-underline"-->
        <!--                    >{{ $t('Log in') }}</Link-->
        <!--                >-->

        <!--                <Link-->
        <!--                    v-if="canRegister"-->
        <!--                    :href="route('register')"-->
        <!--                    class="tw-ml-4 tw-text-sm tw-text-gray-700 tw-underline"-->
        <!--                    >{{ $t('Register') }}</Link-->
        <!--                >-->
        <!--            </template>-->
        <!--        </div>-->

        <div
            class="tw-w-screen tw-h-screen tw-flex tw-flex-col tw-justify-center tw-text-center md:tw-w-7/12 tw-mx-auto tw-px-6"
        >
            <Logo width="100%" class="tw-fill-white tw-mb-4 md:tw-mb-6"></Logo>
            <div>
                <LinkVuetify
                    v-if="!$page.props.auth.user"
                    :append-icon="mdiArrowRightBold"
                    color="primary"
                    variant="elevated"
                    :href="route('login')"
                    size="x-large"
                    >{{ $t('Login') }}
                </LinkVuetify>
                <LinkVuetify
                    v-else
                    :append-icon="mdiArrowRightBold"
                    color="primary"
                    variant="elevated"
                    :href="route('dashboard')"
                    size="x-large"
                    >Dashboard
                </LinkVuetify>
            </div>
        </div>
    </div>
</template>
