<script setup>
import ActionSection from '@/Components/ActionSection.vue';
import TextField from '@/Components/TextField.vue';
import { computed, nextTick, ref, watch } from 'vue';
import { AxiosError } from 'axios';
import DeliveryNoteLinesTable from '@/Pages/WarehouseManagement/Partials/DeliveryNoteLinesTable.vue';
import SaveButton from '@/Components/SaveButton.vue';
import SelectField from '@/Components/SelectField.vue';
import { useForm } from '@inertiajs/vue3';
import ConfirmationModal from '@/Components/ConfirmationModal.vue';
import { mdiInformation } from '@mdi/js';

const props = defineProps({
    locations: {
        type: Array,
        required: true,
    },
    warehouse: {
        type: Object,
        required: true,
    },
});

const form = useForm({
    location_id: props.warehouse.default_warehouse_location ?? null,
});
const deliveryNoteNumber = ref(null);
const limitMaxLength = (e) => {
    if (e.target.value.length > 6) {
        deliveryNoteNumber.value = e.target.value.slice(0, 6);
    }
};

const deliveryNoteLoading = ref(false);
const deliveryNoteError = ref('');
const deliveryNote = ref(null);
const fetchDeliveryNote = async (deliveryNoteNumber) => {
    deliveryNoteLoading.value = true;
    deliveryNoteError.value = '';
    try {
        await nextTick();
        const response = await axios.get(
            route('api.warehouse.delivery.show', {
                shipment_header: 'V-LIEF-' + deliveryNoteNumber,
            }),
        );
        deliveryNote.value = response.data;
    } catch (e) {
        if (e instanceof AxiosError) {
            deliveryNoteError.value = e.response.data.message;
        }
        deliveryNote.value = null;
    } finally {
        deliveryNoteLoading.value = false;
    }

    deliveryNoteLoading.value = false;
};

watch(deliveryNoteNumber, (val) => {
    if (val.length === 6) {
        fetchDeliveryNote(val);
    }
});
const submittable = computed(() => {
    return deliveryNote.value !== null && form.location_id !== null;
});

const showConfirmationModal = ref(false);
const acceptDeliveryNote = () => {
    showConfirmationModal.value = false;
    form.post(
        route('warehouse.delivery.accept', {
            shipment_header: deliveryNote.value.delivery_note_number,
        }),
    );
};
</script>

<template>
    <v-container class="tw-min-h-full">
        <ConfirmationModal
            :show="showConfirmationModal"
            :toolbar-icon="mdiInformation"
            :toolbar-props="{ color: 'info' }"
        >
            <template #title>{{
                $t('app.warehouse.accept_delivery_note')
            }}</template>
            <template #content>
                {{
                    $t('app.warehouse.accept_delivery_note_confirmation', {
                        delivery_note: deliveryNote.delivery_note_number,
                        location: locations.find(
                            (location) => location.id === form.location_id,
                        )?.description,
                    })
                }}
            </template>
            <template #footer>
                <v-btn
                    class="tw-mr-2"
                    color="danger"
                    variant="elevated"
                    @click="showConfirmationModal = false"
                    >{{ $t('Cancel') }}</v-btn
                >
                <SaveButton type="button" @click="acceptDeliveryNote"
                    >{{ $t('Accept') }}
                </SaveButton>
            </template>
        </ConfirmationModal>
        <ActionSection>
            <template #title>{{
                $t('Accept goods arrival from Maillog')
            }}</template>
            <template #description>{{
                $t(
                    'Please provide the delivery note number for the Maillog goods arrival and select the destination of the arrival.',
                )
            }}</template>
            <template #content>
                <TextField
                    v-model="deliveryNoteNumber"
                    :disabled="form.processing"
                    :readonly="deliveryNoteLoading"
                    :loading="deliveryNoteLoading"
                    type="number"
                    variant="outlined"
                    :label="$t('Delivery Note Number')"
                    :error-messages="deliveryNoteError"
                    @input="limitMaxLength"
                >
                    <template #prepend-inner>
                        <span
                            class="tw-whitespace-nowrap"
                            style="
                                margin-right: calc(
                                    var(--v-field-padding-start) * -1
                                );
                            "
                            >V-LIEF-</span
                        >
                    </template>
                    <template v-if="deliveryNoteLoading" #append-inner>
                        <v-progress-circular
                            color="primary"
                            indeterminate
                        ></v-progress-circular>
                    </template>
                </TextField>
                <v-spacer v-if="deliveryNote"></v-spacer>
                <DeliveryNoteLinesTable
                    v-if="deliveryNote"
                    :row-data="deliveryNote.lines"
                />
            </template>
            <template v-if="deliveryNote" #actions>
                <v-toolbar-items class="tw-flex tw-grow tw-items-center">
                    <SelectField
                        v-model="form.location_id"
                        :disabled="form.processing"
                        class="tw-ml-4 tw-w-1/2"
                        variant="solo"
                        bg-color="surface"
                        density="compact"
                        :label="$t('Location')"
                        hide-details="auto"
                        :items="locations"
                        item-value="id"
                        item-title="description"
                        :errors="form.errors.location_id"
                    ></SelectField>
                </v-toolbar-items>
                <div class="tw-w-1/3"></div>
                <SaveButton
                    :loading="form.processing"
                    :disabled="!submittable"
                    type="button"
                    @click="showConfirmationModal = true"
                    >{{ $t('Acceptance of goods') }}</SaveButton
                >
            </template>
        </ActionSection>
    </v-container>
</template>

<style scoped></style>
