<script setup>
import { Head, useForm } from '@inertiajs/vue3';
import UpdateProfileInformationForm from '@/Pages/Profile/Partials/UpdateProfileInformationForm.vue';
import ApiTokenManager from '@/Pages/API/Partials/ApiTokenManager.vue';
import SectionBorder from '@/Components/SectionBorder.vue';
import ActionMessage from '@/Components/ActionMessage.vue';
import ConfirmationModal from '@/Components/ConfirmationModal.vue';
import { ref } from 'vue';
import UpdatePermissionsForm from '@/Pages/Profile/Partials/UpdatePermissionsForm.vue';
import UpdatePasswordForm from '@/Pages/Profile/Partials/UpdatePasswordForm.vue';

const props = defineProps({
    status: {
        type: String,
        default: null,
    },
    user: {
        type: Object,
        required: true,
    },
});

const invitingUser = ref(false);
const userInviteForm = useForm({});
const postInviteForm = function () {
    userInviteForm.post(route('user.invite', { user: props.user.id }), {
        onSuccess: () => (invitingUser.value = false),
    });
};
</script>

<template>
    <v-container>
        <Head :title="$t('Edit User')"></Head>
        <ConfirmationModal :show="invitingUser" @close="invitingUser = false">
            <template #title>
                {{ $t('Send Invitation Link') }}
            </template>

            <template #content>
                {{
                    $t(
                        'Are you sure you would like to send an invitation link to :email?',
                        {
                            email: props.user.email,
                        },
                    )
                }}
            </template>

            <template #footer>
                <v-btn
                    variant="elevated"
                    color="surface"
                    class="tw-mr-2"
                    @click="invitingUser = false"
                    >{{ $t('Cancel') }}
                </v-btn>
                <v-btn
                    variant="elevated"
                    color="danger"
                    :loading="userInviteForm.processing"
                    @click="postInviteForm"
                    >{{ $t('Send Invitation Link') }}
                </v-btn>
            </template>
        </ConfirmationModal>

        <UpdateProfileInformationForm
            class="tw-mt-4"
            :user="$page.props.user"
            :update-route="route('user.update', { user: $page.props.user.id })"
        >
            <template #form-title>{{ $t('User Information') }}</template>
            <template #form-description
                >{{
                    $t(
                        "Update your account's profile information and email address.",
                    )
                }}
            </template>
            <template
                v-if="$page.props.auth.canCreateInvitations"
                #additional-actions="{ form }"
            >
                <ActionMessage
                    class="tw-mr-2"
                    :on="userInviteForm.recentlySuccessful"
                >
                    {{ status }}
                </ActionMessage>
                <v-btn
                    v-if="!form.isDirty && form.email"
                    type="button"
                    class="tw-mr-2"
                    :loading="userInviteForm.processing"
                    @click.prevent="invitingUser = true"
                >
                    {{ $t('Send Invitation Link') }}
                </v-btn>
            </template>
        </UpdateProfileInformationForm>
        <div v-if="$page.props.auth.canUpdateRoles">
            <section-border />
            <UpdatePermissionsForm
                v-if="
                    $page.props.auth.canUpdateRoles ||
                    $page.props.settings.length > 0
                "
                :can-update-roles="$page.props.auth.canUpdateRoles"
                :settings="$page.props.settings"
                :user="user"
            >
            </UpdatePermissionsForm>
        </div>
        <div v-if="$page.props.auth.canUpdatePassword">
            <section-border />
            <UpdatePasswordForm
                :user-id="user.id"
                :needs-current="false"
            ></UpdatePasswordForm>
        </div>
        <!--        <UpdateWarehouseTenantsForm-->
        <!--            v-if="-->
        <!--                $page.props.auth.canUpdateTenants ||-->
        <!--                $page.props.auth.canUpdateWarehouseSettings-->
        <!--            "-->
        <!--            :warehouse-tenant="-->
        <!--                $page.props.user.warehouse_tenants?.[0]?.id ?? null-->
        <!--            "-->
        <!--            :user="$page.props.user"-->
        <!--        >-->
        <!--        </UpdateWarehouseTenantsForm>-->
        <div v-if="$page.props.auth.canUpdateApiTokens">
            <section-border></section-border>
            <ApiTokenManager
                :tokens="$page.props.tokens"
                :user="$page.props.user"
                :store-route="
                    route('user.api-tokens.store', {
                        user: $page.props.user.id,
                    })
                "
                :available-permissions="$page.props.availablePermissions"
                :default-permissions="$page.props.defaultPermissions"
            >
            </ApiTokenManager>
        </div>
    </v-container>
</template>
