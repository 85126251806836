<script setup>
import { Head, useForm, usePage } from '@inertiajs/vue3';
import TextField from '@/Components/TextField.vue';
import ActionSection from '@/Components/ActionSection.vue';
import { computed, inject, onBeforeMount, ref, watch } from 'vue';
import SelectField from '@/Components/SelectField.vue';
import OrderLineForm from '@/Pages/Order/Partials/OrderLineForm.vue';
import AddressSelect from '@/Pages/Order/Partials/AddressSelect.vue';
import scrollIntoView from '@/lib/utilities/scroll-into-view';
import AutocompleteField from '@/Components/AutocompleteField.vue';
import DocumentFormSection from '@/Pages/Order/Partials/DocumentFormSection.vue';
import ConfirmOrderModal from '@/Pages/Order/Partials/ConfirmOrderModal.vue';
import CheckboxField from '@/Components/CheckboxField.vue';
import OutlinedSheet from '@/Components/OutlinedSheet.vue';
import { trans as $t } from 'laravel-vue-i18n';
import TextareaField from '@/Pages/Order/TextareaField.vue';

const props = defineProps({
    order: {
        type: Object,
        default: null,
    },
    customers: {
        type: Array,
        default: () => [],
    },
    addresses: {
        type: Array,
        default: () => [],
    },
});
const page = usePage();
const flash = inject('flash');
const isReservation = computed(() => !page.props.auth.canShipOrders);

let form = null;
if (props.order === null) {
    const servicelevel =
        page.props.serviceLevels.find(
            (servicelevel) => servicelevel.code === 'STANDARD',
        )?.code ?? page.props.serviceLevels[0].code;

    form = useForm({
        customer_number: props.customers[0].number,
        order_number: null,
        reference: null,
        name: null,
        name2: null,
        address: null,
        address2: null,
        postal_code: null,
        city: null,
        country: 'AT',
        contact: null,
        phone: null,
        email: null,
        comment: null,
        user_comment: null,
        servicelevel: servicelevel,
        requested_delivery_date: moment()
            .add(1, 'days')
            .format('YYYY-MM-DD HH:mm:ss'),
        cost_center: null,
        project: null,
        add_email: null,
        lines: [],
        shipping_agent_code: null,
        shipping_agent_service_code: null,
        documents: [],
        import: false,
        lift_platform: true,
        aviso_type: 0,
        aviso_destination: null,
        aviso_contact: null,
        manufacturing_instructions: null,
        number_of_pallets: null,
    });
} else {
    form = useForm({ ...props.order, import: false });
}

const selectedCustomer = computed(() =>
    props.customers.find(
        (customer) => customer.number === form.customer_number,
    ),
);
watch(
    () => form.customer_number,
    () => {
        form.cost_center = null;
        form.project = null;
        form.lines = [];
        manualShipping.value = false;
        shippingAgents.value = [];
    },
);

// Conditional fields (dependent on servicelevel and wether order is reservation)
const showPhoneAndContact = computed(() => {
    if (isReservation.value) {
        return false;
    }

    switch (form.servicelevel) {
        case 'EXPRESS':
        case 'KÜHLTRANSPORT':
        case 'ABHOLUNG':
            return true;
    }
    return false;
});
const showAddEmail = computed(() => {
    return page.props.customer_permissions[
        selectedCustomer.value.number
    ].includes('set order add_email field');
});
const showRequestedDeliveryDate = computed(() => {
    return isReservation.value || form.servicelevel !== 'STANDARD';
});

const showComment = computed(() => {
    if (isReservation.value) {
        return true;
    }

    switch (form.servicelevel) {
        case 'ABHOLUNG':
        case 'TERMIN':
            return true;
    }
    return false;
});

watch(showComment, (val) => {
    if (!val) {
        form.user_comment = null;
    }
});

const showProject = computed(() => {
    if (isReservation.value) {
        return false;
    }

    return selectedCustomer.value.projects.length > 0;
});
const showCostCenter = computed(() => {
    if (isReservation.value) {
        return false;
    }

    return selectedCustomer.value.cost_centers.length > 0;
});
const showDocuments = computed(() => {
    return !isReservation.value;
});

const requestedDeliveryDate = computed({
    get: () => {
        return form.requested_delivery_date
            ? moment(form.requested_delivery_date).format('YYYY-MM-DD')
            : null;
    },
    set: (value) => {
        form.requested_delivery_date = moment(value).format(
            'YYYY-MM-DD HH:mm:ss',
        );
    },
});

const hasShippingOptions = computed(() => {
    return page.props.customer_settings[selectedCustomer.value.number]
        .has_shipping_options;
});
const needsAvisoFields = computed(() => {
    return (
        page.props.customer_settings[selectedCustomer.value.number]
            .needs_aviso_fields && hasShippingOptions.value
    );
});
const manualShipping = ref(!!form.shipping_agent_code);
const shippingAgent = ref(form.shipping_agent_code);
const shippingAgentServiceCode = ref(form.shipping_agent_service_code);
const shippingAgents = ref([]);
const shippingAgentsLoading = ref(false);

const loadShippingAgents = async () => {
    if (shippingAgents.value.length === 0 && manualShipping.value) {
        shippingAgentsLoading.value = true;
        const response = await axios.get(
            route('api.maillog.shipping-agent.index'),
            {
                params: {
                    filter: {
                        customer_number: form.customer_number,
                    },
                },
            },
        );
        shippingAgentsLoading.value = false;
        shippingAgents.value = response.data;
    }
};
onBeforeMount(loadShippingAgents);
const shippingAgentServices = computed(() => {
    return shippingAgent.value
        ? shippingAgents.value.find(
              (agent) => agent.code === shippingAgent.value,
          )?.services ?? []
        : [];
});

watch(manualShipping, (value) => {
    if (value) {
        loadShippingAgents();
    }

    form.shipping_agent_code = value ? shippingAgent.value : null;
    form.shipping_agent_service_code = value
        ? shippingAgentServiceCode.value
        : null;
});
form.shipping_agent_code = manualShipping.value ? shippingAgent : null;
form.shipping_agent_service_code = manualShipping.value
    ? shippingAgentServiceCode
    : null;

watch(shippingAgent, (value) => {
    form.shipping_agent_code = value;
    form.shipping_agent_service_code = null;
    shippingAgentServiceCode.value = null;
});

watch(shippingAgentServiceCode, (value) => {
    form.shipping_agent_service_code = value;
});

const transformBeforeSubmit = (data) => {
    data = { ...data };
    data.lines = data.lines.map((line) => {
        line = { ...line };
        delete line.item;
        line.id = typeof line.id === 'string' ? null : line.id;
        return line;
    });
    return data;
};

// Check if not EU and require documents
const isEU = computed(() => {
    return page.props.countries.find((country) => country.code === form.country)
        ?.is_eu;
});

const onError = (error) => {
    if (error.flash) {
        flash(error.flash, 'danger');
        form.reset('import');
    }
};
const onSuccess = () => {
    form.defaults(props.order);
    form.reset();
    selectedAddress.value = null;
};
const requestOptions = {
    onError,
    onSuccess,
};

const formElement = ref(null);
const nameField = ref(null);
const confirmingOrder = ref(false);

const onSubmit = function (e) {
    const toImport = e.submitter.dataset.action === 'import';
    if (toImport) {
        confirmingOrder.value = true;
    } else {
        submitCreateOrderForm(toImport);
    }
};
const submitCreateOrderForm = async function (toImport) {
    confirmingOrder.value = false;
    const { valid, errors } = await formElement.value.validate();

    if (!valid) {
        scrollIntoView(errors[0].id, 85);
        return;
    }

    form.import = toImport;

    if (!Number.isInteger(form.id)) {
        form.transform(transformBeforeSubmit).post(route('order.store'), {
            ...requestOptions,
        });
    } else {
        form.transform(transformBeforeSubmit).put(
            route('order.update', { order: form.id }),
            {
                ...requestOptions,
            },
        );
    }
};

const selectedAddress = ref(null);
watch(selectedAddress, (address_id) => {
    if (address_id !== null) {
        const address = props.addresses.find(
            (address) => address.id === address_id,
        );
        form.name = address.name;
        form.name2 = address.name2;
        form.address = address.address;
        form.address2 = address.address2;
        form.postal_code = address.postal_code;
        form.city = address.city;
        form.country = address.country;
        form.email = address.email;
    }
});

const avisoTypeName = ref(null);
watch(
    () => form.aviso_type,
    (value, oldValue) => {
        if (oldValue !== undefined && oldValue !== 0) {
            form.aviso_destination = null;
        }

        switch (value) {
            case 1:
                avisoTypeName.value = $t('E-Mail');
                break;
            case 2:
                avisoTypeName.value = $t('Telephone Number');
                break;
            default:
            // avisoTypeName.value = null;
            // form.aviso_destination = null;
            // form.aviso_contact = null;
        }
    },
    { immediate: true },
);
</script>
<template>
    <v-container>
        <ConfirmOrderModal
            v-model="confirmingOrder"
            :order="form"
            @confirm="submitCreateOrderForm(true)"
        ></ConfirmOrderModal>
        <Head :title="$t('Create Order')"></Head>
        <v-form ref="formElement" validate-on="lazy" @submit.prevent="onSubmit">
            <ActionSection
                class="tw-mt-4"
                toolbar-color="surface"
                toolbar-density="compact"
            >
                <template #title>{{ $t('Address') }}</template>
                <template #description
                    >{{
                        $t('Enter the customer address and note for the order.')
                    }}
                </template>
                <template #toolbar>
                    <AddressSelect
                        v-model="selectedAddress"
                        :addresses="addresses"
                        :form="form"
                    />
                </template>
                <template #content>
                    <v-row class="tw-pb-4">
                        <v-col class="tw-pb-0" cols="12" md="6">
                            <TextField
                                ref="nameField"
                                v-model.trim="form.name"
                                hide-details="auto"
                                :label="$t('Name')"
                                :error-messages="form.errors['name']"
                            ></TextField>
                        </v-col>
                        <v-col class="tw-pb-0" cols="12" md="6">
                            <TextField
                                v-model.trim="form.name2"
                                required
                                hide-details="auto"
                                :label="$t('Name 2')"
                                :error-messages="form.errors['name2']"
                            ></TextField>
                        </v-col>
                        <v-col class="tw-pb-0" cols="12" md="6">
                            <TextField
                                v-model.trim="form.address"
                                hide-details="auto"
                                :label="$t('Address')"
                                :error-messages="form.errors['address']"
                            ></TextField>
                        </v-col>
                        <v-col class="tw-pb-0" cols="12" md="6">
                            <TextField
                                v-model.trim="form.address2"
                                hide-details="auto"
                                :label="$t('Address 2')"
                                :error-messages="form.errors['address2']"
                            ></TextField>
                        </v-col>
                        <v-col class="tw-pb-0" cols="6">
                            <TextField
                                v-model.trim="form.postal_code"
                                hide-details="auto"
                                :label="$t('Postal Code')"
                                :error-messages="form.errors['postal_code']"
                            ></TextField>
                        </v-col>
                        <v-col class="tw-pb-0" cols="6" md="6">
                            <TextField
                                v-model.trim="form.city"
                                hide-details="auto"
                                :label="$t('City')"
                                :error-messages="form.errors['city']"
                            ></TextField>
                        </v-col>
                        <v-col class="tw-pb-0" cols="12">
                            <AutocompleteField
                                v-model="form.country"
                                :label="$t('Country')"
                                :items="page.props.countries"
                                item-title="name"
                                item-value="code"
                                hide-details="auto"
                                :error-messages="form.errors['country']"
                            />
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row class="tw-pt-4">
                        <v-col cols="12" md="6">
                            <OutlinedSheet class="tw-min-h-full"
                                ><template #title>{{ $t('Contact') }}</template>
                                <v-row
                                    ><v-col class="tw-pb-0" cols="12">
                                        <TextField
                                            v-model.trim="form.email"
                                            hide-details="auto"
                                            :label="$t('Email')"
                                            :error-messages="
                                                form.errors['email']
                                            "
                                        ></TextField>
                                    </v-col>
                                    <v-col
                                        v-if="showPhoneAndContact"
                                        class="tw-pb-0"
                                        cols="12"
                                    >
                                        <TextField
                                            v-model.trim="form.contact"
                                            hide-details="auto"
                                            :label="$t('Contact Person')"
                                            :error-messages="
                                                form.errors['contact']
                                            "
                                            required
                                        ></TextField>
                                    </v-col>
                                    <v-col
                                        v-if="showPhoneAndContact"
                                        class="tw-pb-0"
                                        cols="12"
                                    >
                                        <TextField
                                            v-model.trim="form.phone"
                                            hide-details="auto"
                                            :label="$t('Phone')"
                                            :error-messages="
                                                form.errors['phone']
                                            "
                                            required
                                        ></TextField>
                                    </v-col>
                                </v-row>
                            </OutlinedSheet>
                        </v-col>
                        <v-col v-if="needsAvisoFields" cols="12" md="6">
                            <OutlinedSheet class="tw-min-h-full">
                                <template #title>
                                    {{ $t('Aviso') }}
                                </template>
                                <v-row>
                                    <v-col class="tw-pb-0" cols="12">
                                        <SelectField
                                            v-model="form.aviso_type"
                                            :label="$t('Aviso Type')"
                                            hide-details="auto"
                                            :items="page.props.avisoTypes"
                                            item-title="name"
                                            item-value="value"
                                            :error-messages="
                                                form.errors['aviso_type']
                                            "
                                        ></SelectField>
                                    </v-col>
                                    <v-col class="tw-pb-0" cols="12">
                                        <TextField
                                            v-if="form.aviso_type !== 0"
                                            v-model="form.aviso_destination"
                                            :label="
                                                $t('Aviso') +
                                                ' ' +
                                                avisoTypeName
                                            "
                                            hide-details="auto"
                                            :error-messages="
                                                form.errors['aviso_destination']
                                            "
                                        >
                                        </TextField>
                                    </v-col>
                                    <v-col class="tw-pb-0" cols="12">
                                        <TextField
                                            v-if="form.aviso_type !== 0"
                                            v-model="form.aviso_contact"
                                            :label="$t('Aviso Kontaktperson')"
                                            :error-messages="
                                                form.errors['aviso_contact']
                                            "
                                        ></TextField>
                                    </v-col>
                                </v-row>
                            </OutlinedSheet>
                        </v-col>
                    </v-row>
                </template>
            </ActionSection>

            <ActionSection v-if="!isReservation" class="tw-mt-4">
                <template #title>{{ $t('Transport') }}</template>
                <template #description
                    >{{ $t('order.transport.description') }}
                </template>
                <template #content>
                    <v-row class="tw-pb-4">
                        <v-col class="tw-pb-0" cols="12" md="6">
                            <SelectField
                                v-model.trim="form.servicelevel"
                                class="tw-mb-2"
                                hide-details="auto"
                                :label="$t('Service Level')"
                                :items="page.props.serviceLevels"
                                item-value="code"
                                item-title="description"
                                :error-messages="form.errors['servicelevel']"
                            ></SelectField>
                        </v-col>
                        <v-col
                            v-if="showRequestedDeliveryDate"
                            class="tw-pb-0"
                            cols="12"
                            md="6"
                        >
                            <TextField
                                v-model.trim="requestedDeliveryDate"
                                type="date"
                                hide-details="auto"
                                :label="$t('Requested Delivery Date')"
                                :error-messages="
                                    form.errors['requested_delivery_date']
                                "
                                required
                            ></TextField>
                        </v-col>
                        <v-col v-if="true" cols="12" md="6">
                            <TextField
                                v-model.trim="form.comment"
                                hide-details="auto"
                                :label="$t('Note')"
                                :error-messages="form.errors['comment']"
                            ></TextField>
                        </v-col>
                        <v-col v-if="showComment" cols="12" md="6">
                            <TextField
                                v-model.trim="form.user_comment"
                                hide-details="auto"
                                :label="$t('Anlieferinformation')"
                                :error-messages="form.errors['user_comment']"
                            ></TextField>
                        </v-col>
                        <v-col v-if="needsAvisoFields" cols="12" md="6">
                            <TextField
                                v-model.trim="form.number_of_pallets"
                                hide-details="auto"
                                :label="$t('Number of Pallets')"
                                :error-messages="
                                    form.errors['number_of_pallets']
                                "
                                type="number"
                            ></TextField>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            v-if="hasShippingOptions"
                            class="tw-pb-0"
                            cols="12"
                            md="6"
                        >
                            <v-checkbox
                                v-model="manualShipping"
                                hide-details="auto"
                                :label="$t('Manual Shipping Method')"
                                :error-messages="form.errors['manual_shipping']"
                            ></v-checkbox>
                        </v-col>
                        <v-col
                            v-if="needsAvisoFields && showComment"
                            class="tw-pb-0"
                            cols="12"
                            md="6"
                        >
                            <CheckboxField
                                v-model="form.lift_platform"
                                :disabled="!manualShipping"
                                :label="$t('Delivery with truck-lift')"
                            ></CheckboxField>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            v-if="hasShippingOptions"
                            class="tw-pb-0"
                            cols="12"
                            md="6"
                        >
                            <AutocompleteField
                                v-model.trim="shippingAgent"
                                :disabled="!manualShipping"
                                hide-details="auto"
                                :label="$t('Shipping Agent')"
                                :items="shippingAgents"
                                :loading="shippingAgentsLoading"
                                item-title="code"
                                :error-messages="
                                    form.errors['shipping_agent_code']
                                "
                            ></AutocompleteField>
                        </v-col>
                        <v-col
                            v-if="hasShippingOptions"
                            class="tw-pb-0"
                            cols="12"
                            md="6"
                        >
                            <AutocompleteField
                                v-model.trim="shippingAgentServiceCode"
                                :disabled="!manualShipping || !shippingAgent"
                                hide-details="auto"
                                :label="$t('Shipping Service')"
                                :items="shippingAgentServices"
                                :item-title="
                                    (value, item) =>
                                        `${item.code} - ${item.description}`
                                "
                                item-value="code"
                                :error-messages="
                                    form.errors['shipping_agent_service_code']
                                "
                            ></AutocompleteField>
                        </v-col>
                    </v-row>
                </template>
            </ActionSection>

            <OrderLineForm
                v-model="form.lines"
                :errors="form.errors"
                :customer-number="form.customer_number"
            ></OrderLineForm>

            <ActionSection class="tw-mt-4">
                <template #title>{{ $t('Order Details') }}</template>
                <template v-if="!isReservation" #description
                    >{{ $t('order.specification.description1') }}
                    <span
                        :class="{
                            'text-danger': !isEU,
                            'tw-font-black': !isEU,
                        }"
                    >
                        {{ $t('order.specification.description2') }}
                    </span>
                </template>
                <template v-else #description>
                    {{ $t('order.specification.reservation_description') }}
                </template>
                <template #content>
                    <v-row>
                        <v-col
                            v-if="customers.length > 1"
                            class="tw-pb-0"
                            cols="12"
                            md="6"
                        >
                            <SelectField
                                v-model="form.customer_number"
                                :label="$t('Customer')"
                                :disabled="customers.length === 1"
                                :items="customers"
                                item-value="number"
                                item-title="name"
                                hide-details="auto"
                                :error-messages="form.errors['customer_number']"
                            ></SelectField>
                        </v-col>
                        <v-col
                            v-if="showProject"
                            class="tw-pb-0"
                            cols="12"
                            md="6"
                        >
                            <SelectField
                                ref="projectSelect"
                                v-model="form.project"
                                clearable
                                class="tw-mb-2"
                                hide-details="auto"
                                :label="$t('Project')"
                                :items="selectedCustomer.projects"
                                item-value="description"
                                item-title="description"
                                :error-messages="form.errors['project']"
                            ></SelectField>
                        </v-col>
                        <v-col
                            v-if="showCostCenter"
                            class="tw-pb-0"
                            cols="12"
                            md="6"
                        >
                            <SelectField
                                ref="costCenterSelect"
                                v-model="form.cost_center"
                                clearable
                                class="tw-mb-2"
                                hide-details="auto"
                                :label="$t('Cost Center')"
                                :items="selectedCustomer.cost_centers"
                                item-value="description"
                                item-title="description"
                                :error-messages="form.errors['cost_center']"
                            ></SelectField>
                        </v-col>
                        <v-col
                            v-if="
                                form.order_number !== null &&
                                isNaN(Number(form.order_number))
                            "
                            class="tw-pb-0"
                            cols="12"
                            md="6"
                        >
                            <TextField
                                v-model.trim="form.order_number"
                                readonly
                                hide-details="auto"
                                :label="$t('Order Number')"
                                :error-messages="form.errors['order_number']"
                            ></TextField>
                        </v-col>
                        <v-col class="tw-pb-0" cols="12" md="6">
                            <TextField
                                v-model.trim="form.reference"
                                hide-details="auto"
                                :label="$t('External Reference')"
                            ></TextField>
                        </v-col>

                        <v-col v-if="showAddEmail" cols="12" md="6">
                            <TextField
                                v-model.trim="form.add_email"
                                type="email"
                                hide-details="auto"
                                :label="$t('Additional Email')"
                                :error-messages="form.errors['add_email']"
                            ></TextField>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            v-if="hasShippingOptions"
                            class="tw-pb-0"
                            cols="12"
                        >
                            <TextareaField
                                v-model.trim="form.manufacturing_instructions"
                                :error-messages="
                                    form.errors['manufacturing_instructions']
                                "
                                hide-details="auto"
                                :label="$t('Manufacturing Instructions')"
                            />
                        </v-col>
                    </v-row>
                    <DocumentFormSection
                        v-if="showDocuments"
                        v-model="form.documents"
                        :order="order"
                        :required="false"
                    />
                </template>
            </ActionSection>

            <ActionSection>
                <template #title>{{ $t('Actions') }}</template>
                <template #content>
                    <v-btn
                        v-if="!isReservation"
                        :loading="form.processing"
                        :disabled="!form.id && !form.isDirty"
                        data-action="import"
                        class="tw-mr-2"
                        type="submit"
                        color="primary"
                        >{{ $t('Book Order') }}</v-btn
                    >
                    <v-btn
                        :loading="form.processing"
                        :disabled="!form.isDirty"
                        color="primary"
                        variant="outlined"
                        type="submit"
                    >
                        {{
                            !isReservation
                                ? $t('Save Draft')
                                : $t('Create Reservation')
                        }}
                    </v-btn>
                </template>
            </ActionSection>
        </v-form>
    </v-container>
</template>
