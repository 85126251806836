<script setup>
import { useForm, usePage } from '@inertiajs/vue3';
import { useVModel } from '@vueuse/core';
import TextField from '@/Components/TextField.vue';
import SelectField from '@/Components/SelectField.vue';
import SaveButton from '@/Components/SaveButton.vue';
import { computed, watch } from 'vue';

const props = defineProps({
    modelValue: {
        type: Boolean,
        default: false,
    },
    locations: {
        type: Array,
        required: true,
    },
    warehouseLocation: {
        type: Object,
        default: null,
    },
    warehouseItem: {
        type: Object,
        default: null,
    },
});
const emit = defineEmits(['update:modelValue', 'reserved']);

const page = usePage();

const escapedLocationName = computed(() => {
    return _.escape(props.warehouseLocation?.description);
});
const showReservationModal = useVModel(props, 'modelValue', emit);
const reservationForm = useForm({
    warehouse_item_id: props.warehouseItem?.id,
    warehouse_location_id: props.warehouseLocation?.id,
    for_warehouse_location_id:
        page.props.warehouse.default_warehouse_location ?? null,
    quantity: 0,
    note: null,
});
const submitReservationForm = () => {
    reservationForm.post(route('warehouse.reservation.store'), {
        onSuccess() {
            reservationForm.reset();
            emit('reserved');
            showReservationModal.value = false;
        },
        preserveState: true,
    });
};

watch(showReservationModal, (show) => {
    if (show) {
        reservationForm.warehouse_location_id = props.warehouseLocation.id;
        reservationForm.warehouse_item_id = props.warehouseItem.id;
    } else {
        reservationForm.reset();
    }
});
</script>

<template>
    <v-dialog
        v-model="showReservationModal"
        content-class="tw-max-w-lg"
        @close="reservationForm.reset()"
    >
        <v-card>
            <template #title>
                <!-- eslint-disable vue/no-v-html-->
                <div
                    class="tw-uppercase"
                    v-html="
                        $t('Reservation from <b>:location</b>', {
                            location: escapedLocationName,
                        })
                    "
                ></div>
                <div class="tw-mb-2 tw-text-base tw-font-light tw-uppercase">
                    {{ $t('For') }} {{ $t('Article') }}:
                    <span class="tw-font-black">
                        {{ warehouseItem.description }}
                    </span>
                </div>
            </template>

            <template #text>
                <TextField
                    v-model="reservationForm.quantity"
                    type="number"
                    hide-details="auto"
                    :error-messages="reservationForm.errors['quantity']"
                >
                    <template #append
                        ><span class="tw-uppercase">{{
                            $t('Items to reserve')
                        }}</span>
                    </template>
                </TextField>
                <SelectField
                    v-model="reservationForm.for_warehouse_location_id"
                    :items="locations"
                    item-title="description"
                    item-value="id"
                    hide-details="auto"
                    :errors-messages="
                        reservationForm.errors['for_warehouse_location_id']
                    "
                >
                    <template #prepend>
                        <span class="tw-uppercase">{{ $t('For') }}</span>
                    </template>
                </SelectField>
            </template>
            <v-toolbar height="50" class="tw-py-1">
                <v-spacer></v-spacer>
                <div class="tw-flex tw-h-full tw-items-center">
                    <v-btn
                        :disabled="reservationForm.processing"
                        color="danger"
                        variant="elevated"
                        class="tw-mr-2"
                        @click="showReservationModal = false"
                        >{{ $t('Cancel') }}
                    </v-btn>
                    <SaveButton
                        :disabled="
                            reservationForm.for_warehouse_location_id ===
                                null || reservationForm.quantity === 0
                        "
                        :loading="reservationForm.processing"
                        class="tw-mr-4"
                        type="button"
                        @click="submitReservationForm"
                        >{{ $t('Reserve') }}
                    </SaveButton>
                </div>
            </v-toolbar>
        </v-card>
    </v-dialog>
</template>

<style scoped></style>
