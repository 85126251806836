<script setup>
import { computed, ref } from 'vue';
import { Head, useForm, usePage } from '@inertiajs/vue3';
import LinkVuetify from '@/Components/LinkVuetify.vue';
import ConfirmationModal from '@/Components/ConfirmationModal.vue';
import { mdiAccountEdit, mdiPlus, mdiTrashCanOutline } from '@mdi/js';
import LaravelQueryTable from '@/Components/LaravelQueryTable.vue';

const props = defineProps({
    users: { type: Object, required: true },
});
const page = usePage();

const columns = [
    { field: 'username', label: 'Username', search: true },
    { field: 'email', label: 'Email', search: true },
    { field: 'first_name', label: 'First Name' },
    { field: 'last_name', label: 'Last Name' },
    {
        field: 'maillog_customers',
        label: 'Customers',
        search: true,
        maxWidth: 200,
    },
];

if (!page.props.auth.canViewCustomers) {
    columns.splice(4, 1);
}

const data = computed(() => {
    const users = JSON.parse(JSON.stringify(props.users));
    users.data = users.data.map((user) => {
        user.maillog_customers =
            typeof user.maillog_customers !== 'string'
                ? user.maillog_customers
                      .map((customer) => customer.name)
                      .join(', \n')
                : user.maillog_customers;
        return user;
    });
    return users;
});

const deleteForm = useForm({});
const toDelete = ref(null);
const deleteConfirming = ref(false);
const deleteUser = function (user) {
    toDelete.value = user;
    deleteConfirming.value = true;
};
const cancelDelete = function () {
    deleteConfirming.value = false;
    toDelete.value = null;
};
const confirmDelete = function () {
    deleteForm.delete(route('user.destroy', { user: toDelete.value.id }), {
        onSuccess: () => {
            toDelete.value = null;
            deleteConfirming.value = false;
        },
    });
};
</script>

<template>
    <v-container>
        <Head :title="$t('Users')"></Head>
        <ConfirmationModal :show="deleteConfirming" @close="cancelDelete">
            <template #title>{{ $t('Delete User') }}</template>
            <template #content
                >{{ $t('Are you sure you want to delete the user?') }}
                <strong>{{ toDelete?.username }}</strong
                >?
            </template>
            <template #footer>
                <v-btn
                    variant="elevated"
                    :loading="deleteForm.processing"
                    color="danger"
                    @click="confirmDelete"
                >
                    {{ $t('Delete') }}
                </v-btn>
                <v-btn color="danger" @click="cancelDelete">{{
                    $t('Cancel')
                }}</v-btn>
            </template>
        </ConfirmationModal>

        <div class="tw-mb-2">
            <LinkVuetify
                v-if="$page.props.canCreateUsers"
                :href="route('user.create')"
                size="small"
                color="black"
                :prepend-icon="mdiPlus"
                >{{ $t('Create User') }}
            </LinkVuetify>
        </div>

        <LaravelQueryTable
            :columns="columns"
            :data="data"
            :space="32 + 36"
            route-name="user.index"
        >
            <template v-if="$page.props.auth.canEditUsers" #actions="{ row }">
                <LinkVuetify
                    :href="route('user.edit', { user: row.id })"
                    size="small"
                    color="surface"
                >
                    <v-icon size="x-large" :icon="mdiAccountEdit"></v-icon>
                </LinkVuetify>
                <!-- <LinkVuetify :href="route('user.show', {id: user.id})" size="small" color="primary">Edit</LinkVuetify>-->
                <v-btn
                    method="delete"
                    size="small"
                    color="surface"
                    class="tw-ml-2"
                    @click="deleteUser(row)"
                >
                    <v-icon size="x-large" :icon="mdiTrashCanOutline"></v-icon>
                </v-btn>
            </template>
        </LaravelQueryTable>
    </v-container>
</template>
