export default (request, autoGroupColumnName = null, perPage = 100) => {
    const params = {
        filter: {},
        sort: [],
        page: Math.ceil(request.endRow / perPage),
    };
    // Create filter request params
    Object.keys(request.filterModel).forEach((field) => {
        let fieldName = field;

        if (autoGroupColumnName && field === 'ag-Grid-AutoColumn') {
            fieldName = autoGroupColumnName;
        }

        switch (request.filterModel[field].filterType) {
            case 'text':
                params['filter'][fieldName] = request.filterModel[field].filter;
                break;
            case 'date':
                switch (request.filterModel[field].type) {
                    case 'equals':
                        params['filter'][fieldName] =
                            request.filterModel[field].dateFrom;
                        break;
                    case 'greaterThan':
                        params['filter']['after'] =
                            request.filterModel[field].dateFrom;
                        break;
                    case 'lessThan':
                        params['filter']['before'] =
                            request.filterModel[field].dateFrom;
                        break;
                    case 'inRange':
                        params['filter']['between'] =
                            request.filterModel[field].dateFrom +
                            ',' +
                            request.filterModel[field].dateTo;
                        break;
                }
                break;
            case 'number':
                switch (request.filterModel[field].type) {
                    case 'equals':
                        params['filter'][fieldName] =
                            '=,' + request.filterModel[field].filter;
                        break;
                    case 'greaterThan':
                        params['filter'][fieldName] =
                            '>,' + request.filterModel[field].filter;
                        break;
                    case 'lessThan':
                        params['filter'][fieldName] =
                            '<,' + request.filterModel[field].filter;
                        break;
                    case 'inRange':
                        params['filter'][fieldName] =
                            '<>,' +
                            request.filterModel[field].filter +
                            ',' +
                            request.filterModel[field].filterTo;
                }
                break;
            case 'set':
                params['filter'][fieldName] = request.filterModel[field].values;
        }
    });

    // Create sort request params
    params.sort = request.sortModel.map((sort) => {
        if (autoGroupColumnName && sort.colId === 'ag-Grid-AutoColumn') {
            sort.colId = autoGroupColumnName;
        }

        return sort.sort === 'asc' ? sort.colId : '-' + sort.colId;
    });

    return params;
};
