<script setup>
import { router, usePage } from '@inertiajs/vue3';
import {
    computed,
    nextTick,
    onUnmounted,
    reactive,
    ref,
    watch,
    watchPostEffect,
} from 'vue';
import AgTableCard from '@/Components/AgTableCard.vue';
import { AgGridVue } from 'ag-grid-vue3';

import MaterialSelectEditCellRenderer from '@/Components/CellRenderers/MaterialSelectEditCellRenderer.vue';
import CustomerSelectCellRenderer from '@/Pages/ShippingAgentMatrix/Partials/CustomerSelectCellRenderer.vue';
import { mdiPlus } from '@mdi/js';
import defaultColumnDefinitions from '@/lib/ag-grid/default-column-definitions';
import {
    columnStateEvents,
    getColumnState,
    gridApi,
    onGridReady,
    stateApplied,
} from '@/lib/ag-grid/save-column-state';
import { empty_string_to_null, isValidationError } from '@/lib/helpers';
import columnTypes from '@/lib/ag-grid/column-types';
import MatrixActionsCellRenderer from '@/Pages/ShippingAgentMatrix/Partials/MatrixActionsCellRenderer.vue';
import MaterialBlankEditCellRenderer from '@/Components/CellRenderers/MaterialBlankEditCellRenderer.vue';
import CustomerSelect from '@/Components/CustomerSelect.vue';
import { useUrlSearchParams } from '@vueuse/core';

const page = usePage();
const searchParams = useUrlSearchParams();

getColumnState('shipping_agent_matrix');

// for click outside detection
const menus = ref(null);
const newRowButton = ref(null);
const ignoredElements = [newRowButton, menus];

const defaultColDef = computed(() => ({
    ...defaultColumnDefinitions,
    editable: !visiting.value,
    filter: true,
}));
const columnDefs = computed(() => [
    {
        field: 'customer_number',
        cellEditor:
            context.selectedCustomer === null
                ? CustomerSelectCellRenderer
                : MaterialBlankEditCellRenderer,
    },
    {
        field: 'country',
        cellEditor: MaterialSelectEditCellRenderer,
        cellEditorParams: {
            items: page.props.countries,
            itemTitle: 'name',
            itemValue: 'code',
            clearable: true,
            menuProps: {
                attach: menus.value,
            },
        },
    },
    {
        field: 'servicelevel',
        cellEditor: MaterialSelectEditCellRenderer,
        cellEditorParams: {
            items: page.props.servicelevels,
            itemTitle: 'code',
            itemValue: 'code',
            clearable: true,
            menuProps: {
                attach: menus.value,
            },
        },
    },
    {
        field: 'weight_from',
        type: 'numericColumn',
        valueFormatter: weightValueFormatter,
    },
    {
        field: 'weight_to',
        type: 'numericColumn',
        valueFormatter: weightValueFormatter,
    },
    {
        field: 'packaging',
    },
    {
        field: 'shipping_agent',
        cellEditor: MaterialSelectEditCellRenderer,
        cellEditorParams: {
            items: page.props.shipping_agents,
            itemTitle: 'code',
            itemValue: 'code',
            onValueUpdated: (value) => {
                context.shipping_agent = value;
            },
            menuProps: {
                attach: menus.value,
            },
        },
    },
    {
        field: 'shipping_agent_service',
        cellEditor: MaterialSelectEditCellRenderer,
        cellEditorParams: {
            items: (params) => {
                return page.props.shipping_agents.find(
                    (item) => item.code === params.context.shipping_agent,
                )?.services;
            },
            itemTitle: 'code',
            itemValue: 'code',
            menuProps: {
                attach: menus.value,
            },
        },
    },
    {
        field: 'department',
    },
    {
        headerName: '',
        field: 'actions',
        cellClass: 'ag-cell-no-border',
        cellRenderer: MatrixActionsCellRenderer,
        cellRendererParams: {
            disabled: visiting.value,
            onDelete: deleteRow,
        },
        cellEditor: MaterialBlankEditCellRenderer,
        sortable: false,
        flex: 0,
        minWidth: 100,
        resizable: true,
    },
]);

const context = reactive({
    shipping_agent: null,
    selectedCustomer: null,
});

const lastRowNode = ref(null);

const rowIsEmpty = (row) => {
    const keys = Object.keys(row).filter((key) => {
        if (context.selectedCustomer !== null) {
            return key !== 'id' && key !== 'customer_number';
        }
        return key !== 'id';
    });
    return !keys.some((key) => row[key] !== null);
};
const getDataId = (data) => {
    return (
        data.id ||
        `${data.customer_number}-${data.country}-${data.servicelevel}-${data.weight_from}-${data.weight_to}`
    );
};
const getRowId = (params) => {
    return params.data.id;
};
const weightValueFormatter = (params) => {
    return params.value !== null ? params.value.toLocaleString() + ' g' : null;
};

const onRowEditingStarted = (params) => {
    context.shipping_agent = params.data.shipping_agent;
    context.shipping_agent_service = params.data.shipping_agent_service;
};
// remove empty rows (only null) on stop editing
const onRowEditingStopped = async (params) => {
    if (rowIsEmpty(params.data)) {
        gridApi.value.applyTransaction({
            remove: [params.data],
        });
        lastRowNode.value = null;
    }
};
const onRowValueChanged = (params) => {
    if (typeof params.data.id === 'number') {
        createRow(params);
    } else {
        updateRow(params);
    }
};
const onClickOutside = (e) => {
    if (
        ignoredElements.some((el) => {
            let element = el.value.$el ?? el.value;
            return element.contains(e.target);
        }) ||
        !document.documentElement.contains(e.target)
    ) {
        return;
    }

    gridApi.value.stopEditing();
};

const rows = ref(null);
// watch post effect because changing shipping_agent_matrix triggered
// multiple rerenders of ag grid. Second rerender delayed until column rerender
// is finished (seems like ag-grid bug)
watchPostEffect(async () => {
    rows.value = page.props.shipping_agent_matrix.map((row) => {
        row = empty_string_to_null(row);
        row.id = getDataId(row);
        return row;
    });
});

const newRow = async () => {
    await nextTick();
    let rowNode = lastRowNode.value;

    if (!(lastRowNode.value && rowIsEmpty(lastRowNode.value.data))) {
        const newItem = {
            id: Date.now(),
            customer_number: context.selectedCustomer,
            country: null,
            servicelevel: null,
            weight_from: null,
            weight_to: null,
            packaging: null,
            shipping_agent: null,
            shipping_agent_service: null,
            department: null,
        };

        rowNode = gridApi.value.applyTransaction({
            add: [newItem],
        }).add[0];
        lastRowNode.value = rowNode;
    }

    gridApi.value.ensureNodeVisible(rowNode, 'bottom');
    await nextTick();
    gridApi.value.startEditingCell({
        rowIndex: rowNode.rowIndex,
        colKey: 'customer_number',
    });
};
const createRow = async (params) => {
    try {
        params.node.setData({
            ...params.data,
            saving: true,
        });

        const response = await axios.post(
            route('api.maillog.shipping-agent-matrix.store'),
            params.data,
        );

        params.node.setData({
            id: getDataId(response.data.data),
            ...response.data.data,
            saving: false,
        });
    } catch (e) {
        if (isValidationError(e)) {
            params.node.setData({
                ...params.data,
                error: e.response.data.message,
                saving: false,
            });

            refreshActionCell(params.node);
        }
    }
};
const updateRow = async (params) => {
    try {
        params.node.setData({
            ...params.data,
            saving: true,
        });

        const response = await axios.put(
            route('api.maillog.shipping-agent-matrix.update', params.data.id),
            params.data,
        );

        const data = empty_string_to_null(response.data.data);

        params.node.setData({
            id: getDataId(data),
            ...data,
            saving: false,
        });
    } catch (e) {
        if (isValidationError(e)) {
            params.node.setData({
                ...params.data,
                error: e.response.data.message,
                saving: false,
            });

            refreshActionCell(params.node);
        }
    }
};
const deleteRow = async (params) => {
    try {
        if (typeof params.data.id !== 'number') {
            params.node.setData({
                ...params.data,
                deleting: true,
            });

            await axios.delete(
                route(
                    'api.maillog.shipping-agent-matrix.destroy',
                    params.data.id,
                ),
            );

            params.api.applyTransaction({
                remove: [params.node],
            });
        }
    } catch (e) {
        // nothing to do
    }
};

const refreshActionCell = (node) => {
    gridApi.value.refreshCells({
        force: true,
        columns: ['actions'],
        rowNodes: [node],
    });
};

context.selectedCustomer = searchParams?.['filter[customer_number]'] ?? null;
watch(
    () => context.selectedCustomer,
    (value) => {
        router.visit(
            route('shipping-agent-matrix.index', {
                filter: { customer_number: value },
            }),
            { only: ['shipping_agent_matrix'], preserveState: true },
        );
    },
);

const visiting = ref(false);
onUnmounted(
    router.on('start', () => {
        visiting.value = true;
    }),
);
onUnmounted(
    router.on('finish', () => {
        visiting.value = false;
    }),
);
</script>

<template>
    <div id="menus" ref="menus"></div>
    <ag-table-card :loading="!stateApplied">
        <template #actions>
            <CustomerSelect
                v-model="context.selectedCustomer"
                :label="$t('Customer')"
                hide-details
                clearable
                density="compact"
            ></CustomerSelect>
            <v-spacer></v-spacer>
            <v-btn
                ref="newRowButton"
                :disabled="visiting"
                size="small"
                color="black"
                :prepend-icon="mdiPlus"
                @click="newRow"
                >{{ $t('New Row') }}
            </v-btn>
        </template>
        <ag-grid-vue
            v-click-outside="onClickOutside"
            class="ag-theme-material tw-h-full"
            :row-data="rows"
            :column-types="columnTypes"
            :default-col-def="defaultColDef"
            :column-defs="columnDefs"
            edit-type="fullRow"
            :context="context"
            :get-row-id="getRowId"
            @grid-ready="onGridReady"
            @row-editing-started="onRowEditingStarted"
            @row-editing-stopped="onRowEditingStopped"
            @row-value-changed="onRowValueChanged"
            v-on="columnStateEvents"
        >
        </ag-grid-vue>
    </ag-table-card>
</template>

<style scoped>
:deep(.ag-row-editing .ag-cell-inline-editing) {
    border-left: 0 !important;
    border-right: 0 !important;
    box-shadow: 0 0 0 0 !important;
}

:deep(.v-field) {
    padding-bottom: 4px !important;
}
</style>
