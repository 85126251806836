<script setup>
import { computed, inject, ref } from 'vue';
import colors from 'tailwindcss/colors';
import AppBarActionsTeleport from '@/Components/AppBarActionsTeleport.vue';
import PrinterSelect from '@/Components/PrinterSelect.vue';

const flash = inject('flash');

const props = defineProps({
    packProcess: {
        type: Object,
        required: true,
    },
    printers: {
        type: Array,
        required: true,
    },
    readonly: {
        type: Boolean,
        default: false,
    },
    errors: {
        type: Object,
        default: () => ({}),
    },
});

const printer = defineModel({
    type: [String, null],
    required: true,
});

const addLabel = () => {
    console.log(
        'Add label for shipment',
        props.packProcess.external_shipment_id,
    );
};

const reprintingLabels = ref(false);
const reprintLabels = async () => {
    if (reprintingLabels.value) {
        return;
    }

    reprintingLabels.value = true;
    try {
        await axios.post(
            route('api.pack.label.print', {
                pack_process: props.packProcess.id,
            }),
            {
                printer_name: printer.value,
            },
        );
    } catch (e) {
        if (e.response) {
            if (e.response.status >= 500) {
                flash(e.response.data.message, 'danger');
            } else {
                restErrors.value = e.response.data.errors;
            }
        } else {
            flash(e.message, 'danger');
        }
    } finally {
        reprintingLabels.value = false;
    }
};

const restErrors = ref([]);
const allErrors = computed(() => {
    return Object.assign({}, props.errors, restErrors.value);
});
</script>

<template>
    <v-expansion-panel
        :readonly="readonly"
        :bg-color="readonly ? colors.gray[100] : 'surface'"
    >
        <v-expansion-panel-title>
            <span
                class="tw-z-50 tw-text-xl tw-font-semibold tw-uppercase"
                :class="{ 'tw-text-gray-300': readonly }"
            >
                {{ $t('Shipment Management') }}
            </span>
            <v-spacer />
            <AppBarActionsTeleport>
                <PrinterSelect
                    v-model="printer"
                    :error-messages="allErrors.printer_name"
                    density="compact"
                    class="tw-pointer-events-auto !tw-mb-0 tw-mr-4 tw-w-48"
                    :items="printers"
                    @click.stop
                >
                </PrinterSelect>
            </AppBarActionsTeleport>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
            <v-row
                v-if="
                    packProcess.shipment !== null &&
                    packProcess.shipment.shipment_type !== 'UNSUPPORTED'
                "
            >
                <v-col cols="12" md="6">
                    <table>
                        <tr>
                            <th class="tw-px-2 tw-py-1 tw-text-left">
                                {{ $t('Shipment Number') }}
                            </th>
                            <td class="tw-px-2 tw-py-1 tw-text-right">
                                {{ packProcess.shipment.shipment_id }}
                            </td>
                        </tr>
                        <tr
                            v-if="
                                packProcess.shipment.shipment_type !==
                                'SCHENKER'
                            "
                        >
                            <th class="tw-px-2 tw-py-1 tw-text-left">
                                {{ $t('Labels') }}
                            </th>
                            <td class="tw-px-2 tw-py-1 tw-text-right">
                                {{ packProcess.colli.length }}
                            </td>
                        </tr>
                        <tr>
                            <th class="tw-px-2 tw-py-1 tw-text-left">
                                {{ $t('Shipment Type') }}
                            </th>
                            <td class="tw-px-2 tw-py-1 tw-text-right">
                                {{ packProcess.shipment.shipment_type }}
                            </td>
                        </tr>
                    </table>
                </v-col>
                <v-col cols="12" md="6">
                    <div class="tw-flex tw-h-full tw-items-center">
                        <v-spacer></v-spacer>
                        <div class="tw-flex tw-flex-col tw-justify-center">
                            <v-btn
                                color="info"
                                class="tw-mb-2"
                                :loading="reprintingLabels"
                                @click="reprintLabels"
                                >{{ $t('Reprint Labels') }}
                            </v-btn>
                            <v-btn class="tw-mr-2" disabled @click="addLabel">{{
                                $t('Add Label')
                            }}</v-btn>
                        </div>
                    </div>
                </v-col>
            </v-row>
            <v-row v-else>
                <div
                    class="tw-flex tw-h-32 tw-w-full tw-items-center tw-justify-center"
                >
                    <span class="tw-text-lg tw-font-light tw-text-gray-500">
                        {{ $t('No shipment attached.') }}
                    </span>
                </div>
            </v-row>
        </v-expansion-panel-text>
    </v-expansion-panel>
</template>

<style scoped></style>
