import { useAxios } from '@vueuse/integrations/useAxios';

export function useOrderStats() {
    return useAxios(route('api.dashboard.order-stats'), { method: 'get' });
}

export function useStockStats() {
    return useAxios(route('api.dashboard.stock-stats'), { method: 'get' });
}

export function useArticleStats() {
    return useAxios(route('api.dashboard.article-stats'), { method: 'get' });
}
