<script setup>
import ItemSelect from '@/Components/ItemSelect.vue';

const value = defineModel({
    type: [String, null],
    required: true,
});
</script>

<template>
    <ItemSelect
        v-model="value"
        bg-color="surface"
        density="compact"
        variant="plain"
        hide-details="auto"
        single-line
        class="!tw-mb-0"
    ></ItemSelect>
</template>

<style scoped>
::v-deep(.v-field .v-field__input),
::v-deep(.v-field__append-inner) {
    padding-top: 0;
}
</style>
