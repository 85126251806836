<script setup>
import { useVModel, watchDebounced } from '@vueuse/core';
import { computed, ref, watch } from 'vue';
import { trans as $t } from 'laravel-vue-i18n';
import { mdiSetSplit, mdiTrashCan } from '@mdi/js';
import rules from '@/lib/utilities/rules';
import { usePage } from '@inertiajs/vue3';
import AutocompleteField from '@/Components/AutocompleteField.vue';

const props = defineProps({
    modelValue: { type: Object, required: true },
    customerNumber: { type: String, required: true },
    index: { type: Number, required: true },
    withBatch: { type: Boolean, required: true },
    manualBatch: { type: Boolean, default: false },
    showAllItems: { type: Boolean, default: false },
});
const emit = defineEmits(['update:modelValue', 'delete']);
const page = usePage();

const rowErrors = ref(
    typeof props.modelValue.id === 'string'
        ? []
        : page.props.errors['lines.' + props.index + '.item_number'],
);
watch(
    () => page.props.errors,
    () => {
        rowErrors.value =
            page.props.errors['lines.' + props.index + '.item_number'];
    },
);

const line = useVModel(props, 'modelValue', emit);
const itemUrl = computed(() =>
    route('api.maillog.item.index', {
        filter: { customer_number: props.customerNumber },
    }),
);
const loading = ref(false);

const selected = ref(line.value.item ?? null);
const search = ref('');
const items = ref(line.value.item ? [line.value.item] : []);
const units = computed(() =>
    line.value.item && line.value.item.units_of_measure
        ? line.value.item.units_of_measure.map((unit) => unit.name)
        : [],
);

const querySelection = async (search) => {
    loading.value = true;
    const response = await axios.get(itemUrl.value, {
        params: {
            filter: {
                search: search,
            },
        },
    });
    items.value = response.data;
    loading.value = false;
};

watchDebounced(
    search,
    (val) => {
        if (
            val &&
            val.length >= 3 &&
            (!selected.value || val !== selected.value.number)
        )
            querySelection(val);
    },
    { debounce: 300 },
);

const quantityField = ref(null);
const enter = ref(false);
const onEnter = () => {
    enter.value = true;
};
const switchFocus = function (force = false) {
    if (enter.value || force === true) {
        quantityField.value.focus();
        enter.value = false;
    }
};

watch(selected, (val, oldValue) => {
    if (val) {
        line.value.sku = val.sku;
        line.value.item_number = val.number;
        line.value.batch = '';
        line.value.unit = val.base_unit_of_measure;
        line.value.item = val;
    } else {
        selected.value = oldValue;
    }
});

const itemSelect = ref(null);
const calculateItemStock = (item) => {
    return item.total_stock - item.total_reserved;
};
const isDisabled = (item) => {
    if (!item.raw) return true;

    if (props.showAllItems) return false;

    const noStock =
        !page.props.auth.canOrderMoreThanStock &&
        calculateItemStock(item.raw) <= 0 &&
        !item.raw.components_exist;
    return noStock || item.raw.blocked || item.raw.inactive;
};

/*const searchBatch = ref('');
const itemsBatch = ref([]);

const loadingBatch = ref(false);
const queryBatch = async function (search) {
    loadingBatch.value = true;
    const response = await axios.get(
        route('api.maillog.item.batch.index', {
            item: selected.value.number,
            filter: { number: search },
        }),
    );
    itemsBatch.value = response.data;
    loadingBatch.value = false;
};
watchDebounced(
    searchBatch,
    (val) => {
        val &&
            (!line.value.batch || val !== line.value.batch) &&
            queryBatch(val);
    },
    { debounce: 500 },
);

watch(
    () => line.value.batch,
    (val) => {
        if (val) {
            line.value.batch = val;
        }
    },
);*/
</script>

<template>
    <tr>
        <td>
            <AutocompleteField
                ref="itemSelect"
                v-model="selected"
                v-model:search="search"
                :error-messages="rowErrors"
                center-affix
                bg-color="surface"
                density="compact"
                single-line
                hide-details="auto"
                variant="underlined"
                return-object
                :items="items"
                :item-title="() => ''"
                :loading="loading"
                no-filter
                :rules="[rules.required($t('Artikel'))]"
                :no-data-text="
                    $t(
                        'Please enter at least 3 characters to search for Articles.',
                    )
                "
                @focus="switchFocus"
            >
                <template #selection="{ item }">
                    <div
                        class="tw-overflow-hidden tw-overflow-ellipsis tw-whitespace-nowrap"
                    >
                        {{
                            item.raw.number +
                            ' ' +
                            item.raw.description +
                            (item.raw.description2
                                ? ' (' + item.raw.description2 + ')'
                                : '')
                        }}
                    </div>
                </template>
                <template #item="{ props: itemProps, item }">
                    <v-list-item
                        v-if="item.raw"
                        v-bind="itemProps"
                        :title="
                            item.raw.number +
                            ' ' +
                            item.raw.description +
                            (item.raw.description2
                                ? ' (' + item.raw.description2 + ')'
                                : '')
                        "
                        :disabled="isDisabled(item)"
                        @keydown.enter="onEnter"
                        @click="switchFocus(true)"
                    >
                        <v-list-item-subtitle
                            class="tw-flex tw-justify-between"
                        >
                            <span>{{ item.raw.sku }}</span
                            ><span v-if="!item.raw.components_exist"
                                >{{ $t('Stock') }}:
                                {{ calculateItemStock(item.raw) }}</span
                            >
                            <v-icon v-else :icon="mdiSetSplit"></v-icon>
                        </v-list-item-subtitle>
                    </v-list-item>
                </template>
            </AutocompleteField>
        </td>
        <td class="tw-max-w-[34px]">
            <v-text-field
                ref="quantityField"
                v-model="line.quantity"
                density="compact"
                single-line
                hide-details
                variant="plain"
                type="number"
                :placeholder="$t('Quantity')"
            ></v-text-field>
        </td>
        <td v-if="withBatch" class="tw-min-w-36 tw-max-w-[140px]">
            <!--            <v-autocomplete
                            v-model="line.batch"
                            v-model:search="searchBatch"
                            bg-color="surface"
                            density="compact"
                            single-line
                            hide-details
                            variant="plain"
                            :items="itemsBatch"
                            :loading="loadingBatch"
                            :disabled="!selected || !selected.has_batches"
                            item-title="number"
                            item-value="number"
                        />-->
            <v-text-field
                v-if="manualBatch"
                v-model="line.batch"
                :disabled="!line.item?.has_batches"
                bg-color="surface"
                density="compact"
                :single-line="true"
                hide-details
                variant="plain"
                type="text"
                :placeholder="$t('Batch')"
            >
            </v-text-field>
        </td>
        <td class="tw-max-w-[130px]">
            <v-select
                v-model="line.unit"
                density="compact"
                single-line
                hide-details
                variant="underlined"
                :items="units"
                :placeholder="$t('Unit')"
            ></v-select>
        </td>
        <td
            class="tw-w-[15px] tw-overflow-hidden tw-text-center tw-text-red-500"
        >
            <v-icon :icon="mdiTrashCan" @click="emit('delete')"></v-icon>
        </td>
    </tr>
</template>
