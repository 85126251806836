<script setup>
import { router, usePage } from '@inertiajs/vue3';
import { computed, onUnmounted, ref, watch } from 'vue';
import { useLayout } from 'vuetify';
import { mdiAlertCircle, mdiCheckCircle, mdiInformationOutline } from '@mdi/js';

const show = ref(false);
const page = usePage();

const style = computed(
    () => page.props.jetstream.flash?.bannerStyle || 'success',
);
const message = computed(() => page.props.jetstream.flash?.banner || '');

const bannerStyle = ref('success');
const bannerMessage = ref('');

onUnmounted(
    router.on('success', () => {
        show.value = message.value !== null && message.value !== '';
    }),
);

watch(
    message,
    async () => {
        if (message.value !== '') {
            show.value = true;
            bannerStyle.value = style.value;
            bannerMessage.value = message.value;
        }
    },
    { immediate: true },
);

const { mainRect } = useLayout();
const bannerTop = computed(() => {
    return mainRect.value.top;
});

const icon = computed(() => {
    if (bannerStyle.value === 'success') {
        return mdiCheckCircle;
    } else if (bannerStyle.value === 'danger') {
        return mdiAlertCircle;
    } else if (bannerStyle.value === 'info') {
        return mdiInformationOutline;
    } else {
        return null;
    }
});

let timeoutId = null;
const flash = function (message, style, timeout = null) {
    if (timeoutId) {
        clearTimeout(timeoutId);
    }
    if (Number.isInteger(timeout)) {
        timeoutId = setTimeout(() => {
            show.value = false;
            timeoutId = null;
        }, timeout);
    }

    show.value = true;
    bannerStyle.value = style;
    bannerMessage.value = message;
};

const close = () => (show.value = false);

defineExpose({
    flash,
    close,
});
</script>

<template>
    <v-alert
        v-model="show"
        position="sticky"
        variant="tonal"
        border
        :style="{ top: bannerTop + 'px', 'z-index': 1005 }"
        :text="bannerMessage"
        :color="bannerStyle"
        :icon="icon"
        closable
    />
</template>

<style scoped></style>
