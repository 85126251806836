<script setup>
import { Head, Link, useForm } from '@inertiajs/vue3';

import backgroundImage from '@/../image/maillog_background.jpg';

import TextField from '@/Components/TextField.vue';
import AuthenticationCard from '@/Components/AuthenticationCard.vue';
import { computed } from 'vue';

defineProps({
    canResetPassword: Boolean,
    status: { type: String, default: null },
});

const form = useForm({
    username: '',
    password: '',
    remember: false,
});

const submit = () => {
    form.transform((data) => ({
        ...data,
        remember: form.remember ? 'on' : '',
    })).post(route('login'), {
        onFinish: () => {
            form.reset('password');
        },
    });
};

const backgroundStyle = computed(() => ({
    backgroundImage: "url('" + backgroundImage + "')",
    backgroundPosition: 'center',
    backgroundSize: 'cover',
}));
</script>

<script>
export default { layout: null };
</script>

<template>
    <Head :title="$t('Log in')" />
    <div class="tw-h-screen tw-w-screen" :style="backgroundStyle">
        <AuthenticationCard>
            <v-form @submit.prevent="submit">
                <v-row>
                    <v-col cols="12" md="10">
                        <div
                            v-if="status"
                            class="text-success tw-mb-2 tw-text-sm tw-font-medium"
                        >
                            {{ status }}
                        </div>
                        <TextField
                            v-model="form.username"
                            :label="$t('Email') + '/' + $t('Username')"
                            variant="underlined"
                            :bg-color="null"
                            type="text"
                            required
                            autofocus
                            :error-messages="form.errors.username"
                        />
                        <TextField
                            v-model="form.password"
                            :label="$t('Password')"
                            variant="underlined"
                            :bg-color="null"
                            type="password"
                            required
                            :error-messages="form.errors.password"
                        />
                    </v-col>
                </v-row>

                <v-checkbox
                    v-model="form.remember"
                    color="primary"
                    :label="$t('Remember me')"
                    hide-details
                ></v-checkbox>
                <div class="tw-flex tw-items-center tw-justify-end">
                    <Link
                        v-if="canResetPassword"
                        :href="route('password.request')"
                        class="tw-text-sm tw-text-gray-600 tw-underline hover:tw-text-gray-900"
                    >
                        {{ $t('Forgot your password?') }}
                    </Link>
                    <v-btn
                        type="submit"
                        class="tw-ml-4"
                        color="primary"
                        width="100px"
                        :loading="form.processing"
                        >{{ $t('Login') }}</v-btn
                    >
                </div>
            </v-form>
        </AuthenticationCard>
    </div>
</template>
