<script setup>
import { computed, inject, ref, watch } from 'vue';
import TextField from '@/Components/TextField.vue';
import { Head, useForm, usePage } from '@inertiajs/vue3';
import useBarcodeScanner from '@/lib/composables/barcode-scanner.js';

const page = usePage();

const { onBarcodeScanned } = useBarcodeScanner({
    pauseEventListener: inject('pauseCaptureKeydown'),
});
const deliveryNote = computed(() => {
    return page.props.delivery_note;
});
onBarcodeScanned((barcode) => {
    form.delivery_note = barcode;
    submitDeliveryNoteForm();
});
const deliveryNoteNumberInput = ref(null);

const limitMaxLength = (e) => {
    if (e.target.value.length > 13) {
        form.delivery_note = e.target.value.slice(0, 13);
    }
};

const form = useForm({
    delivery_note: deliveryNote.value?.delivery_note_number ?? null,
});
form.errors = page.props.errorBags.pack_process_form ?? {};

const submitDeliveryNoteForm = () => {
    if (!form.processing) {
        form.post(route('pack.store'), {
            errorBag: 'pack_process_form',
            onError() {
                form.reset();
                deliveryNoteNumberInput.value.focus();
            },
        });
    }
};
watch(
    () => form.delivery_note,
    (value) => {
        if (value?.length === 13) {
            form.delivery_note = value;
            submitDeliveryNoteForm();
        }
    },
);
</script>

<template>
    <v-container class="tw-h-full">
        <Head :title="page.props.title"></Head>
        <v-row>
            <v-col>
                <v-card class="tw-p-2">
                    <TextField
                        ref="deliveryNoteNumberInput"
                        v-model="form.delivery_note"
                        autofocus
                        clarable
                        :readonly="!!deliveryNote"
                        :label="`${$t('Delivery Note')} / EAN`"
                        :hint="$t('pack.delivery_note_input_hint')"
                        persistent-hint
                        single-line
                        :error-messages="form.errors.delivery_note"
                        @input="limitMaxLength"
                        @keydown.stop
                    >
                    </TextField>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<style scoped></style>
