<script setup>
import { useForm } from '@inertiajs/vue3';
import { computed, ref } from 'vue';
import FormSection from '@/Components/FormSection.vue';
import TextField from '@/Components/TextField.vue';
import ConfirmationModal from '@/Components/ConfirmationModal.vue';
import CustomerCombobox from '@/Components/CustomerCombobox.vue';
import SelectField from '@/Components/SelectField.vue';

const props = defineProps({
    user: {
        type: Object,
        required: true,
    },
    updateRoute: {
        type: String,
        default: route('user-profile-information.update'),
    },
    deletePhotoRoute: {
        type: String,
        default: route('current-user-photo.destroy'),
    },
});

const form = useForm({
    _method: 'PUT',
    username: props.user.username,
    email: props.user.email,
    first_name: props.user.first_name,
    last_name: props.user.last_name,
    gender: props.user.gender,
    customers: props.user.maillog_customers?.map((customer) => customer.number),
    roles: props.user.roles?.map((role) => role.name),
    photo: null,
});

// const verificationLinkSent = ref(null);
// const photoPreview = ref(null);
const photoInput = ref(null);

const updateProfileInformation = () => {
    if (photoInput.value) {
        form.photo = photoInput.value.files[0];
    }

    form.post(props.updateRoute, {
        errorBag: 'updateProfileInformation',
        preserveScroll: true,
        onSuccess: () => clearPhotoFileInput(),
        onFinish: () => (updatingProfileInformation.value = false),
    });
};

const updatingProfileInformation = ref(false);
const submitProfileInformation = () => {
    if (deletedCustomers.value && deletedCustomers.value.length > 0) {
        updatingProfileInformation.value = true;
    } else {
        updateProfileInformation();
    }
};

const cancel = () => {
    updatingProfileInformation.value = false;
    customerSelect.value.reset();
};

// const sendEmailVerification = () => {
//     verificationLinkSent.value = true;
// };
//
// const selectNewPhoto = () => {
//     photoInput.value.click();
// };
//
// const updatePhotoPreview = () => {
//     const photo = photoInput.value.files[0];
//
//     if (!photo) return;
//
//     const reader = new FileReader();
//
//     reader.onload = (e) => {
//         photoPreview.value = e.target.result;
//     };
//
//     reader.readAsDataURL(photo);
// };
//
// const deletePhoto = () => {
//     router.delete(props.deletePhotoRoute, {
//         preserveScroll: true,
//         onSuccess: () => {
//             photoPreview.value = null;
//             clearPhotoFileInput();
//         },
//     });
// };

const clearPhotoFileInput = () => {
    if (photoInput.value?.value) {
        photoInput.value.value = null;
    }
};

const customerSelect = ref(null);
const deletedCustomers = computed(() =>
    props.user.maillog_customers
        .map((item) => item.number)
        .filter((value) => {
            return (
                form.customers.find((selected) => selected === value) ===
                undefined
            );
        }),
);

const roleErrorMessages = computed(() => {
    return Object.keys(form.errors).filter((field) => /roles/.test(field))
        .length > 0
        ? ['Ausgewählte Rollen sind ungültig.']
        : [];
});

const customerErrorMessages = computed(() => {
    return Object.keys(form.errors).filter((field) => /customers/.test(field))
        .length > 0
        ? ['Ausgewählte Kunden sind ungültig.']
        : [];
});
</script>

<template>
    <FormSection @submitted="submitProfileInformation">
        <template #title>
            <slot name="form-title">{{ $t('Profile Information') }}</slot>
        </template>
        <template #description>
            <slot name="form-description">{{
                $t(
                    "Update your account's profile information and email address.",
                )
            }}</slot>
        </template>
        <template #form>
            <ConfirmationModal
                :show="updatingProfileInformation"
                @close="updatingProfileInformation = false"
            >
                <template #title>
                    <slot name="modal-title">{{
                        $t('Save Profile Information')
                    }}</slot>
                </template>

                <template #content>
                    <slot name="modal-content">
                        {{
                            $t(
                                'Are you sure you would like to save your profile information? Tokens for removed customers will be deleted.',
                            )
                        }}
                    </slot>
                </template>

                <template #footer>
                    <v-btn
                        :loading="form.processing"
                        color="primary"
                        @click="updateProfileInformation"
                        >{{ $t('Save') }}</v-btn
                    >
                    <v-btn color="danger" @click="cancel">{{
                        $t('Cancel')
                    }}</v-btn>
                </template>
            </ConfirmationModal>

            <TextField
                v-model="form.username"
                :label="$t('Username')"
                :error-messages="form.errors.username"
            />
            <TextField
                v-model="form.email"
                :label="$t('Email')"
                :error-messages="form.errors.email"
                type="email"
            ></TextField>
            <TextField
                v-model="form.first_name"
                :label="$t('First Name')"
                :error-messages="form.errors.first_name"
            ></TextField>
            <TextField
                v-model="form.last_name"
                :label="$t('Last Name')"
                :error-messages="form.errors.last_name"
            ></TextField>
            <SelectField
                v-model="form.gender"
                :label="$t('Gender')"
                :items="$page.props.genders"
                item-title="name"
                :error-messages="form.errors.gender"
            />
            <CustomerCombobox
                v-if="$page.props.auth.canUpdateCustomers"
                ref="customerSelect"
                v-model="form.customers"
                :selected="props.user.maillog_customers"
                :error-messages="customerErrorMessages"
            ></CustomerCombobox>
            <SelectField
                v-if="$page.props.auth.canUpdateRoles"
                v-model="form.roles"
                :error-messages="roleErrorMessages"
                :label="$t('Roles')"
                multiple
                item-title="name"
                item-value="name"
                chips
                :items="$page.props.roles"
            />
        </template>
        <template #actions>
            <slot name="additional-actions" :form="form"></slot>
            <v-btn
                :loading="form.processing"
                :disabled="!form.isDirty"
                variant="elevated"
                type="submit"
                color="primary"
                >{{ $t('Save') }}
            </v-btn>
        </template>
    </FormSection>
</template>
