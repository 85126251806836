<script setup>
import { computed, ref, watch } from 'vue';
import { useForm } from '@inertiajs/vue3';
import ActionMessage from '@/Components/ActionMessage.vue';
import ActionSection from '@/Components/ActionSection.vue';
import ConfirmationModal from '@/Components/ConfirmationModal.vue';
import DialogModal from '@/Components/DialogModal.vue';
import FormSection from '@/Components/FormSection.vue';
import TextField from '@/Components/TextField.vue';
import SectionBorder from '@/Components/SectionBorder.vue';

const props = defineProps({
    availablePermissions: { type: Array, default: () => [] },
    defaultPermissions: { type: Array, default: () => [] },
    user: { type: Object, required: true },
    storeRoute: { type: String, required: true },
    tokens: { type: Array, default: () => [] },
});

const displayingToken = ref(false);
const managingPermissionsFor = ref(null);
const apiTokenBeingDeleted = ref(null);

const firstCustomer = computed(() => {
    return props.user.maillog_customers?.at(0);
});

watch(
    () => props.user.maillog_customers.length,
    () => {
        createApiTokenForm.customer_number = firstCustomer.value?.number;
    },
);

const shownTokens = computed(() =>
    props.tokens.filter(
        (token) =>
            token.maillog_customer_number ===
            createApiTokenForm.customer_number,
    ),
);

const createApiTokenForm = useForm({
    name: '',
    permissions: props.defaultPermissions,
    customer_number: firstCustomer.value?.number,
});

const updateApiTokenForm = useForm({
    permissions: [],
});

const deleteApiTokenForm = useForm({});

const createApiToken = () => {
    createApiTokenForm.post(props.storeRoute, {
        preserveScroll: true,
        onSuccess: () => {
            displayingToken.value = true;
            createApiTokenForm.reset('name');
        },
    });
};

const manageApiTokenPermissions = (token) => {
    updateApiTokenForm.permissions = token.abilities;
    managingPermissionsFor.value = token;
};

// const updateApiToken = () => {
//     updateApiTokenForm.put(
//         route('api-tokens.update', managingPermissionsFor.value),
//         {
//             preserveScroll: true,
//             preserveState: true,
//             onSuccess: () => (managingPermissionsFor.value = null),
//         }
//     );
// };

const confirmApiTokenDeletion = (token) => {
    apiTokenBeingDeleted.value = token;
};

const deleteApiToken = () => {
    deleteApiTokenForm.delete(
        route('api-tokens.destroy', apiTokenBeingDeleted.value),
        {
            preserveScroll: true,
            preserveState: true,
            onSuccess: () => (apiTokenBeingDeleted.value = null),
        },
    );
};
</script>

<template>
    <div v-if="firstCustomer">
        <!-- Generate API Token -->
        <FormSection @submitted="createApiToken">
            <template #title>
                {{ $t('Create API Token') }}
            </template>

            <template #description>
                {{
                    $t(
                        'API tokens allow third-party services to authenticate with our application on your behalf.',
                    )
                }}
            </template>

            <template #form>
                <!-- Debitor Number -->
                <v-select
                    v-model="createApiTokenForm.customer_number"
                    class="tw-mb-2"
                    :label="$t('Customer')"
                    color="primary"
                    :items="user.maillog_customers"
                    item-title="number"
                    item-value="number"
                    :error-messages="createApiTokenForm.errors.customer"
                />
                <!-- Token Name -->
                <TextField
                    v-model="createApiTokenForm.name"
                    :label="$t('Name')"
                    autofocus
                    :error-messages="createApiTokenForm.errors.name"
                />

                <!-- Token Permissions -->
                <!-- TODO: Vuetify -->
                <!-- <div v-if="availablePermissions.length > 0" class="tw-col-span-6">
                                    <InputLabel for="permissions" value="Permissions"/>

                                    <div class="tw-mt-2 tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-4">
                                        <div v-for="permission in availablePermissions" :key="permission">
                                            <label class="tw-flex tw-items-center">
                                                <Checkbox v-model:checked="createApiTokenForm.permissions" :value="permission"/>
                                                <span class="tw-ml-2 tw-text-sm tw-text-gray-600">{{ permission }}</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>-->
            </template>

            <template #actions>
                <v-btn :href="route('docs.v1')">{{
                    $t('Documentation')
                }}</v-btn>
                <ActionMessage
                    :on="createApiTokenForm.recentlySuccessful"
                    class="tw-mr-3"
                >
                    {{ $t('Created.') }}
                </ActionMessage>

                <v-btn
                    color="primary"
                    type="submit"
                    variant="elevated"
                    :loading="createApiTokenForm.processing"
                >
                    {{ $t('Create') }}
                </v-btn>
            </template>
        </FormSection>

        <div v-if="shownTokens.length > 0">
            <SectionBorder />

            <!-- Manage API Tokens -->
            <div class="tw-mt-10 sm:tw-mt-0">
                <ActionSection>
                    <template #title>
                        {{ $t('Manage API Tokens') }}
                    </template>

                    <template #description>
                        {{
                            $t(
                                'You may delete any of your existing tokens if they are no longer needed.',
                            )
                        }}
                    </template>

                    <!-- API Token List -->
                    <template #content>
                        <div class="tw-space-y-6">
                            <div
                                v-for="token in shownTokens"
                                :key="token.id"
                                class="tw-flex tw-items-center tw-justify-between"
                            >
                                <div class="tw-break-all">
                                    {{ token.name }}
                                </div>

                                <div class="tw-flex tw-items-center tw-ml-2">
                                    <div
                                        v-if="token.last_used_ago"
                                        class="tw-text-sm tw-text-gray-400"
                                    >
                                        {{
                                            $t('Last used :last_used_ago', {
                                                last_used_ago:
                                                    token.last_used_ago,
                                            })
                                        }}
                                    </div>

                                    <button
                                        v-if="availablePermissions.length > 0"
                                        class="tw-cursor-pointer tw-ml-6 tw-text-sm tw-text-gray-400 tw-underline"
                                        @click="
                                            manageApiTokenPermissions(token)
                                        "
                                    >
                                        {{ $t('Permissions') }}
                                    </button>

                                    <button
                                        class="tw-cursor-pointer tw-ml-6 tw-text-sm tw-text-red-500"
                                        @click="confirmApiTokenDeletion(token)"
                                    >
                                        {{ $t('Delete') }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </template>
                </ActionSection>
            </div>
        </div>

        <!-- Token Value Modal -->
        <DialogModal :show="displayingToken" @close="displayingToken = false">
            <template #title>
                {{ $t('API Token') }}
            </template>

            <template #content>
                <div>
                    {{
                        $t(
                            "Please copy your new API token. For your security, it won't be shown again.",
                        )
                    }}
                </div>

                <div
                    v-if="$page.props.jetstream.flash.token"
                    class="tw-mt-4 tw-bg-gray-100 tw-px-4 tw-py-2 tw-rounded tw-font-mono tw-text-sm tw-text-gray-500 tw-break-all"
                >
                    {{ $page.props.jetstream.flash.token }}
                </div>
            </template>

            <template #footer>
                <v-btn variant="elevated" @click="displayingToken = false">
                    {{ $t('Close') }}
                </v-btn>
            </template>
        </DialogModal>

        <!-- API Token Permissions Modal -->
        <!-- TODO: Vuetify -->
        <!-- <DialogModal :show="managingPermissionsFor != null" @close="managingPermissionsFor = null">
                    <template #title>
                        API Token Permissions
                    </template>

                    <template #content>
                        <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-4">
                            <div v-for="permission in availablePermissions" :key="permission">
                                <label class="tw-flex tw-items-center">
                                    <Checkbox v-model:checked="updateApiTokenForm.permissions" :value="permission"/>
                                    <span class="tw-ml-2 tw-text-sm tw-text-gray-600">{{ permission }}</span>
                                </label>
                            </div>
                        </div>
                    </template>

                    <template #footer>
                        <SecondaryButton @click="managingPermissionsFor = null">
                            Cancel
                        </SecondaryButton>

                        <PrimaryButton
                            class="tw-ml-3"
                            :class="{ 'tw-opacity-25': updateApiTokenForm.processing }"
                            :disabled="updateApiTokenForm.processing"
                            @click="updateApiToken"
                        >
                            Save
                        </PrimaryButton>
                    </template>
                </DialogModal>-->

        <!-- Delete Token Confirmation Modal -->
        <ConfirmationModal
            :show="apiTokenBeingDeleted != null"
            @close="apiTokenBeingDeleted = null"
        >
            <template #title>
                {{ $t('Delete API Token') }}
            </template>

            <template #content>
                {{
                    $t('Are you sure you would like to delete this API token?')
                }}
            </template>

            <template #footer>
                <v-btn
                    variant="elevated"
                    color="surface"
                    class="tw-mr-2"
                    @click="apiTokenBeingDeleted = null"
                    >{{ $t('Cancel') }}
                </v-btn>
                <v-btn
                    variant="elevated"
                    color="danger"
                    :loading="deleteApiTokenForm.processing"
                    @click="deleteApiToken"
                    >{{ $t('Delete') }}
                </v-btn>
            </template>
        </ConfirmationModal>
    </div>
    <div v-else>
        <ActionSection>
            <template #title>
                {{ $t('API Tokens') }}
            </template>
            <template #description>
                {{
                    $t(
                        'API tokens allow third-party services to authenticate with our application on your behalf.',
                    )
                }}
            </template>
            <template #content>
                <p class="tw-px-2 tw-py-4 tw-font-light tw-text-base">
                    {{ $t('No Customer Numbers Found.') }}
                </p>
            </template>
        </ActionSection>
    </div>
</template>
