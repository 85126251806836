import { AxiosError } from 'axios';

export async function downloadFile(url, params) {
    const response = await axios.get(url, {
        params: params,
        responseType: 'blob',
    });
    const objectUrl = window.URL.createObjectURL(response.data);

    const link = document.createElement('a');

    link.download = response.headers['content-disposition']
        .split('filename=')
        .pop();
    link.href = objectUrl;
    link.click();
}

export function undot(dotNotationObj) {
    const result = {};

    for (const [key, value] of Object.entries(dotNotationObj)) {
        const keys = key.split('.');
        let current = result;

        for (let i = 0; i < keys.length; i++) {
            if (i === keys.length - 1) {
                current[keys[i]] = value;
            } else {
                current[keys[i]] = current[keys[i]] || {};
                current = current[keys[i]];
            }
        }
    }

    return result;
}

export function is_empty_or_null(value) {
    return value === null || value.trim() === '';
}

export function join_names(first, second, seperator = ' ', brackets = false) {
    first = first ?? '';
    second = second ?? '';

    return first.trim() !== '' && second.trim() !== ''
        ? brackets
            ? `${first} (${second})`
            : `${first}${seperator}${second}`
        : `${first}${second}`;
}

export function empty_string_to_null(object) {
    const newObject = { ...object };
    for (const [key, value] of Object.entries(object)) {
        if (typeof value === 'string' && value.trim() === '') {
            newObject[key] = null;
        }
    }

    return newObject;
}

export function isValidationError(error) {
    return (
        error instanceof AxiosError &&
        error.response &&
        error.response.status === 422 &&
        error.response.data &&
        error.response.data.message
    );
}
