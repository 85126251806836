<script setup>
import FormSection from '@/Components/FormSection.vue';
import TextField from '@/Components/TextField.vue';
import { trans as $t } from 'laravel-vue-i18n';
import SelectField from '@/Components/SelectField.vue';
import { useForm, usePage } from '@inertiajs/vue3';
import CustomerPermissionsFields from '@/Pages/Customer/Partials/CustomerPermissionsFields.vue';
import CustomerBoolSettingsFields from '@/Pages/Customer/Partials/CustomerCheckboxSettingsFields.vue';
import { collect } from 'collect.js';
import OaidItemSelect from '@/Pages/Customer/Partials/OaidItemSelect.vue';
import ShippingOptions from '@/Pages/Customer/Partials/ShippingOptions.vue';
import PackagingListsSettings from '@/Pages/Customer/Partials/PackagingListsSettings.vue';

const props = defineProps({
    customer: {
        type: Object,
        required: true,
    },
    serviceLevels: {
        type: Array,
        required: true,
    },
});
const page = usePage();

const getDefaultSettingValue = (type) => {
    switch (type) {
        case 'checkbox':
            return false;
        case 'array':
            return [];
        default:
            return null;
    }
};
const form = useForm({
    ...props.customer,
    settings: {
        ...collect(page.props.settings)
            .mapWithKeys((settingEnum) => [
                settingEnum.value,
                getDefaultSettingValue(settingEnum.type),
            ])
            .all(),
        ...page.props.customer.settings,
    },
});
const submitCustomerData = () => {
    form.patch(route('customer.update', { customer: props.customer.id }));
};
const cancel = () => {
    form.reset();
};
</script>

<template>
    <FormSection class="tw-mt-4" @submitted="submitCustomerData">
        <template #title>{{ $t('Customer Information') }}</template>
        <template #description>
            {{
                $t(
                    'Update editable Customer Information. Fields that are disabled have to be set in Microsoft Dynamics NAV.',
                )
            }}
        </template>

        <template #form>
            <TextField
                v-model="form.number"
                :label="$t('Customer Number')"
                :readonly="true"
                :error-messages="form.errors.number"
            ></TextField>
            <TextField
                v-model="form.name"
                :label="$t('Customer Name')"
                :error-messages="form.errors.name"
            ></TextField>
            <SelectField
                v-model="form.servicelevels"
                multiple
                :label="$t('Service Levels')"
                item-title="description"
                item-value="code"
                chips
                :items="props.serviceLevels"
                :error-messages="form.errors.servicelevels"
            >
            </SelectField>
            <CustomerPermissionsFields
                v-model="form.permissions"
            ></CustomerPermissionsFields>
            <CustomerBoolSettingsFields
                v-model="form.settings"
            ></CustomerBoolSettingsFields>
            <OaidItemSelect
                v-model="form.settings.oaid_items"
                :customer-number="customer.number"
            />
            <ShippingOptions
                v-model="form.settings.has_shipping_options"
                v-model:shipping-agents="
                    form.settings.selectable_shipping_agents
                "
            />
            <PackagingListsSettings v-model="form.settings">
            </PackagingListsSettings>
        </template>

        <template #actions>
            <v-btn
                :loading="form.processing"
                color="success"
                variant="elevated"
                :disabled="!form.isDirty"
                type="submit"
                >{{ $t('Save') }}
            </v-btn>
            <v-btn
                class="tw-ml-2"
                color="danger"
                variant="elevated"
                :disabled="!form.isDirty"
                @click="cancel"
                >{{ $t('Cancel') }}
            </v-btn>
        </template>
    </FormSection>
</template>
