<script setup>
import { AgGridVue } from 'ag-grid-vue3';
import { computed } from 'vue';
import { Collection } from 'collect.js';

const props = defineProps({
    rows: {
        type: Collection,
        required: true,
    },
    locationsById: {
        type: Object,
        required: true,
    },
    itemsById: {
        type: Object,
        required: true,
    },
});

const getItemNumberById = (itemId) => {
    const item = props.itemsById[itemId];
    const sku = item.sku ? `(${item.sku})` : '';
    return `${item.item_number} ${sku}`;
};

const rowData = computed(() =>
    props.rows
        .map((quantity, itemId) => ({
            item_number: getItemNumberById(itemId),
            description: props.itemsById[itemId].description,
            quantities: quantity,
        }))
        .toArray(),
);

const columnDefs = [
    {
        field: 'item_number',
        flex: 1,
    },
    {
        field: 'description',
        flex: 1,
    },
    {
        field: 'quantities',
        cellClass: 'tw-font-black tw-text-right',
    },
];
</script>

<template>
    <ag-grid-vue
        class="ag-theme-material ag-theme-material-dense tw-h-64"
        :header-height="-1"
        :row-data="rowData"
        :column-defs="columnDefs"
    >
    </ag-grid-vue>
</template>

<style scoped></style>
