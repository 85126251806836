<script setup>
import ScannerCheckmark from '@/Components/ScannerCheckmark.vue';
import { join_names } from '../../../lib/helpers.js';
import moment from 'moment';
import { nextTick, ref, watch } from 'vue';
import { watchDebounced } from '@vueuse/core';

const props = defineProps({
    scan: {
        type: [Object, null],
        default: null,
    },
    item: {
        type: [Object, null],
        default: null,
    },
    scanCount: {
        type: Number,
        required: true,
    },
});

const showCheckmark = ref(false);

watch(
    () => props.scanCount,
    (newVal, oldVal) => {
        if (newVal > oldVal) {
            showCheckmark.value = false;
            nextTick(() => (showCheckmark.value = true));
        }
    },
);

watchDebounced(
    showCheckmark,
    (show) => {
        if (show) {
            showCheckmark.value = false;
        }
    },
    { debounce: 1400 },
);
</script>

<template>
    <v-card class="tw-h-[240px] tw-px-4">
        <v-card-title class="tw-mt-2 tw-uppercase"
            >{{ $t('Last Scan') }}
            <span v-if="scan !== null" class="tw-text-sm tw-text-gray-400"
                >{{ $t('Scanned at') }}
                {{
                    moment(scan.created_at).format('DD.MM.YYYY HH:mm:ss')
                }}</span
            >
        </v-card-title>

        <v-card-text class="tw-mt-2">
            <div
                v-if="scan === null"
                class="tw-mt-12 tw-flex tw-items-center tw-justify-center"
            >
                <div class="tw-text-center">
                    <div class="tw-text-3xl tw-font-light">
                        {{ $t('Scan an item...') }}
                    </div>
                </div>
            </div>
            <div v-else class="tw-flex tw-items-center tw-justify-between">
                <div class="tw-text-3xl tw-font-light">
                    <div class="tw-mb-2">
                        <div>{{ item.No_ }}</div>
                        <div>
                            {{
                                join_names(
                                    item.Description,
                                    item['Description 2'],
                                    null,
                                    true,
                                )
                            }}
                        </div>
                    </div>
                    <div class="tw-text-xl tw-font-light">
                        <strong>{{ scan.unit }}</strong>
                        <span v-if="scan && scan.data.batch">
                            - {{ scan.data.batch }}</span
                        >
                    </div>
                </div>
                <ScannerCheckmark
                    style="height: 129px; width: 110px"
                    :show="showCheckmark"
                ></ScannerCheckmark>
            </div>
        </v-card-text>
    </v-card>
</template>

<style scoped></style>
