<script setup>
import { computed } from 'vue';
import { useTheme } from 'vuetify';
import { trans as $t } from 'laravel-vue-i18n';

import { useOrderStats } from '@/lib/services/dashboard-service';
import defaultColumnDefinitions from '@/lib/ag-grid/default-column-definitions';
import DashboardCard from '@/Pages/Dashboard/Widgets/DashboardCard.vue';
import { AgGridVue } from 'ag-grid-vue3';
import { AgChartsVue } from 'ag-charts-vue3';
import { mdiGift } from '@mdi/js';

const { data: orderStats } = useOrderStats();
const theme = useTheme();

const options = computed(() => ({
    autoSize: true,
    data: orderStats.value,
    series: [
        {
            type: 'bar',
            xKey: 'month',
            yKey: 'order_count',
            fill: theme.current.value.colors.primary,
            strokeWidth: 0,
            shadow: { xOffset: 3 },
            highlightStyle: {
                item: {
                    fill: theme.current.value.colors.success,
                    stroke: theme.current.value.colors.success,
                    strokeWidth: 2,
                },
            },
        },
    ],
    axes: [
        {
            type: 'number',
            position: 'left',
            tick: {
                interval:
                    Math.floor(
                        Math.max(
                            ...(orderStats?.value?.map(
                                (s) => s.order_count,
                            ) ?? [0]),
                        ) / 10,
                    ) || 1,
            },
        },
        {
            type: 'category',
            position: 'bottom',
            paddingInner: 0.1,
        },
    ],
    overlays: {
        noData: {
            text: $t('Loading') + '...',
        },
    },
    theme: 'ag-material',
}));

const columnDefs = computed(() => [
    {
        headerName: $t('Month') + '/' + $t('Year'),
        field: 'month',
        initialSort: 'desc',
    },
    {
        headerName: $t('Orders.short'),
        field: 'order_count',
        type: 'numericColumn',
    },
    {
        headerName: $t('Order Lines'),
        field: 'line_count',
        type: 'numericColumn',
    },
    {
        headerName: $t('Articles'),
        field: 'line_sum_quantity',
        type: 'numericColumn',
    },
]);
</script>

<template>
    <DashboardCard>
        <template #title>
            <v-icon class="tw-mr-2" :icon="mdiGift"></v-icon>
            {{ $t('Orders') }}
        </template>
        <div class="tw-h-[300px]">
            <ag-charts-vue :options="options"></ag-charts-vue>
        </div>
        <v-divider></v-divider>
        <v-card-text>
            <ag-grid-vue
                class="ag-theme-material ag-theme-material-dense tw-mt-4"
                dom-layout="autoHeight"
                :column-defs="columnDefs"
                :row-data="orderStats"
                :default-col-def="defaultColumnDefinitions"
            ></ag-grid-vue>
        </v-card-text>
    </DashboardCard>
</template>

<style scoped></style>
