import { ref } from 'vue';
import { watchDebounced } from '@vueuse/core';

//TODO: rewrite into proper composable
const onColumnStateChanged = function (params) {
    if (
        (params.finished ||
            params.type === 'filterChanged' ||
            params.type === 'sortChanged') &&
        params.source !== 'flex' &&
        params.source !== 'api'
    )
        columnState.value = params.api.getColumnState();
};

let tableKey = null;
let stateResponse = null;
let filterResponse = null;
export const getColumnState = function (name, useFilter = false) {
    tableKey = name;
    stateResponse = axios
        .get(route('api.settings.show', tableKey + '_column_state'))
        .then((response) => response.data.value);
    if (useFilter) {
        filterResponse = axios
            .get(route('api.settings.show', tableKey + '_filter'))
            .then((response) => response.data.value);
    } else {
        filterResponse = null;
    }
    return stateResponse;
};

export const stateApplied = ref(false);
export const gridApi = ref(null);
export const columnApi = ref(null);
export const onGridReady = async (params) => {
    gridApi.value = params.api;
    columnApi.value = params.columnApi;

    const columnState = await stateResponse;
    if (columnState !== null) {
        gridApi.value.applyColumnState({
            state: columnState,
            applyOrder: true,
        });
    }
    if (filterResponse !== null) {
        const filterState = await filterResponse;
        if (filterState !== null) {
            gridApi.value.setFilterModel(filterState);
        }
    }
    stateApplied.value = true;
};

const columnState = ref(null);
export const columnStateEvents = {
    columnResized: onColumnStateChanged,
    columnVisible: onColumnStateChanged,
    columnMoved: onColumnStateChanged,
    filterChanged: onColumnStateChanged,
    sortChanged: onColumnStateChanged,
};

watchDebounced(
    columnState,
    (value) => {
        axios.post(route('api.settings.store'), {
            key: tableKey + '_column_state',
            value: value,
        });
        if (filterResponse !== null) {
            axios.post(route('api.settings.store'), {
                key: tableKey + '_filter',
                value: gridApi.value.getFilterModel(),
            });
        }
    },
    { debounce: 300 },
);
