<script setup>
import { mdiPackageVariantClosedCheck, mdiPlus } from '@mdi/js';
import { useForm, usePage } from '@inertiajs/vue3';
import { computed, inject, ref } from 'vue';
import { collect } from 'collect.js';
import ColloExpansionPanelCard from '@/Pages/Pack/Partials/ColloExpansionPanelCard.vue';
import { useDraggable } from '@/lib/composables/draggable';
import { useColloStore } from '@/lib/composables/collo-state.js';

const page = usePage();
const props = defineProps({
    scansByColloId: {
        type: Object,
        required: true,
    },
    canCreateCollo: {
        type: Boolean,
        required: true,
    },
    currentColloId: {
        type: [Number, null],
        required: true,
    },
    processClosed: {
        type: Boolean,
        required: true,
    },
    packagingScanCount: {
        type: Number,
        required: true,
    },
    colloIndexes: {
        type: Object,
        required: true,
    },
});
const emit = defineEmits([
    'collo:added',
    'collo:selected',
    'scan:delete',
    'scan:delete:packaging',
    'scan:update',
    'settings:multi-collo',
]);

const deliveryNote = computed(() => {
    return page.props.pack_process.delivery_note;
});

const linesByLineNumber = computed(() => {
    return collect(deliveryNote.value.lines).keyBy('line_number').all();
});
const packagingByItemNumber = computed(() => {
    return collect(page.props.packaging_barcodes)
        .mapWithKeys((packaging, barcode) => [
            packaging.item_number,
            { ...packaging, barcode },
        ])
        .all();
});

const currentColloIndex = computed(() => {
    return (
        page.props.pack_process.colli.findIndex(
            (collo) => collo.id === props.currentColloId,
        ) + 1
    );
});
const colloCount = computed(() => {
    return page.props.pack_process.colli.length;
});

const promptDimensions = inject('promptDimensions');
const addColloForm = useForm({});
const addCollo = async () => {
    try {
        await promptDimensions(
            currentCollo.value,
            props.scansByColloId[props.currentColloId] ?? [],
        );
    } catch (e) {
        return;
    }

    emit('collo:added');

    addColloForm.post(
        route('pack.colli.add', {
            pack_process: page.props.pack_process.id,
        }),
        {
            only: ['pack_process'],
        },
    );
};

const currentlyEditing = ref(null);
const onEditStart = (colloId) => {
    if (props.processClosed) {
        return;
    }
    currentlyEditing.value = colloId;
};
const onEditStop = () => {
    currentlyEditing.value = null;
};

const onUpdateScan = (oldColloId, newColloId, lineNumber) => {
    const scans = props.scansByColloId[oldColloId]?.filter(
        (scan) => scan.line_number === Number(lineNumber),
    );
    console.log(scans);

    emit('scan:update', lineNumber, oldColloId, newColloId);
};

const colloRefs = ref([]);
const dragFilter = (colloRef) => {
    return colloRef.$attrs['data-collo-id'] !== currentlyEditing.value;
};
const { fromContainer, toContainer, draggingElement, onDragStop } =
    useDraggable(colloRefs, '.draggable', dragFilter);

onDragStop(() => {
    const oldColloId = fromContainer.value?.$attrs['data-collo-id'];
    const newColloId = toContainer.value?.$attrs['data-collo-id'];
    const lineNumber = Number(draggingElement.value.dataset.lineNumber);

    if (oldColloId === newColloId || newColloId === undefined) {
        return;
    }

    onUpdateScan(oldColloId, newColloId, lineNumber);
});

const { savedDimensions } = useColloStore();
const currentCollo = computed(() => {
    return page.props.pack_process.colli.find(
        (collo) => collo.id === props.currentColloId,
    );
});
const currentWeight = computed(() => {
    if (currentCollo.value !== undefined) {
        if (savedDimensions.value[currentCollo.value.id]) {
            if (savedDimensions.value[currentCollo.value.id].weight) {
                return savedDimensions.value[currentCollo.value.id].weight;
            }
        } else {
            if (currentCollo.value.weight !== null) {
                return currentCollo.value.weight;
            }
        }
    }

    const scans = props.scansByColloId[props.currentColloId] ?? [];

    const lineScans = collect(scans)
        .filter((scan) => scan.data.type === 'line')
        .groupBy('line_number')
        .map((scans) => scans.sum('quantity'));

    const packagingScans = scans.filter(
        (scan) => scan.data.type === 'packaging',
    );

    const line_weight = lineScans
        .map((quantity, line_number) => {
            return (
                quantity * linesByLineNumber.value[line_number].weight_per_item
            );
        })
        .sum();
    const packaging_weight = collect(packagingScans)
        .map(
            (scan) => packagingByItemNumber.value[scan.data.item_number].weight,
        )
        .sum();

    return line_weight + packaging_weight;
});
</script>

<template>
    <v-expansion-panel>
        <template #title>
            <v-chip
                class="tw-mr-2"
                variant="tonal"
                color="info"
                @click="emit('settings:multi-collo')"
                >Multi-Collo</v-chip
            >
            <span class="tw-z-50 tw-text-2xl tw-font-semibold tw-uppercase">
                Aktuelles Collo:
                {{
                    currentColloIndex !== 0 ? currentColloIndex : colloCount
                }}/{{ colloCount }}
            </span>
            <span
                v-if="packagingScanCount > 0"
                class="tw-ml-2 tw-text-2xl tw-font-bold tw-text-blue-900"
                >({{ packagingScanCount }} x
                <v-icon size="sm" :icon="mdiPackageVariantClosedCheck"></v-icon
                >)</span
            ><span class="tw-ml-2">{{ currentWeight }} g</span>
            <v-spacer></v-spacer>
            <!--            <AppBarActionsTeleport>-->
            <v-btn
                v-if="!processClosed"
                size="x-large"
                :disabled="!canCreateCollo"
                :loading="addColloForm.processing"
                color="primary"
                :prepend-icon="mdiPlus"
                @keydown.prevent
                @click.stop="addCollo"
                >Collo
            </v-btn>
            <!--            </AppBarActionsTeleport>-->
        </template>

        <v-expansion-panel-text>
            <v-row class="tw-items-stretch">
                <ColloExpansionPanelCard
                    v-for="(collo, index) in page.props.pack_process.colli"
                    ref="colloRefs"
                    :key="collo.id"
                    :process-closed="processClosed"
                    :data-collo-id="collo.id"
                    :index="index + 1"
                    :collo="collo"
                    :can-edit="!processClosed"
                    :editing="!processClosed && currentlyEditing === collo.id"
                    :scans="scansByColloId[collo.id] ?? []"
                    :lines-by-line-number="linesByLineNumber"
                    :packaging-by-item-number="packagingByItemNumber"
                    :selected="currentColloId === collo.id"
                    :collo-indexes
                    @edit:start="onEditStart"
                    @edit:stop="onEditStop"
                    @selected="$emit('collo:selected', $event)"
                    @delete="$emit('scan:delete', $event, collo.id)"
                    @delete:packaging="
                        $emit('scan:delete', $event, collo.id, true)
                    "
                ></ColloExpansionPanelCard>
            </v-row>
        </v-expansion-panel-text>
    </v-expansion-panel>
</template>

<style scoped>
.draggable-container--over :deep(.v-card) {
    border: rgb(var(--v-theme-primary)) solid 2px !important;
    box-sizing: border-box;
}
</style>
