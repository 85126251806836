<script setup>
import { Head, usePage } from '@inertiajs/vue3';
import { mdiPencil, mdiPlus, mdiTrashCan } from '@mdi/js';
import { trans as $t } from 'laravel-vue-i18n';
import LinkVuetify from '@/Components/LinkVuetify.vue';
import LaravelQueryTable from '@/Components/LaravelQueryTable.vue';
import { reactive } from 'vue';

const page = usePage();

const columns = [
    { field: 'name', label: $t('Name') },
    {
        field: 'maillog_customer_numbers',
        label: $t('Customer Number'),
        valueGetter: (row) => row['maillog_customer_numbers'].join(', '),
    },
];

const deleteLoading = reactive({});
</script>

<template>
    <v-container>
        <Head :title="page.props.title"></Head>
        <div class="tw-mb-2">
            <LinkVuetify
                :href="route('warehouse.tenants.create')"
                size="small"
                color="black"
                :prepend-icon="mdiPlus"
                >{{ $t('Create Tenant') }}
            </LinkVuetify>
        </div>

        <LaravelQueryTable
            route-name="warehouse.tenants.index"
            :space="32 + 36"
            :columns="columns"
            :data="page.props.tenants"
        >
            <template #actions="{ row }">
                <LinkVuetify
                    class="tw-mr-2"
                    :href="route('warehouse.tenants.edit', { tenant: row.id })"
                    size="small"
                    color="secondary"
                >
                    <v-icon size="x-large" :icon="mdiPencil"></v-icon>
                </LinkVuetify>
                <LinkVuetify
                    :href="
                        route('warehouse.tenants.destroy', { tenant: row.id })
                    "
                    :loading="deleteLoading[row.id]"
                    method="delete"
                    size="small"
                    color="danger"
                    @start="deleteLoading[row.id] = true"
                    @finish="deleteLoading[row.id] = false"
                >
                    <v-icon size="x-large" :icon="mdiTrashCan"></v-icon>
                </LinkVuetify>
            </template>
        </LaravelQueryTable>
    </v-container>
</template>

<style scoped></style>
