<script setup>
import { computed } from 'vue';

const props = defineProps({
    goodsArrival: {
        type: Object,
        required: true,
    },
});

const customer = computed(() => props.goodsArrival.customer);
</script>

<template>
    <div>
        <h2 class="tw-font-semibold">
            {{ customer.name }}
            <span v-if="goodsArrival.reference" class="tw-font-light"
                >({{ goodsArrival.reference }})</span
            >
            <span>
                - {{ goodsArrival.quantity }}
                {{ goodsArrival.package_type }}</span
            >
        </h2>
    </div>
</template>

<style scoped></style>
