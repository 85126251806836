<script setup>
import { computed, ref } from 'vue';
import { collect } from 'collect.js';
import { onLongPress } from '@vueuse/core';

const props = defineProps({
    line: {
        type: Object,
        required: true,
    },
    scans: {
        type: Array,
        required: true,
    },
    index: {
        type: Number,
        required: true,
    },
});
const emit = defineEmits(['select']);

const count = computed(() => collect(props.scans).sum('quantity'));
const scan = () => {
    emit('select', props.line);
};
const rowRef = ref(null);

onLongPress(
    rowRef,
    () => {
        emit('select', props.line, props.line.quantity - count.value);
    },
    {
        modifiers: {
            prevent: true,
            stop: true,
        },
    },
);
</script>

<template>
    <tr
        ref="rowRef"
        v-wave="{
            trigger: 'row-' + line.line_number,
            color: count / line.quantity === 1 ? 'red' : 'green',
        }"
        v-wave-trigger:[`row-${line.line_number}`]
        class="even:tw-bg-gray-400 even:tw-bg-opacity-10"
        :class="{
            'tw-bg-lime-600 odd:tw-bg-opacity-30 even:tw-bg-lime-500 even:tw-bg-opacity-20':
                count / line.quantity === 1,
        }"
        @click="scan"
    >
        <td class="tw-border tw-border-y-2 tw-border-solid tw-py-1">
            <div
                class="tw-relative tw-flex tw-w-full tw-text-3xl tw-font-semibold"
            >
                <div class="tw-w-full">
                    <div class="tw-flex tw-w-full tw-justify-between">
                        <div>
                            <span
                                class="tw-mr-1 tw-inline-block tw-rounded-xl tw-border-2 tw-border-solid tw-px-2 tw-leading-none tw-text-orange-600"
                            >
                                {{ index + 1 }}
                            </span>
                            <span class="tw-text-blue-700">
                                {{ line.bin_code }}</span
                            >
                        </div>
                        <span>
                            <span class="tw-text-orange-600">{{
                                line.sku
                            }}</span></span
                        >
                    </div>
                    <span class="tw-font-bold">{{ line.description }} </span
                    ><br />
                    {{ line.description2 }}
                </div>
            </div>
            <div
                class="tw-relative tw-flex tw-w-full tw-justify-between tw-whitespace-nowrap tw-text-3xl tw-font-bold"
            >
                <div>
                    <span>{{ line.quantity }} {{ line.unit }}</span>
                    <span v-if="line.batch"> - </span>
                    <span v-if="line.batch" class="tw-text-orange-500"
                        >CHARGE {{ line.batch }}</span
                    >
                </div>
                <span>{{ count }}/{{ line.quantity }}</span>
            </div>
        </td>
    </tr>
</template>

<style scoped></style>
