<script setup>
import AutocompleteField from '@/Components/AutocompleteField.vue';
import { ref } from 'vue';
import { trans as $t } from 'laravel-vue-i18n';
import { watchDebounced } from '@vueuse/core';

const article = defineModel({
    type: [String, null],
    required: true,
});
const items = ref([]);
const search = ref(null);
const itemsLoading = ref(false);

const fetchItems = async (search) => {
    if (search && search.length >= 3 && search !== article.value) {
        itemsLoading.value = true;
        const response = await axios.get(route('api.maillog.item.index'), {
            params: {
                filter: {
                    customer_number: '',
                    search: search,
                },
            },
        });

        items.value = response.data;
        itemsLoading.value = false;
    }
};
watchDebounced(search, fetchItems, { debounce: 300 });
</script>

<template>
    <AutocompleteField
        v-model="article"
        v-model:search="search"
        :items="items"
        item-title="number"
        item-value="number"
        no-filter
        :loading="itemsLoading"
        :no-data-text="
            $t('Please enter at least 3 characters to search for Articles.')
        "
    >
        <template #item="{ props, item }">
            <v-list-item v-bind="props">
                <template #title>
                    {{
                        item.raw.number +
                        ' ' +
                        item.raw.description +
                        (item.raw.description2
                            ? ' (' + item.raw.description2 + ')'
                            : '')
                    }}
                </template>
            </v-list-item>
        </template>
    </AutocompleteField>
</template>

<style scoped></style>
