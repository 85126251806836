<script setup>
import { mdiMagnify } from '@mdi/js';

const props = defineProps({
    params: {
        type: Object,
        required: true,
    },
});

// eslint-disable-next-line vue/no-setup-props-destructure
const { onShow } = props.params;
</script>

<template>
    <div
        v-if="params.node.level === 0"
        class="tw-flex tw-h-full tw-items-center tw-justify-center"
    >
        <v-btn
            class="tw-mr-2"
            color="info"
            density="comfortable"
            size="small"
            :icon="mdiMagnify"
            @click.prevent="onShow(props.params.data)"
        >
        </v-btn>
    </div>
</template>

<style scoped></style>
