<script setup>
import { mdiFloppy, mdiTrashCanOutline } from '@mdi/js';
import { nextTick, reactive, ref, watch } from 'vue';
import { router, useForm } from '@inertiajs/vue3';
import { useVModel } from '@vueuse/core';
import { cloneDeep, isEqual } from 'lodash';

const props = defineProps({
    modelValue: {
        type: Number,
        default: null,
    },
    addresses: {
        type: Array,
        required: true,
    },
    form: {
        type: Object,
        required: true,
    },
});
const emit = defineEmits(['update:modelValue']);

const addressKeys = [
    'name',
    'name2',
    'address',
    'address2',
    'postal_code',
    'city',
    'country',
    'email',
];
const getCurrentFormAddress = function () {
    const currentFormAddress = {};
    addressKeys.forEach((key) => {
        currentFormAddress[key] = props.form[key] ?? '';
    });
    return currentFormAddress;
};

const addresses = ref(cloneDeep(props.addresses));
addresses.value.sort((a, b) => {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();
    if (nameA < nameB) {
        return -1;
    }
    if (nameA > nameB) {
        return 1;
    }
    return 0;
});

const addressForm = useForm(Object.assign({}, getCurrentFormAddress()));
watch(props.form, () => {
    addressKeys.forEach((key) => {
        addressForm[key] = props.form[key] ?? '';
    });
});

const createdAddressId = () =>
    addresses.value
        .toReversed()
        ?.find((address) =>
            addressKeys.every(
                (key) => addressForm[key] === (address[key] ?? ''),
            ),
        )?.id;

const selectedAddress = useVModel(props, 'modelValue', emit);
const saveAddress = () => {
    addressForm.post(route('address.store'), {
        only: ['addresses', 'errors', 'errorBags', 'jetstream'],
        preserveScroll: true,
        onSuccess() {
            addresses.value = cloneDeep(props.addresses);
            selectedAddress.value = createdAddressId();
        },
    });
};

watch(selectedAddress, (addressId) => {
    if (addressId !== null) {
        nextTick(() => {
            addressForm.defaults(addressForm.data());
            addressForm.isDirty = false;
        });
    }
});

const notNullish = (value) =>
    value !== null && value !== undefined && value.trim() !== '';
const getItemTitle = (address) =>
    [address.name, address.name2].filter(notNullish).join(', ');
const getItemSubtitle = (address) => {
    const addressLines = [address.address, address.address2]
        .filter(notNullish)
        .join(', ');
    const city = [address.postal_code, address.city]
        .filter(notNullish)
        .join(' ');
    const country = address.country;
    return [addressLines, city, country].filter(notNullish).join(', ');
};

const deletingAddress = ref(false);
const deletingAddressLocal = reactive({});
const deleteAddress = (index) => {
    const address_id = addresses.value[index].id;
    if (!deletingAddress.value) {
        router.delete(route('address.destroy', address_id), {
            only: ['addresses'],
            preserveState: true,
            preserveScroll: true,
            onSuccess: () => {
                addresses.value = cloneDeep(props.addresses);
                onDeleted(address_id);
            },
            onFinish() {
                deletingAddressLocal[index] = false;
                deletingAddress.value = false;
            },
        });
    }

    deletingAddressLocal[index] = true;
    deletingAddress.value = true;
};
const addressFilterFields = [
    'name',
    'name2',
    'address',
    'address2',
    'email',
    'city',
    'country',
];
const addressFilter = (itemTitle, queryText, item) => {
    queryText = queryText.toLowerCase();
    return addressFilterFields.some((field) => {
        return item.raw[field]?.toLowerCase()?.includes(queryText);
    });
};
const onDeleted = (address_id) => {
    if (selectedAddress.value === address_id) {
        selectedAddress.value = null;
    }

    if (selectedAddress.value === address_id || addresses.value.length === 0) {
        const emptyAddress = {};
        addressKeys.forEach((key) => {
            emptyAddress[key] = '';
        });
        addressForm.defaults(emptyAddress);
        addressForm.isDirty = !isEqual(addressForm.data(), emptyAddress);
    }
};
</script>

<template>
    <v-autocomplete
        v-model="selectedAddress"
        class="tw-mx-6 tw-mt-2"
        item-value="id"
        :item-title="getItemTitle"
        :placeholder="$t('Saved Addresses')"
        :items="addresses"
        :custom-filter="addressFilter"
        variant="plain"
        hide-details
        single-line
        density="comfortable"
    >
        <template #item="{ props: listItemProps, item, index }">
            <v-list-item
                v-bind="listItemProps"
                :title="getItemTitle(item.raw)"
                :subtitle="getItemSubtitle(item.raw)"
            >
                <template #append>
                    <v-btn
                        class="tw-ml-4"
                        density="comfortable"
                        variant="tonal"
                        color="danger"
                        icon
                        :disabled="
                            !deletingAddressLocal[index] && deletingAddress
                        "
                        :loading="
                            deletingAddressLocal.hasOwnProperty(index) &&
                            deletingAddressLocal[index]
                        "
                        @click.stop.prevent="deleteAddress(index)"
                    >
                        <v-icon :icon="mdiTrashCanOutline"></v-icon>
                    </v-btn>
                </template>
            </v-list-item>
        </template>
        <template #append>
            <v-btn
                class="-tw-mt-2"
                :disabled="!addressForm.isDirty"
                density="comfortable"
                variant="tonal"
                color="success"
                icon
                :loading="addressForm.processing"
                @click="saveAddress"
            >
                <v-icon :icon="mdiFloppy"></v-icon>
            </v-btn>
        </template>
    </v-autocomplete>
</template>
