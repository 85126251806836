<script setup>
import { computed } from 'vue';
import { join_names } from '@/lib/helpers.js';

const props = defineProps({
    rows: {
        type: Array,
        required: true,
    },
    scans: {
        type: Array,
        required: true,
    },
    itemsByItemNumber: {
        type: Object,
        required: true,
    },
    unitsByItemNumber: {
        type: Object,
        required: true,
    },
});

const scanQuantityForItemNumber = (itemNumber) => {
    return props.scans
        .filter((scan) => scan.item_number === itemNumber)
        .map((scan) => props.unitsByItemNumber[scan.item_number][scan.unit])
        .reduce((a, b) => a + b, 0);
};

const missingScans = computed(() => {
    return props.rows.map((row) => {
        const scannedQuantity = scanQuantityForItemNumber(row.item_number);
        const missingQuantity = row.quantity - scannedQuantity;

        return {
            ...row,
            quantity: missingQuantity,
            description: join_names(
                props.itemsByItemNumber[row.item_number].Description,
                props.itemsByItemNumber[row.item_number]['Description 2'],
                ' - ',
            ),
        };
    });
});
</script>

<template>
    <v-card class="tw-h-[240px] tw-overflow-auto">
        <v-card-title class="tw-mt-2 tw-uppercase"
            >{{ $t('Missing Items') }}
            <small class="tw-text-gray-400">{{
                $t('without batch')
            }}</small></v-card-title
        >
        <v-card-text>
            <div
                v-for="scan in missingScans"
                :key="scan.id"
                class="tw-flex tw-w-full tw-items-center tw-justify-between tw-p-2 even:tw-bg-gray-100"
            >
                <div>
                    <div>
                        {{ scan.item_number }}
                        {{ scan.description }}
                    </div>
                </div>
                <div>{{ scan.quantity }}</div>
            </div>
        </v-card-text>
    </v-card>
</template>

<style scoped></style>
