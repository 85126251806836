<script setup>
import ActionSection from '@/Components/ActionSection.vue';
import TextField from '@/Components/TextField.vue';
import { useForm, usePage } from '@inertiajs/vue3';
import SelectField from '@/Components/SelectField.vue';
import TextareaField from '@/Pages/Order/TextareaField.vue';
import ItemSelectFormSection from '@/Components/ItemSelectFormSection.vue';
import SaveButton from '@/Components/SaveButton.vue';
import CustomerSelect from '@/Components/CustomerSelect.vue';
import { computed, ref, watchEffect } from 'vue';
import LinkVuetify from '@/Components/LinkVuetify.vue';
import { mdiTrashCan } from '@mdi/js';

const page = usePage();

const shipping_advice_id = computed(
    () => page.props.shipping_advice?.id ?? null,
);

const form = useForm({
    customer_number: page.props.selected_customer_number,
    reference: page.props.shipping_advice?.reference ?? null,
    arrival_date: page.props.shipping_advice?.arrival_date ?? null,
    quantity: page.props.shipping_advice?.quantity ?? null,
    package_type: page.props.shipping_advice?.package_type ?? null,
    comment: page.props.shipping_advice?.comment ?? null,
    lines: page.props.shipping_advice?.lines ?? [],
});

const lines = ref(page.props.shipping_advice?.lines ?? []);
watchEffect(() => {
    form.lines = lines.value.map((line) => {
        return {
            item_number: line.item_number,
            batch: line.batch,
            quantity: line.quantity,
            unit: line.unit,
        };
    });
});
const submitShippingAdviceForm = () => {
    if (shipping_advice_id.value) {
        form.put(route('shipping-advice.update', shipping_advice_id.value));
    } else {
        form.post(route('shipping-advice.store'));
    }
};
</script>

<template>
    <v-container>
        <v-form @submit.prevent="submitShippingAdviceForm">
            <ActionSection class="tw-mt-4">
                <template #title>
                    {{ $t('Create Shipping Advice') }}
                </template>
                <template #description>
                    {{
                        $t(
                            'Enter the necessary information to create a new shipping advice.',
                        )
                    }}
                </template>
                <template #content>
                    <v-row>
                        <v-col cols="12" md="10">
                            <CustomerSelect
                                v-model="form.customer_number"
                            ></CustomerSelect>
                            <TextField
                                v-model="form.reference"
                                :label="$t('Reference')"
                                :error-messages="form.errors.reference"
                            />
                            <TextField
                                v-model="form.arrival_date"
                                type="date"
                                :label="$t('Arrival Date')"
                                :error-messages="form.errors.arrival_date"
                            />
                            <TextField
                                v-model="form.quantity"
                                type="number"
                                :label="$t('Quantity')"
                                :error-messages="form.errors.quantity"
                            />
                            <SelectField
                                v-model="form.package_type"
                                :label="$t('Package Type')"
                                :error-messages="form.errors.package_type"
                                item-value="value"
                                item-title="name"
                                :items="page.props.package_types"
                            />
                            <TextareaField
                                v-model="form.comment"
                                :label="$t('Comment')"
                                :error-messages="form.errors.comment"
                            />
                        </v-col>
                    </v-row>
                </template>
            </ActionSection>
            <ItemSelectFormSection
                v-if="form.customer_number"
                v-model="lines"
                :errors="form.errors"
                :customer-number="form.customer_number"
                :with-batch="true"
                :show-all-items="true"
                :table-add-items="true"
            />
            <ActionSection>
                <template #title>{{ $t('Actions') }}</template>
                <template #content
                    ><SaveButton
                        class="tw-mr-2"
                        :loading="form.processing"
                        :disabled="!form.isDirty"
                    ></SaveButton>
                    <LinkVuetify
                        v-if="shipping_advice_id"
                        :prepend-icon="mdiTrashCan"
                        color="danger"
                        method="delete"
                        :href="
                            route('shipping-advice.destroy', {
                                shipping_advice: shipping_advice_id,
                            })
                        "
                        >{{ $t('Delete') }}</LinkVuetify
                    >
                </template>
            </ActionSection>
        </v-form>
    </v-container>
</template>

<style scoped></style>
