<script setup>
import { router } from '@inertiajs/vue3';
import { mergeDataIntoQueryString } from '@inertiajs/core';
import { VBtn, VListItem } from 'vuetify/components';
import { computed, inject, ref, watch } from 'vue';

const props = defineProps({
    as: { type: String, default: 'v-btn' },
    data: { type: Object, default: () => ({}) },
    href: { type: String, required: true },
    method: { type: String, default: 'get' },
    replace: { type: Boolean, default: false },
    preserveScroll: { type: Boolean, default: false },
    preserveState: { type: Boolean, default: null },
    only: { type: Array, default: () => [] },
    headers: { type: Object, default: () => ({}) },
    queryStringArrayFormat: { type: String, default: 'brackets' },
    showLoading: { type: Boolean, default: false },
    onBefore: { type: Function, default: () => {} },
    onSuccess: { type: Function, default: () => {} },
    confirm: { type: Function, default: null },
    icon: { type: String, default: undefined },
});

const closeDrawer = inject('closeDrawer', null);
const method = props.method.toLowerCase();

const [ihref, idata] = [ref(null), ref(null)];
watch(
    [() => props.href, () => props.data],
    () => {
        [ihref.value, idata.value] = mergeDataIntoQueryString(
            method,
            props.href || '',
            props.data,
            props.queryStringArrayFormat,
        );
    },
    { immediate: true },
);

const is = computed(() => {
    switch (props.as) {
        case 'v-btn':
            return VBtn;
        case 'v-list-item':
            return VListItem;
        default:
            return VBtn;
    }
});

const emit = defineEmits(['start', 'success', 'finish']);

const loading = ref(false);
const visit = async () => {
    if (typeof closeDrawer === 'function') {
        closeDrawer();
    }

    if (props.confirm && !(await props.confirm())) {
        return;
    }

    router.visit(ihref.value, {
        method,
        data: idata.value,
        replace: props.replace,
        preserveScroll: props.preserveScroll,
        preserveState: props.preserveState,
        only: props.only,
        headers: props.headers,
        onBefore: props.onBefore,
        onStart: () => {
            loading.value = true;
            emit('start');
        },
        onSuccess: props.onSuccess,
        onFinish: () => {
            loading.value = false;
            emit('finish');
        },
    });
};
</script>

<template>
    <component
        :is="is"
        v-if="!icon"
        :loading="showLoading ? loading : false"
        :href="href"
        @click.prevent="visit"
        ><slot v-if="!icon" />
    </component>
    <component
        :is="is"
        v-else
        :icon
        :loading="showLoading ? loading : false"
        :href="href"
        @click.prevent="visit"
    ></component>
</template>

<style scoped></style>
