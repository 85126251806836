<script setup>
import { mdiMagnify } from '@mdi/js';

const props = defineProps({
    // eslint-disable-next-line vue/require-default-prop
    params: {
        type: Object,
        required: true,
    },
});

// eslint-disable-next-line vue/no-setup-props-destructure
const { onShow } = props.params;
</script>

<template>
    <div class="tw-flex tw-h-full tw-items-center tw-justify-center">
        <v-btn
            v-if="props.params.data.order_number !== ''"
            class="tw-mr-2"
            color="info"
            density="comfortable"
            size="small"
            :icon="mdiMagnify"
            @click.prevent="onShow(props.params.data.order_number)"
        >
        </v-btn>
        <!--        <v-btn-->
        <!--            size="small"-->
        <!--            color="primary"-->
        <!--            @click.prevent="onEdit(props.params.data.order_number)"-->
        <!--            >Edit</v-btn-->
        <!--        >-->
    </div>
</template>
