<script setup>
import { Head } from '@inertiajs/vue3';
import backgroundImage from '@/../image/maillog_background.jpg?url';
import LogoWhite from '@/../svg/maillog_logo_white.svg';
import { onMounted } from 'vue';

const backgroundImageStyle = {
    'background-image': `url("${backgroundImage}")`,
};
onMounted(() => {});
</script>

<template>
    <Head :title="$t('Welcome')"></Head>

    <div
        class="tw-opacity-30 tw-bg-cover tw-absolute tw-inset-0 tw-z-0"
        :style="backgroundImageStyle"
    ></div>
    <div class="tw-h-full tw-flex tw-items-center tw-justify-center">
        <v-card width="400">
            <div
                class="tw-w-full tw-h-52 tw-bg-cover tw-flex tw-justify-center tw-items-center tw-p-2 sm:tw-p-6"
                :style="backgroundImageStyle"
            >
                <LogoWhite class="tw-fill-white"></LogoWhite>
            </div>
            <v-card-title> Willkommen im neuen Kundenportal! </v-card-title>
            <v-card-text class="tw-text-base">
                <p class="tw-mb-2">
                    Fast alle Ihre Wünsche werden hier erfüllt. Für den Rest
                    kontaktieren Sie bitte unseren Kundendienst:
                </p>
                <ul class="tw-list-none tw-mb-2">
                    <li>
                        <b>mail: </b
                        ><a href="mailto:logistik@maillog.at"
                            >logistik@maillog.at</a
                        >
                    </li>
                    <li>
                        <b>tel: </b><a href="tel:02236-312105">02236/312105</a>
                    </li>
                </ul>
                <p>Wir wünschen Ihnen einen erfolgreichen Tag!</p>
                <p class="tw-font-bold">Ihr Maillog-Team</p>
            </v-card-text>
        </v-card>
        <!--        <div
            class='tw-max-w-7xl tw-mx-auto sm:tw-px-6 lg:tw-px-8 tw-text-center'
        >
            <Logo class='tw-w-44 mb-2'></Logo>
            <v-card>
                <v-toolbar color='primary'>
                    <template #title>
                        <div class='tw-flex tw-items-center tw-justify-center'>
                            <v-icon :icon='mdiHumanGreeting'></v-icon>
                            <span class='tw-whitespace-normal'
                            >Willkommen im neuen Kundenportal!</span
                            >
                        </div>
                    </template>
                </v-toolbar>
                <v-card-text class='tw-text-base'>
                    <p class='tw-mb-2'>
                        Fast alle Ihre Wünsche werden hier erfüllt. Für den Rest
                        kontaktieren Sie bitte unseren Kundendienst:
                        <a href='mailto:logistik@maillog.at'
                        >logistik@maillog.at</a
                        >
                        oder <a href='tel:02236-312105'>02236/312105</a>.
                    </p>
                    <p>Wir wünschen Ihnen einen erfolgreichen Tag!</p>
                    <p class='tw-font-bold'>Ihr Maillog-Team</p>
                </v-card-text>
            </v-card>
        </div>-->
    </div>
</template>
