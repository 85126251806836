<script setup>
import ColloExpansionPanelDetails from '@/Pages/Pack/Partials/ColloExpansionPanelCard.vue';
import { computed, ref } from 'vue';
import { collect } from 'collect.js';
import { usePage } from '@inertiajs/vue3';
import { mdiPackageVariantClosedCheck } from '@mdi/js';

defineProps({
    scans: {
        type: Array,
        required: true,
    },
    packagingScanCount: {
        type: Number,
        required: true,
    },
});
defineEmits(['scan:delete', 'settings:multi-collo']);
const page = usePage();

const deliveryNote = computed(() => {
    return page.props.pack_process.delivery_note;
});

const collo = computed(() =>
    page.props.pack_process.colli.find(
        (collo) => collo.id === page.props.pack_process.current_collo_id,
    ),
);

const linesByLineNumber = computed(() => {
    return collect(deliveryNote.value.lines).keyBy('line_number').all();
});
const packagingByItemNumber = computed(() => {
    return collect(page.props.packaging_barcodes)
        .mapWithKeys((packaging, barcode) => [
            packaging.item_number,
            { ...packaging, barcode },
        ])
        .all();
});

const editing = ref(false);
</script>

<template>
    <v-expansion-panel>
        <template #title>
            <v-chip
                class="tw-mr-2"
                variant="tonal"
                color="alert"
                @click="$emit('settings:multi-collo')"
                >Single</v-chip
            >
            <span class="tw-z-50 tw-text-2xl tw-font-semibold tw-uppercase">
                {{ $t('Items Scanned') }}
                <span
                    v-if="packagingScanCount > 0"
                    class="tw-ml-2 tw-text-2xl tw-font-bold tw-text-blue-900"
                    >({{ packagingScanCount }} x
                    <v-icon
                        size="sm"
                        :icon="mdiPackageVariantClosedCheck"
                    ></v-icon
                    >)</span
                >
            </span>
        </template>
        <template #text>
            <ColloExpansionPanelDetails
                class="tw-p-0"
                :collo="collo"
                :scans="scans"
                :packaging-by-item-number="packagingByItemNumber"
                :lines-by-line-number="linesByLineNumber"
                selected
                :can-edit="false"
                :can-move="false"
                :editing="editing"
                @edit:start="editing = true"
                @edit:stop="editing = false"
                @delete="$emit('scan:delete', $event, collo.id)"
                @delete:packaging="$emit('scan:delete', $event, collo.id, true)"
            ></ColloExpansionPanelDetails>
        </template>
    </v-expansion-panel>
</template>

<style scoped>
:deep(.v-expansion-panel-text__wrapper) {
    padding: 0;
}
</style>
